export const getActorColor = (actor, employees) => {
  if (!actor) return { backgroundColor: "#f3f4f6", color: "#4b5563" }; // gray-100 and gray-600

  const name = actor.userName?.toLowerCase();
  if (name === "system") return { backgroundColor: "#f3f4f6", color: "#4b5563" }; // gray-100 and gray-600
  if (name === "customer") return { backgroundColor: "#e0e7ff", color: "#4f46e5" }; // indigo-100 and indigo-600

  if (actor.id) {
    const employee = employees.find((emp) => emp.id === actor.id);
    if (employee && employee.color && employee.color.value) {
      const bgColor = `${employee.color.value}1A`; // 1A in hex is 10% opacity
      return {
        backgroundColor: bgColor,
        color: "#4b5563",
      };
    }
  }

  return { backgroundColor: "#fefce8", color: "#ca8a04" }; // yellow-50 and yellow-600
};
