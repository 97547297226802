import MuiBasicDropdown from "components/reusable/MuiBasicDropdown";
import { CloseModalIconButton } from "components/NewButtons/CloseModalIconButton";
import Dialogue from "components/modals/Dialogue";
import { useEffect, useState } from "react";
import DisabledButton from "components/buttons/DisabledButton";
import {
  addOrRemoveCustomerTags,
  deleteCustomersFirestore,
} from "utils/firestoreCalls";
import { useSnackBar } from "context/SnackBarContext";
import { useUserContext } from "context/UserContext";
import InputMUI from "components/reusable/InputMUI";

export default function DeleteCustomersModal({
  open,
  selectedRows,
  closeModal,
  setSelectedRows,
  refetchCustomers,
}) {
  const { userData } = useUserContext();
  const { openSnackBar } = useSnackBar();
  const [confirmationInput, setConfirmationInput] = useState("");
  const confirmationText = `I want to delete ${selectedRows?.length} customer${
    selectedRows?.length > 1 ? "s" : ""
  }`;

  // reset confirmation input if modal is closed
  useEffect(() => {
    if (open === false) {
      setConfirmationInput("");
    }
  }, [open]);

  return (
    <Dialogue open={open}>
      <div className="absolute top-2 right-2">
        <CloseModalIconButton handleCancel={closeModal} />
      </div>

      <p className="mb-3">
        You are about to delete {selectedRows?.length || 0} customer
        {selectedRows?.length > 1 ? "s" : ""}. <br />
        <span className="font-bold">This action cannot be undone.</span>
        <br />
        <br />
        To confirm, type:
        <br />
        {confirmationText}
      </p>
      <InputMUI
        label={"Confirmation"}
        placeholder={""}
        value={confirmationInput}
        setValue={setConfirmationInput}
        autoComplete="off"
      />
      <DisabledButton
        text={"Confirm"}
        additionalClasses="mx-auto"
        width="w-24 mt-8"
        disabled={
          confirmationInput.toLowerCase() !== confirmationText.toLowerCase()
        }
        onClick={async () => {
          try {
            closeModal();
            openSnackBar(
              `Deleting customer${selectedRows?.length > 1 ? "s" : ""}...`,
              false,
              false,
              120000
            );
            await deleteCustomersFirestore({
              customerIds: selectedRows,
              userData,
            });
            openSnackBar(
              `${selectedRows?.length} customer${
                selectedRows?.length > 1 ? "s" : ""
              } deleted.`,
              true,
              false,
              120000
            );
            refetchCustomers();
            setSelectedRows([]);
          } catch (e) {
            console.log(
              "error in DeleteCustomersModal associated with deleting customers: ",
              e
            );
            openSnackBar(
              `There was a problem deleting ${
                selectedRows?.length > 1 ? "customers." : "a customer."
              }`,
              false,
              true,
              120000
            );
          }
        }}
      />
    </Dialogue>
  );
}
