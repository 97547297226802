import PanelWrapper from '../lowerLevelComponents/PanelWrapper';
import { localData } from '../../helpers';
import TextAndButtons from '../lowerLevelComponents/TextAndButtons';

const showOnlyCustomersButtons = [
  { label: 'No', data: 'markers' },
  { label: 'Yes', data: 'customers' },
];

const FilterByPersonButtonsCreator = (employees) => {
  return [
    {
      label: 'Show all',
      data: null,
    },
  ].concat(
    employees?.map((employee) => ({
      label: employee.firstName + ' ' + employee.lastName,
      data: employee.id,
    }))
  );
};

export default function FilterPanel({ visible, setDataToDisplay, dataToDisplay, employees, closeFunction }) {
  return (
    <PanelWrapper visible={visible} closeFunction={closeFunction}>
      <TextAndButtons
        title={'Filter by date'}
        buttonData={localData.dateRanges}
        setDataToDisplay={setDataToDisplay}
        dataLabel={'dateRange'}
        disabled={dataToDisplay?.type !== 'markers'}
      />
      <TextAndButtons
        containerStyle={'mt-10'}
        title={'Filter by person'}
        buttonData={FilterByPersonButtonsCreator(employees)}
        setDataToDisplay={setDataToDisplay}
        dataLabel={'uidToFilterBy'}
        disabled={dataToDisplay?.type !== 'markers'}
      />
      <TextAndButtons
        containerStyle={'mt-10'}
        title={'Show only customers'}
        buttonData={showOnlyCustomersButtons}
        setDataToDisplay={setDataToDisplay}
        dataLabel={'type'}
      />
    </PanelWrapper>
  );
}
