import { useCallback, useEffect, useRef, useState } from 'react';

// handles which panel is visible and prevents certain panels from being closed without a confirmation
export default function usePanel() {
  const [panelVisible, setPanelVisibleInternal] = useState({ type: '', data: null, askToConfirmClose: false });
  const panelVisibleRef = useRef(panelVisible);
  const [showPanelConfirmDialogue, setShowPanelConfirmDialogue] = useState(false);
  const suspendedCallDataRef = useRef({});

  // mimic a set state, except invokes a callback after successfully updating panel if one is provided
  const setPanelVisible = useCallback((arg, callback = undefined) => {
    const newState = typeof arg === 'function' ? arg(panelVisibleRef.current) : arg;
    // stop for confirmation conditionally
    if (panelVisibleRef.current.askToConfirmClose && newState.type !== panelVisibleRef.current.type) {
      setShowPanelConfirmDialogue(true);
      suspendedCallDataRef.current = { arg, callback };
      return;
    }
    // update panel
    setPanelVisibleInternal(newState);
    setShowPanelConfirmDialogue(false);
    if (callback) callback();
    suspendedCallDataRef.current = {};
  }, []);

  // confirm changing panel / closing the current with suspended argument and callback
  const confirmChangePanel = (confirm = true) => {
    if (!confirm) {
      setShowPanelConfirmDialogue(false);
      return;
    }
    if (typeof suspendedCallDataRef.current.arg === 'function')
      setPanelVisibleInternal(suspendedCallDataRef.current.arg(panelVisibleRef.current));
    else setPanelVisibleInternal(suspendedCallDataRef.current.arg);
    if (suspendedCallDataRef.current.callback) suspendedCallDataRef.current.callback();
    suspendedCallDataRef.current = {};
  };

  // keep a ref of the current panel visible
  useEffect(() => {
    panelVisibleRef.current = panelVisible;
  }, [panelVisible]);

  return { panelVisible, setPanelVisible, confirmChangePanel, showPanelConfirmDialogue };
}
