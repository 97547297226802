import React, { useContext, useState, useCallback, useEffect } from 'react';
import { UserContext } from 'index';
import { db } from 'utils/firebase';
import { collection, getDocs, query, limit, orderBy, startAfter } from 'firebase/firestore';

// process customer data
const processCustomers = (unprocessedCustomers) => {
  let geocodedCustomersWithId = [];
  // process the customers data so that we will have a pin for each address
  // (sometimes customers will have multiple geocodedObjects in their geocodedAddress field).
  for (const customer of unprocessedCustomers) {
    if (customer.geocodedAddresses && customer.geocodedAddresses.length > 0) {
      customer.geocodedAddresses.forEach((geocodedAddress, index) => {
        // Create a unique ID by combining the customer's ID with the index of the geocodedAddress object
        // const uniqueId = `${customer.customerId}_${index}`;

        // Create a new object with the required information
        const geocodedCustomerWithId = {
          ...customer,
          // customerId: customer.customerId, // changed to not be id + index
          // firstName: customer?.firstName,
          // lastName: customer?.lastName,
          name: customer?.displayName || '', // Assuming 'name' is the field name for customer's name
          address: geocodedAddress.address, // Using the address from the geocodedAddress object
          phoneNumber: customer?.phone?.mobile || '', // Assuming 'phoneNumber' is the field name for customer's phone number
          email: customer?.email?.[0],
          lastJobDate: customer?.lastJob ? new Date(customer.lastJob?.seconds * 1000) : null,
          createdAt: new Date(customer.dateAdded?.seconds * 1000),
          lat: geocodedAddress.lat,
          lng: geocodedAddress.lng,
        };
        // Add the new object to the geocodedCustomersWithId array
        geocodedCustomersWithId.push(geocodedCustomerWithId);
      });
    }
  }
  return geocodedCustomersWithId;
};

export default function useCustomers({ dataToDisplay, setShowLoadingIndicator }) {
  const { userData } = useContext(UserContext);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    // Fetch all customers
    const fetchCustomersRecursive = async (lastDoc = null, accumulatedCustomers = []) => {
      try {
        setShowLoadingIndicator(true);
        const customersCollectionRef = collection(db, 'businesses', userData?.userData?.businessId, 'customers');
        let customerQuery = query(customersCollectionRef, orderBy('dateAdded'), startAfter(lastDoc), limit(1000));
        const customersSnapshot = await getDocs(customerQuery);
        const customers = customersSnapshot.docs.map((doc) => doc.data());
        if (customersSnapshot.empty || customers.length === 0) {
          setShowLoadingIndicator(false);
          return accumulatedCustomers;
        } else {
          // Since we haven't reached the end of the collection, fetch the next page
          accumulatedCustomers.push(...customers);
          const newLastDoc = customersSnapshot.docs[customersSnapshot.docs.length - 1];
          return await fetchCustomersRecursive(newLastDoc, accumulatedCustomers);
        }
      } catch (e) {
        console.log('error fetching customers: ', e);
        setShowLoadingIndicator(false);
        return [];
      }
    };
    (async function () {
      if (dataToDisplay.type === 'customers') {
        const unprocessedCustomers = await fetchCustomersRecursive();
        const geocodedCustomersWithId = processCustomers(unprocessedCustomers);
        setCustomers(geocodedCustomersWithId);
      } else setCustomers([]);
    })();
  }, [dataToDisplay.type, setShowLoadingIndicator, userData]);

  return { customers };
}
