// EstimatePreview.jsx
import React from "react";
import { formatPhoneDisplay } from "utils/formatPhone";
import { splitAddress } from "utils/helpers";
import { formatCurrency } from "../../../utils/helpers";

export const EstimatePreview = ({ userData, estimateData, estimateSettings }) => {
  if (!estimateData || !userData || !estimateSettings) return null;
  const { customer: customerData } = estimateData;
  const businessAddressSplit = splitAddress(userData?.bizData?.address);
  const customerAddressSplit = splitAddress(customerData?.address[0]);

  const subTotal = estimateData.lineItems.reduce((acc, item) => {
    const quantity = parseFloat(item.quantity) || 0;
    const unitPrice = parseFloat(item.unitPrice) || 0;
    const total = quantity * unitPrice;
    return acc + total;
  }, 0);

  const taxTotal = estimateData.lineItems.reduce((acc, item) => {
    const quantity = parseFloat(item.quantity) || 0;
    const unitPrice = parseFloat(item.unitPrice) || 0;
    const total = quantity * unitPrice;
    const taxRate = parseFloat(item?.taxRate?.rate) || 0;
    const tax = total * (taxRate / 100);
    return acc + tax;
  }, 0);

  const total = subTotal + taxTotal;

  return (
    // min-h-[994px]
    <div className="px-8 py-8 pb-20 bg-white shadow-bold w-full relative min-h-[80vh]">
      <div className="flex justify-between ">
        <div className="flex flex-col">
          {/* Estimate Details */}
          <h2 className=" text-xl font-bold">Estimate</h2>
          <div className="mt-2 text-sm">
            {estimateSettings?.estimateNumber && (
              <p>
                <span className="">Number:</span> {estimateData.estimateNumber}
              </p>
            )}
            {estimateSettings?.estimateDate && (
              <p>
                <span className="">Date: </span>
                {estimateData.dateAdded.toDate().toLocaleDateString()}
              </p>
            )}
          </div>
        </div>
        {estimateSettings?.logo ? (
          <>
            {userData?.bizData?.logo ? (
              <img src={userData?.bizData?.logo} alt="Business Logo" className="w-24 h-24" />
            ) : (
              <div className="w-24 h-24">
                <p className="text-center text-xs">
                  No logo found. You can upload it in company profile
                </p>
              </div>
            )}
          </>
        ) : (
          <>
            {estimateSettings?.estimateDate || estimateSettings?.estimateNumber ? (
              <div className="w-24 h-24"></div>
            ) : null}
          </>
        )}
      </div>
      <div className="flex justify-between text-sm mt-4">
        {/* Customer Details */}
        <div>
          {estimateSettings?.customerDisplayName && (
            <p className="font-medium">{customerData.displayName}</p>
          )}
          {/* {estimateSettings?.customerAddress && <p>{customerData.address[0]}</p>} */}
          {estimateSettings?.customerAddress && (
            <div>
              <p className="">{customerAddressSplit[0]}</p>

              {customerAddressSplit?.[1] ? (
                <p className="text-wrap">{customerAddressSplit[1]}</p>
              ) : null}
            </div>
          )}
          {estimateSettings?.customerEmail && customerData.email && <p>{customerData.email[0]}</p>}
          {estimateSettings?.customerPhone && customerData.phone && (
            <p>{formatPhoneDisplay(customerData.phone.mobile)}</p>
          )}
        </div>
        {/* Business Details */}
        <div className="text-right w-2/5 text-wrap">
          {estimateSettings?.businessName && (
            <p className="font-medium">{userData?.bizData?.companyName}</p>
          )}
          {estimateSettings?.businessAddress && (
            <div>
              <p className="">{businessAddressSplit[0]}</p>

              {businessAddressSplit?.[1] ? (
                <p className="text-wrap">{businessAddressSplit[1]}</p>
              ) : null}
            </div>
          )}

          {estimateSettings?.businessEmail && (
            <p className="text-wrap break-words">{userData?.bizData?.email}</p>
          )}
          {estimateSettings?.businessPhone && (
            <p>{formatPhoneDisplay(userData?.bizData?.companyPhone)}</p>
          )}
        </div>
      </div>

      {/* Line Items */}
      <div className="mt-12 text-sm ">
        <table className="w-full">
          <thead className=" border-b border-gray-400 text-xs">
            <tr>
              <th className="text-left  px-2 w-1/2">Line Item</th>
              {estimateSettings?.lineItemsQuantity && (
                <th className="text-center  px-2">Quantity</th>
              )}
              {estimateSettings?.lineItemsUnitPrice && (
                <th className="text-right  px-2">Unit Price</th>
              )}
              {estimateSettings?.lineItemsAmount && <th className="text-right  px-2">Amount</th>}
            </tr>
          </thead>
          <tbody>
            {estimateData.lineItems.map((lineItem, index) => (
              <LineItemRow lineItem={lineItem} estimateSettings={estimateSettings} key={index} />
            ))}
          </tbody>
        </table>
      </div>
      {/* Totals */}
      <div className="mt-4 flex justify-end text-right w-full text-sm">
        <div className=" w-5/12">
          {estimateSettings?.subtotal && (
            <p className="flex justify-between px-2">
              <span className="">Subtotal</span> <span>${formatCurrency(subTotal)}</span>
            </p>
          )}
          {estimateSettings?.tax && (
            <p className="flex justify-between px-2 pt-2">
              <span className="">Tax</span>
              <span>${formatCurrency(taxTotal)}</span>
            </p>
          )}

          {(estimateSettings?.tax || estimateSettings?.subtotal) && (
            <div className="border-b border-gray-300 my-2"></div>
          )}

          <div className="flex justify-between px-2">
            <p className="font-bold">Total</p>
            <p className="">${formatCurrency(total)}</p>
          </div>
        </div>
      </div>
      {/* Footer */}
      <div className="mt-4 text-start absolute bottom-6 w-full left-0 right-0">
        {(estimateSettings?.footerBusinessName || estimateSettings?.footerBusinessWebsite) && (
          <div className="border-b border-gray-200 mx-2" />
        )}
        <div className="flex flex-row mt-2 justify-between">
          <p className="text-2xs text-gray-500 pl-8">
            {estimateSettings?.footerBusinessName ? `${userData?.bizData?.companyName}` : ""}
          </p>

          <p className="text-2xs text-gray-500 pr-8">
            {estimateSettings?.footerBusinessWebsite ? `${userData?.bizData?.companyWebsite}` : ""}
          </p>
        </div>
      </div>
    </div>
  );
};

const LineItemRow = ({ estimateSettings, lineItem }) => {
  const amount = (parseFloat(lineItem.quantity) || 0) * (parseFloat(lineItem.unitPrice) || 0);

  return (
    <tr className="border-b border-gray-300">
      <td className="py-4 px-2 align-top ">
        <p>{lineItem.name}</p>
        {estimateSettings?.lineItemsDescription && lineItem.description && (
          <p className="text-xs mt-1">{lineItem.description}</p>
        )}
      </td>
      {estimateSettings?.lineItemsQuantity && (
        <td className="text-center py-4 px-2 align-top">{lineItem.quantity}</td>
      )}
      {estimateSettings?.lineItemsUnitPrice && (
        <td className="text-right py-4 px-2 align-top">${formatCurrency(lineItem.unitPrice)}</td>
      )}
      {estimateSettings?.lineItemsAmount && (
        // <td className="text-right py-4 px-2 align-top">$100.00</td>
        <td className="text-right py-4 px-2 align-top">${formatCurrency(amount)}</td>
      )}
    </tr>
  );
};
