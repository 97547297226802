import { useEffect, useState } from 'react';

const calculateButtonStyle = ({ i, selected, oddRow, threeOptionSelect }) => {
  let style = '';
  if (!threeOptionSelect) style += i === 0 && oddRow ? ' w-full' : ' w-1/2';
  else style += 'w-1/3';
  if (i === selected) style += ' bg-gray-100';
  return style;
};

export default function TextAndButtons({
  title,
  setDataToDisplay, // for adding filters
  buttonData, // if updating filters [{label: dataField/shown value, data: what to update field to}, ...], otherwise [{label: shown value, onClick: function called when clicked on}, ...]
  containerStyle = '',
  dataLabel,
  disabled = false,
  threeOptionSelect = false,
  initalSelectedButton = 0,
}) {
  const [selected, setSelected] = useState(initalSelectedButton);
  const oddRow = buttonData?.length % 2 === 1;

  return (
    <div className={`flex flex-col max-w-72 mx-auto ${disabled && 'opacity-50'} ` + containerStyle}>
      <p className='self-center mb-3 text-lg font-bold'>{title}</p>
      {buttonData?.length && (
        <div className='flex flex-row items-center flex-wrap'>
          {buttonData?.map((button, i) => (
            <button
              key={'button-in-text-and-button-' + i}
              disabled={disabled}
              onClick={() => {
                setSelected(i);
                if (button.onClick) button.onClick();
                else
                  setDataToDisplay((p) => ({
                    ...p,
                    [dataLabel]: Object.hasOwn(button, 'data') ? button.data : button, // set the state to be equal to the data property or all button data. ex) for date ranges
                  }));
              }}
              className={'rounded ' + calculateButtonStyle({ selected, i, oddRow, threeOptionSelect })}
            >
              {button.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
