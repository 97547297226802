import { colors } from 'theme/colors';
import { FaLocationCrosshairs } from 'react-icons/fa6';

export default function CurrentLocationButton({ map, setUserLocation, errorToast }) {
  const getUserLocation = () => {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            if (map) {
              map.panTo(pos);
              setUserLocation(pos);
            }
          },
          () => {
            errorToast(
              'There was an issue getting your location. Please check your internet connection and try again.'
            );
          }
        );
      } else {
        // console.log("Error: Your browser doesn't support geolocation.");
        errorToast("Error: Your browser doesn't support geolocation.");
      }
    } catch (e) {
      console.log('error with getting current location: ', e);
      errorToast('There was an issue getting your location. Please check your internet connection and try again.');
    }
  };

  return (
    <button
      onClick={getUserLocation}
      className='h-8 w-8 bg-white hover:bg-gray-200 absolute right-[8px] rounded-sm bottom-40 z-[1] flex items-center justify-center'
    >
      <FaLocationCrosshairs size={20} color={colors.gray600} />
    </button>
  );
}
