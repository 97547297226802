// utils/userData.js
export const calculateFreeTrialTimeLeft = (userData) => {
  if (
    userData?.subData?.status !== "manual trial" ||
    !userData?.subData?.manualTrialStart
  )
    return null;

  const trialStart = userData?.subData?.manualTrialStart?.seconds
    ? new Date(userData.subData.manualTrialStart.seconds * 1000)
    : userData?.subData?.manualTrialStart;
  const trialEnd = new Date(trialStart.getTime() + 14 * 24 * 60 * 60 * 1000);
  const now = new Date();
  const diff = trialEnd - now;

  return Math.round(diff / (1000 * 60 * 60 * 24));
};

export const useBannerVisibility = (userData, location) => {
  const isNavBarHidden = () => {
    const alwaysHiddenPaths = [
      "/login",
      "/register",
      "/invoice",
      "/update-subscription",
      "/subscription-success",
      "/new-trial",
      "/review",
      "/view-estimate",
      "/leaderboard",
      "/service-subscription",
      "/register-sales",
      "/new-subscription-success",
      "/estimate-overview",
    ];

    // Always hide NavBar for these paths, regardless of user status
    if (alwaysHiddenPaths.some((path) => location.pathname.startsWith(path))) {
      return true;
    }
    // Specific logic for '/games' route
    if (location.pathname.startsWith("/games")) {
      // Hide NavBar on '/games' if there is no user data (not logged in)
      return !userData;
    }

    // Default to not hiding the NavBar
    return false;
  };

  const getStripeConnectStatus = () => {
    // if (location.pathname.startsWith("/trial-success")) {
    //   return null;
    // }
    if (location.pathname.startsWith("/games")) {
      return false;
    }
    if (userData?.userData?.isAdmin) {
      if (!userData?.bizData?.stripeAccountId) {
        return "None";
      } else if (
        userData.bizData?.stripeAccountId &&
        (!userData.bizData?.stripe_charges_enabled ||
          !userData.bizData?.stripe_payouts_enabled ||
          !userData.bizData?.stripe_details_submitted)
      ) {
        return "Incomplete";
      }
    }
    return null;
  };

  const freeTrialTimeLeft = calculateFreeTrialTimeLeft(userData);

  const isDLCBannerVisible = () => {
    // if (location.pathname.startsWith("/trial-success")) {
    //   return false;
    // }

    if (location.pathname.startsWith("/games")) {
      return false;
    }

    const DLCInfo = userData?.bizData?.DLCInfo;
    return (
      userData?.userData?.isAdmin &&
      userData?.subData?.status === "active" &&
      !(
        DLCInfo?.legalCompanyName &&
        DLCInfo?.DBA &&
        DLCInfo?.EIN &&
        DLCInfo?.EINCountry &&
        DLCInfo?.businessAddress &&
        DLCInfo?.email &&
        DLCInfo?.phone
      )
    );
  };

  return {
    hideNavBar: isNavBarHidden(),
    stripeConnectStatus: getStripeConnectStatus(),
    freeTrialTimeLeft,
    dlcBannerShouldBeVisible: isDLCBannerVisible(),
  };
};
