import MuiBasicDropdown from "components/reusable/MuiBasicDropdown";
import { CloseModalIconButton } from "components/NewButtons/CloseModalIconButton";
import Dialogue from "components/modals/Dialogue";
import { useEffect, useMemo, useRef, useState } from "react";
import DisabledButton from "components/buttons/DisabledButton";
import { addOrRemoveCustomerTags } from "utils/firestoreCalls";
import { useSnackBar } from "context/SnackBarContext";
import { useUserContext } from "context/UserContext";
import CustomerConfirmList from "../lowerLevelComponents/CustomerConfirmList";
import { handleReviewRequests } from "../../helpers";
import { massText } from "utils/twilio";

export default function RequestReviewsModal({
  open,
  selectedRows,
  closeModal,
  allCustomers,
}) {
  const { userData } = useUserContext();
  const { openSnackBar } = useSnackBar();
  const selectedRowsSet = useMemo(() => new Set(selectedRows), [selectedRows]);
  const [customers, setCustomers] = useState(
    allCustomers.filter((customer) => selectedRowsSet.has(customer.customerId))
  );

  const handleSend = async () => {
    try {
      openSnackBar(
        `Queuing review request${customers.length > 1 ? "s" : ""}...`,
        false,
        false,
        120000
      );
      closeModal();

      const { noErrors, sent, notSent } = await handleReviewRequests({
        userData,
        customers,
      });

      if (!noErrors) {
        openSnackBar(
          "There was an error queuing your review requests. Please check your internet connection and try again",
          false,
          true,
          1200000
        );
      } else
        openSnackBar(
          `${sent} review request${sent > 1 ? "s" : ""} queued.${
            notSent
              ? " " +
                notSent +
                " not queued, ensure customers have a mobile number, notification enabled, and a name"
              : ""
          }`,
          true,
          false,
          1200000
        );
    } catch (error) {
      console.log("error queuing message: ", error);
      openSnackBar(
        "There was an error queuing your review requests. " + error,
        false,
        true,
        1200000
      );
      // alert("There was an error sending your review requests: " + error);
    }
  };

  return (
    <Dialogue open={open}>
      <div className="absolute top-2 right-2">
        <CloseModalIconButton handleCancel={closeModal} />
      </div>

      <p className="mb-3">Send review requests: </p>

      <CustomerConfirmList
        customers={customers}
        allCustomers={allCustomers}
        setCustomers={setCustomers}
        crossOffCriteria={(customer) => !!customer.phone?.mobile}
        contextMessage="with a mobile phone number"
        options={{
          phonePath: (customer) => customer.phone?.mobile,
          showEmailIcon: false,
        }}
      />

      <DisabledButton
        text={"Send"}
        additionalClasses="mx-auto"
        width="w-24 mt-8"
        disabled={!customers?.length}
        onClick={handleSend}
      />
    </Dialogue>
  );
}
