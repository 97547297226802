import CancelButton from 'components/buttons/CancelButton';
import NormalButton from 'components/buttons/NormalButton';
import { CloseModalIconButton } from 'components/NewButtons/CloseModalIconButton';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import { useEffect, useState } from 'react';

export default function PanelWrapper({
  children,
  visible,
  closeFunction,
  title,
  confirmChangePanel,
  showPanelConfirmDialogue,
  expandButton = false,
  expanded = false,
  setExpanded = () => null,
}) {
  useEffect(() => {
    if (!visible) setExpanded(false);
  }, [visible, setExpanded]);

  return (
    <div
      className={`absolute overflow-y-auto left-0 pl-[70px] h-full transition-all bg-white z-10${
        visible ? '' : ' -translate-x-96'
      }${showPanelConfirmDialogue ? ' overflow-y-hidden' : ''} ${expanded ? 'w-full' : 'w-96'}
      `}
    >
      {/* confirmation window (for some panels) */}
      {showPanelConfirmDialogue && (
        <div
          className={`fixed left-[70px] top-[64px] ${
            expanded ? 'w-[calc(100% - 70px)]' : 'w-[314px]'
          } right-0 bottom-0 h-[100% - 64px] bg-gray-200 bg-opacity-90 z-10 flex justify-center items-center`}
        >
          <div className='bg-white w-64 p-4 rounded-md'>
            <p className='text-center font-semibold'>
              Panel data will be lost
              <br />
              would you like to continue?
            </p>
            <div className='flex flex-row justify-around mt-4'>
              <CancelButton
                handleCancel={() => {
                  confirmChangePanel(false);
                }}
              />
              <NormalButton onClick={confirmChangePanel} text={'Continue'} width='w-24' />
            </div>
          </div>
        </div>
      )}
      <div className='absolute top-1 right-1 rounded-full flex flex-col'>
        <CloseModalIconButton handleCancel={closeFunction} />
        {expandButton && (
          <button className='hover:bg-gray-100 rounded-lg p-1' onClick={() => setExpanded(!expanded)}>
            {expanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </button>
        )}
      </div>
      <div className='px-6 pb-6 pt-8 flex w-full flex-col items-center justify-start min-h-full max-w-5xl mx-auto'>
        {title && <h2 className='mb-4 font-semibold text-xl'>{title}</h2>}
        {children}
      </div>
    </div>
  );
}
