import * as geofire from 'geofire-common';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from 'index';
import { collection, endAt, getCountFromServer, getDocs, orderBy, query, startAt, where } from 'firebase/firestore';
import { db } from 'utils/firebase';
import { useToast } from '@chakra-ui/react';
import { updateMarkerDB } from 'utils/firestoreCalls';

const scaleFactor = 2; // factor for fetching, 1 is a radius of screen height from middle of screen
const MarkerFetchCutoff = 0.03;

const checkIfShouldRefetchBasedOnLocationChange = (lastFetchLocation, currentLocation) => {
  try {
    // if theres no fetch location get markers
    if (!lastFetchLocation) return true;

    // find out how far the center of the screen has moved since last fetch and the distance to a corner
    const centerMovementMeters =
      geofire.distanceBetween(
        [lastFetchLocation.latitude, lastFetchLocation.longitude],
        [currentLocation.latitude, currentLocation.longitude]
      ) * 1000;

    // Calculate the coordinates of the corner of the screen
    let latCorner = currentLocation.latitude + currentLocation.latitudeDelta / 2;
    let lngCorner = currentLocation.longitude + currentLocation.longitudeDelta / 2;

    // check and correct for outside of range (-180, 180) lat/lng caused by calculating corner.
    if (latCorner > 180) latCorner = latCorner - currentLocation.latitudeDelta;
    if (lngCorner > 180) lngCorner = lngCorner - currentLocation.longitudeDelta;

    // Calculate the distance from center to corner using Haversine formula
    let distance = geofire.distanceBetween(
      [currentLocation.latitude, currentLocation.longitude],
      [latCorner, lngCorner]
    );

    // convert km to m
    const distanceToCorner = distance * 1000;

    // if a corner of the screen falls outside the radius of the last fetch, and is zoomed in enough
    if (distanceToCorner + centerMovementMeters > lastFetchLocation.latitudeDelta * 111139 * scaleFactor) return true;
    return false;
  } catch (e) {
    console.log('error in useMarkers location refetch check useEffect: ', e);
  }
};

export default function useMarkers({ dataToDisplay, location, setShowLoadingIndicator, errorToastRetry, errorToast }) {
  const { userData } = useContext(UserContext);
  const toast = useToast();
  const toastOnTimeout = useRef(false); // used to prevent zoomed out toast from re-rendering too quickly
  const [markers, setMarkers] = useState([]);
  const lastFetchLocationRef = useRef(null);
  const allMarkersCountRef = useRef(-1);
  const allMarkersFetchedRef = useRef(false);
  const dataDisplayedRef = useRef(null); // keep in state a snapshot of the data currently displayed, this can be compared to changes is data being displayed

  const fetchLocalMarkers = useCallback(
    async ({ location, dataToDisplay }) => {
      setShowLoadingIndicator(true);
      try {
        // get location/time bounds
        const center = [location.latitude, location.longitude];
        const radiusInM = location.latitudeDelta * 111139 * scaleFactor;
        const startDate = dataToDisplay.dateRange.startDate();

        // query by location bounds and by uuid (if applicable)
        const bounds = geofire.geohashQueryBounds(center, radiusInM);
        const promises = [];
        for (const b of bounds) {
          let q = collection(db, 'businesses', userData.userData.businessId, 'markers');
          if (dataToDisplay.uidToFilterBy) q = query(q, where('uid', '==', dataToDisplay.uidToFilterBy));

          q = query(q, orderBy('coordinate.geohash'), startAt(b[0]), endAt(b[1]));
          promises.push(getDocs(q));
        }

        // Collect all the query results together into a single list
        const snapshots = await Promise.all(promises);
        // filter out false positves & out of range timestamps
        const matchingDocs = [];
        for (const snap of snapshots) {
          for (const doc of snap.docs) {
            const lat = doc.get('coordinate.latitude');
            const lng = doc.get('coordinate.longitude');
            const createdAt = doc.get('createdAt');
            const JSDateCreatedAt = new Date(createdAt.seconds * 1000 + createdAt.nanoseconds / 1000000);

            // filter out a few false positives due to GeoHash accuracy
            const distanceInKm = geofire.distanceBetween([lat, lng], center);
            const distanceInM = distanceInKm * 1000;
            if (distanceInM <= radiusInM && JSDateCreatedAt >= startDate) {
              matchingDocs.push(doc.data());
            }
          }
        }
        setMarkers(matchingDocs);
      } catch (err) {
        errorToastRetry({
          retryFunction: () => fetchLocalMarkers({ location, dataToDisplay }),
        });
      } finally {
        setShowLoadingIndicator(false);
      }
    },
    [errorToastRetry, setShowLoadingIndicator, userData.userData.businessId]
  );

  const fetchAllMarkers = useCallback(async () => {
    setShowLoadingIndicator(true);
    try {
      // query user docs matching selected user ID
      let q = collection(db, 'businesses', userData.userData.businessId, 'markers');
      const startDate = dataToDisplay.dateRange.startDate();
      if (dataToDisplay.uidToFilterBy) {
        q = query(q, where('uid', '==', dataToDisplay.uidToFilterBy));
      }
      q = query(q, where('createdAt', '>=', startDate));

      let snapshot = await getDocs(q);

      setMarkers(snapshot?.docs?.map((doc) => doc.data()));
    } catch (err) {
      console.log('problem with fetchAll markers in useMarkers: ', err);
      errorToastRetry({
        retryFunction: () => fetchAllMarkers(),
      });
      return [];
    } finally {
      setShowLoadingIndicator(false);
    }
  }, [
    dataToDisplay.dateRange,
    dataToDisplay.uidToFilterBy,
    errorToastRetry,
    setShowLoadingIndicator,
    userData.userData.businessId,
  ]);

  const getMarkerCount = useCallback(async () => {
    try {
      const snapshot = await getCountFromServer(
        query(collection(db, 'businesses', userData.userData.businessId, 'markers'))
      );
      const count = snapshot.data().count;
      allMarkersCountRef.current = count;
    } catch (e) {
      errorToastRetry({ retryFunction: getMarkerCount });
    }
  }, [errorToastRetry, userData.userData.businessId]);

  const updateMarker = (marker) => {
    try {
      // update db then state, then ref
      updateMarkerDB({ marker, businessId: userData.userData.businessId });
      setMarkers((prev) => prev?.map((el) => (el.id === marker.id ? marker : el)));
    } catch (e) {
      console.log('error updating marker from useMarkers: ', e);
      errorToast('There was an issue updating this marker. Please check your connection and try again.');
    }
  };

  // run on initial load and location change to fetch markers
  useEffect(() => {
    (async () => {
      // get marker count and check for early return
      if (allMarkersCountRef.current === -1) await getMarkerCount();
      if (dataToDisplay.type !== 'markers') return;
      // fetch all marker conditions and logic
      if (dataToDisplay.dateRange.label === 'Today' || allMarkersCountRef.current < 1000) {
        if (!allMarkersFetchedRef.current || dataDisplayedRef.current !== dataToDisplay) {
          await fetchAllMarkers();
          allMarkersFetchedRef.current = true;
          dataDisplayedRef.current = dataToDisplay;
        }
        return;
      }
      // attempt to fetch local markers below
      allMarkersFetchedRef.current = false;
      // call or reset too zoomed out toast
      if (location.latitudeDelta >= MarkerFetchCutoff) {
        if (!toast.isActive('zoomed-out-toast') && !toastOnTimeout.current) {
          toast({
            id: 'zoomed-out-toast',
            title: 'Zoom in to fetch markers.',
            variant: 'subtle',
            duration: 3000,
            isClosable: true,
          });
          toastOnTimeout.current = setTimeout(() => {
            toastOnTimeout.current = false;
          }, 15000);
        }
        return;
      }
      if (toastOnTimeout.current) {
        clearTimeout(toastOnTimeout.current);
        toastOnTimeout.current = false;
        toast.close('zoomed-out-toast');
      }
      // fetch local markers if location has moved outside fetch radius or data to display has changed
      if (
        checkIfShouldRefetchBasedOnLocationChange(lastFetchLocationRef.current, location) ||
        dataDisplayedRef.current !== dataToDisplay
      ) {
        lastFetchLocationRef.current = location;
        await fetchLocalMarkers({
          location,
          dataToDisplay,
        });
        dataDisplayedRef.current = dataToDisplay;
      }
    })();
    return () => clearTimeout(toastOnTimeout.current);
  }, [dataToDisplay, location, fetchLocalMarkers, fetchAllMarkers, getMarkerCount, toast]);

  return { markers, updateMarker };
}
