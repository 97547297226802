import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { node } from "../../../constants/constants";

import NormalButton from "components/buttons/NormalButton";
import { FaToggleOn, FaToggleOff } from "react-icons/fa";
import PhoneInputManualPayment from "features/JobDetails/components/PhoneInputManualPayment";
import { IoCloseSharp } from "react-icons/io5";
import { BiSend } from "react-icons/bi";
import { useUserContext } from "context/UserContext";
import { db } from "utils/firebase";
import { collection, doc, setDoc, serverTimestamp, getCountFromServer } from "firebase/firestore";
import { useSnackBar } from "context/SnackBarContext";

export default function ConfirmModal({ modal, setModal, customerData, newEstimateData }) {
  const { userData } = useUserContext();
  const { openSnackBar } = useSnackBar();

  const [email, setEmail] = useState(customerData?.email?.[0] || "");
  const [shouldSendEmail, setShouldSendEmail] = useState(customerData?.email?.[0] ? true : false);
  const [phone, setPhone] = useState(customerData?.phone?.mobile || "");
  const [shouldSendText, setShouldSendText] = useState(customerData?.phone?.mobile ? true : false);
  const [loading, setLoading] = useState(false);

  // we need this useEffect to properly set the email and phone number when the customerData changes
  useEffect(() => {
    if (customerData) {
      setEmail(customerData?.email?.[0] || "");
      setShouldSendEmail(!!customerData?.email?.[0]);
      setPhone(customerData?.phone?.mobile || "");
      setShouldSendText(!!customerData?.phone?.mobile);
    }
  }, [customerData]);

  const createAndSendEstimate = async () => {
    if (!customerData) {
      alert("Please select a customer first.");
      setModal(false);
      return;
    }
    if (!email && !phone) {
      alert("Please provide an email or phone number");
      return;
    }
    if (!shouldSendEmail && !shouldSendText) {
      alert("Please select at least one method of sending the estimate");
      return;
    }
    if (shouldSendEmail && !email) {
      alert("Please provide an email, or untoggle the email option");
      return;
    }
    if (shouldSendText && !phone) {
      alert("Please provide a phone number, or untoggle the phone option");
      return;
    }

    try {
      setLoading(true);
      const newEstimateRef = doc(
        collection(db, "businesses", userData.userData.businessId, "estimates")
      );
      // add firestore ref to the newestimate data
      newEstimateData.estimateId = newEstimateRef.id;
      newEstimateData.estimateSettings = {
        ...userData.bizData.estimateSettings,
      };

      // also need to add the estimate number here -- fetch number of estimates in estimates collection for business and then add one
      // this will be the default number but then we will allow it so users can add their own number if they want...
      const estimateCollectionRef = collection(
        db,
        "businesses",
        userData.userData.businessId,
        "estimates"
      );
      const estimateCountSnapshot = await getCountFromServer(estimateCollectionRef);
      const estimateCount = estimateCountSnapshot.data().count;
      newEstimateData.estimateNumber = estimateCount + 1;

      const response = await fetch(`${node}/estimate/estimate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          estimate: newEstimateData,
          businessName: userData.bizData.companyName,
          businessEmail: userData.bizData.email,
          businessPhone: userData.bizData.phone,
          businessAddress: userData.bizData.address,
          emailToSend: shouldSendEmail ? email : null,
          phoneToSend: shouldSendText ? phone : null,
          bizId: userData.userData.businessId,
        }),
      });

      const result = await response.json();

      console.log("result", result);

      if (!response.ok) {
        throw new Error(result.message || "Network response was not ok.");
      }

      if (result.message !== "Email sent") {
        throw new Error(result.message || "Unexpected response from the server.");
      }

      // could probably handle adding the doc to firestore on the backend... and above we should turn that into a firebase function, where we sent the estimate and then save it.. bc we want to ensure that it actually sends right?
      // since it was sent successfuly we can now add it to firestore
      // with invoice we just build it first and then fire it off to the client without waiting for a response.. so to improve responsiveness we could probably do something like that here..
      // just handle creating it and then if something goes wrong we will open up the universal snackbar so the user knows that something went wrong

      newEstimateData.sentInfo = {
        email: shouldSendEmail,
        phone: shouldSendText,
        date: serverTimestamp(),
      };
      await setDoc(newEstimateRef, newEstimateData);

      //   setSuccess(true);
      openSnackBar("Estimate sent successfully", true);
      setLoading(false);
      // setTimeout(() => {
      //   navigate("/schedule");
      // }, 5000);
    } catch (error) {
      console.error(error);
      alert(error.message || "An error occurred while sending the email");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={modal}
      onClose={() => setModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[500px] bg-white shadow-bold p-10 py-8 rounded-md">
        <button
          className="absolute top-3 right-3 hover:bg-gray-100 rounded-lg p-1"
          onClick={() => setModal(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
          </svg>
        </button>
        <div className="flex flex-col">
          <div className="flex flex-row gap-2 items-center mb-5">
            <h1 className="text-xl font-bold">Send Estimate</h1>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-row items-center ">
              <label className="text-gray-500 text-sm ml-2 ">Email for estimate</label>
              {shouldSendEmail ? (
                <button onClick={() => setShouldSendEmail(false)}>
                  <FaToggleOn className="text-green-400 text-lg ml-2" />
                </button>
              ) : (
                <button onClick={() => setShouldSendEmail(true)}>
                  <FaToggleOff className="text-gray-500 text-lg ml-2 " />
                </button>
              )}
            </div>
            <input
              type="text"
              className="border border-gray-300 rounded-md p-3 outline-none "
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="flex flex-row items-center mt-2">
            <label className="text-gray-500 text-sm ml-2">Phone for estimate</label>
            {shouldSendText ? (
              <button onClick={() => setShouldSendText(false)}>
                <FaToggleOn className="text-green-400 text-lg ml-2" />
              </button>
            ) : (
              <button onClick={() => setShouldSendText(true)}>
                <FaToggleOff className="text-gray-500 text-lg ml-2" />
              </button>
            )}
          </div>
          <PhoneInputManualPayment label="Phone for estimate" value={phone} setValue={setPhone} />
        </div>
        {/* <button
        disabled={loading || success}
        onClick={sendReceipt}
        className=" h-10 mt-4 w-32 flex items-center justify-center bg-gray-900 text-white hover:text-yellow-100 rounded-md shadow-md"
      >
        {success ? (
          <span>✅</span>
        ) : (
          <>
            {loading ? (
              <ImSpinner className="animate-spin-slow" />
            ) : (
              <span>Send Receipt</span>
            )}
          </>
        )}
      </button> */}
        <NormalButton
          loading={loading}
          disabled={loading}
          //   success={success}
          onClick={createAndSendEstimate}
          text="Send"
          additionalClasses="mt-4"
          width="w-32"
          icon={<BiSend className="text-lg" />}
          iconPosition="right"
        />
      </div>
    </Modal>
  );
}
