import { db } from "utils/firebase";
import { UserContext } from "context/UserContext";
import { collection, getCountFromServer } from "firebase/firestore";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { filterCustomersClient, fetchCustomers } from "../helpers";
import algoliasearch from "algoliasearch";

export default function useCustomers({ setSelectedRows }) {
  const { userData } = useContext(UserContext);
  const [customers, setCustomers] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    amount: 25,
    lastDoc: null,
  }); // { searchInput: String, orderBy: [field, 'asc' || 'desc'] , createdAtStartDate: null, createdAtEndDate: null, lastJobStartDate: null, lastJobEndDate: null, city: '', tag: '', amount: number, lastDoc: null }
  const searchCustomersLoadingRef = useRef(false);
  const lastDocHistoryRef = useRef([]); // use this when returning to prev pages, the first page has no lastDoc
  const [totalCustomersCount, setTotalCustomersCount] = useState(0);
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [fetchType, setFetchType] = useState(""); // "searchInput" || "all" || "paginated"
  const [totalCustomersInDB, setTotalCustomersInDB] = useState(-1); // -1 signifies that the count has not been fetched yet

  const [refetchTrigger, setRefetchTrigger] = useState(false); // flip between true and false to trigger a refetch of customers

  // create a memorized index for customer search, client permissions and a ref to customers.
  const index = useMemo(() => {
    const client = algoliasearch(
      process.env.REACT_APP_ALGOLIA_APP_ID,
      process.env.REACT_APP_ALGOLIA_SEARCH_KEY
    );
    return client.initIndex("customers");
  }, []);
  const { notAbleToViewCustomers, customerCollectionRef } = useMemo(
    () => ({
      notAbleToViewCustomers:
        userData?.userData?.customPermissions?.view_all_customers === false,
      customerCollectionRef: collection(
        db,
        "businesses",
        userData.userData.businessId,
        "customers"
      ),
    }),
    [userData]
  );

  const refetchCustomers = async () => {
    setRefetchTrigger((p) => !p);
  };

  const nextPage = async (pageNumber) => {
    try {
      // console.log("next page called: ", filterOptions, pageNumber);
      // fetch page from server else client
      if (fetchType === "paginated") {
        setLoadingCustomers(true);
        let customers, lastDoc;

        // if fetching from server, fetch previous page else a new one
        if (pageNumber < lastDocHistoryRef.current.length) {
          customers = allCustomers.slice(
            pageNumber * filterOptions.amount,
            (pageNumber + 1) * filterOptions.amount
          );
          lastDoc = lastDocHistoryRef.current[pageNumber - 1];
        } else {
          // console.log("fetching customers to turn page");
          ({ customers, lastDoc } = await fetchCustomers({
            filterOptions: {
              ...filterOptions,
              lastDoc:
                lastDocHistoryRef.current[lastDocHistoryRef.current.length - 1],
            },
            userData,
          }));
          lastDocHistoryRef.current = [...lastDocHistoryRef.current, lastDoc];
          setAllCustomers((p) => [...p, ...customers]);
        }
        // console.log('about to set customers in useCustomers: ', customers);
        setCustomers(customers);
      } else {
        console.log("turning page, getting client side customers");
        const newPage = allCustomers.slice(
          pageNumber * filterOptions.amount,
          (pageNumber + 1) * filterOptions.amount
        );
        setCustomers(newPage);
      }
    } catch (e) {
      console.log("error in nextPage function of useCustomers: ", e);
    } finally {
      setLoadingCustomers(false);
    }
  };

  const searchCustomers = useCallback(
    async (filterOptions) => {
      if (notAbleToViewCustomers) return;
      if (!filterOptions) return;
      if (searchCustomersLoadingRef.current) return;

      const businessId = userData.userData.businessId;

      try {
        searchCustomersLoadingRef.current = true;
        const { hits } = await index.search(filterOptions.searchInput, {
          filters: `businessId:${businessId}`,
        });
        return (
          hits?.map((customer) => ({
            ...customer,
            id: customer.objectID,
          })) || []
        );

        // can use algolia with other filters, could impliment
        // const date = new Date('2024-09-26T15:01:38Z'); // Specific date
        // const timestampInMilliseconds = date.getTime(); // Convert to milliseconds
        // const { hits } = await index.search(customerSearchInput, {
        //   filters: `businessId:${businessId} AND firstName:Grayson AND lastName:Sporer AND dateAdded < ${timestampInMilliseconds}`,
        // });
        // setPreFilteredCustomers(hits);
      } catch (err) {
        console.error("Error with search", err);
      } finally {
        searchCustomersLoadingRef.current = false;
      }
    },
    [
      index,
      notAbleToViewCustomers,
      searchCustomersLoadingRef,
      userData.userData.businessId,
    ]
  );

  // fetches customer on filter change or refetch trigger
  useEffect(() => {
    if (!userData || !filterOptions) return;
    (async () => {
      try {
        setLoadingCustomers(true);
        // reset all customers and last docs
        setAllCustomers([]);
        setSelectedRows([]); // We reset selected rows because they are only ID's of customers stored in allCustomers
        lastDocHistoryRef.current = [];
        let lastDoc, totalCount, clientSideFilters, localFetchType;
        let customers = [];
        // if theres a search input want to use algolia search then apply client side filters otherwise fetch them from firebase
        if (filterOptions.searchInput) {
          customers = await searchCustomers(filterOptions);
          if (customers === undefined) return; // algolia returns undef if input changes quickly, skip these calls
          setAllCustomers(customers);
          totalCount = customers.length;
          customers = customers.slice(0, filterOptions.amount);
          localFetchType = "searchInput";
        } else {
          ({
            customers,
            lastDoc,
            totalCount,
            clientSideFilters,
            localFetchType,
          } = await fetchCustomers({
            filterOptions,
            userData,
          }));
          setAllCustomers(customers);
          // if fetched all customers by criteria we know there are client side filters to apply
          if (localFetchType === "all") {
            customers = filterCustomersClient(customers, clientSideFilters);
            setAllCustomers(customers);
            totalCount = customers.length;
            customers = customers.slice(0, filterOptions.amount);
          }
        }

        setFetchType(localFetchType);
        setCustomers(customers);
        setTotalCustomersCount(totalCount);
        lastDocHistoryRef.current = [...lastDocHistoryRef.current, lastDoc];
      } catch (e) {
        console.log("error in useCustomers hook: ", e);
      } finally {
        setLoadingCustomers(false);
      }
    })();
  }, [
    filterOptions,
    userData,
    customerCollectionRef,
    searchCustomers,
    setLoadingCustomers,
    setFetchType,
    refetchTrigger,
  ]);

  // get total customer count in DB associated with business
  useEffect(() => {
    (async () => {
      const customerCollectionRef = collection(
        db,
        "businesses",
        userData.userData.businessId,
        "customers"
      );
      const totalCustomersInDB = (
        await getCountFromServer(customerCollectionRef)
      ).data().count;
      setTotalCustomersInDB(totalCustomersInDB);
    })();
  }, [userData.userData.businessId]);

  return {
    customers,
    setCustomers,
    setFilterOptions,
    filterOptions,
    nextPage,
    totalCustomersCount,
    searchCustomers,
    loadingCustomers,
    fetchType,
    allCustomers,
    refetchCustomers,
    totalCustomersInDB,
  };
}
