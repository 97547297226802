import React, { useState, useEffect, useRef, useContext } from 'react';
import { useUserContext } from 'context/UserContext';
import { Link, useLocation } from 'react-router-dom';
import NavbarDropdown from 'components/other/NavBarDropdown';
import NewDropdown from 'components/other/NewDropdown';
import QuestionMarkDropDown from 'components/other/QuestionMarkDropDown';
import { IoMenu } from 'react-icons/io5';
import AlgoliaSearch from 'components/other/AlgoliaSearch';
import { Menu } from '@headlessui/react';
import { FiMenu } from 'react-icons/fi';
import { BsThreeDots } from 'react-icons/bs';

export default function NavBar() {
  const { bannerVisible, userData } = useUserContext();
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState(pathname);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (pathname.includes('/messages')) {
      setActiveTab('/messages');
    } else if (pathname.includes('/customers')) {
      setActiveTab('/customers');
    } else {
      setActiveTab(pathname);
    }
  }, [pathname]);

  const handleTabClick = (to) => {
    setActiveTab(to);
  };
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const mobileMenuButtonRef = useRef();

  // useEffect that listens to clicks outside of the mobile menu and closes it
  const mobileRef = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu

      if (
        mobileMenuOpen &&
        mobileRef.current &&
        !mobileRef.current.contains(e.target) &&
        !mobileMenuButtonRef.current.contains(e.target)
      ) {
        setMobileMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [mobileMenuOpen]);

  // add this to nav if you want to fix the scroll thing -- fixed top-0 left-0 right-0 z-50 --
  // but then we need to add a pt-16 to all the pages with a navbar -- should use this as a chance to refactor and use the page-layout everywhere so that things are reused...
  return (
    <nav className='bg-gray-900 '>
      <div className='flex relative flex-row justify-between shadow-md h-16 items-center py-3 pr-5 pl-5 font-bold ml-12'>
        <Link
          style={{
            position: 'absolute',
            top: '10px',
            left: '-35px',
          }}
          className=' rounded-lg  opacity-80 '
          to='/'
          onClick={() => handleTabClick('/')}
        >
          <img
            src='/images/Homebase3-03.png'
            // className="pt-0.5"
            alt='Homebase Logo'
            width={45}
          />
        </Link>

        <div
          ref={mobileMenuButtonRef}
          className='lg:hidden block hover:bg-gray-800 p-2 rounded-lg opacity-80 hover:opacity-100 cursor-pointer'
          onClick={toggleMobileMenu}
        >
          <IoMenu className='text-xl text-gray-200 ' />
        </div>

        <ul className='lg:hidden flex text-gray-200 flex-row gap-6 items-center'>
          <div className='hidden sm:block'>
            <AlgoliaSearch />
          </div>
          <NewDropdown bannerVisible={bannerVisible} userData={userData} />
          <QuestionMarkDropDown bannerVisible={bannerVisible} />
          <NavbarDropdown bannerVisible={bannerVisible} />
        </ul>

        <ul className='hidden lg:flex flex-row gap-2'>
          {links.map((link) => (
            <li
              className={`my-2 border-b-2 ${
                activeTab === link.to ? ' border-yellow-300/70' : 'border-transparent opacity-80 hover:opacity-100'
              }`}
              key={link.to}
            >
              <Link
                className={`px-2 text-sm rounded-md cursor-pointer ${
                  activeTab === link.to ? 'text-gray-50' : 'text-gray-200 hover:text-gray-50'
                }`}
                to={link.to}
                onClick={() => handleTabClick(link.to)}
              >
                {link.label}
              </Link>
            </li>
          ))}
        </ul>

        {/* This is the components when it is normal sized screen */}
        <div className='hidden lg:flex flex-row  gap-6 items-center'>
          <AlgoliaSearch />
          <div className='hidden lg:block '>
            <ul className='flex text-gray-200 flex-row gap-6 items-center'>
              <NewDropdown bannerVisible={bannerVisible} userData={userData} />
              <QuestionMarkDropDown bannerVisible={bannerVisible} />
              <NavbarDropdown bannerVisible={bannerVisible} />
            </ul>
          </div>
        </div>

        {mobileMenuOpen && (
          <div
            ref={mobileRef}
            className={`lg:hidden fixed ${
              bannerVisible ? 'top-28' : 'top-14'
            } left-0 bg-gray-900 py-2 w-36 px-4 rounded-b-md`}
            style={{
              zIndex: 10000,
            }}
          >
            <ul className='flex flex-col gap-2'>
              {links.map((link) => (
                <li
                  className={`my-2 border-b-2 ${
                    activeTab === link.to ? ' border-yellow-300/70' : 'border-transparent opacity-80 hover:opacity-100'
                  }`}
                  key={link.to}
                >
                  <Link
                    className={`block px-2 py-1 text-sm rounded-md cursor-pointer ${
                      activeTab === link.to ? 'text-gray-50' : 'text-gray-200 hover:text-gray-50'
                    }`}
                    to={link.to}
                    onClick={() => {
                      handleTabClick(link.to);
                      setMobileMenuOpen(false);
                    }}
                  >
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
}

const links = [
  {
    label: 'Home',
    to: '/',
  },
  {
    label: 'Messages',
    to: '/messages',
  },
  {
    label: 'Schedule',
    to: '/schedule',
  },
  {
    label: 'Customers',
    to: '/customers',
  },
  // {
  //   label: "Add Job",
  //   to: "/addjob",
  // },
];
