import React, { useMemo, useEffect, useState } from "react";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { RiMessageLine } from "react-icons/ri";
import ViewAttachmentsAndTagsAccordion from "./lowerLevelComponents/ViewAttachmentsAndTagsAccordion";
import PaymentMethodCustomerDetails from "./lowerLevelComponents/PaymentMethodCustomerDetails";
import { splitAddress } from "utils/helpers";
import GoogleMaps from "../../../components/GoogleMaps/GoogleMaps";

export default function InfoColumn({
  customer,
  conversation,
  setCustomer,
  loading,
  loadingConversation,
}) {
  const navigate = useNavigate();

  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    if (!loading && !loadingConversation) {
      const timer = setTimeout(() => setShowLoading(false), 100); // Small delay to ensure smooth transition
      return () => clearTimeout(timer);
    } else {
      setShowLoading(true);
    }
  }, [loading, loadingConversation]);
  // const dateAdded = new Date(customer.dateAdded?.seconds * 1000);
  // const lastJob = new Date(customer.lastJob?.seconds * 1000);
  // let lastMessageDate = null;
  // if (conversation?.lastMessageTime?.seconds) {
  //   lastMessageDate = new Date(conversation.lastMessageTime.seconds * 1000);
  // }

  const memoizedDates = useMemo(() => {
    return {
      dateAdded: customer?.dateAdded
        ? new Date(customer.dateAdded.seconds * 1000)
        : null,
      lastJob: customer?.lastJob
        ? new Date(customer.lastJob.seconds * 1000)
        : null,
      lastMessageDate: conversation?.lastMessageTime?.seconds
        ? new Date(conversation.lastMessageTime.seconds * 1000)
        : null,
    };
  }, [customer?.dateAdded, customer?.lastJob, conversation?.lastMessageTime]);

  const { dateAdded, lastJob, lastMessageDate } = memoizedDates;

  const formatPhonenumber = (phone) => {
    if (!phone) return "";

    return (
      "(" +
      phone?.substring(2, 5) +
      ") " +
      phone?.substring(5, 8) +
      "-" +
      phone?.substring(8, 12)
    );
  };

  const LoadingSkeleton = ({ width = "w-full", height = "h-6", className }) => (
    <div
      className={`${width} ${height} bg-gray-200 rounded animate-pulse ${className}`}
    ></div>
  );

  const address1Split = splitAddress(customer?.address?.[0] || "");
  const address2Split = splitAddress(customer?.address?.[1] || "");

  return (
    <div className="flex flex-col ml-5 mr-5 min-h-0 pb-8">
      <div className="flex flex-col bg-white rounded-md shadow-md pb-5">
        <h1 className="bg-gray-800 text-gray-50 text-2xl px-5 py-6 font-semibold rounded-t-md">
          Customer details
        </h1>
        <h1 className="text-gray-900 px-5 py-2 pt-5 font-medium">
          {showLoading ? (
            <LoadingSkeleton width="w-48" />
          ) : (
            `${customer?.firstName || ""} ${customer?.lastName || ""}`
          )}
        </h1>
        {!showLoading ? (
          <div className="px-5 py-2 flex flex-row">
            <div className="flex-1">
              <label className="text-gray-400 text-sm font-medium">
                Date Created
              </label>
              <p className="text-gray-900">
                {customer?.dateAdded
                  ? dateAdded?.toLocaleDateString()
                  : "No date"}
              </p>
            </div>
            <div className="flex-1">
              <label className="text-gray-400 text-sm font-medium">
                Last Job Added
              </label>
              <p className="text-gray-900">
                {customer?.lastJob ? lastJob?.toLocaleDateString() : "No jobs"}
              </p>
            </div>
          </div>
        ) : (
          <div className="px-5 py-2 ">
            <LoadingSkeleton width="w-full" />
            <div className="mt-1" />
            <LoadingSkeleton width="w-full" />
          </div>
        )}

        {showLoading ||
        customer?.phone?.mobile ||
        customer?.phone?.home ||
        customer?.phone?.work ||
        customer?.phone?.additional ? (
          <>
            <hr className="border-gray-200 mt-2" />
            <h1 className="text-gray-900 px-5 py-2 font-medium">Phone</h1>
          </>
        ) : null}

        {showLoading ? (
          <>
            <LoadingSkeleton width="w-40" className="mx-5 my-2" />
          </>
        ) : (
          <>
            {customer?.phone?.mobile && (
              <h1 className="text-gray-900 px-5 py-2 flex flex-row items-center">
                <RiMessageLine
                  className="mr-2 text-lg text-yellow-600 cursor-pointer hover:text-yellow-700"
                  onClick={() => {
                    navigate(`/messages/${customer.phone.mobile}`, {
                      state: {
                        phone: customer?.phone?.mobile,
                        name: customer?.displayName,
                      },
                    });
                  }}
                />
                <span>{formatPhonenumber(customer?.phone?.mobile)}</span>
              </h1>
            )}
            {customer?.phone?.home && (
              <h1 className="text-gray-900 px-5 py-2">
                <span className="">Home: </span>
                {formatPhonenumber(customer?.phone?.home)}
              </h1>
            )}
            {customer?.phone?.work && (
              <h1 className="text-gray-900 px-5 py-2">
                <span className="">Work: </span>
                {formatPhonenumber(customer?.phone?.work)}
              </h1>
            )}
            {customer?.phone?.additional && (
              <h1 className="text-gray-900 px-5 py-2">
                <span className="">Other: </span>
                {formatPhonenumber(customer?.phone?.additional)}
              </h1>
            )}
          </>
        )}

        {showLoading || customer?.email?.[0] || customer?.email?.[1] ? (
          <>
            <hr className="border-gray-200 mt-2" />
            <h1 className="text-gray-900 px-5 py-2 font-medium">Email</h1>
          </>
        ) : null}

        {showLoading ? (
          <LoadingSkeleton width="w-48" className="mx-5 my-2" />
        ) : (
          <>
            {customer?.email?.[0] && (
              <h1 className="text-gray-900 px-5 py-2">
                {customer?.email?.[0]}
              </h1>
            )}
            {customer?.email?.[1] && (
              <h1 className="text-gray-900 px-5 py-2">
                {customer?.email?.[1]}
              </h1>
            )}
          </>
        )}

        {conversation?.lastMessage && (
          <>
            {!showLoading && (
              <>
                <hr className="border-gray-200 mt-2" />
                <h1 className="text-gray-900 px-5 py-2 font-medium">
                  Last Message
                </h1>
              </>
            )}

            {showLoading ? (
              <LoadingSkeleton width="w-48" className="mx-5 my-2" />
            ) : (
              <div className="flex flex-row items-center px-5 py-2 gap-2 ">
                <RiMessageLine
                  className="text-lg text-yellow-600 cursor-pointer hover:text-yellow-700"
                  onClick={() => {
                    navigate(`/messages/${customer.phone.mobile}`, {
                      state: {
                        phone: customer?.phone?.mobile,
                        name: customer?.displayName,
                      },
                    });
                  }}
                />
                <p className=" flex-1 break-all whitespace-pre-wrap line-clamp-1">
                  {conversation?.lastMessage}
                </p>
                <p className="text-gray-400 text-sm font-medium">
                  {conversation?.lastMessageTime &&
                    lastMessageDate &&
                    format(lastMessageDate, "MM/dd/yyyy, h:mm a")}
                </p>
              </div>
            )}
          </>
        )}

        {showLoading || customer?.notes ? (
          <>
            <hr className="border-gray-200 mt-2" />
            <div className="px-5 py-2">
              <h1 className="text-gray-900 font-medium pb-2">Notes</h1>
              {loading ? (
                <>
                  <LoadingSkeleton className="mt-1 mb-1" />

                  <LoadingSkeleton width="w-3/4" className="" />
                </>
              ) : (
                <div className="mt-1 text-gray-900 whitespace-pre-wrap max-h-24 overflow-y-auto">
                  {customer?.notes}
                </div>
              )}
            </div>
          </>
        ) : null}

        {customer?.attachments && customer?.attachments.length > 0 && (
          <hr className="border-gray-200 mt-2" />
        )}
        <ViewAttachmentsAndTagsAccordion
          attachments={customer?.attachments}
          tags={customer?.tags}
        />

        <hr className="border-gray-200 mt-2" />
        {/* Component that will have a button to add card on file, or request card on file. If there is already a card on file, it will show the card details and then give the option to replace or remove it. */}

        <div className="px-5 py-2">
          <h2 className="text-gray-900 font-medium pb-4">Payment Method</h2>
          {showLoading ? (
            <LoadingSkeleton height="h-12" />
          ) : (
            <PaymentMethodCustomerDetails
              customerDetails={customer}
              setCustomerDetails={setCustomer}
            />
          )}
        </div>
        {showLoading || customer?.address?.[0] || customer?.address?.[1] ? (
          <>
            <hr className="border-gray-200 mt-2" />
            <h1 className="text-gray-900 px-5 py-2 font-medium">Address</h1>
          </>
        ) : null}

        {showLoading ? (
          <>
            <LoadingSkeleton width="w-48" className="mx-5 mt-2 mb-1" />
            <LoadingSkeleton width="w-36" className="mx-5 mb-2" />
          </>
        ) : (
          <>
            {address1Split?.[0] && (
              <div className="px-5 p-2">
                <h1 className="text-gray-900 ">
                  <div>
                    <p className="">{address1Split[0]}</p>

                    {address1Split?.[1] ? (
                      <p className="text-wrap">{address1Split[1]}</p>
                    ) : null}
                  </div>
                </h1>
                <GoogleMaps customer={customer} isStreetViewOnly={false} />
              </div>
            )}
            {address2Split?.[0] && (
              <h1 className="text-gray-900 px-5 py-2">
                <div>
                  <p className="">{address2Split[0]}</p>

                  {address2Split?.[1] ? (
                    <p className="text-wrap">{address2Split[1]}</p>
                  ) : null}
                </div>
              </h1>
            )}
          </>
        )}
      </div>
    </div>
  );
}
