import { colors } from '../../theme/colors';
// import { Ionicons, FontAwesome5, AntDesign, FontAwesome, Feather, MaterialCommunityIcons } from '@expo/vector-icons';
// import { BsQuestionCircleFill, Ionicons, AntDesign } from "react-icons/bs";
import { IoHome, IoTime } from 'react-icons/io5';
import { FaXmark } from 'react-icons/fa6';
import { FaFileCircleCheck } from 'react-icons/fa6';
import { FaCheckCircle, FaQuestion, FaSkullCrossbones } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa';
import { useToast } from '@chakra-ui/react';

// marker SVGs
import homeSVG from '../../svg/markerIcons/home.svg';
import xMarkSVG from '../../svg/markerIcons/xMark.svg';
import clockSVG from '../../svg/markerIcons/clock.svg';
import noteSVG from '../../svg/markerIcons/note.svg';
import checkSVG from '../../svg/markerIcons/check.svg';
import questionSVG from '../../svg/markerIcons/question.svg';
import skullSVG from '../../svg/markerIcons/skull.svg';

export const localData = {
  dateRanges: [
    {
      label: 'Today',
      startDate: () => {
        const now = new Date();
        return new Date(now.setHours(0, 0, 0, 0));
      },
    },
    {
      label: 'Last 7 Days',
      startDate: () => {
        const now = new Date();
        return new Date(now.setDate(now.getDate() - 7));
      },
    },
    {
      label: 'Last 30 Days',
      startDate: () => {
        const now = new Date();
        return new Date(now.setDate(now.getDate() - 30));
      },
    },
    {
      label: 'Last 3 Months',
      startDate: () => {
        const now = new Date();
        return new Date(now.setMonth(now.getMonth() - 3));
      },
    },
    {
      label: 'Last 6 Months',
      startDate: () => {
        const now = new Date();
        return new Date(now.setMonth(now.getMonth() - 6));
      },
    },
    {
      label: 'Last Year',
      startDate: () => {
        const now = new Date();
        return new Date(now.setFullYear(now.getFullYear() - 1));
      },
    },
    {
      label: 'All Time',
      startDate: () => {
        const oldDate = new Date('2000-01-01');
        return new Date(oldDate);
      },
    },
  ],
  markerTypes: [
    { id: 0, label: 'Not home', icon: 'home', color: colors.gray100, library: 'Ionicons' },
    { id: 1, label: 'No', icon: 'close', color: colors.red400, library: 'FontAwesome' },
    { id: 2, label: 'Come back', icon: 'time', color: colors.gray100, library: 'Ionicons' },
    { id: 3, label: 'Quote', icon: 'note-check', color: colors.yellow200, library: 'MaterialCommunityIcons' },
    { id: 4, label: 'Sale', icon: 'check-circle', color: colors.green400, library: 'FontAwesome5' },
    { id: 5, label: 'Uncertain', icon: 'question', color: colors.yellow300, library: 'FontAwesome' },
    { id: 6, label: 'Avoid', icon: 'skull-crossbones', color: colors.gray200, library: 'FontAwesome5' },
  ],
};
export const deltasPointsConverter = ({ ne, sw, location }) => {
  try {
    if (ne !== undefined && sw !== undefined) {
      const eastLng = ne.lng();
      const westLng = sw.lng();
      const northLat = ne.lat();
      const southLat = sw.lat();
      const longitudeDelta = eastLng - westLng;
      const latitudeDelta = northLat - southLat;
      return { longitudeDelta, latitudeDelta };
    } else if (location) {
      const halfLatDelta = location.latitudeDelta / 2;
      const halfLngDelta = location.longitudeDelta / 2;
      const sw = {
        lat: location.latitude - halfLatDelta,
        lng: location.longitude - halfLngDelta,
      };
      const ne = {
        lat: location.latitude + halfLatDelta,
        lng: location.longitude + halfLngDelta,
      };
      return { sw, ne };
    } else {
      throw new Error('invalid arguments used in deltasPointsConverter');
    }
  } catch (e) {
    console.log('error in DeltasPointsConverter: ', e);
  }
};

export const getIcon = (library, name, size, color) => {
  // update whites to be colors that will contrast most backgrounds
  let updatedColor = color;
  let updatedName = name;
  let updatedIconSize = size * 0.7;
  if (name === 'home') {
    // not home (house)
    updatedIconSize = updatedIconSize * 0.9;
    updatedColor = colors.gray400;
  }
  if (name === 'time') updatedColor = colors.indigo500; // come back (clock)
  if (color === '#e5e7eb') {
    // avoid (skull)
    updatedIconSize = updatedIconSize * 0.9;
    updatedColor = colors.red600;
  }
  if (color === '#fef08a') {
    // quote (notes)
    updatedColor = colors.lime500;
  }
  if (color === '#fde047') updatedColor = colors.yellow500; // uncertain (question mark)
  if (name === 'check-circle') {
    // sale {checkmark}
    updatedName = 'check';
    updatedIconSize = updatedIconSize * 0.9;
  }

  // convert library/icon name into a react icons component. expo icons and react icons aren't all the same so there are some substitutions
  let ConvertedComponent;
  if (library === 'Ionicons' && name === 'home') ConvertedComponent = IoHome;
  else if (library === 'FontAwesome' && name === 'close')
    ConvertedComponent = FaXmark; // React Icons doens't have this one, substituting xmark
  else if (library === 'Ionicons' && name === 'time') ConvertedComponent = IoTime;
  else if (library === 'MaterialCommunityIcons' && name === 'note-check')
    ConvertedComponent = FaFileCircleCheck; // React Icons also doesn't have this, substituting FileCircleCheck
  else if (library === 'FontAwesome5' && name === 'check-circle') ConvertedComponent = FaCheck;
  else if (library === 'FontAwesome' && name === 'question') ConvertedComponent = FaQuestion;
  else if (library === 'FontAwesome5' && name === 'skull-crossbones') ConvertedComponent = FaSkullCrossbones;
  if (!ConvertedComponent) return null;

  return (
    <div
      style={{
        backgroundColor: updatedColor,
        borderRadius: 9999,
        width: size,
        height: size,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ConvertedComponent size={updatedIconSize} color={colors.gray100} />
    </div>
  );
};

export const isAbleToViewAllMarkers = (userData) =>
  userData?.userData?.userType === 'Admin' ||
  userData?.userData?.userType === 'Manager' ||
  userData?.userData?.userType === 'Team Lead' ||
  userData?.userData?.customPermissions?.view_all_markers === true;

export const isAbleToCreateMaps = (userData) =>
  userData?.userData?.userType === 'Admin' ||
  userData?.userData?.userType === 'Manager' ||
  userData?.userData?.userType === 'Team Lead' ||
  userData?.userData?.customPermissions?.create_maps === true;

export const convertTerritoryFormat = (territories, to) => {
  try {
    let formattedTerritories;
    if (to === 'web') {
      formattedTerritories = territories?.map((territory) => ({
        ...territory,
        coordinates: territory.coordinates.map((coords) => ({
          lat: coords.latitude,
          lng: coords.longitude,
        })),
      }));
    } else if (to === 'mobile') {
      formattedTerritories = territories?.map((territory) => ({
        ...territory,
        coordinates: territory.coordinates.map((coords) => ({
          latitude: coords.lat,
          longitude: coords.lng,
        })),
      }));
    }
    return formattedTerritories;
  } catch (e) {
    console.log('error formatting territories: ', e);
  }
};

export const addressObjToString = (addressObj) => {
  if (
    addressObj?.street === undefined ||
    addressObj?.city === undefined ||
    addressObj?.state === undefined ||
    addressObj?.postalCode === undefined
  )
    return '';
  return (
    addressObj.street +
    ' ' +
    (addressObj.unit !== '' ? addressObj.unit + ' ' : '') +
    addressObj.city +
    ' ' +
    addressObj.state +
    ' ' +
    addressObj.postalCode
  );
};

// Usage: markerSVGs[iconName] = icon SVG
export const markerSVGs = {
  home: homeSVG,
  close: xMarkSVG,
  time: clockSVG,
  'note-check': noteSVG,
  'check-circle': checkSVG,
  question: questionSVG,
  'skull-crossbones': skullSVG,
};

export const getAddressFromLatLng = async (lat, lng) => {
  // need to move apiKey
  const apiKey = process.env.REACT_APP_API_KEY;

  const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`);
  const data = await response.json();

  if (data.status === 'OK') {
    const componentList = data.results[0].address_components;
    let city = '';
    let state = '';
    let postalCode = '';
    let unit = '';
    let street_number = '';
    let route = '';

    componentList.forEach((component) => {
      const componentType = component.types[0];

      // eslint-disable-next-line default-case
      switch (componentType) {
        case 'street_number':
          street_number += component.long_name;
          break;
        case 'route':
          route += ' ' + component.long_name;
          break;
        case 'locality':
          city = component.long_name;
          break;
        case 'administrative_area_level_1':
          state = component.short_name;
          break;
        case 'postal_code':
          postalCode = component.long_name;
          break;
        case 'subpremise':
          unit = component.long_name;
          break;
      }
    });
    return { street: street_number + route, unit, city, state, postalCode };
  } else {
    // if we return null here without throwing an error, then it will set the marker.address to null, which will then cause an infinite loop of trying to update the address in the MarkerModal.js
    throw new Error('Error fetching address: ' + data.status);
  }
};

// check if point is in ponygon (ray-casting algorithm)
export function isPointInPolygon(point, polygon) {
  if (!polygon?.length) return;
  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i].longitude,
      yi = polygon[i].latitude;
    const xj = polygon[j].longitude,
      yj = polygon[j].latitude;

    // prettier-ignore
    const intersect = ((yi > point.lat) !== (yj > point.lat)) && (point.lng < (xj - xi) * (point.lat - yi) / (yj - yi) + xi);
    if (intersect) {
      inside = !inside;
    }
  }
  return inside;
}
