import { Tooltip } from "@mui/material";
import { HiOutlineQuestionMarkCircle } from "react-icons/hi";
import { BsQuestionCircleFill } from "react-icons/bs";

export default function HelperTooltip({ text, containerClass }) {
  return (
    <Tooltip title={text}>
      <div className={`h-6 w-6 opacity-10 ${containerClass}`}>
        <BsQuestionCircleFill size={20} />
      </div>
    </Tooltip>
  );
}
