import MuiBasicDropdown from "components/reusable/MuiBasicDropdown";
import { CloseModalIconButton } from "components/NewButtons/CloseModalIconButton";
import Dialogue from "components/modals/Dialogue";
import { useState } from "react";
import DisabledButton from "components/buttons/DisabledButton";
import { addOrRemoveCustomerTags } from "utils/firestoreCalls";
import { useSnackBar } from "context/SnackBarContext";
import { useUserContext } from "context/UserContext";
import MarketingMessage from "../lowerLevelComponents/MarketingMessage";

export default function MarketingMessageModal({
  open,
  selectedRows,
  closeModal,
  allCustomers,
  customers,
  setSelectedRows,
}) {
  const { userData } = useUserContext();
  const { openSnackBar } = useSnackBar();
  return (
    <Dialogue
      width={700}
      open={open}
      containerClass="max-h-[90vh] overflow-auto"
    >
      <div className="absolute top-2 right-2">
        <CloseModalIconButton handleCancel={closeModal} />
      </div>
      <MarketingMessage
        userData={userData}
        selectedRows={selectedRows}
        allCustomers={allCustomers}
        customers={customers}
        openSnackBar={openSnackBar}
        setSelectedRows={setSelectedRows}
        closeModal={closeModal}
      />
    </Dialogue>
  );
}
