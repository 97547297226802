import { v4 as uuidv4 } from "uuid";
import { EstimateEventTypes, getEstimateEventTypeName } from "../data/constants";
import { useEmployeeContext } from "../../../context/EmployeeContext";
import { getActorColor } from "../data/helpers";
import { convertToJsDateNano } from "utils/helpers";
import { format } from "date-fns";
import { Link } from "react-router-dom";

export const EstimateHistory = ({ estimateHistory }) => {
  if (!estimateHistory) return null;
  return (
    <div className="flex flex-col  rounded-md shadow-bold bg-white  overflow-x-auto  flex-shrink-0 py-6 gap-2 mt-0 mb-8">
      <div className="px-4">
        <h1 className="text-xl font-bold">Activity History</h1>
      </div>
      <div className="w-full overflow-y-auto max-h-96">
        <table className="w-full text-sm px-2">
          <thead className="border-b border-gray-200 bg-white">
            <tr>
              <th className="sticky top-0 px-6 py-3 text-left font-medium text-gray-500 bg-white">
                By
              </th>
              <th className="sticky top-0 px-6 py-3 text-left font-medium text-gray-500 bg-white flex-1">
                Action
              </th>
              <th className="sticky top-0 px-6 py-3 text-left font-medium text-gray-500 bg-white">
                Date
              </th>
              <th className="sticky top-0 px-3 py-3 text-left font-medium text-gray-500 z-10 bg-white"></th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {estimateHistory.length > 0 ? (
              [...estimateHistory].reverse().map((historyEvent) => {
                const id = uuidv4();
                return <HistoryEvent key={id} event={historyEvent} />;
              })
            ) : (
              <tr>
                <td colSpan="5" className="px-6 py-4 text-center text-sm text-gray-400 italic">
                  No Activity to display
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const HistoryEvent = ({ event }) => {
  const { employees } = useEmployeeContext();

  return (
    <tr className="transition-opacity duration-1000 ease-in-out ">
      <td className="px-6 py-4 text-sm">
        <span
          style={getActorColor(event.initiatedBy, employees)}
          className={`px-2 py-1 rounded-full text-xs font-medium truncate max-w-[140px] inline-block `}
        >
          {event.initiatedBy?.userName}
        </span>
      </td>
      <HistoryEventText event={event} />
      <td className="pl-6 pr-2 py-4 text-sm text-gray-500">
        {format(convertToJsDateNano(event?.timestamp), "eee MM/dd/yyyy hh:mm a")}
      </td>
    </tr>
  );
};

const HistoryEventText = ({ event }) => {
  const { type } = event;
  if (type === EstimateEventTypes.ESTIMATE_COPIED) {
    return (
      <td className="px-6 py-4 text-sm text-gray-500">
        <Link to={`/jobdetails/${event.data.jobId}`} className="text-yellow-600">
          {getEstimateEventTypeName(type)}
        </Link>
      </td>
    );
  } else if (
    type === EstimateEventTypes.ESTIMATE_APPROVED ||
    type === EstimateEventTypes.ESTIMATE_APPROVED_PUBLIC
  ) {
    return (
      <td className="px-6 py-4 text-sm text-gray-500">
        Estimate{" "}
        <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-medium rounded bg-green-50 text-gray-600 border border-green-100">
          approved
        </span>
      </td>
    );
  } else if (type === EstimateEventTypes.ESTIMATE_APPROVAL_REVERTED) {
    return (
      <td className="px-6 py-4 text-sm text-gray-500">
        Estimate approval{" "}
        <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-medium rounded bg-red-50 text-red-400 border border-red-200">
          reverted
        </span>
      </td>
    );
  } else if (type === EstimateEventTypes.ESTIMATE_DECLINED) {
    return (
      <td className="px-6 py-4 text-sm text-gray-500">
        Estimate{" "}
        <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-medium rounded bg-red-50 text-red-400 border border-red-200">
          declined
        </span>
      </td>
    );
  } else {
    return <td className="px-6 py-4 text-sm text-gray-500">{getEstimateEventTypeName(type)}</td>;
  }
};
