import React, { useCallback, useContext, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from 'utils/firebase';
import { UserContext } from 'context/UserContext';
import LoadingIndicator from '../LoadingIndicator';

export default function StatsTable({ rankBy, timeframe, employees, errorToast, expanded }) {
  const { userData } = useContext(UserContext);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAndFormatData = async () => {
      try {
        setLoading(true);
        let markerStatsData;
        if (timeframe?.start || timeframe?.end) {
          let markerStatsRef = collection(
            db,
            'businesses',
            userData.userData.businessId,
            'stats',
            'markerStats',
            'markersByDay'
          );
          if (timeframe?.start) markerStatsRef = query(markerStatsRef, where('date', '>=', timeframe.start));
          if (timeframe?.end) markerStatsRef = query(markerStatsRef, where('date', '<=', timeframe.end));
          // fetch day stats then format into a total
          markerStatsData = (await getDocs(markerStatsRef)).docs.reduce((totals, currentDay) => {
            currentDay = currentDay.data();
            for (const userId in currentDay) {
              if (userId === 'date') continue;
              if (!totals[userId]) totals[userId] = {};
              for (const markerType in currentDay[userId]) {
                totals[userId][markerType] = (totals[userId][markerType] || 0) + currentDay[userId][markerType];
              }
            }
            return totals;
          }, {});
        } else {
          const markerStatsRef = doc(db, 'businesses', userData.userData.businessId, 'stats', 'markerStats');
          markerStatsData = (await getDoc(markerStatsRef)).data();
        }

        // add total doors knocked
        for (const id in markerStatsData) {
          let totalDoorsKnocked = 0;
          Object.values(markerStatsData[id]).forEach((totalForType) => {
            totalDoorsKnocked += totalForType;
          });
          markerStatsData[id]['Doors Knocked'] = totalDoorsKnocked;
        }
        // add employee names to marker stats, even if employee has no activity
        const employeeNameTable = {};
        employees.forEach((employee) => {
          if (!markerStatsData[employee.id])
            markerStatsData[employee.id] = { firstName: employee.firstName, lastName: employee.lastName };
          employeeNameTable[employee.id] = { firstName: employee.firstName, lastName: employee.lastName };
        });
        for (const id in markerStatsData) {
          markerStatsData[id].firstName = employeeNameTable[id]?.firstName || 'unknown';
          markerStatsData[id].lastName = employeeNameTable[id]?.lastName || 'employee';
        }

        // transform obj into sorted arr without unknown employees
        markerStatsData = Object.keys(markerStatsData)
          .map((id) => ({ ...markerStatsData[id], id }))
          .filter((el) => employeeNameTable.hasOwnProperty(el.id))
          .sort((a, b) => (b[rankBy] || 0) - (a[rankBy] || 0));
        setTableData(markerStatsData);
      } catch (e) {
        console.log('error in MuiBasicTable: ', e);
        errorToast(
          'There was a problem retrieving employee information. Please check your internet connection and try again.'
        );
      } finally {
        setLoading(false);
      }
    };
    if (rankBy && timeframe) fetchAndFormatData();
  }, [rankBy, timeframe, setTableData, userData.userData.businessId, employees, errorToast]);

  const fields = ['Come back', 'Not home', 'Avoid', 'Uncertain', 'Sale', 'No', 'Quote', 'Doors Knocked'];

  return (
    <>
      {!tableData && !loading && (
        <div className='text-sm text-gray-500'>Select a metric and timeframe to display table data.</div>
      )}
      <LoadingIndicator visible={loading} />
      <div className='w-full overflow-x-auto'>
        {tableData && (
          <TableContainer component={Paper} style={{ overflowX: 'auto', minWidth: expanded ? '600px' : 'auto' }}>
            <Table stickyHeader style={{ tableLayout: 'fixed' }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell align='left'>Name</TableCell>
                  <TableCell align='left'>{rankBy}</TableCell>
                  {expanded &&
                    fields.map(
                      (field, i) =>
                        field !== rankBy && (
                          <TableCell key={'head-row' + field + i} align='left'>
                            {field}
                          </TableCell>
                        )
                    )}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData?.map((dataRow, i) => (
                  <TableRow key={dataRow.id}>
                    <TableCell align='left' className='border-r'>
                      {dataRow.firstName + ' ' + dataRow.lastName}
                    </TableCell>
                    <TableCell align='left'>{dataRow[rankBy] || 0}</TableCell>
                    {expanded &&
                      fields.map(
                        (field, j) =>
                          field !== rankBy && (
                            <TableCell key={'body-row' + i + j} align='left'>
                              {dataRow[field] || 0}
                            </TableCell>
                          )
                      )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </>
  );
}
