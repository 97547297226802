import React, { useState, useEffect, useCallback, useMemo } from "react";
import { BiSend, BiCheckCircle } from "react-icons/bi";
import { BsExclamationCircle } from "react-icons/bs";
import { ImSpinner } from "react-icons/im";
import { massText } from "../../../../utils/twilio";
import { IoCloseSharp } from "react-icons/io5";
import { useSnackBar } from "../../../../context/SnackBarContext";
import Tooltip from "@mui/material/Tooltip";
import NormalButton from "components/buttons/NormalButton";
import RichTextInput from "components/reusable/RichTextInput";
import Dialogue from "components/modals/Dialogue";
import { FaXmark } from "react-icons/fa6";
import { colors } from "theme/colors";
import { FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { el } from "date-fns/locale";
import MuiBasicDropdown from "components/reusable/MuiBasicDropdown";
import { CloseModalIconButton } from "components/NewButtons/CloseModalIconButton";
// import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { httpsCallable, getFunctions } from "firebase/functions";
import { Checkbox } from "components/ui/checkbox";

// Check if a character is GSM-7 safe
const isGSMCharacter = (char) => {
  const gsmChars =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz\n\r !\"#$%&'()*+,-./:;<=>?@[\\]^_{}|~";
  return gsmChars.includes(char);
};

const DYNAMIC_VARIABLES = [
  { label: "Customer First Name", value: "{firstName}" },
  { label: "Customer Last Name", value: "{lastName}" },
];

const htmlToText = (html) => {
  try {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    if (doc.body.firstChild && doc.body.firstChild.nodeName === "parsererror") {
      console.error("Error parsing HTML:", doc.body.firstChild.textContent);
      return html; // Return original HTML if parsing fails
    }
    let text = "";
    const traverse = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        text += node.textContent;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.nodeName === "BR") {
          text += "\n";
        } else if (node.nodeName === "DIV" || node.nodeName === "P") {
          // Add a newline before block-level elements, but not for the first one
          if (text.length > 0) {
            text += "\n";
          }
        }

        if (node.hasAttribute("data-value")) {
          text += node.getAttribute("data-value").replace(/{(.+)}/, "{{$1}}");
        } else {
          for (const childNode of node.childNodes) {
            traverse(childNode);
          }
        }

        // Add a newline after block-level elements
        if (
          (node.nodeName === "DIV" || node.nodeName === "P") &&
          node.nextSibling
        ) {
          text += "\n";
        }
      }
    };

    traverse(doc.body);
    return text.trim(); // Trim to remove any leading/trailing whitespace
  } catch (error) {
    console.error("Error in htmlToText:", error);
    return html; // Return original HTML if any error occurs
  }
};

const textToHtml = (text) => {
  return text.replace(/{{(.+?)}}/g, (match, variable) => {
    const variableInfo = DYNAMIC_VARIABLES.find(
      (v) => v.value === `{${variable}}`
    );
    if (variableInfo) {
      return `<span contenteditable="false" class="bg-yellow-50 border border-gray-400 text-gray-700 py-1 px-2 rounded-full cursor-default text-sm whitespace-nowrap" data-value="{${variable}}">${variableInfo.label}<button class="ml-1 text-gray-800 hover:text-gray-400 font-bold text-lg transition-colors duration-300 ease-in-out" data-action="remove">×</button></span>`;
    }
    return match;
  });
};

export default function MarketingMessage({
  userData,
  selectedRows,
  allCustomers,
  openSnackBar,
  setSelectedRows,
  closeModal,
}) {
  const [loading, setLoading] = useState(false);
  const defaultMessage =
    "Hey {{firstName}}, we're doing a special this month would you like us to service your house again?";
  const defaultSubjectLine =
    "Special Offer for You, {{firstName}}, Ready for Your Next Service?";
  const [message, setMessage] = useState({
    html: textToHtml(defaultMessage),
    text: defaultMessage,
  });
  const [messageSubject, setMessageSubject] = useState({
    html: textToHtml(defaultSubjectLine),
    text: defaultSubjectLine,
  });
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [typeOfMessage, setTypeOfMessage] = useState("phoneNumber");
  const [sent, setSent] = useState(false);
  // const [richTextInputSelectedRef, setRichTextInputSelectedRef] =
  //   useState(null);
  const [sendWithLogo, setSendWithLogo] = useState(!!userData?.bizData?.logo);

  const customerIdsSet = useMemo(() => new Set(selectedRows), [selectedRows]); // model only supplies id of selected rows, we want to save the state of selected customers with details

  const [filteredCustomers, setFilteredCustomers] = useState(
    allCustomers
      .filter((customer) => customerIdsSet.has(customer.customerId)) // filter out un-selected customers
      .map((el) => ({
        ...el,
        phoneNumber:
          el.phone?.mobile ||
          el.phone.work ||
          el.phone.additional ||
          el.phone.home,
      })) // add number
  );
  const [filteredCustomersWithContact, setFilteredCustomersWithContact] =
    useState(
      allCustomers.filter(
        (customer) => customerIdsSet.has(customer.customerId) && customer.phone
      )
    );
  // filter out customers without contact info type
  useEffect(() => {
    if (!filteredCustomers) return;
    if (typeOfMessage === "both")
      setFilteredCustomersWithContact(
        filteredCustomers.filter(
          (customer) => customer.phoneNumber || customer.email
        )
      );
    else
      setFilteredCustomersWithContact(
        filteredCustomers?.filter((customer) => customer[typeOfMessage])
      );
  }, [typeOfMessage, setFilteredCustomersWithContact, filteredCustomers]);

  const handleChangeRichTextChange = useCallback((value, setter) => {
    setter((prevState) => ({
      html: value,
      text: htmlToText(value),
    }));
  }, []);
  //  my updated version

  const handleSendMassMessage = async (e) => {
    e.preventDefault();
    setConfirmationModal(false);
    setSent(true);
    setLoading(true);
    if (loading) return;
    if (!userData?.subData || userData?.subData.status !== "active") {
      alert(
        "You must have an active subscription to send mass text messages. Please contact support if you have any questions."
      );
      return;
    }
    if (typeOfMessage !== "email" && !userData?.bizData?.telnyxNumber) {
      alert(
        "Your phone number is not set up yet. If it has been more than 24 hours since you purchased a subscription please contact support@homebase360.io."
      );
      return;
    }
    // if the user isn't and admin or a manger or has the explicit permission to send mass marketing messages then return
    if (
      userData?.userData?.userType !== "Admin" &&
      userData?.userData?.userType !== "Manger" &&
      !userData?.userData?.customPermissions?.marketing_messages
    ) {
      alert(
        "You do not have permission to send marketing messages. Please contact your admin to get access."
      );
      return;
    }

    const responses = [];
    // send texts
    if (typeOfMessage === "phoneNumber" || typeOfMessage === "both") {
      responses.push(
        massText(
          userData.bizData.id,
          message.text,
          filteredCustomersWithContact,
          userData.bizData.companyName,
          userData.bizData?.telnyxNumber || ""
        )
      );
    }
    // send emails
    if (typeOfMessage === "email" || typeOfMessage === "both") {
      const functions = getFunctions();
      const massEmailSendFunction = httpsCallable(functions, "massEmailSend");
      responses.push(
        massEmailSendFunction({
          bizData: userData.bizData,
          sendWithLogo: sendWithLogo,
          subject: messageSubject.text,
          body: message.text,
          substitutions: ["{{firstName}}", "{{lastName}}"],
          to: filteredCustomersWithContact.map((customer) => ({
            email: customer.email[0],
            firstName: customer.firstName,
            lastName: customer.lastName,
          })), // remove excess data from request by mapping filtered customers
        })
      );
    }
    let errorMessage = null;
    try {
      openSnackBar("Sending messages...", false, false, 1200000);
      const success = (await Promise.all(responses)).reduce(
        (accumBool, response) => {
          return accumBool && (response.success || response.data?.success);
        },
        true
      );
      if (success) {
        openSnackBar(
          "Messages have been scheduled for sending.",
          true,
          false,
          1200000
        );
        setSelectedRows([]);
      } else {
        // catch controlled error
        console.log("error sending message: ", errorMessage);
        setSent(false);
        openSnackBar(
          "There was an error sending your message. Please check your internet connection and try again.",
          false,
          true,
          1200000
        ); // Display the actual error message returned from the backend
      }
    } catch (e) {
      console.log("error sending message: ", e);
      setSent(false);
      openSnackBar(
        "There was an error sending your message. Please check your internet connection and try again.",
        false,
        true,
        1200000
      ); // Display the actual error message returned from the backend
    } finally {
      setLoading(false);
    }
  };

  const shouldCrossOffCustomer = (customer, typeOfMessage) => {
    if (typeOfMessage === "both") return !customer?.email && !customer?.phone;
    else return !customer?.[typeOfMessage];
  };

  // return mass send or confirmation of mass send
  return (
    <>
      {!confirmationModal ? (
        <div
          className={`flex flex-col ${
            sent ? "opacity-50 pointer-events-none" : ""
          }`}
        >
          <h1 className="text-2xl font-medium">Marketing Message</h1>
          {/* </div> */}

          {/* selected & customer list */}
          <>
            <p className="text-sm mt-1">
              {filteredCustomers?.length || 0} selected,{" "}
              {filteredCustomersWithContact?.length} with{" "}
              {typeOfMessage === "phoneNumber"
                ? "a phone number"
                : typeOfMessage === "email"
                ? "an email"
                : "an email or number"}
              .
            </p>

            <div className="max-h-56 overflow-y-auto border p-2 text-sm bg-gray-50 mb-6 mt-2">
              {filteredCustomers?.map((customer, i) => {
                return (
                  <div
                    key={"customer-in-panel" + i}
                    className="flex flex-row items-center mb-1"
                  >
                    <Tooltip disableInteractive title="Remove Customer">
                      <button
                        onClick={() =>
                          setFilteredCustomers((p) =>
                            p.filter(
                              (el) => el.customerId !== customer.customerId
                            )
                          )
                        }
                      >
                        <FaXmark color={colors.red600} />
                      </button>
                    </Tooltip>
                    <p
                      className={`text-nowrap overflow-x-hidden text-ellipsis ml-1 ${
                        shouldCrossOffCustomer(customer, typeOfMessage)
                          ? "line-through"
                          : ""
                      }`}
                    >
                      {customer.firstName + " " + customer.lastName}
                    </p>
                    {!!customer?.phoneNumber && (
                      <Tooltip disableInteractive title={customer?.phoneNumber}>
                        <button className="cursor-auto">
                          <FaPhone
                            color={colors.gray900}
                            size={12}
                            className="ml-1"
                          />
                        </button>
                      </Tooltip>
                    )}
                    {!!customer?.email?.[0]?.length && (
                      <Tooltip disableInteractive title={customer?.email[0]}>
                        <button className="cursor-auto">
                          <MdEmail
                            color={colors.gray900}
                            size={16}
                            className="ml-1"
                          />{" "}
                        </button>
                      </Tooltip>
                    )}
                  </div>
                );
              })}
            </div>
          </>

          {/* message type drop down */}
          <div className="flex flex-row gap-4 items-center">
            <MuiBasicDropdown
              options={[
                { label: "Phone Number", value: "phoneNumber" },
                { label: "Email", value: "email" },
                { label: "Both", value: "both" },
              ]}
              value={typeOfMessage}
              setValue={setTypeOfMessage}
              label="Message Type"
              containerClass="w-72"
            />
            <div
              className={`flex flex-row items-center ${
                typeOfMessage === "phoneNumber" ? "hidden" : ""
              }`}
            >
              <Checkbox
                checked={sendWithLogo}
                onCheckedChange={(checked) => setSendWithLogo(checked)}
              />
              <p className="text-sm ml-2">
                Include Company Logo
                {typeOfMessage === "both" ? " (email only)" : ""}
              </p>
            </div>
          </div>

          {/* message  */}
          <div className="flex flex-col mt-5">
            <div className="font-bold relative text-lg flex items-center -mb-[22px] w-60">
              Customize Message{" "}
              <Tooltip title="To use the first name of the client in the message insert the Customer First Name variable by clicking the Customer First Name button. You can also add the Customer Last Name">
                <span className="inline-block">
                  <BsExclamationCircle className=" ml-1 hover:cursor-pointer" />
                </span>
              </Tooltip>
            </div>
            <div className="pb-4">
              <div className="flex flex-row justify-end items-center">
                <button
                  className="text-xs font-medium text-indigo-500 px-2 py-1 mb-1 rounded-full hover:bg-gray-100 hover:text-indigo-600 transition-colors duration-300 ease-in-out"
                  onClick={() => setPreviewModalOpen(true)}
                >
                  PREVIEW
                </button>
              </div>
              {/* subject line input (email only) */}
              <div
                className={typeOfMessage === "phoneNumber" ? "hidden" : "mb-10"}
              >
                <p className="text-sm font-semibold mb-1">
                  Subject Line (Email Only):
                </p>
                <RichTextInput
                  value={messageSubject.html}
                  onChange={(value) =>
                    handleChangeRichTextChange(value, setMessageSubject)
                  }
                  label="Customer Message"
                  DYNAMIC_VARIABLES={DYNAMIC_VARIABLES}
                  LIMIT={120}
                  htmlToText={htmlToText}
                />
              </div>
              {/* message line input */}
              {typeOfMessage !== "phoneNumber" && (
                <p className="text-sm font-semibold mb-1">Message:</p>
              )}
              <RichTextInput
                value={message.html}
                onChange={(value) =>
                  handleChangeRichTextChange(value, setMessage)
                }
                label="Customer Message"
                DYNAMIC_VARIABLES={DYNAMIC_VARIABLES}
                LIMIT={400}
                htmlToText={htmlToText}
              />
            </div>
          </div>

          {/* send button */}
          <div className="mt-6 flex flex-row items-center">
            <NormalButton
              onClick={() => {
                if (!message.text) {
                  openSnackBar(
                    "Please enter a message before sending.",
                    false,
                    true,
                    120000
                  );
                  return;
                }
                setConfirmationModal(true);
              }}
              loading={loading}
              text={"Send"}
              additionalClasses="w-36 text-lg"
              icon={<BiSend className="text-lg" />}
              iconPosition="right"
              disabled={!filteredCustomersWithContact.length}
            />
            <p
              className={`ml-2 text-sm text-red-300${
                filteredCustomersWithContact.length ? " hidden" : ""
              }`}
            >
              There are no customers for the selected contact method.
            </p>
          </div>

          <PreviewModal
            open={previewModalOpen}
            onClose={() => setPreviewModalOpen(false)}
            message={message.text}
            subjectLine={
              typeOfMessage === "phoneNumber" ? "" : messageSubject.text
            }
            logo={
              sendWithLogo && typeOfMessage !== "phoneNumber"
                ? userData?.bizData?.logo
                : null
            }
          />
        </div>
      ) : (
        <ConfirmModal
          handleSendMassMessage={handleSendMassMessage}
          setModal={setConfirmationModal}
          loading={loading}
          filteredCustomersWithContact={filteredCustomersWithContact}
          message={message.text}
          messageSubject={
            typeOfMessage === "phoneNumber" ? "" : messageSubject.text
          }
          setSent={setSent}
          logo={
            typeOfMessage !== "phoneNumber" && sendWithLogo
              ? userData?.bizData?.logo
              : null
          }
        />
      )}
    </>
  );
}

const MessageExample = ({
  exampleText,
  ExampleHeader,
  visible,
  logo = null,
}) => {
  return visible ? (
    <div className="self-start w-full">
      <div className="flex flex-row items-start justify-center mt-4 mb-2 w-full">
        <h1 className="text-lg font-bold w-36 flex-shrink-0">
          {ExampleHeader}
        </h1>
        <h1 className="ml-1 -mt-1 py-2 px-3 bg-gray-50 rounded-sm shadow whitespace-pre-wrap break-words text-start text-sm min-h-14 w-full">
          {logo && (
            <img
              className="mx-auto mb-4"
              alt="company logo"
              width={60}
              height={60}
              src={logo}
            />
          )}
          {exampleText}
        </h1>
      </div>
    </div>
  ) : (
    <></>
  );
};

function ConfirmModal({
  handleSendMassMessage,
  setModal,
  filteredCustomersWithContact,
  message,
  messageSubject,
  logo,
  setSent,
}) {
  const firstName =
    filteredCustomersWithContact && filteredCustomersWithContact[0]?.firstName;
  const lastName =
    filteredCustomersWithContact && filteredCustomersWithContact[0]?.lastName;

  let messageExample = message.replace(/{{\s*firstName\s*}}/gi, firstName);
  messageExample = messageExample.replace(/{{\s*lastName\s*}}/gi, lastName);

  let messageSubjectExample = messageSubject.replace(
    /{{\s*firstName\s*}}/gi,
    firstName
  );
  messageSubjectExample = messageSubjectExample.replace(
    /{{\s*lastName\s*}}/gi,
    lastName
  );

  return (
    <>
      {!filteredCustomersWithContact.length ? (
        <>
          <div className="flex flex-row items-center gap-2 mt-4 mb-2 ml-2">
            <h1 className="text-xl font-bold">
              Looks like you haven't selected any customers with contact
              information!
            </h1>
          </div>
          <div className="flex flex-row items-start justify-center">
            <h1 className="ml-1 mt-1">
              Create more customers or change the filter so that we can send
              them some messages. 🚀
            </h1>
          </div>
          <div className="flex flex-row items-center gap-4 mt-8">
            <button
              onClick={() => setModal(false)}
              className=" bg-gray-100  text-gray-500  font-bold py-2 px-4 rounded"
            >
              Ok
            </button>
          </div>
        </>
      ) : (
        <div className="flex flex-col flex-1 items-center text-center lg:col-span-2">
          <div className="flex flex-row items-center gap-2 mt-4 mb-2 ml-2">
            <h1 className="text-xl font-bold">
              Confirm this is the message you'd like to send to{" "}
              {filteredCustomersWithContact?.length}{" "}
              {filteredCustomersWithContact?.length > 1
                ? "customers"
                : "customer"}
            </h1>
          </div>
          <MessageExample
            exampleText={messageSubjectExample}
            ExampleHeader="Example Subject Line"
            visible={!!messageSubject}
          />
          <MessageExample
            exampleText={messageExample}
            ExampleHeader="Example Message"
            visible={true}
            logo={logo}
          />
          <div className="flex flex-row items-center gap-4 mt-8">
            <button
              onClick={() => setModal(false)}
              className=" bg-gray-100  text-gray-500  font-bold py-2 px-4 rounded"
            >
              Back
            </button>
            <button
              onClick={handleSendMassMessage}
              className=" bg-gray-700 hover:bg-gray-800 text-green-200  font-bold py-2 px-4 rounded"
            >
              Confirm Send
            </button>
          </div>
          <div className="flex flex-row items-center gap-4 mt-8 text-gray-600 text-xs">
            <p>
              (Messages will be sent in batches of 8 every 2 minutes to avoid
              rate limiting.)
            </p>
          </div>
        </div>
      )}
    </>
  );
}

const PreviewModal = ({ open, onClose, message, subjectLine, logo }) => {
  const newMessage = message
    .replace(/{{firstName}}/g, "John")
    .replace(/{{lastName}}/g, "Doe");

  const newMessageSubject = subjectLine
    .replace(/{{firstName}}/g, "John")
    .replace(/{{lastName}}/g, "Doe");

  return (
    <Dialogue open={open} onClose={onClose} width={500} p="p-6" py="pt-6 pb-8">
      <div className="absolute top-2 right-2">
        <CloseModalIconButton handleCancel={onClose} />
      </div>
      <h2 className="text-lg font-bold mb-4">Marketing Message Preview</h2>
      {subjectLine && (
        <>
          <p className="text-sm font-semibold mb-1">Subject Line:</p>
          <div className="bg-gray-50 px-3 py-2 rounded-sm shadow min-h-8">
            <p className="text-sm text-gray-700 whitespace-pre-wrap break-words">
              {newMessageSubject}
            </p>
          </div>
        </>
      )}
      {subjectLine && (
        <p className="text-sm font-semibold mb-1 mt-4">Message:</p>
      )}
      <div className="bg-gray-50 px-3 py-2 rounded-sm shadow min-h-8">
        {logo && (
          <img
            className="mx-auto mb-4"
            alt="company logo"
            width={60}
            height={60}
            src={logo}
          />
        )}
        <p className="text-sm text-gray-700 whitespace-pre-wrap break-words">
          {newMessage}
        </p>
      </div>
    </Dialogue>
  );
};
