// UserContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../utils/firebase";
import { doc, onSnapshot } from "firebase/firestore";

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, loading, error] = useAuthState(auth);
  const [userData, setUserData] = useState(null);
  const [userDataLoading, setUserDataLoading] = useState(true);

  useEffect(() => {
    const pathname = window.location.pathname;
    if (
      pathname.startsWith("/register") ||
      pathname === "/invoice" ||
      pathname === "/review" ||
      pathname === "/estimate-overview"
    ) {
      return;
    }
    if (!user) {
      setUserData(null);
      setUserDataLoading(false);
      return;
    }

    const unsubscribes = [];

    const userUnsub = onSnapshot(doc(db, "users", user.uid), (docSnap) => {
      if (!docSnap.exists()) {
        console.log("user doc does not exist");
        setUserDataLoading(false);
        setUserData(null);
        return;
      }
      const businessId = docSnap.data().businessId;
      let newUserData = { userData: docSnap.data() };

      const bizUnsub = onSnapshot(
        doc(db, "businesses", businessId),
        (bizDocSnap) => {
          if (!bizDocSnap.exists()) {
            console.log("bizDoc does not exist, exiting (UserContext.js)");
            return;
          }
          newUserData.bizData = bizDocSnap.data();

          const subUnsub = onSnapshot(
            doc(db, "businesses", businessId, "stripe", "subscription"),
            (subDocSnap) => {
              if (subDocSnap.exists()) {
                newUserData.subData = subDocSnap.data();
              }
            }
          );

          const stripeCustUnsub = onSnapshot(
            doc(db, "businesses", businessId, "stripe", "customer"),
            (stripeCustDocSnap) => {
              if (stripeCustDocSnap.exists()) {
                newUserData.stripeCustomer = stripeCustDocSnap.data();
              }
              setUserData({ ...newUserData });
              setUserDataLoading(false);
            }
          );

          unsubscribes.push(subUnsub, stripeCustUnsub);
        }
      );

      unsubscribes.push(bizUnsub);
    });

    unsubscribes.push(userUnsub);

    return () => {
      unsubscribes.forEach((unsub) => unsub()); // Unsubscribe from all listeners
    };
  }, [user, loading]);

  const manualTrial = userData?.subData?.status === "manual trial";
  const bannerVisible = manualTrial && userData?.userData?.isAdmin;

  const value = {
    user,
    userData,
    userDataLoading,
    setUserData,
    bannerVisible,
    error,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);

// // UserContext.js
// import React, { createContext, useState, useEffect, useContext } from "react";
// import { useAuthState } from "react-firebase-hooks/auth";
// import { auth, db } from "../utils/firebase";
// import { getDoc, doc } from "firebase/firestore";

// export const UserContext = createContext(null);

// export const UserProvider = ({ children }) => {
//   const [user, loading, error] = useAuthState(auth);
//   const [userData, setUserData] = useState(null);
//   const [rerun, setRerun] = useState(false);
//   const [userDataLoading, setUserDataLoading] = useState(true);

//   useEffect(() => {
//     const pathname = window.location.pathname;
//     if (
//       pathname.startsWith("/register") ||
//       pathname === "/invoice" ||
//       pathname === "/review"
//     ) {
//       return;
//     }
//     if (!user) {
//       setUserData(null);
//       return;
//     }

//     const fetchUserData = async () => {
//       const docRef = doc(db, "users", user.uid);
//       try {
//         const docSnap = await getDoc(docRef);
//         if (!docSnap.exists()) {
//           console.log("user doc does not exist");
//           return;
//         }

//         const bizDocRef = doc(db, "businesses", docSnap.data().businessId);
//         const subDocRef = doc(
//           db,
//           "businesses",
//           docSnap.data().businessId,
//           "stripe",
//           "subscription"
//         );
//         const stripeCustDocRef = doc(
//           db,
//           "businesses",
//           docSnap.data().businessId,
//           "stripe",
//           "customer"
//         );

//         const [bizDocSnap, subDocSnap, stripeCustDocSnap] = await Promise.all([
//           getDoc(bizDocRef),
//           getDoc(subDocRef),
//           getDoc(stripeCustDocRef),
//         ]);
//         let newUserData = { userData: docSnap.data() };
//         if (bizDocSnap.exists()) {
//           newUserData.bizData = bizDocSnap.data();
//         } else {
//           console.log("bizDoc does not exist, exiting (UserContext.js)");
//           return;
//         }
//         if (subDocSnap.exists()) {
//           newUserData.subData = subDocSnap.data();
//         }
//         if (stripeCustDocSnap.exists()) {
//           newUserData.stripeCustomer = stripeCustDocSnap.data();
//         }

//         setUserData(newUserData);
//         setUserDataLoading(false);
//         // console.log("user data set.(UserContext.js)");
//       } catch (error) {
//         // console.error("Error getting user document:", error);
//       }
//     };

//     fetchUserData();
//   }, [user, rerun, loading]);

//   const manualTrial = userData?.subData?.status === "manual trial";
//   const bannerVisible = manualTrial && userData?.userData?.isAdmin;

//   const value = {
//     user,
//     userData,
//     userDataLoading,
//     setRerun,
//     setUserData,
//     bannerVisible,
//     error,
//   };

//   return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
// };

// export const useUserContext = () => useContext(UserContext);
