import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { BsPersonFill } from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";
import { FaToggleOff } from "react-icons/fa";
import { FaToggleOn } from "react-icons/fa";
import AttachmentsAccordion from "components/reusable/AttachmentsAccordion";
import { CgNotes } from "react-icons/cg";
import NormalButton from "components/buttons/NormalButton";
import InputMUI from "components/reusable/InputMUI";
import PhoneInputMUI from "components/reusable/PhoneInputMUI";
import ExtraPhoneAccordion from "features/Customers/components/lowerLevelComponents/ExtraPhoneAccordion";
import ExtraEmailAccordion from "features/Customers/components/lowerLevelComponents/ExtraEmailAccordion";
import AutocompleteMUI from "features/Customers/components/lowerLevelComponents/AutocompleteMUI";
import ExtraAutocompleteAccordion from "features/Customers/components/lowerLevelComponents/ExtraAutocompleteAccordion";
import { useUserContext } from "context/UserContext";
import {
  checkForDuplicateCustomer,
  saveCustomerData,
  mergeCustomerData,
} from "utils/firestoreCalls";
import { useSnackBar } from "context/SnackBarContext";
import { uploadTags, updateDetails } from "./api";
import { updateCustomerData } from "utils/firestoreCalls";
import { OutlineButton } from "../NewButtons/OutlineButton";
import { SolidButton } from "../NewButtons/SolidButton";

export default function CustomerModalCombined({
  modal,
  setModal,
  customer = null, // Pass 'null' or 'undefined' if adding a new customer
  setSelectedCustomer,
  jobDetails,
  customerId,
}) {
  const isEditMode = !!customer;
  const isUpdate = !!customerId;
  const { userData } = useUserContext();

  const [warningModal, setWarningModal] = useState(false);

  const [firstName, setFirstName] = useState(customer?.firstName || "");
  const [lastName, setLastName] = useState(customer?.lastName || "");
  const [displayName, setDisplayName] = useState(
    customer?.displayName?.trim() || ""
  );
  const [mobilePhone, setMobilePhone] = useState(customer?.phone?.mobile || "");
  const [homePhone, setHomePhone] = useState(customer?.phone?.home || "");
  const [workPhone, setWorkPhone] = useState(customer?.phone?.work || "");
  const [additionalPhone, setAdditionalPhone] = useState(
    customer?.phone?.additional ?? ""
  );
  const [email, setEmail] = useState(customer?.email?.[0] || "");
  const [additionalEmail, setAdditionalEmail] = useState(
    customer?.email?.[1] ?? ""
  );
  const [address, setAddress] = useState(customer?.address?.[0] || "");
  const [additionalAddress, setAdditionalAddress] = useState(
    customer?.address?.[1] ?? ""
  );
  const [notes, setNotes] = useState(customer?.notes || "");

  const [attachments, setAttachments] = useState(customer?.attachments || []);
  const [tags, setTags] = useState(customer?.tags || []);
  const [notifications, setNotifications] = useState(
    customer?.notifications ?? true
  );
  // const [dirty, setDirty] = useState(false);

  const { openSnackBar } = useSnackBar();
  const [duplicateCustomer, setDuplicateCustomer] = useState(null);
  const [savingCustomer, setSavingCustomer] = useState(false);
  const [savingDuplicateCustomer, setSavingDuplicateCustomer] = useState(false);
  const [duplicateCustomerWarning, setDuplicateCustomerWarning] =
    useState(false);

  // console.log("opening customer");

  const toggleModal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    if (isEditMode) {
      // Set state based on existing customer data
      setFirstName(customer?.firstName || "");
      setLastName(customer?.lastName || "");
      setDisplayName(customer?.displayName?.trim() || "");
      setMobilePhone(customer?.phone?.mobile ?? "");
      setHomePhone(customer?.phone?.home || "");
      setWorkPhone(customer?.phone?.work || "");
      setAdditionalPhone(customer?.phone?.additional || "");
      setEmail(customer?.email?.[0] || "");
      setAdditionalEmail(customer?.email?.[1] || "");
      setAddress(customer?.address?.[0] || "");
      setAdditionalAddress(customer?.address?.[1] || "");
      setNotes(customer?.notes || "");
      setAttachments(customer?.attachments || []);
      setTags(customer?.tags || []);
      setNotifications(customer?.notifications || true);
    }
  }, [customer, isEditMode]);

  useEffect(() => {
    // Update display name
    if (firstName || lastName) {
      setDisplayName(`${firstName} ${lastName}`.trim());
    } else {
      setDisplayName("");
    }
  }, [firstName, lastName]);

  const handleSave = async () => {
    const customerData = {
      firstName,
      lastName,
      displayName,
      phone: {
        mobile: mobilePhone,
        home: homePhone,
        work: workPhone,
        additional: additionalPhone,
      },
      email: [email, additionalEmail],
      address: [address, additionalAddress],
      notes,
      attachments,
      tags,
      notifications,
    };

    if (!customerData) {
      toggleModal();
      return;
    }
    if (savingCustomer || savingDuplicateCustomer) {
      return;
    }
    setSavingCustomer(true);
    const savedCustomerData = isUpdate
      ? await updateCustomer({ customerData, jobDetails, customerId })
      : await saveCustomer({ customerData });

    // if (savedCustomerData) {
    //   if (isUpdate) {
    //     // in isUpdate case this updates the JobDetails and EstimateDetails state, instead of just populating selected customer. Good idea to refactor JobDetails and EstimateDetails to extact customer data into separate state.
    //     setSelectedCustomer((prev) => {
    //       return { ...prev, customer: { ...savedCustomerData } };
    //     });
    //   } else {
    //     setSelectedCustomer(savedCustomerData);
    //   }
    // }

    setSavingCustomer(false);

    uploadTags({ userData, tags });
  };

  async function saveCustomer({ customerData: newCustomerData }) {
    try {
      const duplicateCustomer = await checkForDuplicateCustomer(
        userData,
        newCustomerData
      );
      if (duplicateCustomer) {
        setDuplicateCustomer(duplicateCustomer);
        setDuplicateCustomerWarning(true);
        return;
      }

      if (duplicateCustomerWarning || duplicateCustomer) {
        return;
      }

      const savedCustomerData = await saveCustomerData(
        userData,
        newCustomerData
      );
      openSnackBar("Customer added successfully", true);
      setDuplicateCustomerWarning(false);
      toggleModal();
      setSelectedCustomer(savedCustomerData);
      return savedCustomerData;
    } catch (error) {
      console.error("Error adding customer: ", error);
      alert("Error adding customer:", error);
      return null;
    }
  }

  async function updateCustomer({
    customerData: newCustomerData,
    jobDetails,
    customerId,
  }) {
    try {
      const updatedCustomerData = await updateCustomerData(
        userData,
        newCustomerData,
        customerId
        // jobDetails.customer.customerId
      );

      if (!!jobDetails)
        await updateDetails({ userData, jobDetails, updatedCustomerData });

      // if we made it this far, without an error, we can update the jobDetails state

      // MF: in isUpdate case this updates the JobDetails and EstimateDetails state, instead of just populating selected customer. Good idea to refactor JobDetails and EstimateDetails to extact customer data into separate state.

      setSelectedCustomer(updatedCustomerData);
      openSnackBar("Customer updated successfully", true);
      toggleModal();
    } catch (error) {
      console.log(error);
      alert("Failed to update customer. Please try again.");
    }
  }

  const handleMerge = async () => {
    const customerData = {
      firstName,
      lastName,
      displayName,
      phone: {
        mobile: mobilePhone,
        home: homePhone,
        work: workPhone,
        additional: additionalPhone,
      },
      email: [email, additionalEmail],
      address: [address, additionalAddress],
      notes,
      attachments,
      tags,
      notifications,
    };
    await handleMergeAfterWarning({ customerData });
  };

  const handleCancel = () => {
    const originalCustomer = {
      firstName: customer?.firstName ?? "",
      lastName: customer?.lastName ?? "",
      displayName: customer?.displayName ?? "",
      phone: {
        mobile: customer?.phone?.mobile ?? "",
        home: customer?.phone?.home ?? "",
        work: customer?.phone?.work ?? "",
        additional: customer?.phone?.additional ?? "",
      },
      // email: customer?.email ? [...customer.email, ""] : ["", ""],
      email: customer?.email ? customer.email : ["", ""],

      address: customer?.address ?? ["", ""],
      notes: customer?.notes ?? "",
      attachments: customer?.attachments ?? [],
      tags: customer?.tags ?? [],
      notifications: customer?.notifications ?? true,
    };

    const currentCustomer = {
      firstName,
      lastName,
      displayName,
      phone: {
        mobile: mobilePhone,
        home: homePhone,
        work: workPhone,
        additional: additionalPhone,
      },
      email: [email, additionalEmail],
      address: [address, additionalAddress],
      notes,
      attachments,
      tags,
      notifications,
    };

    const dirty =
      JSON.stringify(originalCustomer) !== JSON.stringify(currentCustomer);

    console.log(
      "dirty",
      dirty,
      JSON.stringify(originalCustomer),
      JSON.stringify(currentCustomer)
    );

    if (dirty) {
      // Show warning modal if form is dirty and in edit mode
      toggleWarningModal();
    } else {
      setModal(false);
    }
  };

  const toggleWarningModal = () => {
    setWarningModal(!warningModal);
  };

  const handleConfirmWarningModal = () => {
    setModal(false);
    setWarningModal(false);
  };

  const handleMergeAfterWarning = async ({ customerData: newCustomerData }) => {
    if (savingDuplicateCustomer) return; // don't allow multiple clicks
    try {
      setSavingDuplicateCustomer(true);

      const mergedCustomerData = await mergeCustomerData(
        userData,
        duplicateCustomer,
        newCustomerData
      );

      setSelectedCustomer(mergedCustomerData);
      openSnackBar("Customer merged successfully", true);
      toggleModal();
      setDuplicateCustomerWarning(false);
    } catch (error) {
      console.error("Error merging customer: ", error);
      alert("Error merging customer:", error);
    } finally {
      setSavingDuplicateCustomer(false);
    }
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  if (!modal) return null;

  return (
    <div className={`modal`}>
      <div className="overlay"></div>
      <div className="modal-content ">
        <button
          className="absolute top-3 right-3 hover:bg-gray-100 rounded-lg p-1"
          onClick={handleCancel}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <h2 className="text-xl font-bold text-center mb-3">
          {isEditMode ? "Edit Customer" : "Create a New Customer"}
        </h2>
        <div className="flex flex-row mb-4 items-center">
          <BsPersonFill className="text-2xl mr-2 text-gray-600" />
          <h2 className="text-lg font-medium">Contact Details</h2>
        </div>
        <div className="flex flex-row justify-center gap-4">
          <InputMUI
            label="First Name"
            value={firstName}
            setValue={setFirstName}
          />

          <InputMUI label="Last Name" value={lastName} setValue={setLastName} />
        </div>

        <div className="mt-4 flex-1 w-full">
          <InputMUI
            label="Display Name"
            value={displayName}
            setValue={setDisplayName}
          />
        </div>
        <div className="w-full flex flex-row justify-center gap-4 mt-4">
          <PhoneInputMUI
            label="Mobile Phone"
            value={mobilePhone}
            setValue={setMobilePhone}
          />

          <PhoneInputMUI
            label="Home Phone"
            value={homePhone}
            setValue={setHomePhone}
          />
        </div>

        <ExtraPhoneAccordion
          workPhone={workPhone}
          setWorkPhone={setWorkPhone}
          additionalPhone={additionalPhone}
          setAdditionalPhone={setAdditionalPhone}
        />

        <InputMUI label="Email" value={email} setValue={setEmail} />
        <ExtraEmailAccordion
          additionalEmail={additionalEmail}
          setAdditionalEmail={setAdditionalEmail}
        />

        <hr className="mt-8 border-1 border-gray-400 border-dashed" />
        <div className="flex flex-row mb-4 mt-8 items-center">
          <HiLocationMarker className="text-2xl mr-2 text-gray-600" />
          <h2 className="text-lg font-medium">Address</h2>
        </div>

        <AutocompleteMUI
          label="Address"
          address={address}
          setAddress={setAddress}
        />

        <ExtraAutocompleteAccordion
          additionalAddress={additionalAddress}
          setAdditionalAddress={setAdditionalAddress}
        />

        <hr className="mt-8 border-1 border-gray-400 border-dashed" />
        <div className="flex flex-row mb-4 mt-8 items-center">
          <CgNotes className="text-2xl text-gray-600 mr-2" />
          <h2 className="text-lg font-medium">Notes</h2>
        </div>
        {/* <InputWithLabel label="Notes" value={notes} setValue={setNotes} /> */}
        <InputMUI label="Notes" value={notes} setValue={setNotes} multiline />
        <hr className="mt-8 border-1 border-gray-400 border-dashed" />
        <AttachmentsAccordion
          attachments={attachments}
          setAttachments={setAttachments}
          tags={tags}
          setTags={setTags}
        />
        <div className="flex flex-row mb-4 mt-8 items-center">
          {/* {notifications ? (
            <FaToggleOn
              onClick={() => setNotifications(!notifications)}
              className="text-3xl mr-2 cursor-pointer"
            />
          ) : (
            <FaToggleOff
              onClick={() => setNotifications(!notifications)}
              className="text-3xl mr-2 cursor-pointer"
            />
          )} */}
          {notifications ? (
            <FaToggleOn
              onClick={() => setNotifications(!notifications)}
              className="text-3xl mr-2 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105"
            />
          ) : (
            <FaToggleOff
              onClick={() => setNotifications(!notifications)}
              className="text-3xl mr-2 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105"
            />
          )}

          <h2 className="text-lg font-medium">Notifications</h2>
        </div>
        <hr className="mt-8 mb-8 border-1 border-gray-400 border-dashed" />
        <div className="flex flex-row justify-end items-center mb-4 gap-2 flex-1">
          <OutlineButton
            text="Cancel"
            onClick={handleCancel}
            disabled={savingCustomer}
            fullWidth
          />
          <SolidButton
            onClick={handleSave}
            loading={savingCustomer}
            text="Save"
            fullWidth
          />
        </div>

        <Modal
          open={warningModal}
          onClose={toggleWarningModal}
          aria-labelledby="warning-modal-title"
          aria-describedby="warning-modal-description"
        >
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[500px] bg-white p-7 rounded-md h-48">
            <div className="">
              <h1 className="text-2xl font-semibold">Are you sure?</h1>
              <h1>Your changes will be discarded</h1>
            </div>
            <div className="flex flex-row justify-end items-center mt-10 gap-3 flex-1">
              <OutlineButton text="Cancel" onClick={handleCancel} fullWidth />
              <SolidButton
                onClick={handleConfirmWarningModal}
                loading={savingCustomer}
                text="Confirm"
                fullWidth
              />
            </div>
          </div>
        </Modal>

        <Modal
          open={duplicateCustomerWarning}
          onClose={() => setDuplicateCustomerWarning(false)}
          aria-labelledby="duplicate-warning-modal-title"
          aria-describedby="duplicate-warning-modal-description"
        >
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[500px] min-w-[500px] bg-white p-7 rounded-md">
            <div className="mt-5">
              <h1 className="text-2xl font-semibold">
                There is already a customer with the same phone number
              </h1>
              <h1>Do you want to merge them?</h1>
            </div>
            <div className="flex flex-row justify-end items-center mt-8 gap-3 flex-1">
              <OutlineButton
                disabled={savingDuplicateCustomer}
                text="Cancel"
                onClick={handleCancel}
                fullWidth
              />
              <SolidButton
                onClick={handleMerge}
                loading={savingDuplicateCustomer}
                text="Confirm"
                fullWidth
              />
            </div>
          </div>
        </Modal>
      </div>
      {/* </Modal> */}
    </div>
  );
}
