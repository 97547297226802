import React from "react";
import ExitModalInlineButton from "components/buttons/ExitModalInlineButton";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

export default function Header({
  title,
  onClose,
  openInNewTab = false,
  containerClass = "",
}) {
  const handleOpenInNewTab = () => {
    // Construct the full URL
    const fullUrl = new URL(openInNewTab, window.location.origin).href;
    window.open(fullUrl, "_blank");
  };
  return (
    <div
      className={`w-full h-16 min-h-16 flex flex-row items-center justify-between border-b border-gray-300 ${containerClass}`}
    >
      <div className="flex flex-row items-center">
        <h1 className="text-xl font-bold text-gray-800 ml-6">{title}</h1>
        {openInNewTab && (
          <button
            onClick={handleOpenInNewTab}
            className="ml-4 p-1 rounded-lg hover:bg-gray-100 transition-colors duration-200"
            title="Open in new tab"
          >
            <ArrowTopRightOnSquareIcon className="h-6 w-6 text-gray-600" />
          </button>
        )}
      </div>
      <ExitModalInlineButton handleClose={onClose} className="mr-6" />
    </div>
  );
}
