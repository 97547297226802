import React, { useEffect, useState } from "react";
import { useUserContext } from "context/UserContext";
import { node } from "constants/constants";
import { useNavigate, useLocation } from "react-router-dom";
import { BsFillArrowRightSquareFill } from "react-icons/bs";

// /new-subscription-success?session_id=cs_test_a1234567890&intent=games
export default function NewSubscriptionSuccess() {
  const { userData, setUserData } = useUserContext();

  //   console.log("userData.subData", userData?.subData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const sessionId = params.get("session_id"); // Get the checkout session ID from URL
  const intent = params.get("intent");

  useEffect(() => {
    // if at any point the userData?.subData?.status is active then we can navigate to the dashboard because that means the subscription is active and was updated by the webhook
    if (userData?.subData?.status === "active") {
      console.log(
        "userData?.subData?.status was found active, automatically navigating to dash"
      );
      navigateToDashboard();
    }
  }, [userData]);

  useEffect(() => {
    const getSubscriptionData = async () => {
      if (!sessionId || !userData) return;

      try {
        setLoading(true);
        // Fetch checkout session data from backend
        const resSession = await fetch(
          `${node}/subscriptions/get-checkout-session`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              checkoutSessionId: sessionId,
            }),
          }
        );

        const sessionData = await resSession.json();
        if (!resSession.ok) {
          throw new Error(
            sessionData.error || "Failed to retrieve checkout session"
          );
        }

        const checkoutSession = sessionData.checkoutSession;
        // console.log("Checkout Session:", checkoutSession);

        // Get subscription ID from checkout session
        const subscriptionId = checkoutSession.subscription;
        if (!subscriptionId) {
          throw new Error("No subscription ID found in checkout session");
        }

        // Fetch subscription data from backend
        const resSubscription = await fetch(
          `${node}/subscriptions/get-subscription`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              subscriptionId: subscriptionId,
            }),
          }
        );

        const subscriptionData = await resSubscription.json();
        if (!resSubscription.ok) {
          throw new Error(
            subscriptionData.error || "Failed to retrieve subscription data"
          );
        }

        const subscription = subscriptionData.subscription;
        // console.log("Subscription Data:", subscription);

        // Update userData context with new subscription data
        setUserData({ ...userData, subData: subscription });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching subscription data:", error);
        setError("Failed to fetch subscription data. Please contact support.");
        setLoading(false);
      }
    };

    getSubscriptionData();
  }, [sessionId]);

  const navigateToDashboard = () => {
    if (intent === "games") {
      navigate("/games");
    } else {
      navigate("/", { state: { newSubscription: true } });
    }
  };

  if (error) {
    return (
      <div className="flex h-screen items-center justify-center bg-slate-100">
        <div className="rounded-lg bg-white p-8 shadow-lg">
          <h1 className="mb-4 text-2xl font-bold text-red-600">Error</h1>
          <p className="text-gray-700">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-full min-h-screen w-full flex-col items-center overflow-auto bg-slate-100/90 font-stripe scrollbar-hide">
      <h1 className="mt-12 flex justify-center pl-2 text-2xl font-semibold text-slate-600">
        <img
          src="/images/Homebase-logo-black.png"
          width={200}
          alt="Homebase logo"
        />
      </h1>
      <div className="relative mt-10 mb-36 flex w-11/12 max-w-4xl flex-col items-center rounded-lg bg-slate-50 px-5 shadow-2xl sm-px-10">
        <h1 className="mt-10 flex max-w-xl justify-center pl-2 text-center text-2xl font-semibold text-gray-600">
          Welcome to Homebase 360! Your subscription is active. 🎉
        </h1>

        <p className="mt-4 mb-6 flex max-w-xl justify-center pl-2 text-center text-lg font-semibold text-gray-600">
          {loading
            ? "We're finalizing your account setup. This will only take a moment."
            : "Your account is ready! You can now access all the features of your subscription."}
        </p>

        <button
          disabled={loading}
          className={`mt-4 mb-10 flex h-12 w-56 flex-row items-center justify-center rounded-md bg-gray-800 font-medium text-yellow-50 ${
            !loading && "hover:text-yellow-100"
          }`}
          onClick={navigateToDashboard}
        >
          {loading ? (
            <span className="">Finalizing setup...</span>
          ) : (
            <>
              <span className="mr-2">Go to dashboard</span>
              <BsFillArrowRightSquareFill className="text-sm text-green-400" />
            </>
          )}
        </button>
      </div>
    </div>
  );
}
