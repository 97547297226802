import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { useUserContext } from "context/UserContext";
import { formatDateTimezone } from "utils/helpers";
import { formatPhoneDisplay } from "utils/formatPhone";
import { CircularProgress, Skeleton } from "@mui/material";
import { colors } from "theme/colors";
import { formatCustomerForDisplay } from "../../helpers";

const stylingOverride = {
  "& .MuiDataGrid-root": {
    fontFamily: "inherit", // Use the inherited font family
  },
  "& .MuiDataGrid-cell": {
    fontFamily: "inherit", // Use the inherited font family for cells
    padding: "8px", // Set your desired padding
  },
  "& .MuiDataGrid-columnHeader": {
    fontFamily: "inherit", // Use the inherited font family for column headers
  },
  "& .MuiDataGrid-row": {
    cursor: "pointer",
  },
  "& .MuiDataGrid-row.Mui-selected": {
    backgroundColor: "#fefce8", // This is a light yellow. Change this to your desired color.
  },
  "& .MuiDataGrid-row.Mui-selected:hover": {
    backgroundColor: "#fef9c3", // This is a yellow color for the hover effect. Adjust as needed.
  },
  "& .MuiDataGrid-cell:focus": {
    boxShadow: "none",
    outline: "none",
  },
  "& .MuiDataGrid-cellCheckbox:focus-within": {
    boxShadow: "none",
    outline: "none",
  },
  "& .MuiDataGrid-columnHeader:focus-within": {
    boxShadow: "none",
    outline: "none",
  },
  "& .MuiDataGrid-columnHeader:focus": {
    boxShadow: "none",
    outline: "none",
  },
  "& .MuiDataGrid-columnHeader:focus .MuiDataGrid-sortIcon": {
    boxShadow: "none",
    outline: "none",
  },
  "& .MuiDataGrid-columnHeaderCheckbox:focus-within": {
    boxShadow: "none",
    outline: "none",
  },
  "& .MuiDataGrid-columnHeaderCheckbox .MuiIconButton-root:focus-visible": {
    boxShadow: "none",
    outline: "none",
  },
  "& .MuiTouchRipple-root": {
    display: "none", // hide the ripple effect
  },
  "& .MuiCheckbox-root": {
    color: "#111827",
  },
  "& .MuiCircularProgress-root": {
    color: colors.yellow200,
  },
  "& .Mui-checked .MuiSvgIcon-root": {
    color: "#111827",
  },
};

export default function CustomerTable({
  customers,
  selectedRows,
  setSelectedRows,
  handleCustomerSelect,
  setFilterOptions,
  filterOptions,
  nextPage,
  totalCustomersCount,
  columnHeaders,
  loadingCustomers,
  fetchType,
}) {
  const { userData } = useUserContext();
  const [pagnationModel, setPagnationModel] = useState({
    pageSize: filterOptions.amount || 25,
    page: 0,
  });

  const tableFormattedCustomers = useMemo(
    () =>
      customers?.map((customer) =>
        formatCustomerForDisplay(customer, userData)
      ),
    [customers, userData]
  );

  return (
    <Box className="relative rounded-b-md h-[calc(100vh-240px)]">
      <DataGrid
        sx={stylingOverride}
        rows={tableFormattedCustomers}
        columns={
          fetchType === "searchInput"
            ? columnHeaders.map((col) => ({ ...col, sortable: false })) // algolia search returns sorted results, sorting them causes issues
            : columnHeaders
        }
        loading={loadingCustomers}
        localeText={{
          noRowsLabel: "No customers",
        }}
        slots={{
          LoadingOverlay: CustomLoadingOverlay, // Add your custom loading overlay here
          // NoRowsOverlay: CustomNoRowsOverlay,
        }}
        initialState={{
          columnVisibilityModel: {
            name: false,
            address: false,
          },
        }}
        onRowClick={(params) => handleCustomerSelect(params.row)}
        pageSizeOptions={[10, 25, 50, 100]}
        onPaginationModelChange={(pages) => {
          if (pages.pageSize !== filterOptions.amount) {
            // the amount has been changed, reset the model
            setFilterOptions((p) => ({ ...p, amount: pages.pageSize })); // updating amount
            setPagnationModel({ ...pages, page: 0 });
          } else {
            // turn page
            nextPage(pages.page);
            setPagnationModel(pages);
          }
        }}
        paginationModel={pagnationModel}
        checkboxSelection
        disableRowSelectionOnClick
        getRowHeight={() => "auto"}
        keepNonExistentRowsSelected
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setSelectedRows(newRowSelectionModel);
        }}
        pagination
        checkboxSelectionVisibleOnly
        rowSelectionModel={selectedRows}
        paginationMode={"server"}
        rowCount={totalCustomersCount}
        sortingMode="server"
        onSortModelChange={(newSortModel) => {
          setPagnationModel((p) => ({ ...p, page: 0 })); // return to page 0, on sorting change
          // add new order by model or delete it if there is no new model
          if (newSortModel.length)
            setFilterOptions((p) => ({
              ...p,
              orderBy: [newSortModel[0]?.field, newSortModel[0]?.sort],
            }));
          else
            setFilterOptions((p) => {
              const newFilterOptions = { ...p };
              delete newFilterOptions.orderby;
              return newFilterOptions;
            });
        }}
      />
    </Box>
  );
}

// Custom loading overlay component
const CustomLoadingOverlay = () => {};

// const CustomNoRowsOverlay = () => (
//   <GridOverlay>
//     <Box sx={{ mt: 1 }}>No customers found</Box>
//   </GridOverlay>
// );
