import React, { useState, useEffect, useRef, useContext, useMemo, useCallback } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Scheduled from "./components/Scheduled";
import LineItemsStatic from "./components/LineItemsStatic";
import CustomerJob from "./components/CustomerJob";
import Summary from "./components/Summary";
import SelectDateModal from "./components/SelectDateModal";
import DispatchedTo from "./components/DispatchedTo";
import EditDispatchedToModal from "./components/EditDispatchedToModal";
import LeadSourceJobDetails from "./components/LeadSourceJobDetails";
import EditLeadSourceModal from "./components/EditLeadSourceModal";
import NoteJobDetails from "./components/NoteJobDetails";
import AddNoteModal from "./components/AddNoteModal";
import AddLineItemModal from "./components/AddLineItemModal";
import ManualPaymentModal from "./components/ManualPaymentModal";
import RefundModal from "./components/RefundModal";
import PaymentHistory from "./components/PaymentHistory";
import DeleteModal from "./components/DeleteModal";
import { UserContext } from "../../index";
import { db } from "../../utils/firebase";
import { doc, deleteDoc } from "firebase/firestore";
import CustomerModalCombinedNew from "components/CustomerModalCombined/CustomerModalCombined";
import FutureVisits from "../AddJob/components/FutureVisits";
import SubDetailsPopOverModal from "features/ServiceSubscription/components/SubDetails/SubDetailsPopOverModal";
import useUpdateSubscriptionJobDetails from "features/ServiceSubscription/components/hooks/useUpdateSubscriptionJobDetails";
import { useSubscriptionsJobDetails } from "./data/useSubscriptionsJobDetails";
import { useJobDetailsListener } from "./data/useJobDetailsListener";
import { useToggle } from "../../hooks/useToggle";
import useFetch from "../../hooks/useFetch";
// import { fetchSubscriptions } from "data/api";
import {
  fetchJobDetails,
  getAndUpdateCustomer,
  batchDeleteRecurringJobs,
  toggleNotificationsFirestore,
  fetchSubscriptionsAndSelectedSubscription,
} from "./data/api";
import { JobButtons } from "./components/JobButtons";
import { OptionsDropdown } from "./components/OptionsDropdown";
import { useEmployeeContext } from "../../context/EmployeeContext";
import { BackLink } from "../../components/NewButtons/BackLink";
import { PageHeader } from "../../layouts/PageHeader";
import { AiOutlineConsoleSql } from "react-icons/ai";

export default function JobDetails() {
  const { userData, bannerVisible } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { jobId } = useParams(); // we could get the jobId this way from the params
  const { jobId: oldJobId, currentDate, currentView } = location?.state || {};
  const timeZone = userData?.bizData?.timeZone;
  // const [jobDetails, setJobDetails] = useState(null); // we could eliminate load times by passing all the job details from the schedule page --> but then it creates complexity with changing dates (but not that bad)

  const [modal, setModal] = useState(false);
  const [dateModal, setDateModal] = useState(false);
  const [dispatchModal, setDispatchModal] = useState(false);
  const [leadSourceModal, setLeadSourceModal] = useState(false);
  const [noteModal, setNoteModal] = useState(false);
  const [lineItemModal, setLineItemModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [subDetailsModal, setSubDetailsModal] = useState(false);
  const [manualPaymentModal, setManualPaymentModal] = useState(false);
  const [refundModal, setRefundModal] = useState(false);
  const [refundPayment, setRefundPayment] = useState(null);
  // const [cancelModal, setCancelModal] = useState(false);

  const { employees: employeeList } = useEmployeeContext();

  const { jobDetails, setJobDetails } = useJobDetailsListener({
    businessId: userData.userData.businessId,
    jobId,
  });

  const toggle = useToggle();

  const toggleLineItemModal = () => toggle(setLineItemModal);
  const toggleNoteModal = () => toggle(setNoteModal);
  const toggleLeadSourceModal = () => toggle(setLeadSourceModal);
  const toggleDispatchModal = () => toggle(setDispatchModal);
  const toggleDateModal = () => toggle(setDateModal);
  const toggleModal = () => toggle(setModal);

  const deleteJob = async () => {
    await deleteDoc(jobRef);
    navigate("/schedule");
  };

  const deleteRecurring = async () => {
    await batchDeleteRecurringJobs({ jobDetails, userData });
    navigate("/schedule");
  };

  const handleRefund = async (payment) => {
    setRefundPayment(payment);
    setRefundModal(true);
  };

  const { data: subscription } = useSubscriptionsJobDetails({
    businessId: userData.userData.businessId,
    customerId: jobDetails?.customer?.customerId,
    subscription: jobDetails?.subscription,
  });

  ///////////////////////////////////// deprecated bc we need a listener
  // const fetchSubscriptionsArgs = useMemo(
  //   () => ({
  //     businessId: userData.userData.businessId,
  //     customerId: jobDetails?.customer?.customerId,
  //     subscription: jobDetails?.subscription,
  //   }),
  //   [
  //     jobDetails?.customer?.customerId,
  //     jobDetails?.subscription,
  //     userData.userData.businessId,
  //   ]
  // );

  // // we should just refetch this whenever the jobDetails.subscription changes?? I think that would do the trick probably..
  // // whenever the function args change then this should be refetch automatically
  // // this is now a real time listener.. we need to do some testing to ensure that this works..
  // const { data: subscription } = useFetch(
  //   fetchSubscriptionsAndSelectedSubscription,
  //   fetchSubscriptionsArgs
  // );

  //////////////////////////////////////// Deprecated bc we need a listener as well
  // const fetchJobDetailsArgs = useMemo(
  //   () => ({ businessId: userData.userData.businessId, jobId }),
  //   [userData.userData.businessId, jobId]
  // );

  // const { data: jobDetailsData } = useFetch(
  //   fetchJobDetails,
  //   fetchJobDetailsArgs
  // );

  // useEffect(() => {
  //   if (jobDetailsData) {
  //     setJobDetails(jobDetailsData);
  //   }
  // }, [jobDetailsData]);

  const jobRef = doc(db, "businesses", userData.userData.businessId, "jobs", jobId);

  useEffect(() => {
    getAndUpdateCustomer({
      userData,
      customerId: jobDetails?.customer?.customerId,
      jobRef,
    }).then((customerData) => {
      if (customerData)
        setJobDetails((prev) => ({
          ...prev,
          customer: {
            ...customerData,
          },
        }));
    });
  }, [jobDetails?.customer?.customerId]);

  const toggleNotifications = async () => {
    const newNotificationValue = !jobDetails.customer.notifications;
    try {
      // Optimistically update the state
      setJobDetails({
        ...jobDetails,
        customer: {
          ...jobDetails.customer,
          notifications: newNotificationValue,
        },
      });
      await toggleNotificationsFirestore({ userData, jobDetails, jobRef });
    } catch (err) {
      console.error("Error", err);
      setJobDetails({
        ...jobDetails,
        customer: {
          ...jobDetails.customer,
          notifications: !newNotificationValue, // revert to the original value
        },
      });
    }
  };

  return (
    <>
      <div
        className="bg-gray-50 "
        style={{
          height: bannerVisible ? "calc(100vh - 60px - 60px)" : "calc(100vh - 64px)",
          overflowY: "auto",
        }}
      >
        <div className="max-w-[1510px] mx-auto">
          <PageHeader
            title="Job"
            backLink={
              currentDate &&
              currentView && (
                <BackLink
                  toName={"Schedule"}
                  toLink={"/schedule"}
                  state={{ datePassed: currentDate, viewPassed: currentView }}
                />
              )
            }
          >
            <OptionsDropdown
              jobDetails={jobDetails}
              setJobDetails={setJobDetails}
              jobRef={jobRef}
              setDeleteModal={setDeleteModal}
            />
          </PageHeader>

          <JobButtons
            jobDetails={jobDetails}
            setJobDetails={setJobDetails}
            setManualPaymentModal={setManualPaymentModal}
            jobId={jobId}
          />

          <div className="flex flex-col gap-10 lg:grid lg:grid-cols-5 lg:relative flex-1 p-4 mx-1">
            <div className="flex flex-col flex-grow lg:col-start-3 lg:col-end-6 lg:row-start-1">
              <div className="lg:mb-0 mb-[-40px]">
                <Summary jobDetails={jobDetails} />
              </div>
              <div className="hidden lg:block">
                <LineItemsStatic
                  lineItems={jobDetails?.lineItems || []}
                  toggleModal={toggleLineItemModal}
                  disabled={true}
                />
              </div>
              <div className="hidden lg:block">
                <PaymentHistory
                  jobDetails={jobDetails}
                  handleRefund={handleRefund}
                  setJobDetails={setJobDetails}
                />
              </div>
            </div>
            <div className="flex flex-col lg:col-start-1 lg:col-end-3 lg:row-start-1  gap-2 lg:gap-0">
              <div className="flex flex-col flex-none">
                <CustomerJob
                  toggleModal={toggleModal}
                  customer={jobDetails?.customer}
                  notifications={jobDetails?.customer?.notifications}
                  toggleNotifications={toggleNotifications}
                  navigate={navigate}
                  jobId={jobId}
                />

                {subscription &&
                  subscription?.selectedSubscription &&
                  subscription?.subscriptions && (
                    <FutureVisits
                      subscription={subscription}
                      setSubscription={(newSubscription) => {
                        setJobDetails((prev) => {
                          return { ...prev, subscription: newSubscription };
                        });
                      }}
                      fromJobDetails={jobId}
                      openSubscriptionDetailsModal={() => setSubDetailsModal(true)}
                    />
                  )}

                <Scheduled
                  jobDetails={jobDetails}
                  jobStart={jobDetails?.start}
                  jobEnd={jobDetails?.end}
                  toggleDateModal={toggleDateModal}
                />
                <div className="lg:hidden pt-6 mb-[-32px]">
                  <LineItemsStatic
                    lineItems={jobDetails?.lineItems || []}
                    toggleModal={toggleLineItemModal}
                    disabled={true}
                  />
                </div>
                <DispatchedTo
                  employees={jobDetails?.dispatchedTo}
                  toggleDispatchModal={toggleDispatchModal}
                />
                <LeadSourceJobDetails
                  toggleLeadSourceModal={toggleLeadSourceModal}
                  leadSource={jobDetails?.leadSource}
                />
                <div className="pt-8">
                  <NoteJobDetails
                    note={jobDetails?.note}
                    setNote={(note) => setJobDetails({ ...jobDetails, note })}
                    toggleNoteModal={toggleNoteModal}
                    images={jobDetails?.images || []}
                    voiceMemo={jobDetails?.voiceMemo || null}
                  />
                </div>
                <div className="lg:hidden pb-4 mt-[-6px]">
                  <PaymentHistory jobDetails={jobDetails} handleRefund={handleRefund} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <CustomerModalCombinedNew
          modal={modal}
          setModal={setModal}
          setSelectedCustomer={(updatedCustomerData) => {
            setJobDetails((prev) => {
              return { ...prev, customer: { ...updatedCustomerData } };
            });
          }}
          jobDetails={{ ...jobDetails, type: "job" }}
          customer={jobDetails?.customer}
          customerId={jobDetails?.customer.customerId}
        />

        {dateModal && (
          <SelectDateModal
            modal={dateModal}
            setModal={setDateModal}
            jobDetails={jobDetails}
            setJobDetails={setJobDetails}
          />
        )}

        <EditDispatchedToModal
          employeeList={employeeList}
          modal={dispatchModal}
          setModal={setDispatchModal}
          jobDetails={jobDetails}
          setJobDetails={setJobDetails}
        />

        <EditLeadSourceModal
          employeeList={employeeList}
          modal={leadSourceModal}
          setModal={setLeadSourceModal}
          jobDetails={jobDetails}
          setJobDetails={setJobDetails}
        />

        <AddNoteModal
          jobDetails={jobDetails}
          setJobDetails={setJobDetails}
          modal={noteModal}
          setModal={setNoteModal}
        />

        <AddLineItemModal
          toggleModal={toggleLineItemModal}
          jobDetails={jobDetails}
          setJobDetails={setJobDetails}
          setLineItemModal={setLineItemModal}
          lineItemModal={lineItemModal}
        />

        {manualPaymentModal && (
          <ManualPaymentModal
            toggleModal={() => setManualPaymentModal(false)}
            jobDetails={jobDetails}
            setJobDetails={setJobDetails}
            userData={userData}
            jobRef={jobRef}
          />
        )}
        {refundModal && (
          <RefundModal
            toggleModal={() => setRefundModal(false)}
            payment={refundPayment}
            jobRef={jobRef}
            setJobDetails={setJobDetails}
            userData={userData}
            jobDetails={jobDetails}
          />
        )}
        {deleteModal && (
          <DeleteModal
            deleteJob={deleteJob}
            deleteRecurring={deleteRecurring}
            isRecurring={jobDetails?.isRecurring}
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
          />
        )}

        {subscription?.selectedSubscription?.subscriptionId && (
          <SubDetailsPopOverModal
            open={subDetailsModal}
            onClose={() => setSubDetailsModal(false)}
            subscription={subscription?.selectedSubscription}
            setSubscription={(newSubscription) => {
              setJobDetails((prev) => {
                return {
                  ...prev,
                  subscription: {
                    ...prev.subscription,
                    selectedSubscription: newSubscription,
                  },
                };
              });
            }}
          />
        )}
      </div>
    </>
  );
}
