import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { convertToJsDate } from "utils/helpers";

export default function HistoryTabs({
  selectedTab,
  setSelectedTab,
  jobs,
  invoices,
  estimates,
  subscriptions,
  loadingJobs,
  loadingInvoices,
  loadingEstimates,
  loadingSubscriptions,
}) {
  const tabs = ["Job History", "Estimates", "Invoices", "Subscriptions"];

  // const tabs = ["Job History", "Estimates", "Invoices"];

  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    if (!loadingJobs && !loadingInvoices && !loadingEstimates && !loadingSubscriptions) {
      const timer = setTimeout(() => setShowLoading(false), 100); // Small delay to ensure smooth transition
      return () => clearTimeout(timer);
    } else {
      setShowLoading(true);
    }
  }, [loadingJobs, loadingInvoices, loadingEstimates, loadingSubscriptions]);

  let selectedComponent;
  switch (selectedTab) {
    case "Job History":
      selectedComponent = showLoading ? <JobHistorySkeleton /> : <JobHistory jobs={jobs} />;
      break;
    case "Estimates":
      selectedComponent = showLoading ? <EstimatesSkeleton /> : <Estimates estimates={estimates} />;
      break;
    case "Invoices":
      selectedComponent = showLoading ? <InvoicesSkeleton /> : <Invoices invoices={invoices} />;
      break;
    case "Subscriptions":
      selectedComponent = showLoading ? (
        <SubscriptionsSkeleton />
      ) : (
        <Subscriptions subscriptions={subscriptions} />
      );
      break;
    default:
      selectedComponent = <Default />;
  }

  return (
    <div className="relative  flex-col ml-5 bg-gray-800 rounded-md shadow-lg ">
      <div className="flex flex-row gap-2 mb-5 mt-5 ml-5 ">
        {tabs.map((tab, key) => (
          <button
            key={key}
            className={`${
              selectedTab === tab
                ? "bg-gray-50 text-gray-900"
                : "bg-gray-600 text-gray-50 hover:text-yellow-50"
            } shadow-md rounded p-2 transition duration-300 ease-in-out`}
            onClick={() => setSelectedTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      {selectedComponent}
    </div>
  );
}

function JobHistory({ jobs }) {
  const navigate = useNavigate();
  return (
    <>
      <h1 className="absolute z-5 bottom-[-28px] right-0 pr-2 pt-1 bg-gray-50 font-bold w-full text-right">
        {jobs?.length || "0"} Jobs Total
      </h1>
      <div className="relative flex flex-col bg-white max-h-full-customer-detail-page  overflow-auto shadow-lg">
        <div className="flex flex-row font-semibold items-center w-fit justify-start py-3 px-5 gap-5 border-b-2 border-yellow-400 bg-gray-900/5 whitespace-nowrap ">
          <h1 className="w-20 shrink-0">Date</h1>

          <h1 className="w-28 shrink-0">Description</h1>
          <h1 className="w-28 shrink-0">Lead Source</h1>
          <h1 className="w-20 shrink-0">Amount</h1>
          <h1 className="w-28 shrink-0">Status</h1>
          <h1 className="w-24 shrink-0">Payment</h1>
          <h1 className="w-28 shrink-0">Notes</h1>
          <h1 className="w-36 shrink-0">Employees</h1>
        </div>
        {jobs && jobs.length ? (
          jobs.map((job, key) => (
            <div
              key={key}
              className="flex flex-row items-start justify-start px-5 gap-5 py-3 hover:bg-gray-100 hover:cursor-pointer min-w-fit w-full"
              onClick={() =>
                navigate(`/jobdetails/${job.jobId}`, {
                  state: { jobId: job.jobId },
                })
              }
            >
              <h1 className="w-20 shrink-0">{convertToJsDate(job.start)?.toLocaleDateString()}</h1>

              <h1 className="w-28 shrink-0">{job?.lineItems[0]?.name}</h1>
              <h1 className="w-28 shrink-0">
                {job.leadSource?.salesPerson
                  ? job.leadSource?.salesPerson?.firstName +
                    " " +
                    job.leadSource?.salesPerson?.lastName
                  : ""}
                <br />
                {job.leadSource &&
                  Object.entries(job.leadSource)
                    .filter(([key, value]) => value === true)
                    .map(([key]) => (
                      <div key={key}>
                        {key[0].toUpperCase() + key.slice(1)}
                        <br />
                      </div>
                    ))}
              </h1>
              <h1 className="w-20 shrink-0">
                $
                {parseFloat(job.jobTotal).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </h1>
              <h1 className="w-28 shrink-0">{job.status ?? "scheduled"}</h1>
              <h1 className="w-24 shrink-0">{job.paymentHistory?.[0]?.billingType ?? ""}</h1>
              <h1 className="w-28 shrink-0">{job.note}</h1>
              <h1 className="w-36 shrink-0">
                {" "}
                {job.dispatchedTo &&
                  job.dispatchedTo?.length > 0 &&
                  job.dispatchedTo.map((employee, key) => {
                    return (
                      <div key={key}>
                        {employee.firstName} {employee.lastName}
                        <br />
                      </div>
                    );
                  })}
              </h1>
            </div>
          ))
        ) : (
          <div className="flex flex-row items-center justify-center py-5">
            <h1 className="text-gray-400">No Jobs</h1>
          </div>
        )}
      </div>
    </>
  );
}

function Estimates({ estimates }) {
  const navigate = useNavigate();
  return (
    <>
      <h1 className="absolute z-5 bottom-[-28px] right-0 pr-2 pt-1 bg-gray-50 font-bold w-full text-right">
        {estimates?.length || "0"} Estimates Total
      </h1>
      <div className="relative flex flex-col bg-white max-h-full-customer-detail-page overflow-auto shadow-lg">
        <div className="flex flex-row font-semibold items-center  min-w-fit justify-start py-3 px-5 gap-5 border-b-2 border-yellow-400 bg-gray-900/5 whitespace-nowrap ">
          <h1 className="w-20 shrink-0">Date</h1>

          <h1 className="w-28 shrink-0">Description</h1>
          <h1 className="w-28 shrink-0">Lead Source</h1>
          <h1 className="w-20 shrink-0">Amount</h1>

          <h1 className="w-28 shrink-0">Notes</h1>
        </div>
        {estimates && estimates.length > 0 ? (
          estimates.map((estimate, key) => (
            <div
              key={key}
              className="flex flex-row items-start justify-start px-5 gap-5 py-3 hover:bg-gray-100 hover:cursor-pointer min-w-fit w-full"
              onClick={() =>
                navigate(`/estimates/${estimate.estimateId}`, {
                  state: { estimateId: estimate.estimateId },
                })
              }
            >
              <h1 className="w-20 shrink-0">
                {new Date(estimate.dateAdded.seconds * 1000).toLocaleDateString()}
              </h1>

              <h1 className="w-28 shrink-0">{estimate?.lineItems?.[0]?.description}</h1>
              <h1 className="w-28 shrink-0">
                {estimate.leadSource?.salesPerson
                  ? estimate.leadSource?.salesPerson?.firstName +
                    " " +
                    estimate.leadSource?.salesPerson?.lastName
                  : ""}
                <br />
                {estimate.leadSource &&
                  Object.entries(estimate.leadSource)
                    .filter(([key, value]) => value === true)
                    .map(([key]) => (
                      <div key={key}>
                        {key[0].toUpperCase() + key.slice(1)}
                        <br />
                      </div>
                    ))}
              </h1>
              <h1 className="w-20 shrink-0">
                $
                {parseFloat(estimate.estimateTotal).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </h1>

              <h1 className="w-28 shrink-0">{estimate.note}</h1>
            </div>
          ))
        ) : (
          <div className="flex flex-row items-center justify-center py-5">
            <h1 className="text-gray-400">No Estimates</h1>
          </div>
        )}
      </div>
    </>
  );
}

function Invoices({ invoices }) {
  return (
    <>
      <h1 className="absolute z-5 bottom-[-28px] right-0 pr-2 pt-1 bg-gray-50 font-bold w-full text-right">
        {invoices?.length || "0"} Invoices Total
      </h1>
      <div className="relative flex flex-col bg-white max-h-full-customer-detail-page  overflow-auto shadow-lg">
        <div className="flex flex-row font-semibold items-center min-w-fit justify-start py-3 px-5 gap-5 border-b-2 border-yellow-400 bg-gray-900/5 whitespace-nowrap ">
          <h1 className="w-28 shrink-0">Invoice</h1>
          <h1 className="w-28 shrink-0">Billing Type</h1>
          <h1 className="w-28 shrink-0">Date Sent</h1>
          <h1 className="w-28 shrink-0">Date Paid</h1>
          <h1 className="w-28 shrink-0">Amount</h1>
          <h1 className="w-28 shrink-0">Status</h1>
        </div>
        {invoices && invoices.length > 0 ? (
          invoices.map((invoice, key) => (
            <div
              key={key}
              className="flex flex-row items-start justify-start px-5 gap-5 py-3 hover:bg-gray-100 hover:cursor-pointer min-w-fit w-full"
            >
              <h1 className="w-28 shrink-0">
                {invoice.invoiceNumber
                  ? `#${invoice.invoiceNumber}`
                  : invoice.invoiceId.slice(0, 10).toUpperCase()}
              </h1>
              <h1 className="w-28 shrink-0">{invoice.billingType}</h1>
              <h1 className="w-28 shrink-0">
                {new Date(invoice.invoiceSentTime.seconds * 1000).toLocaleDateString()}
              </h1>
              <h1 className="w-28 shrink-0">
                {invoice.datePaid && new Date(invoice.datePaid.seconds * 1000).toLocaleDateString()}
              </h1>
              <h1 className="w-28 shrink-0">
                $
                {parseFloat(invoice.amount).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </h1>
              <h1 className="w-28 shrink-0">{invoice.status}</h1>
            </div>
          ))
        ) : (
          <div className="flex flex-row items-center justify-center py-5">
            <h1 className="text-gray-400">No Invoices</h1>
          </div>
        )}
      </div>
    </>
  );
}

function Subscriptions({ subscriptions }) {
  const navigate = useNavigate();
  return (
    <>
      <h1 className="absolute z-5 bottom-[-28px] right-0 pr-2 pt-1 bg-gray-50 font-bold w-full text-right">
        {subscriptions?.length || "0"} Subscriptions Total
      </h1>
      <div className="relative flex flex-col bg-white max-h-full-customer-detail-page  overflow-auto shadow-lg">
        <div className="flex flex-row font-semibold items-center min-w-fit justify-start py-3 px-5 gap-5 border-b-2 border-yellow-400 bg-gray-900/5 whitespace-nowrap ">
          <h1 className="w-28 shrink-0">Name</h1>
          <h1 className="w-28 shrink-0">Created At</h1>
          <h1 className="w-28 shrink-0">Status</h1>
          <h1 className="w-28 shrink-0">Sold By</h1>
          <h1 className="w-28 shrink-0">Amount</h1>
        </div>
        {subscriptions && subscriptions.length > 0 ? (
          subscriptions.map((subscription, key) => (
            <div
              onClick={() =>
                navigate(`/servicesubscription/${subscription.subscriptionId}`, {
                  state: {
                    subscriptionId: subscription.subscriptionId,
                    customerId: subscription.customer?.customerId, // signals if we are coming from customer details page.. so we can redirect back
                  },
                })
              }
              key={key}
              className="flex flex-row items-start justify-start px-5 gap-5 py-3 hover:bg-gray-100 hover:cursor-pointer min-w-fit w-full"
            >
              <h1 className="w-28 shrink-0">{subscription.template?.title || "No Name"}</h1>
              <h1 className="w-28 shrink-0">
                {convertToJsDate(subscription.createdAt).toLocaleDateString()}
              </h1>
              <h1 className="w-28 shrink-0">
                {subscription.status.slice(0, 1).toUpperCase() + subscription.status.slice(1)}
              </h1>
              <h1 className="w-28 shrink-0">
                {subscription.soldBy?.firstName && subscription.soldBy?.lastName ? (
                  <>
                    {subscription.soldBy?.firstName} {subscription.soldBy?.lastName}
                  </>
                ) : (
                  "-"
                )}
              </h1>
              <h1 className="w-28 shrink-0">
                $
                {parseFloat(subscription.template?.amount || 0).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </h1>
            </div>
          ))
        ) : (
          <div className="flex flex-row items-center justify-center py-5">
            <h1 className="text-gray-400">No Subscriptions</h1>
          </div>
        )}
      </div>
    </>
  );
}

const Default = () => {
  return (
    <div className="flex flex-row items-center justify-center py-5">
      <h1 className="text-gray-400">Nothing to display here</h1>
    </div>
  );
};

function JobHistorySkeleton() {
  return (
    <>
      <h1 className="absolute z-5 bottom-[-28px] right-0 pr-2 pt-1 bg-gray-50 font-bold w-full text-right text-gray-500">
        Loading...
      </h1>
      <div className="relative flex flex-col bg-white max-h-full-customer-detail-page overflow-auto shadow-lg">
        <div className="flex flex-row font-semibold items-center w-fit justify-start py-3 px-5 gap-5 border-b-2 border-yellow-400 bg-gray-900/5 whitespace-nowrap ">
          <h1 className="w-20 shrink-0">Date</h1>

          <h1 className="w-28 shrink-0">Description</h1>
          <h1 className="w-28 shrink-0">Lead Source</h1>
          <h1 className="w-20 shrink-0">Amount</h1>
          <h1 className="w-28 shrink-0">Status</h1>
          <h1 className="w-24 shrink-0">Payment</h1>
          <h1 className="w-28 shrink-0">Notes</h1>
          <h1 className="w-36 shrink-0">Employees</h1>
        </div>
        {[...Array(2)].map((_, i) => (
          <div
            key={i}
            className="flex flex-row items-start justify-start min-w-fit px-5 gap-5 py-3 "
          >
            <div className="w-20 h-6 bg-gray-200 rounded animate-pulse"></div>

            <div className="w-28 h-6 bg-gray-200 rounded animate-pulse"></div>
            <div className="w-28 h-6 bg-gray-200 rounded animate-pulse"></div>
            <div className="w-20 h-6 bg-gray-200 rounded animate-pulse"></div>
            <div className="w-28 h-6 bg-gray-200 rounded animate-pulse"></div>
            <div className="w-24 h-6 bg-gray-200 rounded animate-pulse"></div>
            <div className="w-28 h-6 bg-gray-200 rounded animate-pulse"></div>
            <div className="w-36 h-6 bg-gray-200 rounded animate-pulse"></div>
          </div>
        ))}
      </div>
    </>
  );
}

function EstimatesSkeleton() {
  return (
    <>
      <h1 className="absolute z-5 bottom-[-28px] right-0 pr-2 pt-1 bg-gray-50 font-bold w-full text-right text-gray-500">
        Loading...
      </h1>
      <div className="relative flex flex-col bg-white max-h-full-customer-detail-page overflow-auto shadow-lg">
        <div className="flex flex-row font-semibold items-center  min-w-fit justify-start py-3 px-5 gap-5 border-b-2 border-yellow-400 bg-gray-900/5 whitespace-nowrap ">
          <h1 className="w-20 shrink-0">Date</h1>
          <h1 className="w-28 shrink-0">Description</h1>
          <h1 className="w-28 shrink-0">Lead Source</h1>
          <h1 className="w-20 shrink-0">Amount</h1>
          <h1 className="w-28 shrink-0">Notes</h1>
        </div>
        {[...Array(2)].map((_, i) => (
          <div
            key={i}
            className="flex flex-row items-start justify-start min-w-fit px-5 gap-5 py-3 "
          >
            <div className="w-20 h-6 bg-gray-200 rounded animate-pulse"></div>

            <div className="w-28 h-6 bg-gray-200 rounded animate-pulse"></div>
            <div className="w-28 h-6 bg-gray-200 rounded animate-pulse"></div>
            <div className="w-20 h-6 bg-gray-200 rounded animate-pulse"></div>
            <div className="w-28 h-6 bg-gray-200 rounded animate-pulse"></div>
          </div>
        ))}
      </div>
    </>
  );
}

function InvoicesSkeleton() {
  return (
    <>
      <h1 className="absolute z-5 bottom-[-28px] right-0 pr-2 pt-1 bg-gray-50 font-bold w-full text-right text-gray-500">
        Loading...
      </h1>
      <div className="relative flex flex-col bg-white max-h-full-customer-detail-page  overflow-auto shadow-lg">
        <div className="flex flex-row font-semibold items-center min-w-fit justify-start py-3 px-5 gap-5 border-b-2 border-yellow-400 bg-gray-900/5 whitespace-nowrap ">
          <h1 className="w-28 shrink-0">Invoice</h1>
          <h1 className="w-28 shrink-0">Billing Type</h1>
          <h1 className="w-28 shrink-0">Date Sent</h1>
          <h1 className="w-28 shrink-0">Date Paid</h1>
          <h1 className="w-28 shrink-0">Amount</h1>
          <h1 className="w-28 shrink-0">Status</h1>
        </div>
        {[...Array(2)].map((_, i) => (
          <div
            key={i}
            className="flex flex-row items-start justify-start min-w-fit px-5 gap-5 py-3 "
          >
            <div className="w-28 h-6 bg-gray-200 rounded animate-pulse"></div>
            <div className="w-28 h-6 bg-gray-200 rounded animate-pulse"></div>
            <div className="w-28 h-6 bg-gray-200 rounded animate-pulse"></div>
            <div className="w-28 h-6 bg-gray-200 rounded animate-pulse"></div>
            <div className="w-28 h-6 bg-gray-200 rounded animate-pulse"></div>
            <div className="w-28 h-6 bg-gray-200 rounded animate-pulse"></div>
          </div>
        ))}
      </div>
    </>
  );
}

function SubscriptionsSkeleton() {
  return (
    <>
      <h1 className="absolute z-5 bottom-[-28px] right-0 pr-2 pt-1 bg-gray-50 font-bold w-full text-right text-gray-500">
        Loading...
      </h1>
      <div className="relative flex flex-col bg-white max-h-full-customer-detail-page  overflow-auto shadow-lg">
        <div className="flex flex-row font-semibold items-center min-w-fit justify-start py-3 px-5 gap-5 border-b-2 border-yellow-400 bg-gray-900/5 whitespace-nowrap ">
          <h1 className="w-28 shrink-0">Name</h1>
          <h1 className="w-28 shrink-0">Created At</h1>
          <h1 className="w-28 shrink-0">Status</h1>
          <h1 className="w-28 shrink-0">Sold By</h1>
          <h1 className="w-28 shrink-0">Amount</h1>
        </div>
        {[...Array(2)].map((_, i) => (
          <div
            key={i}
            className="flex flex-row items-start justify-start min-w-fit px-5 gap-5 py-3 "
          >
            <div className="w-28 h-6 bg-gray-200 rounded animate-pulse"></div>
            <div className="w-28 h-6 bg-gray-200 rounded animate-pulse"></div>
            <div className="w-28 h-6 bg-gray-200 rounded animate-pulse"></div>
            <div className="w-28 h-6 bg-gray-200 rounded animate-pulse"></div>
            <div className="w-28 h-6 bg-gray-200 rounded animate-pulse"></div>
          </div>
        ))}
      </div>
    </>
  );
}
