import InputMUI from 'components/reusable/InputMUI';
import PanelWrapper from '../lowerLevelComponents/PanelWrapper';
import { useContext, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { format } from 'date-fns';
import { UserContext } from 'context/UserContext';
import { Timestamp } from 'firebase/firestore';
import { addressObjToString, getAddressFromLatLng, markerSVGs } from '../../helpers';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Skeleton } from '@mui/material';
// import { Skeleton } from '@chakra-ui/react';

const delayedSubmitChanges = debounce((marker, updateMarker) => {
  updateMarker(marker);
}, 2000);

const getCreatedOrUpdatedAt = (marker, employees) => {
  if (!marker?.createdAt || !employees) return '';

  // find name
  const userId = marker.updatedAt && marker.updatedBy ? marker?.updatedBy : marker?.uid;
  let name = 'unknown';
  for (const employee of employees) {
    if (employee?.id === userId) name = employee.firstName + ' ' + employee.lastName;
  }

  // find date
  const field = marker.updatedAt && marker.updatedBy ? 'updatedAt' : 'createdAt';
  const date = marker?.[field].seconds
    ? format(marker?.[field].toDate(), 'MM-dd-yyyy')
    : format(marker?.[field], 'MM-dd-yyyy');

  // return string
  return (marker.updatedAt ? 'Updated' : 'Created') + ' on ' + date + ' by ' + name;
};

const fetchStreetView = async (address) => {
  try {
    const res = await fetch(
      `https://maps.googleapis.com/maps/api/streetview?size=${266}x${200}&location=${encodeURIComponent(address)}&key=${
        process.env.REACT_APP_API_KEY
      }&return_error_code=true`
    );
    return res.status !== 404 ? res.url : null;
  } catch (error) {
    console.error('Error fetching street view image', error);
    return null;
  }
};

const LittleMapView = ({ location }) =>
  location ? (
    <GoogleMap
      mapContainerStyle={{
        width: '100%',
        height: '100%',
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
      }}
      center={{
        lat: location.latitude || 0,
        lng: location.longitude || 0,
      }}
      zoom={18}
      options={{
        mapTypeId: 'satellite',
        disableDefaultUI: true,
        zoomControl: false,
        streetViewControl: false,
        mapTypeControl: false,
      }}
    >
      <Marker position={{ lat: location?.latitude || 0, lng: location?.longitude || 0 }} />
    </GoogleMap>
  ) : (
    <></>
  );

export default function MarkerPanel({ panelVisible, closeFunction, updateMarker, setPanelVisible, employees }) {
  const { userData } = useContext(UserContext);
  const markerChanges = useRef({});
  const marker = panelVisible?.type === 'marker' ? panelVisible.data : null;
  const [streetView, setStreetView] = useState(null);
  const [loadingStreetView, setLoadingStreetView] = useState(false);
  const [loadingAddress, setLoadingAddress] = useState(false);

  const dataChangeFuncCreator = (field) => {
    return (value) => {
      // update displayed panel, then after a delay trigger a more expensive global/db update
      setPanelVisible((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          updatedAt: Timestamp.fromDate(new Date()),
          updatedBy: userData.userData.id,
          [field]: value,
        },
      }));
      markerChanges.current = { ...markerChanges.current, [field]: value }; // add changes to staging ref
      delayedSubmitChanges({ ...marker, ...markerChanges.current }, updateMarker);
    };
  };

  // get street view from address, if theres no address get that first.
  useEffect(() => {
    (async () => {
      try {
        if (!marker) return;
        setLoadingStreetView(true);
        let address = marker.address;
        if (!marker.address) {
          setLoadingAddress(true);
          address = await getAddressFromLatLng(marker.coordinate.latitude, marker.coordinate.longitude);
          updateMarker({ ...marker, address });
          setPanelVisible((p) => ({
            ...p,
            data: {
              ...p.data,
              address: address,
            },
          }));
          setLoadingAddress(false);
        }
        const view = await fetchStreetView(addressObjToString(address));
        setStreetView(view);
      } catch (e) {
        console.log('error in MarkerPanel get street view: ', e);
      } finally {
        setLoadingStreetView(false);
        setLoadingAddress(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setStreetView, marker?.id]);

  return (
    <PanelWrapper visible={!!marker} closeFunction={closeFunction}>
      <div className='bg-gray-100 rounded-t-md mt-3 overflow-hidden w-[266px] h-[200px]'>
        {loadingStreetView ? (
          <Skeleton animation='wave' variant='rectangular' height={'100%'} />
        ) : streetView ? (
          <img alt='street view of current address' src={streetView} />
        ) : (
          <LittleMapView
            location={{ latitude: marker?.coordinate?.latitude, longitude: marker?.coordinate?.longitude }}
          />
        )}
      </div>
      <div className={`py-3 w-full px-2 flex flex-row rounded-b-md bg-gray-100`}>
        <div className='h-8 w-8 mr-3 my-auto flex-shrink-0'>
          <img className='object-contain w-full h-full' alt={'marker icon'} src={markerSVGs[marker?.type?.icon]} />
        </div>
        <p className='text-sm'>
          {loadingAddress ? 'Searching for address...' : addressObjToString(marker?.address) || 'Address not found.'}
        </p>
      </div>
      <div className={'py-6 w-full'}>
        <InputMUI value={marker?.firstName || ''} setValue={dataChangeFuncCreator('firstName')} label={'First Name'} />
      </div>
      <div className={'pb-6 w-full'}>
        <InputMUI value={marker?.lastName || ''} setValue={dataChangeFuncCreator('lastName')} label={'Last Name'} />
      </div>
      <div className={'pb-12 w-full'}>
        <InputMUI value={marker?.notes || ''} setValue={dataChangeFuncCreator('notes')} label={'Notes'} multiline />
      </div>
      <div className='mt-auto mb-0'>
        <p className='font-light text-xs from-neutral-300'>{getCreatedOrUpdatedAt(marker, employees)}</p>
      </div>
    </PanelWrapper>
  );
}
