import { useMemo } from "react";
import { useUserContext } from "../../../context/UserContext";
import useFetch from "../../../hooks/useFetch";
import { getCustomerBalance } from "../data/api";
import {
  ArrowTopRightOnSquareIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import { formatStripeNumber } from "../data/helpers";
import moment from "moment";

export const AccountBalance = () => {
  const { userData } = useUserContext();

  const getAccountBalanceArgs = useMemo(() => {
    return userData;
  }, [userData]);

  const {
    data: accountBalance,
    isLoading: accountBalanceLoading,
    error,
  } = useFetch(getCustomerBalance, getAccountBalanceArgs);

  // console.log("accountBalance", accountBalance);
  // console.log("error", error);
  // console.log("isLoading", accountBalanceLoading);

  // const { payouts, balance } = accountBalance;
  // console.log("{ payouts, balance }", payouts.data, balance);

  if (accountBalanceLoading)
    return (
      <div className="flex flex-1 justify-center items-center h-24 animate-pulse bg-gray-200"></div>
    );

  if (error) return null;

  // const { payouts = [], balance = {} } = accountBalance;
  const { balance = { available: [], pending: [] }, payouts = [] } =
    accountBalance || {};
  // const balance = { available: [], pending: [] };
  // const payouts = [];

  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:px-6">
        {/* Content goes here */}
        {/* We use less vertical padding on card headers on desktop than on body sections */}
        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <div className="flex flex-row gap-4 items-center">
              <h3 className="text-lg font-semibold leading-6 text-gray-900">
                Stripe Account
              </h3>
              <div className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                Connected
              </div>
            </div>
          </div>
          <div className="ml-4 mt-4 flex-shrink-0 ">
            <button
              className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  gap-1 flex-row"
              onClick={() =>
                window.open("https://www.stripe.com/dashboard", "_blank")
              }
            >
              <ArrowTopRightOnSquareIcon height={18} strokeWidth={2} />
              Open Stripe Dashboard
            </button>
          </div>
        </div>
      </div>
      <dl className="mx-auto grid gap-px bg-gray-200 grid-cols-2 border-b border-gray-200">
        <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
          <dt className="text-sm font-medium leading-6 text-gray-500">
            Available balance
          </dt>
          <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
            {formatStripeNumber(balance?.available?.[0]?.amount)}
          </dd>
        </div>
        <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
          <dt className="text-sm font-medium leading-6 text-gray-500">
            Pending balance
          </dt>
          <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
            {formatStripeNumber(balance?.pending?.[0]?.amount)}
          </dd>
        </div>
      </dl>
      <div className="px-4 py-5 sm:px-6">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Payouts
            </h1>
            {/* <p className="mt-2 text-sm text-gray-700">
              A list of all the users in your account including their name, title, email and role.
            </p> */}
          </div>
        </div>
        {payouts && payouts.data && payouts.data.length > 0 ? (
          <div className="flow-root mt-4">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                      >
                        Date Created
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                      >
                        Arrival Date
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                      >
                        Amount
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {payouts.data.map((payout) => (
                      <PayoutRow payout={payout} key={payout.id} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className="mt-4">
            <p className="text-sm italic text-gray-400">No payouts yet.</p>
          </div>
        )}
      </div>
    </div>
  );
};

const PayoutRow = ({ payout }) => {
  return (
    <tr>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0">
        {new Date(payout.created * 1000).toLocaleDateString()}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
        {new Date(payout.arrival_date * 1000).toLocaleDateString()}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
        {formatStripeNumber(payout.amount)}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
        {payout.status}
      </td>
    </tr>
  );
};
