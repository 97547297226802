import React, { useState, useRef, useEffect, useContext, useMemo } from "react";
import { useUserContext } from "context/UserContext";
import CustomersSearch from "./lowerLevelComponents/CustomersSearch";
import algoliasearch from "algoliasearch";
import BasicSelect from "components/reusable/BasicDropdown";
import FiltersSidebar from "./lowerLevelComponents/FiltersSidebar";
import { IoFilter } from "react-icons/io5";
import Tooltip from "@mui/material/Tooltip";
import CustomerTable from "./lowerLevelComponents/CustomerTable";
// import ColumnDropDownMenu from './lowerLevelComponents/dropDownMenus/ColumnDropDownMenu';
// import { formatPhoneDisplay } from "utils/formatPhone";
import ColumnsMenu from "./dropDownMenus/ColumnsMenu";
import MassActionsMenu from "./dropDownMenus/MassActionsMenu";
import { allColumnHeaders } from "../helpers";
import { generateTestCustomers } from "utils/dataGenerators";
import NoCustomersYet from "./NoCustomersYet";

export default function CustomersList({
  setSelectedCustomer,
  customers,
  navigate,
  loadingCustomers,
  getCustomersNext,
  notAbleToViewCustomers,
  setFilterOptions,
  filterOptions,
  tags,
  nextPage,
  totalCustomersCount,
  fetchType,
  setModal,
  selectedRows,
  setSelectedRows,
  columnHeaders,
  setColumnHeaders,
  allCustomers,
  totalCustomersInDB,
}) {
  const { userData } = useUserContext();
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const filterButtonRef = useRef(null);

  // useEffect(() => {
  //   generateTestCustomers(userData, 1000);
  // }, []);

  function handleCustomerSelect(customer) {
    if (notAbleToViewCustomers) return;
    setSelectedCustomer(customer);
    navigate(`/customers/${customer.customerId}`, {
      state: { customerId: customer.customerId },
    });
  }

  useEffect(() => {
    // create event listener for click outside of dropdown if click is done anywhere then set the dropdown to false
    const handleClickOutside = (event) => {
      if (
        showDropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !filterButtonRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    // Bind the event listener
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef, filterButtonRef, showDropdown]);

  const NotAbleToViewView = () => {
    return (
      <div className="mt-5 mx-5 flex justify-center max-w-[100vw]">
        <div className="relative flex flex-col w-full">
          {[...Array(9)].map((_, index) => (
            <div
              key={index}
              className="flex flex-col shrink-0 justify-start space-x-4 animate-pulse bg-gray-100 rounded h-16 "
            >
              <div className="flex flex-row shrink-0 items-start justify-start space-x-4 animate-pulse mt-6 ">
                <div className="w-3/12 ml-3 h-6 bg-gray-200 rounded"></div>
                <div className="w-4/12 mr-3 h-6 bg-gray-200 rounded"></div>
                <div className="w-3/12 mr-3 h-6 bg-gray-200 rounded"></div>
                <div className="w-2/12 pr-3 h-6 bg-gray-200 rounded"></div>
              </div>
            </div>
          ))}

          {/* Overlay Message */}
          <div className="absolute top-1/4 left-0 right-0 flex items-center justify-center">
            <div className="bg-white p-4 rounded-md text-center shadow-lg">
              <h1 className="text-xl font-semibold text-gray-900">
                It looks like you don't have permission to view customers.
              </h1>
              <p className="text-gray-700">
                If this is a mistake please contact your admin.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="mt-4 mx-5 flex justify-center max-w-[100vw]">
      <div className="bg-white w-full max-w-[1550px] shadow-bold flex flex-col relative lg:self-start rounded-md h-4/6 lg:h-full">
        {/* search and tags/timeframe filters */}
        <div className="flex flex-row justify-between py-3 px-4 rounded-t-md bg-gray-800 h-16 items-center">
          <CustomersSearch
            setFilterOptions={setFilterOptions}
            filterOptions={filterOptions}
          />
          {/* buttons */}
          <div className="flex flex-row items-center">
            {selectedRows.length > 0 && (
              <MassActionsMenu
                setSelectedRows={setSelectedRows}
                setModal={setModal}
                allCustomers={allCustomers}
                columnHeaders={columnHeaders}
                selectedRows={selectedRows}
              />
            )}
            <ColumnsMenu
              columnHeaders={columnHeaders}
              setColumnHeaders={setColumnHeaders}
            />

            {/* filters button  */}
            <div className="mx-2">
              <Tooltip title="Filters" arrow>
                <button
                  onClick={() => setModal("filters")}
                  className="text-gray-50 font-bold px-1 shadow-md flex flex-row rounded-full h-10 w-10 items-center justify-center hover:bg-gray-700"
                >
                  <IoFilter size={24} className="text-xl" />
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
        {notAbleToViewCustomers ? (
          <NotAbleToViewView />
        ) : totalCustomersInDB === 0 ? (
          <NoCustomersYet />
        ) : (
          <CustomerTable
            customers={customers}
            getCustomersNext={getCustomersNext}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            handleCustomerSelect={handleCustomerSelect}
            setFilterOptions={setFilterOptions}
            filterOptions={filterOptions}
            tags={tags}
            nextPage={nextPage}
            totalCustomersCount={totalCustomersCount}
            columnHeaders={columnHeaders}
            loadingCustomers={loadingCustomers}
            fetchType={fetchType}
          />
        )}
      </div>
    </div>
  );
}
