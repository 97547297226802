import { FiEdit } from "react-icons/fi";
import { IoFilter } from "react-icons/io5";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { colors, territoryColorMap } from "theme/colors";
import { IoCloseCircleOutline } from "react-icons/io5";
import { IoAddCircleOutline } from "react-icons/io5";
import { LuLassoSelect } from "react-icons/lu";
import { IoIosStats } from "react-icons/io";

import { useContext } from "react";
import { UserContext } from "index";
import { v4 as uuidv4 } from "uuid";
import { Tooltip } from "@mui/material";

const iconColor = "white";
// const iconColor = '#1f2937';
// fixing autoformating

export default function Controls({
  setPanelVisible,
  editingMode,
  setEditingMode,
  territories,
  updateTerritories,
  dataToDisplay,
  errorToast,
  employees,
}) {
  const { userData } = useContext(UserContext);
  const IconWrapper = ({ icon, onClick, tooltip, visible = true }) =>
    visible ? (
      <Tooltip disableInteractive title={tooltip}>
        <button
          onClick={onClick}
          className="mb-4 hover:bg-gray-700 rounded-xl w-10 h-10 flex items-center justify-center"
        >
          {icon}
        </button>
      </Tooltip>
    ) : (
      <></>
    );

  const deleteClick = () => {
    // delete polygon if editing existing one
    if (editingMode.operation === "editingTerritory")
      updateTerritories({
        businessId: userData.userData.businessId,
        territoryToDelete: editingMode.current,
      });
    // reset state
    setEditingMode({});
  };

  const confirmClick = () => {
    if (territories?.length >= 800 && !editingMode.previous) {
      errorToast("You can only have up to 800 territories");
      return;
    } // if user wants to add a new territory over 800 return
    updateTerritories({
      businessId: userData.userData.businessId,
      newTerritory: editingMode.current,
      territoryToDelete: editingMode.previous, // this will be null if creating a new polygon
    });

    // reset
    setEditingMode({});
  };

  const addTerritoryClick = () => {
    // callback is called if the set state was successful
    setPanelVisible({ type: "", data: null }, () => {
      setEditingMode((p) => {
        if (p.operation === "addingTerritory") return {};
        return {
          operation: "addingTerritory",
          current: employees?.length // add user currently associated with territory
            ? {
                id: uuidv4(),
                user: {
                  color: {
                    ...employees[0].color,
                    value: territoryColorMap[employees[0].color.label],
                  }, // replace color value with a transparent version
                  firstName: employees[0].firstName,
                  lastName: employees[0].lastName,
                },
              }
            : {
                id: uuidv4(),
                user: {
                  color: {
                    label: "Blue",
                    value: "rgba(59,130,246,0.5)",
                  },
                  firstName: "John",
                  lastName: "Doe",
                },
              },
        };
      });
    });
  };

  return (
    <div className="w-[70px] h-full bg-gray-900 drop-shadow-xl z-[10] flex items-center flex-col-reverse flex-shrink-0">
      {/* filters */}
      <IconWrapper
        visible={!editingMode.operation}
        tooltip={"Filters"}
        icon={<IoFilter color={iconColor} size={25} />}
        onClick={() =>
          setPanelVisible((prev) =>
            prev?.type === "filters"
              ? { type: "", data: null }
              : { type: "filters", data: null }
          )
        }
      />

      {/* map stats */}
      <IconWrapper
        visible={!editingMode.operation}
        tooltip={"Employee Map Statistics"}
        icon={<IoIosStats size={23} color={iconColor} />}
        onClick={() => {
          setPanelVisible((prev) =>
            prev?.type === "stats"
              ? { type: "", data: null }
              : { type: "stats", data: null }
          );
        }}
      />
      {/* edit territory */}
      <IconWrapper
        visible={
          !editingMode?.operation ||
          (editingMode?.operation === "editingTerritory" &&
            !editingMode.current)
        }
        tooltip={"Edit Territories"}
        icon={<FiEdit color={iconColor} size={23} />}
        onClick={() => {
          setPanelVisible(
            { type: "", data: null },
            () =>
              setEditingMode((p) => ({
                operation:
                  p.operation === "editingTerritory" ? "" : "editingTerritory",
              })) // callback is called if the set state was successful
          );
        }}
      />
      {/* add territory */}
      <IconWrapper
        visible={!editingMode?.operation}
        tooltip={"Add a Territory"}
        icon={<IoAddCircleOutline color={iconColor} size={30} />}
        onClick={addTerritoryClick}
      />
      {/* polygon select customers */}
      <IconWrapper
        visible={
          dataToDisplay.type === "customers" &&
          editingMode?.operation !== "addingTerritory" &&
          editingMode?.operation !== "editingTerritory"
        }
        tooltip={
          editingMode?.operation === "selectingCustomers"
            ? "Go Back"
            : "Select and Message Customers"
        }
        icon={<LuLassoSelect color={iconColor} size={25} />}
        onClick={() => {
          // setEditingMode({ operation: editingMode?.operation === 'selectingCustomers' ? '' : 'selectingCustomers' });
          setPanelVisible({ type: "", data: null }, () =>
            setEditingMode({
              operation:
                editingMode?.operation === "selectingCustomers"
                  ? ""
                  : "selectingCustomers",
            })
          );
        }}
      />

      {/* delete */}
      <IconWrapper
        visible={!!editingMode?.current}
        tooltip={"Delete Territory"}
        icon={<IoCloseCircleOutline color={colors.red500} size={30} />}
        onClick={deleteClick}
      />
      {/* confirm */}
      <IconWrapper
        visible={editingMode?.current?.coordinates?.length > 2}
        tooltip={"Confirm Changes"}
        icon={<IoCheckmarkCircleOutline color={colors.green500} size={30} />}
        onClick={confirmClick}
      />
    </div>
  );
}
