export const OutlineButton = ({ text, onClick, icon, fullWidth = false, disabled = false }) => {
  return (
    <button
      disabled={disabled}
      type="button"
      className={`rounded-md bg-white px-3.5 py-2.5 font-medium focus:outline-none text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex flex-row items-center justify-center text-base gap-2 ${
        fullWidth && "flex-1"
      }`}
      onClick={onClick}
    >
      {icon && icon}
      <p>{text}</p>
    </button>
  );
};
