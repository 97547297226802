// If you make changes here make sure to update in server repo in: /functions/constants.js

export const EstimateEventTypes = {
  ESTIMATE_CREATED: "estimate-created",
  ESTIMATE_UPDATED: "estimate-updated",
  ESTIMATE_EMAIL_SENT: "estimate-email-sent",
  ESTIMATE_SMS_SENT: "estimate-sms-sent",
  ESTIMATE_APPROVED: "estimate-approved",
  ESTIMATE_APPROVAL_REVERTED: "estimate-approval-reverted",
  ESTIMATE_COPIED: "estimate-copied",
  ESTIMATE_APPROVED_PUBLIC: "estimate-approved-public",
  ESTIMATE_DECLINED: "estimate-declined",
};

export const EstimateEventTypeNames = {
  [EstimateEventTypes.ESTIMATE_CREATED]: "Estimate created",
  [EstimateEventTypes.ESTIMATE_UPDATED]: "Estimate updated",
  [EstimateEventTypes.ESTIMATE_EMAIL_SENT]: "Estimate sent via email",
  [EstimateEventTypes.ESTIMATE_SMS_SENT]: "Estimate sent via SMS",
  [EstimateEventTypes.ESTIMATE_APPROVED]: "Estimate approved",
  [EstimateEventTypes.ESTIMATE_APPROVAL_REVERTED]: "Estimate approval reverted",
  [EstimateEventTypes.ESTIMATE_COPIED]: "Job created from estimate",
  [EstimateEventTypes.ESTIMATE_APPROVED_PUBLIC]: "Estimate approved by client",
  [EstimateEventTypes.ESTIMATE_DECLINED]: "Estimate declined by client",
};

export function getEstimateEventTypeName(type) {
  return EstimateEventTypeNames[type] || "Unknown Event Type";
}
