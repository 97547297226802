import React, { useState, useMemo, useEffect, useCallback } from "react";
import { FaPlus } from "react-icons/fa";
import { AiOutlineCloseCircle } from "react-icons/ai";
import LineItem from "./LineItem";
import useFetch from "hooks/useFetch";
import { fetchAllLineItems } from "features/PriceBook/data/api";
import { useUserContext } from "context/UserContext";
import { OutlineButton } from "../../../components/NewButtons/OutlineButton";
import { PlusCircleIcon } from "@heroicons/react/24/solid";

export default function AddLineItems({
  modal = false,
  setJobTotal,
  taxRates,
  defaultTaxRate,
  lineItems,
  setLineItems,
  lineItemsUnsavable = false,
}) {
  const { userData } = useUserContext();
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);

  const fetchAllLineItemsArgs = useMemo(() => ({ userData }), [userData]);

  const { data: allLineItems, isLoading } = useFetch(fetchAllLineItems, fetchAllLineItemsArgs);

  useEffect(() => {
    setAutocompleteOptions(allLineItems);
  }, [allLineItems]);

  const subTotal = useMemo(() => {
    return lineItems.reduce((acc, item) => {
      const quantity = parseFloat(item.quantity) || 0;
      const unitPrice = parseFloat(item.unitPrice) || 0;
      const total = quantity * unitPrice;
      return acc + total;
    }, 0);
  }, [lineItems]);

  const taxTotal = useMemo(() => {
    return lineItems.reduce((acc, item) => {
      const quantity = parseFloat(item.quantity) || 0;
      const unitPrice = parseFloat(item.unitPrice) || 0;
      const total = quantity * unitPrice;
      const taxRate = parseFloat(item?.taxRate?.rate) || 0;
      const tax = total * (taxRate / 100);
      return acc + tax;
    }, 0);
  }, [lineItems]);

  const total = subTotal + taxTotal;

  // useEffect(() => {
  //   setJobTotal(subTotal);
  // }, [subTotal, setJobTotal]);

  useEffect(() => {
    setJobTotal(total);
  }, [total, setJobTotal]);

  const addLineItem = () => {
    setLineItems([
      ...lineItems,
      {
        name: "",
        description: "",
        quantity: 1,
        unitPrice: "0.00",
        taxRate: defaultTaxRate,
      },
    ]);
  };

  // const updateLineItem =  (index, fieldName, value) => {
  //   setLineItems((currentLineItems) => {
  //     const updatedItems = currentLineItems.map((item, idx) => {
  //       if (idx === index) {
  //         return { ...item, [fieldName]: value };
  //       }
  //       return item;
  //     });

  //     return updatedItems;
  //   });
  // };

  const updateLineItem = useCallback(
    (index, fieldName, value) => {
      setLineItems((currentLineItems) => {
        const updatedItems = currentLineItems.map((item, idx) => {
          if (idx === index) {
            return { ...item, [fieldName]: value };
          }
          return item;
        });
        return updatedItems;
      });
    },
    [setLineItems] // Dependencies
  );

  const removeLineItem = (index) => {
    setLineItems(lineItems.filter((_, idx) => idx !== index));
  };

  return (
    <div
      className={`flex flex-col flex-1 flex-grow ${
        modal ? "" : "rounded-md shadow-bold mb-8"
      }  bg-white py-6 px-4 gap-2`}
    >
      <h1 className="text-xl font-bold">{!modal && "Add "}Line Items</h1>

      <div className="mt-2 flex flex-col gap-4">
        {lineItems.map((item, index) => (
          <div key={index} className="pl-6 flex flex-row relative">
            <div className="absolute -left-2 top-4">
              {lineItems.length > 1 && (
                <AiOutlineCloseCircle
                  className="text-lg hover:text-red-400 cursor-pointer w-6 h-6 mx-auto"
                  onClick={() => removeLineItem(index)}
                />
              )}
            </div>
            <LineItem
              lineItem={item}
              index={index}
              updateLineItem={updateLineItem}
              taxRates={taxRates}
              autocompleteOptions={autocompleteOptions || []}
              setAutocompleteOptions={setAutocompleteOptions}
              unsavable={lineItemsUnsavable}
            />
          </div>
        ))}
      </div>
      <div className="-mt-4 flex flex-row items-center justify-end gap-4">
        {/* <button
          className="inline-flex items-center gap-x-2 rounded-md text-gray-900 px-3.5 py-2.5 font-semibold shadow-sm border border-gray-400 hover:bg-gray-50"
          onClick={addLineItem}
        >
          <FaPlus className="h-3 w-3" />
        </button> */}
        <OutlineButton
          text="New Line Item"
          onClick={addLineItem}
          icon={<FaPlus className="h-3 w-3" />}
        />
      </div>
      <hr className="my-8 border-b-2 border-b-gray-400" />
      <div className="flex flex-col place-self-end items-end pr-2 gap-2 text-lg font-medium">
        <div className="flex flex-row items-end justify-between gap-2  font-medium text-sm w-48 text-gray-500">
          <label className="w-1/2">Subtotal</label>
          <h1 className="font-base text-sm ">
            $
            {subTotal.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h1>
        </div>
        <div className="flex flex-row items-end gap-2 justify-between text-sm font-medium w-48 text-gray-500">
          <label className="w-1/2">Tax</label>
          <h1 className="font-base text-sm">
            $
            {taxTotal.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h1>
        </div>
        <div className="flex flex-row items-end gap-2 justify-between text-lg font-medium w-48">
          <label className="w-1/2">Total</label>
          <h1 className="font-semibold">
            $
            {total.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h1>
        </div>
      </div>
    </div>
  );
}
