import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Checkbox } from "components/ui/checkbox";

export const EstimatePreviewSettings = ({ estimateSettings, setEstimateSettings }) => {
  const handleCheckboxChange = (field) => {
    setEstimateSettings((prevSettings) => ({
      ...prevSettings,
      [field]: !prevSettings[field],
    }));
  };

  return (
    <div>
      <div className="py-4">
        <h2 className="text-xl font-semibold">Estimate Settings</h2>
      </div>
      <div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <p className="font-medium">Logo</p>
          </AccordionSummary>
          <AccordionDetails>
            <label className="flex items-center">
              <Checkbox
                checked={estimateSettings?.logo}
                onCheckedChange={() => handleCheckboxChange("logo")}
              />
              <span className="ml-2">Display Logo</span>
            </label>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <p className="font-medium">Estimate Details</p>
          </AccordionSummary>
          <AccordionDetails>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={estimateSettings?.estimateNumber}
                onCheckedChange={() => handleCheckboxChange("estimateNumber")}
              />
              <span className="ml-2">Estimate Number</span>
            </label>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={estimateSettings?.estimateDate}
                onCheckedChange={() => handleCheckboxChange("estimateDate")}
              />
              <span className="ml-2">Estimate Date</span>
            </label>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            <p className="font-medium">Customer Details</p>
          </AccordionSummary>
          <AccordionDetails>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={estimateSettings?.customerDisplayName}
                onCheckedChange={() => handleCheckboxChange("customerDisplayName")}
              />
              <span className="ml-2">Display Name</span>
            </label>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={estimateSettings?.customerAddress}
                onCheckedChange={() => handleCheckboxChange("customerAddress")}
              />
              <span className="ml-2">Address</span>
            </label>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={estimateSettings?.customerEmail}
                onCheckedChange={() => handleCheckboxChange("customerEmail")}
              />
              <span className="ml-2">Email</span>
            </label>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={estimateSettings?.customerPhone}
                onCheckedChange={() => handleCheckboxChange("customerPhone")}
              />
              <span className="ml-2">Phone number</span>
            </label>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel4-content"
            id="panel4-header"
          >
            <p className="font-medium">Business Details</p>
          </AccordionSummary>
          <AccordionDetails>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={estimateSettings?.businessName}
                onCheckedChange={() => handleCheckboxChange("businessName")}
              />
              <span className="ml-2">Business Name</span>
            </label>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={estimateSettings?.businessEmail}
                onCheckedChange={() => handleCheckboxChange("businessEmail")}
              />
              <span className="ml-2">Email</span>
            </label>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={estimateSettings?.businessPhone}
                onCheckedChange={() => handleCheckboxChange("businessPhone")}
              />
              <span className="ml-2">Phone</span>
            </label>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={estimateSettings?.businessAddress}
                onCheckedChange={() => handleCheckboxChange("businessAddress")}
              />
              <span className="ml-2">Address</span>
            </label>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel5-content"
            id="panel5-header"
          >
            <p className="font-medium">Line Items</p>
          </AccordionSummary>
          <AccordionDetails>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={estimateSettings?.lineItemsDescription}
                onCheckedChange={() => handleCheckboxChange("lineItemsDescription")}
              />
              <span className="ml-2">Description</span>
            </label>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={estimateSettings?.lineItemsQuantity}
                onCheckedChange={() => handleCheckboxChange("lineItemsQuantity")}
              />
              <span className="ml-2">Quantity</span>
            </label>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={estimateSettings?.lineItemsUnitPrice}
                onCheckedChange={() => handleCheckboxChange("lineItemsUnitPrice")}
              />
              <span className="ml-2">Unit Price</span>
            </label>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={estimateSettings?.lineItemsAmount}
                onCheckedChange={() => handleCheckboxChange("lineItemsAmount")}
              />
              <span className="ml-2">Amount</span>
            </label>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel6-content"
            id="panel6-header"
          >
            <p className="font-medium">Total</p>
          </AccordionSummary>
          <AccordionDetails>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={estimateSettings?.subtotal}
                onCheckedChange={() => handleCheckboxChange("subtotal")}
              />
              <span className="ml-2">Subtotal</span>
            </label>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={estimateSettings?.tax}
                onCheckedChange={() => handleCheckboxChange("tax")}
              />
              <span className="ml-2">Tax</span>
            </label>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel7-content"
            id="panel7-header"
          >
            <p className="font-medium">Footer</p>
          </AccordionSummary>
          <AccordionDetails>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={estimateSettings?.footerBusinessName}
                onCheckedChange={() => handleCheckboxChange("footerBusinessName")}
              />
              <span className="ml-2">Business Name</span>
            </label>
            <label className="flex items-center mb-2">
              <Checkbox
                checked={estimateSettings?.footerBusinessWebsite}
                onCheckedChange={() => handleCheckboxChange("footerBusinessWebsite")}
              />
              <span className="ml-2">Website</span>
            </label>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

const EstimateOptions = ({ estimateSettings, setEstimateSettings }) => {
  const handleCheckboxChange = (field) => {
    setEstimateSettings((prevSettings) => ({
      ...prevSettings,
      [field]: !prevSettings[field],
    }));
  };

  return (
    <div className="px-4">
      <h2 className="text-xl font-bold mb-4">Logo</h2>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={estimateSettings?.logo}
          onCheckedChange={() => handleCheckboxChange("logo")}
        />
        <span className="ml-2">Display Logo</span>
      </label>

      <h2 className="text-xl font-bold mt-6 mb-4">Estimate Details</h2>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={estimateSettings?.estimateNumber}
          onCheckedChange={() => handleCheckboxChange("estimateNumber")}
        />
        <span className="ml-2">Estimate Number</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={estimateSettings?.estimateDate}
          onCheckedChange={() => handleCheckboxChange("estimateDate")}
        />
        <span className="ml-2">Estimate Date</span>
      </label>

      <h2 className="text-xl font-bold mt-6 mb-4">Customer Details</h2>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={estimateSettings?.customerDisplayName}
          onCheckedChange={() => handleCheckboxChange("customerDisplayName")}
        />
        <span className="ml-2">Display Name</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={estimateSettings?.customerAddress}
          onCheckedChange={() => handleCheckboxChange("customerAddress")}
        />
        <span className="ml-2">Address</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={estimateSettings?.customerEmail}
          onCheckedChange={() => handleCheckboxChange("customerEmail")}
        />
        <span className="ml-2">Email</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={estimateSettings?.customerPhone}
          onCheckedChange={() => handleCheckboxChange("customerPhone")}
        />
        <span className="ml-2">Phone number</span>
      </label>

      <h2 className="text-xl font-bold mt-6 mb-4">Business Details</h2>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={estimateSettings?.businessName}
          onCheckedChange={() => handleCheckboxChange("businessName")}
        />
        <span className="ml-2">Business Name</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={estimateSettings?.businessEmail}
          onCheckedChange={() => handleCheckboxChange("businessEmail")}
        />
        <span className="ml-2">Email</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={estimateSettings?.businessPhone}
          onCheckedChange={() => handleCheckboxChange("businessPhone")}
        />
        <span className="ml-2">Phone</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={estimateSettings?.businessAddress}
          onCheckedChange={() => handleCheckboxChange("businessAddress")}
        />
        <span className="ml-2">Address</span>
      </label>

      <h2 className="text-xl font-bold mt-6 mb-4">Line Items</h2>

      <label className="flex items-center mb-2">
        <Checkbox
          checked={estimateSettings?.lineItemsDescription}
          onCheckedChange={() => handleCheckboxChange("lineItemsDescription")}
        />
        <span className="ml-2">Description</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={estimateSettings?.lineItemsQuantity}
          onCheckedChange={() => handleCheckboxChange("lineItemsQuantity")}
        />
        <span className="ml-2">Quantity</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={estimateSettings?.lineItemsUnitPrice}
          onCheckedChange={() => handleCheckboxChange("lineItemsUnitPrice")}
        />
        <span className="ml-2">Unit Price</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={estimateSettings?.lineItemsAmount}
          onCheckedChange={() => handleCheckboxChange("lineItemsAmount")}
        />
        <span className="ml-2">Amount</span>
      </label>

      <h2 className="text-xl font-bold mt-6 mb-4">Total</h2>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={estimateSettings?.subtotal}
          onCheckedChange={() => handleCheckboxChange("subtotal")}
        />
        <span className="ml-2">Subtotal</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={estimateSettings?.tax}
          onCheckedChange={() => handleCheckboxChange("tax")}
        />
        <span className="ml-2">Tax</span>
      </label>
      <h2 className="text-xl font-bold mt-6 mb-4">Footer</h2>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={estimateSettings?.footerBusinessName}
          onCheckedChange={() => handleCheckboxChange("footerBusinessName")}
        />
        <span className="ml-2">Business Name</span>
      </label>
      <label className="flex items-center mb-2">
        <Checkbox
          checked={estimateSettings?.footerBusinessWebsite}
          onCheckedChange={() => handleCheckboxChange("footerBusinessWebsite")}
        />
        <span className="ml-2">Website</span>
      </label>
    </div>
  );
};

export default EstimateOptions;
