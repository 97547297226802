import { colors } from "theme/colors";
import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { FaPhone } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

// crossOffCriteria (el) => bool, returns true if customer passes criteria
export default function CustomerConfirmList({
  customers,
  setCustomers,
  crossOffCriteria,
  contextMessage,
  options,
}) {
  // add default options
  options = {
    showPhoneIcon: true,
    showEmailIcon: true,
    phonePath: (customer) => customer.phoneNumber,
    ...options,
  };
  const [filteredCustomers, setFilteredCustomers] = useState(customers);
  // filter out customers without cross off criteria
  useEffect(() => {
    if (!customers) return;
    setFilteredCustomers(customers.filter(crossOffCriteria));
  }, [setFilteredCustomers, customers, crossOffCriteria]);

  return (
    <div>
      <p className="text-sm mt-1">
        {customers?.length || 0} selected, {filteredCustomers?.length}{" "}
        {contextMessage}.
      </p>
      <div className="max-h-56 h-56 overflow-y-auto border p-2 text-sm bg-gray-50 mb-6 mt-2">
        {customers?.map((customer, i) => {
          return (
            <div
              key={"customer-in-panel" + i}
              className="flex flex-row items-center mb-1"
            >
              <Tooltip disableInteractive title="Remove Customer">
                <button
                  onClick={() =>
                    setCustomers((p) =>
                      p.filter((el) => el.customerId !== customer.customerId)
                    )
                  }
                >
                  <FaXmark color={colors.red600} />
                </button>
              </Tooltip>
              <p
                className={`text-nowrap overflow-x-hidden text-ellipsis ml-1 ${
                  !crossOffCriteria(customer) ? "line-through" : ""
                }`}
              >
                {customer.firstName + " " + customer.lastName}
              </p>
              {options.showPhoneIcon && options.phonePath(customer) && (
                <Tooltip
                  disableInteractive
                  title={!!options.phonePath(customer)}
                >
                  <button className="cursor-auto">
                    <FaPhone
                      color={colors.gray900}
                      size={12}
                      className="ml-1"
                    />
                  </button>
                </Tooltip>
              )}
              {options.showEmailIcon && !!customer?.email?.[0]?.length && (
                <Tooltip disableInteractive title={customer?.email[0]}>
                  <button className="cursor-auto">
                    <MdEmail
                      color={colors.gray900}
                      size={16}
                      className="ml-1"
                    />{" "}
                  </button>
                </Tooltip>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
