import React, { useState, useEffect, useContext, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SearchCustomer from "../AddJob/components/SearchCustomer";
import AddLineItem from "../AddJob/components/AddLineItem";
import AddNote from "../AddJob/components/AddNote";
import LeadSource from "../AddJob/components/LeadSource";
import { UserContext } from "../../index";
import { useSnackBar } from "context/SnackBarContext";
import ConfirmModal from "./components/ConfirmModal";
import { useEmployeeContext } from "../../context/EmployeeContext";
import CustomerModalCombinedNew from "components/CustomerModalCombined/CustomerModalCombined";
import { PageHeader } from "../../layouts/PageHeader";
import { SolidButton } from "../../components/NewButtons/SolidButton";
import CenteredMaxWidthLayout from "../../layouts/CenteredMaxWidthLayout";
import { createNewEstimate } from "./data/api";
import { PlusCircleIcon } from "@heroicons/react/20/solid";

export default function NewEstimate() {
  const { userData, bannerVisible } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  // get customerState from location if it exists
  const customerState = location.state?.customer;
  const [modal, setModal] = useState(false);

  const [selectedCustomer, setSelectedCustomer] = useState(
    customerState || false
  );
  const defaultTaxRate = userData?.bizData?.defaultTaxRate || null;
  const [lineItems, setLineItems] = useState([
    {
      name: "",
      description: "",
      quantity: 1,
      unitPrice: "0.00",
      taxRate: defaultTaxRate, // { name: "Tax Rate", rate: "5"}
    },
  ]);
  const [jobTotal, setJobTotal] = useState(0);
  const [leadSource, setLeadSource] = useState("");
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [confirmSendMethodsModal, setConfirmSendMethodsModal] = useState(false);
  const [creatingEstimate, setCreatingEstimate] = useState(false);

  const { employees: employeeList } = useEmployeeContext();

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleCreateEstimate = async () => {
    // we want to create a modal that asks the user if they want to send via email, text or both -- like the receipt modal
    if (!selectedCustomer || !lineItems.length || !jobTotal) {
      alert("Please fill out all fields.");
      return;
    }
    setCreatingEstimate(true);

    try {
      const newEstimateData = {
        dateAdded: new Date(),
        lastUpdated: new Date(),
        businessId: userData.userData.businessId,
        customer: selectedCustomer,
        lineItems: lineItems,
        estimateTotal: jobTotal,
        leadSource: leadSource,
        note: note,
        // createdBy: userData.userData.id || null,
      };
      const newEstimateId = await createNewEstimate({
        userData,
        newEstimateData,
      });

      navigate(`/estimates/${newEstimateId}`, { state: { showSend: true } });
    } catch (err) {
      console.error(err);
      alert("Error creating estimate, please try again later.");
    } finally {
      setCreatingEstimate(false);
    }
  };

  return (
    <>
      <div
        className="bg-gray-50"
        style={{
          height: bannerVisible
            ? "calc(100vh - 60px - 60px)"
            : "calc(100vh - 64px)",
          overflowY: "auto",
        }}
      >
        <CenteredMaxWidthLayout>
          <PageHeader title="New Estimate">
            <SolidButton
              text="Create Estimate"
              loading={creatingEstimate}
              onClick={handleCreateEstimate}
              // icon={<DocumentPlusIcon className="h-6" />}
              icon={<PlusCircleIcon className="h-6" />}
            />
          </PageHeader>
          <div className="flex flex-col flex-1 p-4 pt-0 mx-10 lg:mx-1 lg:grid lg:grid-cols-5 lg:gap-8">
            <div className=" lg:col-span-2">
              <SearchCustomer
                toggleModal={toggleModal}
                selected={selectedCustomer}
                setSelected={setSelectedCustomer}
              />

              <LeadSource
                setLeadSource={setLeadSource}
                employeeList={employeeList}
              />

              <div className="mt-8">
                <AddNote note={note} setNote={setNote} fromEstimate={true} />
              </div>
            </div>
            <div className="lg:mt-0 lg:col-span-3 relative">
              <AddLineItem
                lineItems={lineItems}
                setLineItems={setLineItems}
                setJobTotal={setJobTotal}
                taxRates={userData?.bizData?.taxRates || []}
                defaultTaxRate={defaultTaxRate}
              />
            </div>
          </div>
        </CenteredMaxWidthLayout>
      </div>

      <ConfirmModal
        modal={confirmSendMethodsModal}
        setModal={setConfirmSendMethodsModal}
        customerData={selectedCustomer}
        newEstimateData={{
          dateAdded: new Date(),
          lastUpdated: new Date(),
          businessId: userData.userData.businessId,
          customer: selectedCustomer,
          lineItems: lineItems,
          estimateTotal: jobTotal,
          leadSource: leadSource,
          note: note,
        }}
      />

      {modal && (
        <CustomerModalCombinedNew
          modal={modal}
          setModal={setModal}
          setSelectedCustomer={setSelectedCustomer}
        />
      )}
    </>
  );
}
