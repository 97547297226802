import React, { useState, useEffect, forwardRef } from "react";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import { useUserContext } from "context/UserContext";
import { useSnackBar } from "context/SnackBarContext";
import { useNavigate } from "react-router-dom";
import Footer from "../../ServiceSubscription/components/shared/Footer";
import Header from "../../ServiceSubscription/components/shared/Header";
import Customer from "../../ServiceSubscription/components/NewServiceSubscription/Customer";
import { OutlineButton } from "../../../components/NewButtons/OutlineButton";
import { SolidButton } from "../../../components/NewButtons/SolidButton";
import EstimateOptions from "../../../components/customizeEstimates/EstimateOptions";
import { EstimatePreview } from "./EstimatePreview";
import { EstimatePreviewSettings } from "./EstimatePreviewSettings";
import { ArrowDownTrayIcon, PaperClipIcon } from "@heroicons/react/24/outline";
import AddAttachmentButton from "./AddAttachmentButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { validateAttachments } from "../../../utils/validateAttachments";
import { createEstimatePDFNew } from "../../../utils/CreateAndDownloadPDF";

const TransitionComponent = forwardRef((props, ref) => (
  <Slide direction="left" ref={ref} {...props} />
));

export default function PreviewEstimateModal({
  open,
  onClose,
  estimateDetails,
  onClickSendEstimate,
}) {
  const { userData } = useUserContext();

  const [uploadingFiles, setUploadingFiles] = useState(false);

  const [estimateSettings, setEstimateSettings] = useState(
    estimateDetails.estimateSettings
      ? estimateDetails.estimateSettings
      : userData?.bizData?.estimateSettings || {
          logo: userData?.bizData?.logo ? true : false,
          estimateNumber: true,
          estimateDate: true,
          customerDisplayName: true,
          customerAddress: true,
          customerEmail: true,
          customerPhone: true,
          businessName: true,
          businessPhone: true,
          businessEmail: true,
          businessAddress: true,
          lineItemsDescription: true,
          lineItemsQuantity: true,
          lineItemsUnitPrice: true,
          lineItemsAmount: true,
          subtotal: true,
          tax: true,
          footerBusinessName: true,
          footerWebsite: true,
        }
  );

  const [attachments, setAttachments] = useState(estimateDetails.attachments || []);

  const removeAttachment = (index) => {
    setAttachments((curAttachments) => curAttachments.filter((_, i) => i !== index));
  };

  return (
    <Modal open={open} onClose={() => {}}>
      <TransitionComponent in={open}>
        <div
          className={`absolute top-0 right-0 h-full items-center  md:rounded-tl-md md:rounded-bl-md flex flex-col outline-none w-full md:w-3/4 bg-white`}
        >
          <Header title="Preview and Send Estimate" onClose={onClose} />
          {/* Content */}
          <div className="flex-1 w-full bg-gray-100 overflow-scroll">
            <div className="flex flex-col lg:flex-row items-center lg:items-start p-4 gap-4 lg:gap-8 justify-center ">
              <div className="w-full max-w-3xl">
                <EstimatePreview
                  userData={userData}
                  estimateData={estimateDetails}
                  estimateSettings={estimateSettings}
                />
              </div>
              <div className="w-full lg:w-96">
                <div>
                  <div className="flex flex-row gap-2">
                    <AddAttachmentButton
                      attachments={attachments}
                      setAttachments={setAttachments}
                    />

                    <OutlineButton
                      fullWidth
                      text="Download PDF"
                      icon={<ArrowDownTrayIcon className="h-5 w-5 shrink-0" />}
                      onClick={() => {
                        createEstimatePDFNew(
                          { ...estimateDetails, estimateSettings },
                          userData?.bizData
                        );
                      }}
                    />
                  </div>
                  {attachments.length > 0 && (
                    <div>
                      <div className="pt-4 pb-0">
                        <h2 className="text-xl font-semibold">Attachments</h2>
                      </div>
                      <div className="px-0">
                        {attachments.map((attachment, index) => (
                          <div className="flex flex-row gap-2 items-center ">
                            <button onClick={() => removeAttachment(index)}>
                              <CancelIcon
                                className="text-gray-500 hover:text-gray-600 h-8"
                                fontSize="small"
                              />
                            </button>
                            <p className="text-gray-500 truncate leading-8">{attachment.name}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <EstimatePreviewSettings
                  estimateSettings={estimateSettings}
                  setEstimateSettings={setEstimateSettings}
                />
              </div>
            </div>
          </div>
          {/* Footer */}
          <div className="px-6 h-16 min-h-16 w-full flex flex-row items-center justify-between border-t border-gray-300 ">
            <OutlineButton text="Cancel" onClick={onClose} />
            <SolidButton
              text="Send Estimate"
              loading={uploadingFiles}
              onClick={async () => {
                setUploadingFiles(true);
                const uploadedAttachments = await onClickSendEstimate({
                  estimateSettings,
                  attachments,
                });
                setAttachments(uploadedAttachments);
                setUploadingFiles(false);
              }}
            />
          </div>
        </div>
      </TransitionComponent>
    </Modal>
  );
}
