// helper/stripe.js
import { node } from "constants/constants";
import { findPriceIdSalesRegistration } from "./findPriceId";

export const createFreeTrialCheckoutSession = async ({
  businessId,
  priceId = process.env.REACT_APP_STARTER_MONTH,
  trialDays = 14,
  customerId = null,
  intent = null,
}) => {
  try {
    const response = await fetch(
      `${node}/subscriptions/create-checkout-session-free-trial`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          businessId,
          priceId,
          trialDays,
          customerId,
          intent,
          // Add other parameters as needed
        }),
      }
    );

    const session = await response.json();
    if (response.ok && session.url) {
      return { url: session.url };
    } else {
      console.error("Error creating Stripe checkout session:", session);
      return { error: session }; // Return error for handling in the caller
    }
  } catch (error) {
    console.error("Error initiating Stripe checkout:", error);
    return { error }; // Return error for handling in the caller
  }
};

export const createImmediatePaymentCheckoutSession = async ({
  businessId,
  plan,
  cycle,
  coupon,
}) => {
  try {
    // need to get priceId from the plan and cycle
    const priceId = findPriceIdSalesRegistration(plan, cycle);
    if (!priceId) {
      throw new Error("Invalid plan or cycle");
    }
    const response = await fetch(
      `${node}/subscriptions/create-checkout-session-sales-registration`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          businessId,
          priceId,
          couponId: coupon,
        }),
      }
    );

    const session = await response.json();
    if (response.ok && session.url) {
      return { url: session.url };
    } else {
      console.error("Error creating Stripe checkout session:", session);
      return { error: session?.error || "Error creating checkout session" }; // Return error for handling in the caller
    }
  } catch (error) {
    console.error("Error initiating Stripe checkout:", error);
    return { error: error?.message || "Error creating checkout session" }; // Return error for handling in the caller
  }
};

export const createBillingPortalSession = async (customerId) => {
  try {
    const response = await fetch(
      `${node}/subscriptions/create-customer-portal-session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ customerId }),
      }
    );

    const result = await response.json();
    if (response.ok && result.url) {
      return { url: result.url };
    } else {
      console.error("Error creating billing portal session:", result);
      return { error: result };
    }
  } catch (error) {
    console.error("Error initiating billing portal session:", error);
    return { error };
  }
};

export const createUpdatePlanCheckoutSession = async (
  customerId,
  subscriptionId
) => {
  try {
    const response = await fetch(
      `${node}/subscriptions/update-subscription-plan`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerId: customerId,
          subscriptionId: subscriptionId,
        }),
      }
    );

    const result = await response.json();
    if (response.ok && result.url) {
      return { url: result.url };
    } else {
      console.error("Error creating update plan checkout session:", result);
      return { error: result };
    }
  } catch (error) {
    console.error("Error initiating update plan checkout session:", error);
    return { error };
  }
};

export const createCheckoutSession = async ({
  businessId,
  priceId,
  trialDays = null,
  customerId = null,
}) => {
  try {
    const response = await fetch(
      `${node}/subscriptions/create-checkout-session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          businessId,
          priceId,
          trialDays,
          customerId,
          // Add other parameters as needed
        }),
      }
    );

    const session = await response.json();
    if (response.ok && session.url) {
      return { url: session.url };
    } else {
      console.error("Error creating Stripe checkout session:", session);
      return { error: session }; // Return error for handling in the caller
    }
  } catch (error) {
    console.error("Error initiating Stripe checkout:", error);
    return { error }; // Return error for handling in the caller
  }
};

export const updateSubscriptionCheckoutSession = async ({
  customerId,
  subscriptionId,
  newPriceId,
  businessId,
}) => {
  try {
    const response = await fetch(
      `${node}/subscriptions/confirm-subscription-update`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerId: customerId,
          subscriptionId: subscriptionId,
          newPriceId: newPriceId,
          businessId: businessId,
        }),
      }
    );

    const result = await response.json();
    if (response.ok && result.url) {
      return { url: result.url };
    } else {
      console.error("Error updating subscription checkout session:", result);
      return { error: result };
    }
  } catch (error) {
    console.error(
      "Error initiating subscription update checkout session:",
      error
    );
    return { error };
  }
};
