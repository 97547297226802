import { colors } from "theme/colors";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

// component takes options as the selectable elements, and a setter for assigning option values
export default function MuiBasicDropdown({
  options,
  value,
  setValue,
  label,
  containerClass,
}) {
  return (
    <div className={containerClass}>
      <FormControl
        size="small"
        fullWidth
        sx={{
          "& .css-thfu6k-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#4b5563",
            },
        }}
      >
        <InputLabel>{label}</InputLabel>
        <Select
          aria-hidden="false"
          value={value}
          label={label}
          onChange={(e) => setValue(e.target.value)}
        >
          {options.map((option, i) => (
            <MenuItem
              key={label + option.label + i}
              value={option.value || option.label}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
