import React, { useState, useContext, useMemo, useEffect } from "react";
import CustomersList from "./components/CustomerList";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../../index";
import { useSnackBar } from "../../context/SnackBarContext";
import CustomerModalCombinedNew from "components/CustomerModalCombined/CustomerModalCombined";
import { CustomersPageHeader } from "./components/CustomersPageHeader";
import CustomersImportModal from "./components/CustomersImport/CustomersImportModal";
import useCustomers from "./hooks/useCustomers";
import useTags from "./hooks/useTags";
import RemoveTagModal from "./components/modals/RemoveTagsModal";
import AddTagsModal from "./components/modals/AddTagsModal";
import MarketingMessageModal from "./components/modals/MarketingMessageModal";
import DeleteCustomersModal from "./components/modals/DeleteCustomersModal";
import FiltersSidebar from "./components/lowerLevelComponents/FiltersSidebar";
import RequestReviewsModal from "./components/modals/RequestReviewsModal";
import { allColumnHeaders } from "./helpers";

export default function Customers() {
  const { userData, bannerVisible } = useContext(UserContext);
  // set user permissions
  const { notAbleToViewCustomers, notAbleToEditCustomers } = useMemo(
    () => ({
      notAbleToViewCustomers:
        userData?.userData?.customPermissions?.view_all_customers === false,
      notAbleToEditCustomers:
        userData?.userData?.customPermissions?.manage_customers === false,
    }),
    [userData]
  );

  const { openSnackBar } = useSnackBar();
  const state = useLocation().state;
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [modal, setModal] = useState(state?.newCustomerModal ? "customer" : ""); // 'customer' || 'removeTag' || 'addTag' || 'marketingMessage' || 'filters' || 'deleteCustomers' || 'import' || 'requestReviews' || ''
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);

  const [columnHeaders, setColumnHeaders] = useState(
    allColumnHeaders.slice(0, 7)
  ); // initalize with default columns;

  const tags = useTags();
  const {
    customers,
    getCustomersNext,
    setFilterOptions,
    filterOptions,
    nextPage,
    totalCustomersCount,
    loadingCustomers,
    fetchType,
    allCustomers,
    refetchCustomers,
    totalCustomersInDB,
  } = useCustomers({ setSelectedRows });

  return (
    <>
      <div
        className={`bg-gray-50  ${
          bannerVisible ? "h-full-minus-head-and-banner" : "h-full-minus-header"
        } overflow-y-auto`}
      >
        <CustomersPageHeader
          toggleModal={() =>
            notAbleToEditCustomers
              ? openSnackBar(
                  "You do not have the permissions to edit or create customers."
                )
              : setModal((p) => (p === "customer" ? "" : "customer"))
          }
          userData={userData}
          customers={customers}
          setImportModalOpen={
            notAbleToEditCustomers || notAbleToViewCustomers
              ? () => {}
              : () => setModal("import")
          }
        />

        <CustomersList
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
          customers={customers}
          navigate={navigate}
          loadingCustomers={loadingCustomers}
          getCustomersNext={getCustomersNext}
          notAbleToViewCustomers={notAbleToViewCustomers}
          notAbleToEditCustomers={notAbleToEditCustomers}
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
          tags={tags}
          nextPage={nextPage}
          totalCustomersCount={totalCustomersCount}
          fetchType={fetchType}
          setModal={setModal}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          columnHeaders={columnHeaders}
          setColumnHeaders={setColumnHeaders}
          allCustomers={allCustomers}
          totalCustomersInDB={totalCustomersInDB}
        />
      </div>
      {/* modals */}
      {modal === "customer" && (
        <CustomerModalCombinedNew
          modal={modal === "customer"}
          setModal={(bool) => setModal(bool ? "customer" : "")} // set state is setup to take a boolean within component, but a string in this file
          // MF: needs custom function because need to clear the filter and add customer to page
          setSelectedCustomer={() => {
            refetchCustomers();
          }}
        />
      )}
      <FiltersSidebar
        open={modal === "filters"}
        onClose={() => setModal("")}
        userData={userData}
        setFilterOptions={setFilterOptions}
        filterOptions={filterOptions}
        tags={tags}
        loadingCustomers={loadingCustomers}
        refetchCustomers={refetchCustomers}
      />
      <RemoveTagModal
        open={modal === "removeTag"}
        selectedRows={selectedRows}
        closeModal={() => setModal("")}
        tags={tags}
        setSelectedRows={setSelectedRows}
      />
      <AddTagsModal
        open={modal === "addTag"}
        selectedRows={selectedRows}
        closeModal={() => setModal("")}
        tags={tags}
        setSelectedRows={setSelectedRows}
      />
      <MarketingMessageModal
        open={modal === "marketingMessage"}
        selectedRows={selectedRows}
        closeModal={() => setModal("")}
        allCustomers={allCustomers}
        customers={customers}
        setSelectedRows={setSelectedRows}
      />
      <DeleteCustomersModal
        open={modal === "deleteCustomers"}
        selectedRows={selectedRows}
        closeModal={() => setModal("")}
        allCustomers={allCustomers}
        customers={customers}
        setSelectedRows={setSelectedRows}
        refetchCustomers={refetchCustomers}
      />
      <CustomersImportModal
        isOpen={modal === "import"}
        setOpen={setModal}
        refetchCustomers={refetchCustomers}
      />
      {/* this modals effects are expensive so block it with a conditional */}
      {modal === "requestReviews" && (
        <RequestReviewsModal
          open={true}
          selectedRows={selectedRows}
          allCustomers={allCustomers}
          closeModal={() => setModal("")}
          setSelectedRows={setSelectedRows}
        />
      )}
    </>
  );
}
