import { db } from "utils/firebase";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";

export async function fetchCustomer({ businessId, customerId }) {
  const customerRef = doc(
    db,
    "businesses",
    businessId,
    "customers",
    customerId
  );
  const customerDoc = await getDoc(customerRef);
  if (customerDoc.exists()) {
    return customerDoc.data();
  }
  return null;
}

export async function fetchConversation({
  businessId,
  customerId,
  customerPhone,
}) {
  if (!customerPhone?.mobile) {
    return null;
  }
  const conversationRef = doc(
    db,
    "businesses",
    businessId,
    "conversations",
    customerPhone.mobile
  );
  const conversationDoc = await getDoc(conversationRef);
  // console.log("conversationDoc", conversationDoc.data());
  if (conversationDoc.exists()) {
    return conversationDoc.data();
  }
  return null;
}

export async function fetchJobs({ businessId, customerId }) {
  const jobsRef = collection(db, "businesses", businessId, "jobs");
  const jobsQuery = query(
    jobsRef,
    where("customer.customerId", "==", customerId)
  );
  const jobsSnapshot = await getDocs(jobsQuery);
  const jobsList = jobsSnapshot.docs.map((doc) => doc.data());

  // put oldest jobs first
  return jobsList.sort((a, b) => a.start.toDate() - b.start.toDate());
}

export async function fetchEstimates({ businessId, customerId }) {
  const estimatesRef = collection(db, "businesses", businessId, "estimates");
  const estimatesQuery = query(
    estimatesRef,
    where("customer.customerId", "==", customerId)
  );
  const estimatesSnapshot = await getDocs(estimatesQuery);
  const estimateList = estimatesSnapshot.docs.map((doc) => doc.data());

  return estimateList.sort(
    (a, b) => a.dateAdded.toDate() - b.dateAdded.toDate()
  );
}

export async function fetchInvoices({ businessId, customerId }) {
  const invoicesRef = collection(db, "businesses", businessId, "invoices");
  const invoicesQuery = query(
    invoicesRef,
    where("customerId", "==", customerId)
  );
  const invoicesSnapshot = await getDocs(invoicesQuery);
  const invoicesList = invoicesSnapshot.docs.map((doc) => doc.data());

  return invoicesList.sort(
    (a, b) => a.invoiceSentTime.toDate() - b.invoiceSentTime.toDate()
  );
}

export async function fetchSubscriptions({ businessId, customerId }) {
  const subscriptionsRef = collection(
    db,
    "businesses",
    businessId,
    "subscriptions"
  );
  const subscriptionsQuery = query(
    subscriptionsRef,
    where("customer.customerId", "==", customerId)
  );
  const subscriptionsSnapshot = await getDocs(subscriptionsQuery);
  const subscriptionsList = subscriptionsSnapshot.docs.map((doc) => doc.data());

  return subscriptionsList.sort(
    (a, b) => a.createdAt.toDate() - b.createdAt.toDate()
  );
}
