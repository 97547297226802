import MuiBasicDropdown from "components/reusable/MuiBasicDropdown";
import { CloseModalIconButton } from "components/NewButtons/CloseModalIconButton";
import Dialogue from "components/modals/Dialogue";
import { useEffect, useState } from "react";
import DisabledButton from "components/buttons/DisabledButton";
import { addOrRemoveCustomerTags } from "utils/firestoreCalls";
import { useSnackBar } from "context/SnackBarContext";
import { useUserContext } from "context/UserContext";

export default function RemoveTagModal({
  open,
  selectedRows,
  closeModal,
  tags,
  setSelectedRows,
}) {
  const [workingTag, setWorkingTag] = useState("");
  const { userData } = useUserContext();
  const { openSnackBar } = useSnackBar();

  // reset workingTag if modal is closed
  useEffect(() => {
    if (open === false) {
      setWorkingTag("");
    }
  }, [open]);

  return (
    <Dialogue open={open}>
      <div className="absolute top-2 right-2">
        <CloseModalIconButton handleCancel={closeModal} />
      </div>

      <p className="mb-3">
        Remove a tag from {selectedRows?.length || 0} customer
        {selectedRows?.length > 1 ? "s" : ""}:
      </p>
      <MuiBasicDropdown
        options={tags?.map((tag) => ({ label: tag })) || []}
        value={workingTag}
        setValue={setWorkingTag}
        label="tag"
      />
      <DisabledButton
        text={"Confirm"}
        additionalClasses="mx-auto"
        width="w-24 mt-8"
        disabled={!workingTag}
        onClick={async () => {
          try {
            closeModal();
            openSnackBar(
              `removing tag${selectedRows?.length > 1 ? "s" : ""}...`,
              false,
              false,
              120000
            );
            await addOrRemoveCustomerTags({
              customerIds: selectedRows,
              userData,
              tag: workingTag,
              deleteTags: true,
            });
            openSnackBar(
              `${workingTag} tag removed from ${selectedRows?.length} customer${
                selectedRows?.length > 1 ? "s" : ""
              }.`,
              true,
              false,
              120000
            );
            setSelectedRows([]);
          } catch (e) {
            console.log(
              "error in RemoveTagsModal associated with removing a tag from customers: ",
              e
            );
            openSnackBar(
              `There was a problem removing a tag from ${
                (selectedRows?.length > 1 ? "customers." : "a customer.",
                false,
                true,
                120000)
              }`
            );
          }
        }}
      />
    </Dialogue>
  );
}
