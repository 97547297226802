import React, { useState, useEffect, forwardRef } from "react";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import MomentBasedDatePicker from "components/reusable/MomentBasedDatePicker";
import Header from "features/ServiceSubscription/components/shared/Header";
import Footer from "features/ServiceSubscription/components/shared/Footer";
import InputMUI from "components/reusable/InputMUI";
import { convertToJsDateNano } from "utils/helpers";
import MuiBasicDropdown from "components/reusable/MuiBasicDropdown";
import { Checkbox } from "@mui/material";

const TransitionComponent = forwardRef((props, ref) => (
  <Slide direction="left" ref={ref} {...props} />
));

export default function FiltersSidebar({
  open,
  onClose,
  userData,
  setFilterOptions,
  filterOptions,
  tags,
  loadingCustomers,
}) {
  const [unAppliedFilters, setUnAppliedFilters] = useState({
    ...filterOptions,
  });
  // filters: { notfiications: true, amount: 25, lastDoc: null, createdAtStartDate: null, createdAtEndDate: null, lastJobStartDate: null, lastJobEndDate: null, city: "", tag: "", ...filterOptions, }
  const applyFilters = () => {
    const filtersToApply = {};
    // only transfer filters with a value to filter by -- to increse filtering preformance later on
    for (const key in unAppliedFilters) {
      if (unAppliedFilters[key])
        filtersToApply[key] =
          unAppliedFilters[key] === "false" ? false : unAppliedFilters[key]; // string false is used in MUI selections change it back here
    }
    setFilterOptions((p) => {
      // maintain order by and amount
      if (p.orderBy) filtersToApply.orderBy = p.orderBy;
      if (p.amount) filtersToApply.amount = p.amount;
      return filtersToApply;
    });
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        setUnAppliedFilters({ ...filterOptions }); // reset unapplied filters
      }}
    >
      <TransitionComponent in={open}>
        <div
          className="absolute flex flex-col overflow-y-auto top-0 bg-white shadow-bold rounded-tl-md rounded-bl-md h-full"
          style={{
            right: 0, // Position it on the right edge of the screen
            width: "400px", // Set a fixed width of 300 pixels
          }}
        >
          <Header title="Filters" onClose={onClose} containerClass="shrink-0" />
          <div className="p-6">
            <FilterOption
              title="Created"
              userData={userData}
              containerClass="!mt-0" // bang operator to overwrite default component margin, tailwind doesn't respect class order presedence
              options={[
                {
                  type: "date",
                  value: unAppliedFilters.createdAtStartDate,
                  setValue: (val) =>
                    setUnAppliedFilters((p) => ({
                      ...p,
                      createdAtStartDate: val,
                    })),
                  label: "Start",
                  start: true,
                  field: "createdAtStartDate",
                },
                {
                  type: "date",
                  value: unAppliedFilters.createdAtEndDate,
                  setValue: (val) =>
                    setUnAppliedFilters((p) => ({
                      ...p,
                      createdAtEndDate: val,
                    })),
                  label: "End",
                  field: "createdAtEndDate",
                },
              ]}
              unAppliedFilters={unAppliedFilters}
              setUnAppliedFilters={setUnAppliedFilters}
            />
            <FilterOption
              title="Last job"
              userData={userData}
              options={[
                {
                  type: "date",
                  value: unAppliedFilters.lastJobStartDate,
                  setValue: (val) =>
                    setUnAppliedFilters((p) => ({
                      ...p,
                      lastJobStartDate: val,
                    })),
                  label: "Start",
                  start: true,
                  field: "lastJobStartDate",
                },
                {
                  type: "date",
                  value: unAppliedFilters.lastJobEndDate,
                  setValue: (val) =>
                    setUnAppliedFilters((p) => ({ ...p, lastJobEndDate: val })),
                  label: "End",
                  field: "lastJobEndDate",
                },
              ]}
              unAppliedFilters={unAppliedFilters}
              setUnAppliedFilters={setUnAppliedFilters}
            />
            <FilterOption
              // title="City"
              userData={userData}
              resetOption={false}
              options={[
                {
                  type: "userInput",
                  value: unAppliedFilters.city,
                  setValue: (val) => {
                    setUnAppliedFilters((p) => ({ ...p, city: val }));
                  },
                  label: "City",
                  placeholder: "Enter city",
                  field: "city",
                },
              ]}
              unAppliedFilters={unAppliedFilters}
              setUnAppliedFilters={setUnAppliedFilters}
            />
            <FilterOption
              // title="Tags"
              userData={userData}
              options={[
                {
                  type: "dropdown",
                  value: unAppliedFilters.tag || "",
                  setValue: (val) => {
                    setUnAppliedFilters((p) => ({ ...p, tag: val }));
                  },
                  label: "Tags",
                  field: "tag",
                  dropdownItems: tags?.length
                    ? tags?.map((tag) => ({ value: tag, label: tag }))
                    : [],
                },
              ]}
              unAppliedFilters={unAppliedFilters}
              setUnAppliedFilters={setUnAppliedFilters}
            />
            <FilterOption
              userData={userData}
              options={[
                {
                  type: "dropdown",
                  value: unAppliedFilters.notifications || "",
                  setValue: (val) => {
                    console.log("val", val);
                    setUnAppliedFilters((p) => ({ ...p, notifications: val }));
                  },
                  label: "Notifications",
                  dropdownItems: [
                    { value: true, label: "Enabled" },
                    { value: "false", label: "Disabled" }, // MUI dropdowns have a problem with falsey values, use a string here then convert to bool when applying the filter
                  ],
                  field: "notifications",
                },
              ]}
              unAppliedFilters={unAppliedFilters}
              setUnAppliedFilters={setUnAppliedFilters}
            />
            {/* <div className="flex flex-row items-center gap-2 mt-10">
              <Checkbox
                checked={!!unAppliedFilters?.notificationsEnabled}
                onChange={(e) => {
                  if (e.target.checked)
                    setUnAppliedFilters((p) => ({
                      ...p,
                      notificationsEnabled: e.target.checked,
                    }));
                  else
                    setUnAppliedFilters((p) => {
                      let newObj = { ...p };
                      delete newObj.notificationsEnabled;
                      return newObj;
                    });
                }}
              />
              <p className="text-sm">Notifications enabled only</p>
            </div> */}
          </div>
          <Footer
            onClose={onClose}
            disabled={loadingCustomers}
            onCreate={applyFilters}
            loading={loadingCustomers}
            buttonText="Apply Filters"
            containerClass="!static !bottom-auto !self-end mt-auto mb-0 shrink-0"
            // containerClass="mt-auto mb-0 !bottom-auto self-end"
          />
        </div>
      </TransitionComponent>
    </Modal>
  );
}

// Creates filter options.
// date options reference: [ { type: "date", value: Date, setValue: setState, label: String, ?start = bool } ]
// user input options reference: [ { type: "userInput", value: String, setValue: setState, label: String, placeholder: String } ]
// dropdown options reference: [ { type: 'dropdown', dropdownItems: [value: any, label: String], setValue, value } ]
const FilterOption = ({
  userData,
  options = [],
  resetOption = true,
  title,
  containerClass = "",
  setUnAppliedFilters,
  unAppliedFilters,
}) => {
  const timezone = userData.bizData.timeZone;
  return (
    <div className={`mt-10 w-full ${containerClass}`}>
      {title && (
        <div className="flex flex-row items-center pb-2 gap-4 ">
          <h3 className=" font-medium text-gray-800 ">{title}</h3>
        </div>
      )}
      <div className="flex flex-row justify-between relative w-full">
        {/* date option  */}
        {options.map((option, i) => (
          <div key={title + "-option-" + i}>
            {option.type === "date" && (
              <div className="w-36">
                <MomentBasedDatePicker
                  label={option.label}
                  date={option.value}
                  setDate={(date) => {
                    option.setValue(date);
                  }}
                  timezone={timezone}
                  borderColor="#4b5563" // gray-600
                  start={!!option?.start} // if the time begins at the start or end of day
                />
              </div>
            )}
            {/* user input option */}
            {option.type === "userInput" && (
              <InputMUI
                label={option.label}
                placeholder={option.placeholder}
                value={option.value || ""}
                setValue={option.setValue}
                autoComplete="off"
                size="small"
              />
            )}
            {/* dropdown option */}
            {option.type === "dropdown" && (
              <MuiBasicDropdown
                options={option.dropdownItems}
                value={option.value}
                setValue={option.setValue}
                label={option.label}
                containerClass="w-36"
              />
            )}
          </div>
        ))}

        {/* reset button */}
        {resetOption &&
          options.some((option) =>
            unAppliedFilters.hasOwnProperty(option.field)
          ) && (
            <button
              className="text-yellow-300 absolute -bottom-6 left-0"
              onClick={() =>
                setUnAppliedFilters((p) => {
                  let newObj = { ...p };
                  options.forEach((option) => delete newObj[option.field]);
                  return newObj;
                })
              }
            >
              clear
            </button>
          )}
      </div>
    </div>
  );
};
