import { useState, useEffect } from "react";
import { onSnapshot } from "firebase/firestore";

export default function useSubscription(query) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query,
      (doc) => {
        if (!doc.exists()) {
          console.log("dont exist");
          setData(null);
          setError({ err: "Doc doesn't exist" });
        } else {
          setData(doc.data());
        }
        setLoading(false);
      },
      (err) => {
        console.error("Error subscribing to Firestore", err);
        setError(err);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [query]);

  return { data, loading, error };
}
