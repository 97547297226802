import PanelWrapper from '../lowerLevelComponents/PanelWrapper';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useState } from 'react';
import StatsTable from '../lowerLevelComponents/StatsTable';
import moment from 'moment';
import MuiBasicDropdown from 'components/reusable/MuiBasicDropdown';

const rankByOptions = [
  { label: 'Doors Knocked', value: 'Doors Knocked' },
  { label: 'Not home', value: 'Not home' },
  { label: 'Come back', value: 'Come back' },
  { label: 'Quote', value: 'Quote' },
  { label: 'Sale', value: 'Sale' },
  { label: 'Uncertain', value: 'Uncertain' },
  { label: 'Avoid', value: 'Avoid' },
];
const timeframeOptions = [
  { label: 'Today', value: { start: new Date(moment(new Date()).startOf('day')) } },
  {
    label: 'Yesterday',
    value: {
      start: new Date(moment().subtract(1, 'days').startOf('day')),
      end: new Date(moment().subtract(1, 'days').endOf('day')),
    },
  },
  { label: 'last 7 days', value: { start: new Date(moment().subtract(7, 'days').startOf('day')) } },
  { label: 'last 30 days', value: { start: new Date(moment().subtract(30, 'days').startOf('day')) } },
  { label: 'last 6 months', value: { start: new Date(moment().subtract(6, 'months').startOf('day')) } },
  { label: 'last year', value: { start: new Date(moment().subtract(12, 'months').startOf('day')) } },
  { label: 'all time', value: {} },
];

export default function StatsPanel({ employees, setPanelVisible, panelVisible, errorToast }) {
  const [rankBy, setRankBy] = useState('');
  const [timeframe, setTimeframe] = useState('');
  const [expanded, setExpanded] = useState(false);

  return (
    <PanelWrapper
      title={'Employee Map Statistics'}
      visible={panelVisible.type === 'stats'}
      closeFunction={() => setPanelVisible({ type: '', data: null })}
      expandButton={true}
      expanded={expanded}
      setExpanded={setExpanded}
    >
      <MuiBasicDropdown
        containerClass={'w-full max-w-sm mt-2'}
        options={rankByOptions}
        value={rankBy}
        setValue={setRankBy}
        label='Metric to Rank By'
      />
      <MuiBasicDropdown
        containerClass={'w-full max-w-sm mt-4 mb-6'}
        options={timeframeOptions}
        value={timeframe}
        setValue={setTimeframe}
        label='Timeframe'
      />
      <StatsTable
        employees={employees}
        rankBy={rankBy}
        timeframe={timeframe}
        errorToast={errorToast}
        expanded={expanded}
      />
    </PanelWrapper>
  );
}
