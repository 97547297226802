import { useEffect, useRef, useState } from 'react';
import { FaXmark } from 'react-icons/fa6';

const options = {
  componentRestrictions: { country: ['ca', 'us'] },
  fields: ['geometry', 'formatted_address'],
  types: ['address'],
};

export default function AddressSearch({ addressSearched, setAddressSearched, map, editingMode }) {
  const [value, setValue] = useState(addressSearched?.address || '');
  const [isFocused, setIsFocused] = useState(false);
  const autoCompleteRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, options);

    const setNewAddress = async () => {
      const place = await autoCompleteRef.current.getPlace();
      if (place?.geometry) {
        setValue(place.formatted_address);
        const position = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        setAddressSearched({
          address: place.formatted_address,
          position,
        });
        map.panTo(position);
      }
    };
    const listener = autoCompleteRef.current.addListener('place_changed', setNewAddress);
    return () => listener.remove();
  }, [setAddressSearched, addressSearched, map]);

  return (
    <div
      className={`absolute top-[8px] h-8 right-12 bg-white z-[1] rounded-sm${
        !!editingMode?.operation ? ' hidden' : ''
      }`}
      onBlur={() => setIsFocused(false)}
      onFocus={() => setIsFocused(true)}
    >
      <input
        className={`h-full w-40 outline-none transition-all truncate px-3${isFocused ? ' w-96 pr-8' : ''}`}
        placeholder='Search a location'
        autoComplete='off'
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        ref={inputRef}
      />
      {isFocused && value && (
        <button
          id='search-x-button'
          className='absolute right-2 top-1/2 -translate-y-1/2'
          onMouseDown={(e) => {
            console.log('reset');
            e.preventDefault();
            setValue('');
          }}
        >
          <FaXmark />
        </button>
      )}
    </div>
  );
}
