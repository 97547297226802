import React, { useState } from "react";
import {
  SelectField,
  TextField,
  PhoneField,
  PasswordRegister,
} from "../reusable/fields";
import { Link } from "react-router-dom";
import ManualAddress from "./ManualAddress";
import { ImSpinner } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

const formatPhoneNumber = (value) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

export default function BusinessDetails({
  handleSubmit,
  companyName,
  setCompanyName,
  companyPhoneBeta,
  setCompanyPhoneBeta,
  companyPhoneCountry,
  setCompanyPhoneCountry,
  passwordShown,
  togglePassword,
  password,
  setPassword,
  error,
  setError,
  industry,
  setIndustry,
  companySize,
  setCompanySize,
  website,
  setWebsite,
  address,
  setAddress,
  setStep,
  isLoading,
  notFilledIn,
  // termsAccepted,
  // setTermsAccepted,
}) {
  const handleCompanyPhoneInput = (e) => {
    // this is where we'll call the phoneNumberFormatter function
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    // we'll set the input value using our setInputValue
    setCompanyPhoneBeta(formattedPhoneNumber);
  };

  const navigate = useNavigate();

  const [termsAccepted, setTermsAccepted] = useState(false);

  return (
    <div className="mb-24 flex w-11/12 max-w-md flex-col items-center sm:w-1/2">
      <div aria-label="Home">
        <img className="h-14 w-auto" src="/images/Homebase-logo-black.png" />
      </div>
      <div className="mt-5 flex flex-col items-center">
        <h2 className="text-lg font-semibold text-gray-900">
          Enter your business details
        </h2>
      </div>

      <div className="mt-10 grid grid-cols-1  gap-x-6 sm:grid-cols-4">
        <TextField
          label="Company Name"
          id="company_name"
          name="company_name"
          type="text"
          autoComplete="none"
          className="col-span-full"
          onChange={(e) => setCompanyName(e.target.value)}
          value={companyName}
          required
        />
        <div className="col-span-full mt-5">
          <label htmlFor="phone-number" className="  text-md text-gray-900">
            Business Phone
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 flex items-center">
              <label htmlFor="country" className="sr-only">
                Country
              </label>
              <select
                id="country"
                name="country"
                className="h-full rounded-md border-transparent bg-transparent py-0 pl-4 pr-2 text-gray-500 focus:border-yellow-500 focus:ring-yellow-500 focus:outline-none"
                onChange={(e) => setCompanyPhoneCountry(e.target.value)}
                value={companyPhoneCountry}
              >
                <option>US</option>
                <option>CA</option>
              </select>
            </div>
            <input
              type="tel"
              name="phone-number"
              id="phone-number"
              autoComplete="none"
              className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-yellow-500 focus:bg-white focus:outline-none focus:ring-yellow-500 sm:text-sm pl-20"
              placeholder="(555) 987-6543"
              onChange={(e) => handleCompanyPhoneInput(e)}
              value={companyPhoneBeta}
              required
            />
          </div>
          <ManualAddress
            address={address}
            setAddress={setAddress}
            notFilledIn={notFilledIn}
          />
          <SelectField
            className="col-span-full mt-5"
            label="Industry"
            id="referral_source"
            name="referral_source"
            onChange={(e) => setIndustry(e.target.value)}
            value={industry}
          >
            <option>Window Cleaning</option>
            <option>General Cleaning</option>
            <option>Landscaping</option>
            <option>Painting</option>
            <option>Plumbing</option>
            <option>Electrical</option>
            <option>Roofing</option>
            <option>HVAC</option>
            <option>Other</option>
          </SelectField>
          {companySize && (
            <SelectField
              className="col-span-full mt-5"
              label="Company Size"
              id="referral_source"
              name="referral_source"
              onChange={(e) => setCompanySize(e.target.value)}
              value={companySize}
            >
              <option>Just you</option>
              <option>2-7 people</option>
              <option>8-15 people</option>
              <option>16-30 people</option>
              <option>30-50 people</option>
              <option>50-150 people</option>
              <option>150+ people</option>
            </SelectField>
          )}
          <TextField
            className="col-span-full mt-5"
            label="Website (optional)"
            id="website"
            name="website"
            type="website"
            autoComplete="none"
            onChange={(e) => setWebsite(e.target.value)}
            value={website}
            required
          />

          <PasswordRegister
            className="col-span-full mt-5"
            label="Password"
            id="password"
            name="password"
            autoComplete="new-password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            togglePassword={togglePassword}
            type={passwordShown ? "text" : "password"}
            required
            passwordShown={passwordShown}
          />

          <div className="col-span-full mt-10 mb-5 px-2">
            <label className="">
              <input
                type="checkbox"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
              />
              <span className="ml-2 ">
                By providing your personal phone number, you agree to receive
                account-related notifications and app download links from
                Homebase360 via text message. Message and data rates may apply.
                Message frequency varies based on account activity. Reply STOP
                to opt out. For more information please read our{" "}
                <span>
                  <a
                    className="underline text-yellow-500"
                    href="https://homebase360.io/messaging-terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Messaging Terms and Conditions
                  </a>{" "}
                  and{" "}
                  <a
                    className="underline text-yellow-500"
                    href="https://homebase360.io/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </span>
              </span>
            </label>
          </div>
        </div>

        <div className="col-span-full mb-10">
          <div className="flex flex-row justify-between">
            <button
              disabled={isLoading}
              variant="solid"
              color="slate"
              className={`group mt-10  inline-flex w-1/3 items-center justify-center gap-2 rounded-full
            bg-neutral-900 
               py-2 px-4 text-sm font-semibold text-white ${
                 !isLoading && "hover:bg-neutral-700 hover:text-neutral-100"
               }  focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-900 active:bg-neutral-800 active:text-neutral-300`}
              onClick={() => setStep(1)}
            >
              <span>
                <span aria-hidden="true">&larr;</span> Go back
              </span>
            </button>
            <button
              disabled={isLoading || !termsAccepted}
              type="submit"
              variant="solid"
              color="slate"
              className={` group mt-10  inline-flex w-7/12 items-center justify-center gap-2 rounded-full
              ${
                termsAccepted
                  ? `${
                      isLoading
                        ? "bg-neutral-700"
                        : "bg-neutral-900 hover:bg-neutral-700 hover:text-neutral-100"
                    }  focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-900 active:bg-neutral-800 active:text-neutral-300`
                  : "bg-neutral-300 cursor-not-allowed"
              } py-2 px-4 text-sm font-semibold text-white  ${
                isLoading && "cursor-not-allowed bg-neutral-700"
              }`}
              onClick={handleSubmit}
            >
              {isLoading ? (
                // <ImSpinner className="animate-spin-slow text-lg" />
                <CircularProgress size={20} style={{ color: "#fefce8" }} />
              ) : (
                <span>
                  Sign up <span aria-hidden="true">&rarr;</span>
                </span>
              )}
            </button>
          </div>

          {error && (
            <div className="text-red-600 tx-sm text-center justify-self-center mt-3">
              {error}
            </div>
          )}

          {/* <button
            className="mt-5 text-sm text-gray-500 hover:text-gray-700"
            onClick={() =>
              navigate("/", {
                state: {
                  setUp: true,
                },
              })
            }
          >
            navigate to home test
          </button> */}
        </div>
      </div>
    </div>
  );
}
