import React, { useState, useEffect, useContext, useMemo } from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { UserContext } from "../../index";
import { db } from "../../utils/firebase";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import LineItemsStatic from "../JobDetails/components/LineItemsStatic";
import CustomerJob from "../JobDetails/components/CustomerJob";
import LeadSourceJobDetails from "../JobDetails/components/LeadSourceJobDetails";
import NoteJobDetails from "../JobDetails/components/NoteJobDetails";
import EditLeadSourceModal from "../JobDetails/components/EditLeadSourceModal";
import AddNoteModal from "../JobDetails/components/AddNoteModal";
import AddLineItemModal from "../JobDetails/components/AddLineItemModal";
import CustomerModalCombinedNew from "components/CustomerModalCombined/CustomerModalCombined";
import { useSnackBar } from "context/SnackBarContext";
import { deleteEstimate } from "./data/api";
import { useToggle } from "../../hooks/useToggle";
import { useEmployeeContext } from "../../context/EmployeeContext";
import { PageHeader } from "../../layouts/PageHeader";
import CenteredMaxWidthLayout from "../../layouts/CenteredMaxWidthLayout";
import SendEstimateModal from "./components/SendEstimateModal";
import useSubscription from "../../hooks/useSubscription";
import { EstimateHistory } from "./components/EstimateHistory";
import { EstimateButtons } from "./components/EstimateButtons";
import PreviewEstimateModal from "./components/PreviewEstimateModal";
import { validateAttachments } from "../../utils/validateAttachments";
import { handleEstimateAttachments } from "../../utils/firestoreCalls";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function EstimateDetails() {
  const { userData, bannerVisible } = useContext(UserContext);
  const { openSnackBar } = useSnackBar();
  const { employees: employeeList } = useEmployeeContext();

  const navigate = useNavigate();
  let { estimateId } = useParams();
  const location = useLocation();
  // get customer state from location if it exists
  // const showSend = location.state?.showSend;

  // const { estimateId } = location?.state;
  const businessId = userData?.userData?.businessId;

  // state that we fetch on mount
  const [estimateDetails, setEstimateDetails] = useState(null);

  // state for modals
  const [lineItemModal, setLineItemModal] = useState(false);
  const [customerModal, setCustomerModal] = useState(false);
  const [leadSourceModal, setLeadSourceModal] = useState(false);
  const [noteModal, setNoteModal] = useState(false);

  // state for edited customer data (we can refactor this eventually so it's within the edit customer modal)

  const [previewingEstimate, setPreviewingEstimate] = useState(false);

  const [sendingEstimate, setSendingEstimate] = useState(false);

  const [moreActionsAnchorEl, setMoreActionsAnchorEl] = useState(null);
  const isMoreActionsMenuOpen = Boolean(moreActionsAnchorEl);

  const handleMoreActionsClick = (event) => {
    setMoreActionsAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMoreActionsAnchorEl(null);
  };

  const query = useMemo(
    () => doc(db, "businesses", businessId, "estimates", estimateId),
    [businessId, estimateId]
  );

  const { data: fetchedEstimateDetails, loading, error } = useSubscription(query);

  useEffect(() => {
    if (fetchedEstimateDetails) setEstimateDetails(fetchedEstimateDetails);
  }, [fetchedEstimateDetails]);

  const toggle = useToggle();
  const toggleLineItemModal = () => toggle(setLineItemModal);
  const toggleCustomerModal = () => toggle(setCustomerModal);
  const toggleLeadSourceModal = () => toggle(setLeadSourceModal);
  const toggleNoteModal = () => toggle(setNoteModal);

  const onClickSendEstimate = async ({ estimateSettings, attachments }) => {
    try {
      validateAttachments(attachments);
      const uploadedAttachments = await handleEstimateAttachments(attachments);
      setEstimateDetails((curDetails) => ({
        ...curDetails,
        estimateSettings,
        attachments: uploadedAttachments,
      }));
      setSendingEstimate(true);
      return uploadedAttachments;
    } catch (error) {
      if (error.message.includes("exceed the")) {
        openSnackBar(error.message, false, true);
      } else {
        openSnackBar("Error processing attachments", false, true);
      }
      return attachments;
    }
  };

  if (error) return navigate("/");
  if (loading || !estimateDetails) return null;

  return (
    <>
      <div
        className="bg-gray-50"
        style={{
          height: bannerVisible ? "calc(100vh - 60px - 60px)" : "calc(100vh - 64px)",
          overflowY: "auto",
        }}
      >
        <CenteredMaxWidthLayout>
          <PageHeader title="Estimate Details">
            <div className="flex flex-row items-center justify-between">
              <IconButton
                aria-label="more actions"
                onClick={handleMoreActionsClick}
                sx={{
                  "&:hover": {
                    backgroundColor: "#f3f4f6", // Tailwind's gray-100 color
                  },
                }}
                disableRipple
              >
                <MoreVertIcon style={{ color: "#111827" }} />
              </IconButton>
              <Menu
                anchorEl={moreActionsAnchorEl}
                open={isMoreActionsMenuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": "more-actions-button",
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <Link to={`/estimate-overview/${businessId}/${estimateId}`} target="_blank">
                  <MenuItem
                    onClick={handleMenuClose}
                    disableRipple
                    sx={{
                      fontFamily: "Inter var, system-ui, sans-serif",

                      fontSize: "0.875rem",
                      "&:hover": {
                        backgroundColor: "#fefce8", // Tailwind's yellow-50 color
                      },
                    }}
                  >
                    Open Estimate Link
                  </MenuItem>
                </Link>

                <MenuItem
                  onClick={() => {
                    deleteEstimate({ userData, estimateId });
                  }}
                  disableRipple
                  sx={{
                    fontFamily: "Inter var, system-ui, sans-serif",

                    fontSize: "0.875rem",
                    "&:hover": {
                      backgroundColor: "#fecaca", // Tailwind's yellow-50 color
                    },
                  }}
                >
                  Delete Estimate
                </MenuItem>
              </Menu>
            </div>
          </PageHeader>

          <div className="flex flex-col lg:gap-8 lg:grid lg:grid-cols-5 lg:relative flex-1  pb-4 px-4 mx-1">
            <div className="flex flex-col flex-grow lg:col-start-3 lg:col-end-6 lg:row-start-1">
              <EstimateButtons
                estimateId={estimateId}
                estimateDetails={estimateDetails}
                onClickSend={() => setPreviewingEstimate(true)}
                onClickCopy={() => {
                  navigate(`/addjob`, {
                    state: { customer: estimateDetails.customer, jobDetails: estimateDetails },
                  });
                }}
              />
              <LineItemsStatic
                lineItems={estimateDetails?.lineItems || []}
                toggleModal={toggleLineItemModal}
                disabled={true}
              />
              <EstimateHistory estimateHistory={estimateDetails.history} />
            </div>
            <div className="flex flex-col lg:col-start-1 lg:col-end-3 lg:row-start-1  gap-2 lg:gap-0">
              <div className="flex flex-col flex-none">
                <CustomerJob
                  toggleModal={toggleCustomerModal}
                  customer={estimateDetails?.customer}
                  notifications={estimateDetails?.customer?.notifications}
                  toggleNotifications={null}
                  navigate={navigate}
                />

                <LeadSourceJobDetails
                  toggleLeadSourceModal={toggleLeadSourceModal}
                  leadSource={estimateDetails?.leadSource}
                />
                <div className="pt-8">
                  <NoteJobDetails note={estimateDetails?.note} toggleNoteModal={toggleNoteModal} />
                </div>
              </div>
            </div>
          </div>
        </CenteredMaxWidthLayout>
      </div>
      {sendingEstimate && (
        <SendEstimateModal
          open={sendingEstimate}
          setOpen={setSendingEstimate}
          estimateData={estimateDetails}
          closePreviewModal={() => setPreviewingEstimate(false)}
        />
      )}
      <PreviewEstimateModal
        open={previewingEstimate}
        onClose={() => setPreviewingEstimate(false)}
        estimateDetails={estimateDetails}
        onClickSendEstimate={onClickSendEstimate}
      />

      <CustomerModalCombinedNew
        modal={customerModal}
        setModal={setCustomerModal}
        setSelectedCustomer={(updatedCustomerData) => {
          setEstimateDetails((prev) => {
            return { ...prev, customer: { ...updatedCustomerData } };
          });
        }}
        jobDetails={{ ...estimateDetails, type: "estimate" }}
        customer={estimateDetails?.customer}
        customerId={estimateDetails?.customer?.customerId}
      />
      <EditLeadSourceModal
        employeeList={employeeList}
        modal={leadSourceModal}
        setModal={setLeadSourceModal}
        jobDetails={estimateDetails}
        setJobDetails={setEstimateDetails}
        estimate={true}
      />

      <AddNoteModal
        jobDetails={estimateDetails}
        setJobDetails={setEstimateDetails}
        modal={noteModal}
        setModal={setNoteModal}
        estimate={true}
      />

      <AddLineItemModal
        toggleModal={toggleLineItemModal}
        jobDetails={estimateDetails}
        setJobDetails={setEstimateDetails}
        taxRates={userData?.bizData?.taxRates}
        setLineItemModal={setLineItemModal}
        lineItemModal={lineItemModal}
        estimate={true}
      />
    </>
  );
}
