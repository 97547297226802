import { ColorRing, TailSpin } from 'react-loader-spinner';
import { colors } from '../../../theme/colors';
import { useEffect, useState } from 'react';
export default function LoadingIndicator({ visible = false }) {
  const [opacity, setOpacity] = useState('opacity-0');

  useEffect(() => {
    if (visible) {
      setOpacity('opacity-100');
    } else setOpacity('opacity-0');
  }, [visible]);

  return (
    <TailSpin
      height='80'
      width='80'
      color={colors.yellow400}
      ariaLabel='tail-spin-loading'
      radius='1'
      wrapperClass={`absolute z-10 bottom-14 left-[calc(50%-30px)] transition-opacity duration-1000 padding pointer-events-none ${opacity}`}
    />
  );
}
