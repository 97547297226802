import HelperTooltip from "components/reusable/HelperTooltip";

export const PageHeader = ({
  title,
  loading,
  backLink,
  children,
  helperText = "", // text to give an overview/tips about the page
}) => {
  return (
    <div className="mx-5 my-6">
      {backLink && <div className="mb-1 -ml-3">{backLink}</div>}

      <div className={`flex-1 flex items-center justify-between h-11`}>
        <div className="min-w-0 flex-1 flex flex-row gap-2 items-center">
          {title ? (
            <>
              <h2 className="font-bold leading-9 text-gray-900 truncate text-3xl tracking-tight">
                {title}
              </h2>
              {helperText && (
                <HelperTooltip containerClass={"mt-2"} text={helperText} />
              )}
            </>
          ) : (
            <h2
              className={`font-bold leading-9 text-gray-900 truncate text-3xl tracking-tight text-transparent  ${
                (loading || !title) &&
                "animate-pulse w-72 bg-gray-200 rounded-full"
              }`}
            >
              Empty
            </h2>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};
