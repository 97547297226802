import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import CancelButton from "components/buttons/CancelButton";
import DisabledButton from "components/buttons/DisabledButton";
import { Switch } from "components/ui/switch";
import InputMUI from "components/reusable/InputMUI";
import PhoneInputMUI from "components/reusable/PhoneInputMUI";
import { useUserContext } from "context/UserContext";
import { CgAttachment } from "react-icons/cg";
import CustomFileInput from "components/reusable/CustomFileInput";
import { OutlineButton } from "../../../components/NewButtons/OutlineButton";
import { SolidButton } from "../../../components/NewButtons/SolidButton";
import { doc, getCountFromServer } from "firebase/firestore";
import { collection } from "firebase/firestore";
import { db } from "utils/firebase";
import { setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { createNewEstimate, updateEstimateSettings } from "../data/api";
import { node } from "../../../constants/constants";
import { useSnackBar } from "../../../context/SnackBarContext";

export default function SendEstimateModal({
  open,
  setOpen,
  estimateData,
  closePreviewModal,
}) {
  const { customer: customerData } = estimateData;
  const { userData } = useUserContext();
  const navigate = useNavigate();
  const { openSnackBar } = useSnackBar();

  // const [message, setMessage] = useState(`Custom message here.`);
  const [message, setMessage] = useState(
    userData?.bizData?.onEstimateMessage ||
      `Here is your estimate. \n\n- ${userData.bizData?.companyName}`
  );

  const [emailToSend, setEmailToSend] = useState(
    customerData?.email?.[0] || ""
  );
  // const [shouldSendEmail, setShouldSendEmail] = useState(customerData?.email?.[0] ? true : false);
  const [shouldSendEmail, setShouldSendEmail] = useState(false);

  const [phoneToSend, setPhoneToSend] = useState(
    customerData?.phone?.mobile || ""
  );
  const [shouldSendPhone, setShouldSendPhone] = useState(false);
  // const [shouldSendPhone, setShouldSendPhone] = useState(
  //   customerData?.phone?.mobile ? true : false
  // );

  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [phoneHelperText, setPhoneHelperText] = useState("");

  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    if (!email && !shouldSendEmail) {
      setEmailError(false);
      setEmailHelperText("");
      return;
    }

    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(email)) {
      setEmailError(true);
      setEmailHelperText("Please enter a valid email address.");
    } else {
      setEmailError(false);
      setEmailHelperText("");
    }
  };

  const validatePhone = (phone) => {
    if (!phone && !shouldSendPhone) {
      setPhoneError(false);
      setPhoneHelperText("");
      return;
    }
    // Phone validation logic
    if (phone.length < 12) {
      setPhoneError(true);
      setPhoneHelperText("Please enter a valid phone number.");
    } else {
      setPhoneError(false);
      setPhoneHelperText("");
    }
  };

  const handlePhoneBlur = () => {
    validatePhone(phoneToSend);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      validateEmail(emailToSend);
      validatePhone(phoneToSend);
    }, 200); // Validates only after 200 ms

    return () => clearTimeout(handler);
  }, [emailToSend, phoneToSend]);

  useEffect(() => {
    validateEmail(emailToSend);
    validatePhone(phoneToSend);
  }, [shouldSendPhone, shouldSendEmail]);

  const sendEstimate = async () => {
    setLoading(true);

    try {
      await fetch(`${node}/estimate/send-estimate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          estimateData,
          businessData: userData.bizData,
          emailToSend: shouldSendEmail ? emailToSend : null,
          phoneToSend: shouldSendPhone ? phoneToSend : null,
          customMessage: message,
          userId: userData.userData.id,
          userName: `${userData.userData.firstName} ${userData.userData.lastName}`,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to send estimate.");
          }
          openSnackBar("Estimate sent successfully!", true, false);
        })
        .catch((error) => {
          console.error(error);
          openSnackBar(
            "Error sending the estimate. Please try again.",
            false,
            true
          );
        });
    } catch (error) {
      console.error(error);
      alert("Error creating estimate, please try again later.");
    } finally {
      setLoading(false);
      setOpen(false);
      closePreviewModal();
    }
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div
        className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full sm:w-[600px] bg-white shadow-bold p-8 py-8 rounded-md max-h-90% overflow-y-auto outline-none`}
      >
        <h2 className="text-xl font-bold text-center mb-4">Send Estimate</h2>
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-2">
            <div className="w-1/2">
              <InputMUI
                label="Email"
                value={emailToSend}
                setValue={setEmailToSend}
                error={emailError}
                helperText={emailHelperText}
              />
            </div>

            <div className="w-1/2  justify-start pl-6 flex items-center gap-2">
              <Switch
                label="Send Via Email"
                checked={shouldSendEmail}
                onCheckedChange={() => {
                  setShouldSendEmail(!shouldSendEmail);
                }}
              />
              <label className="text-sm text-gray-600">Send Via Email</label>
            </div>
          </div>
          <div className="flex flex-row gap-2">
            <div className="w-1/2">
              <PhoneInputMUI
                label="Phone"
                value={phoneToSend}
                setValue={setPhoneToSend}
                onBlur={handlePhoneBlur}
                error={phoneError}
                helperText={phoneHelperText}
              />
            </div>
            <div className="w-1/2 justify-start pl-6 flex items-center gap-2">
              <Switch
                label="Send Via Text"
                checked={shouldSendPhone}
                onCheckedChange={() => {
                  setShouldSendPhone(!shouldSendPhone);
                }}
              />
              <label className="text-sm text-gray-600">Send Via Text</label>
            </div>
          </div>
        </div>
        <div className="mt-4 relative">
          <InputMUI
            multiline
            rows={8}
            inputProps={{
              maxLength: 700,
            }}
            label="Message"
            value={message}
            setValue={setMessage}
          />
        </div>
        <div className="flex flex-row items-center mt-4 gap-2">
          <OutlineButton
            text="Cancel"
            onClick={() => setOpen(false)}
            fullWidth
          />
          <SolidButton
            text="Send estimate"
            fullWidth
            disabled={
              (!shouldSendEmail && !shouldSendPhone) ||
              (shouldSendEmail && emailError) ||
              (shouldSendPhone && phoneError)
            }
            loading={loading}
            onClick={sendEstimate}
          />
        </div>
      </div>
    </Modal>
  );
}
