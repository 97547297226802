import MenuItem from "@mui/material/MenuItem";
import DropDownMenuTemplate from "./DropDownMenuTemplate";
import { HiMiniSquare2Stack } from "react-icons/hi2";
import { useMemo } from "react";
import { useUserContext } from "context/UserContext";
import { handleExportToCsv } from "../../helpers";

const calculatePermission = (
  userData,
  { customPermissions, permissionLevel }
) => {
  if (userData.userData.isAdmin) return true; // admin has all permissions
  if (userData.userData.userType === permissionLevel) return true; // this is typically manager level
  if (customPermissions)
    for (const permission of customPermissions) {
      if (userData.userData?.customPermissions?.[permission]) return true;
    }
  return false;
};

export default function MassActionsMenu({
  setModal,
  setSelectedRows,
  selectedRows,
  allCustomers,
  columnHeaders,
}) {
  const { userData } = useUserContext();

  const options = useMemo(
    () => [
      {
        title: "Add Tag",
        modalName: "addTag",
        permission: calculatePermission(userData, {
          customPermissions: ["manage_customers"],
          permissionLevel: "manager",
        }),
      },
      {
        title: "Remove Tag",
        modalName: "removeTag",
        permission: calculatePermission(userData, {
          customPermissions: ["manage_customers"],
          permissionLevel: "manager",
        }),
      },
      {
        title: "Marketing Message",
        modalName: "marketingMessage",
        permission: calculatePermission(userData, {
          customPermissions: ["manage_customers"],
          permissionLevel: "manager",
        }),
      },
      {
        title: "Delete Customers",
        modalName: "deleteCustomers",
        permission: calculatePermission(userData, {
          customPermissions: ["delete_customers"],
          permissionLevel: "admin",
        }),
      },
      {
        title: "Request Reviews",
        modalName: "requestReviews",
        permission: calculatePermission(userData, {
          customPermissions: ["manage_customers"],
          permissionLevel: "manager",
        }),
      },
      {
        title: "Export to CSV",
        action: () => {
          console.log("export to csv");
          handleExportToCsv({
            allCustomers,
            selectedRows,
            columnHeaders,
            userData,
          });
        },
        permission: true,
      },
      {
        title: "Deselect",
        action: () => {
          setSelectedRows([]);
        },
        permission: true,
      },
    ],
    [setSelectedRows, allCustomers, selectedRows, columnHeaders, userData]
  );

  return (
    <>
      <DropDownMenuTemplate
        Icon={<HiMiniSquare2Stack size={22} />}
        tooltip={"Bulk Actions"}
        settings={{ closeOnClick: true }}
        MenuItems={options.map((option, i) => {
          return (
            option.permission && (
              <MenuItem
                key={"menu-action-option-" + i}
                onClick={() =>
                  option.action ? option.action() : setModal(option.modalName)
                }
              >
                {option.title}
              </MenuItem>
            )
          );
        })}
      />
    </>
  );
}
