import { node } from "../../../constants/constants";

export async function getStripeCustomer(userData) {
  try {
    console.log("retrieving account");
    const response = await fetch(`${node}/connect/retrieve-account`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stripeAccountId: userData.bizData.stripeAccountId,
      }),
    });
    const data = await response.json();
    // console.log("data", JSON.stringify(data));
    // console.log("data", data);

    // console.log("REQUIREMENTS", data.account.disabled_reason, data.account.requirements);
    return data?.account;
    // if (data?.account?.requirements?.disabled_reason)
    //   return data.account.requirements.disabled_reason;
    // return null;
  } catch (err) {
    console.log("err getting stripe account", err);
  }
}

export async function getCustomerBalance(userData) {
  if (!userData) return;

  try {
    // console.log("fetching data", userData);

    const response = await fetch(`${node}/connect/retrieve-balance`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stripeAccountId: userData.bizData.stripeAccountId,
      }),
    });
    const data = await response.json();
    // console.log("data", data);
    return data;
    // return data.paymentIntents?.data;
    // console.log("data", JSON.stringify(data));
    // console.log("data", data);

    // console.log("REQUIREMENTS", data.account.disabled_reason, data.account.requirements);
    // return data?.account;
    // if (data?.account?.requirements?.disabled_reason)
    //   return data.account.requirements.disabled_reason;
    // return null;
  } catch (err) {
    console.log("err getting stripe transactions", err);
    return null;
  }
}
