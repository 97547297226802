import React, { useState, useEffect } from "react";
import { ImTarget } from "react-icons/im";
import { TbWorldUpload } from "react-icons/tb";
import { BsPersonPlusFill } from "react-icons/bs";
import SalesPersonSearch from "./SalesPersonSearch";

export default function LeadSource({ setLeadSource, employeeList, leadSource, salesperson }) {
  // console.log("salesperson from leadsource", salesperson);
  const [online, setOnline] = useState(leadSource?.online ? leadSource.online : false);
  // const [referral, setReferral] = useState(false)
  const [direct, setDirect] = useState(leadSource?.direct ? leadSource.direct : false);
  const [other, setOther] = useState(leadSource?.other ? leadSource.other : false);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState(salesperson ? salesperson : null);

  useEffect(() => {
    setLeadSource({
      ...(online ? { online: online } : {}),
      ...(direct ? { direct: direct } : {}),
      ...(other ? { other: other } : {}),
      ...(selectedSalesPerson ? { salesPerson: selectedSalesPerson } : {}),
    });
  }, [online, direct, other, selectedSalesPerson]);

  return (
    <div className="flex flex-col h-52 bg-white rounded-md shadow-bold  gap-2 mt-8 pb-8 p-4">
      <div className="flex flex-row items-center gap-2 mt-2 mb-2">
        <ImTarget className="inline-block text-2xl" />
        <h1 className="text-xl font-bold">Lead Source</h1>
      </div>
      <div className="relative grid grid-cols-2 gap-2 ">
        <SalesPersonSearch
          employeeList={employeeList}
          setSelected={setSelectedSalesPerson}
          selected={salesperson || null}
        />
        <button
          className={`flex flex-row h-12 items-center w-full px-2 gap-2 py-3 border border-gray-300 rounded-md text-sm font-medium focus:outline-none   ${
            online
              ? "bg-gray-800 text-yellow-100 font-semibold"
              : "bg-white text-gray-700 hover:bg-gray-50"
          }`}
          onClick={() => {
            {
              setOnline(!online);
              setDirect(false);
              setOther(false);
            }
            setDirect(false);
            setOther(false);
          }}
          style={{
            flexGrow: 0,
            flexShrink: 1,
            flexBasis: "50%",
          }}
        >
          <TbWorldUpload className={`${online ? "text-yellow-300" : "text-gray-700"} text-xl`} />{" "}
          Online
        </button>
        <button
          className={`flex flex-row h-12 items-center w-full px-2 gap-2 py-3 border border-gray-300 rounded-md text-sm font-medium focus:outline-none ${
            direct
              ? "bg-gray-800 text-yellow-100 font-semibold"
              : "bg-white text-gray-700 hover:bg-gray-50"
          }`}
          onClick={() => {
            {
              setDirect(!direct);
              setOnline(false);
              setOther(false);
            }
            setOnline(false);
            setOther(false);
          }}
        >
          <BsPersonPlusFill className={`${direct ? "text-yellow-300" : "text-gray-700"} text-xl`} />
          Direct
        </button>
        <button
          className={`flex flex-row h-12 items-center w-full px-2 gap-2 py-3 border border-gray-300 rounded-md text-sm font-medium focus:outline-none ${
            other
              ? "bg-gray-800  text-yellow-100 font-semibold"
              : "bg-white text-gray-700 hover:bg-gray-50"
          }`}
          onClick={() => {
            setOther(!other);
            setOnline(false);
            setDirect(false);
          }}
        >
          Other
        </button>
      </div>
    </div>
  );
}
