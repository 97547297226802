import { useCallback, useEffect, useRef, useState } from 'react';
import { isPointInPolygon } from '../../helpers';
import { set } from 'date-fns';
import { debounce } from 'lodash';
// this hook adds lasso data to editingMode and sets panel visible when completed
export default function useCustomerLasso({
  map,
  editingMode,
  setEditingMode,
  setPanelVisible,
  customers,
  errorToast,
  panelVisible,
}) {
  const [lassoingCustomers, setLassoingCustomers] = useState(false);
  const addingPointsTimeoutIdRef = useRef(null); // used to cancel timeout of automattically adding lasso points
  const mousePositionRef = useRef(null);

  const newCoordinatesRef = useRef([]);
  useEffect(() => {
    newCoordinatesRef.current = editingMode?.newCoordinates || [];
  }, [editingMode.newCoordinates]);

  // add a point off param input event (inital click) or cursor position
  const addPoint = useCallback(
    (e = null) => {
      setEditingMode((prev) => ({
        ...prev,
        newCoordinates: [
          ...newCoordinatesRef.current,
          {
            // use click event for first point then mouse position after
            latitude: e ? e.latLng.lat() : mousePositionRef.current.lat(),
            longitude: e ? e.latLng.lng() : mousePositionRef.current.lng(),
          },
        ],
      }));
    },
    [setEditingMode]
  );

  const startLassoingCustomers = (clickEvent) => {
    addPoint(clickEvent); // add initial click point
    setLassoingCustomers(true);
  };

  const stopLassoingCustomers = useCallback(() => {
    // reset state
    clearTimeout(addingPointsTimeoutIdRef.current);
    addingPointsTimeoutIdRef.current = null;
    setLassoingCustomers(false);
    mousePositionRef.current = null;
    const selectedCustomers = [];
    // get customers in polygon, add them to panel
    customers.forEach(
      (customer) => isPointInPolygon(customer, editingMode?.newCoordinates) && selectedCustomers.push(customer)
    );
    setEditingMode((prev) => ({ newCoordinates: prev?.newCoordinates })); // leave new coords to display lasso selection
    if (selectedCustomers.length > 0)
      setPanelVisible({ type: 'customers', data: selectedCustomers, askToConfirmClose: true });
    else errorToast('Click and drag around the customers you want to select.'); // not really an error but this works well as a way to help the user
  }, [customers, editingMode?.newCoordinates, setEditingMode, setPanelVisible, errorToast]);

  // track mouse latLng position when lassoing customers
  useEffect(() => {
    if (!map) return;
    const getMousePositionAndAddPoint = (e) => {
      mousePositionRef.current = e.latLng;
      if (!addingPointsTimeoutIdRef.current)
        addingPointsTimeoutIdRef.current = setTimeout(() => {
          if (mousePositionRef.current) {
            addPoint();
            addingPointsTimeoutIdRef.current = null;
          }
        }, 100);
    };
    let listener;
    if (lassoingCustomers) listener = map.addListener('mousemove', getMousePositionAndAddPoint);

    return () => {
      if (listener) {
        window.google.maps.event.removeListener(listener);
      }
    };
  }, [map, lassoingCustomers, mousePositionRef, addPoint, stopLassoingCustomers]);

  // clear timeout when lassoing stops
  useEffect(() => {
    if (addingPointsTimeoutIdRef.current) clearTimeout(addingPointsTimeoutIdRef.current);
  }, [addingPointsTimeoutIdRef]);

  return { startLassoingCustomers, stopLassoingCustomers };
}
