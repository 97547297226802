import React, { useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import { auth, db } from "../../utils/firebase";
import { setDoc, doc, Timestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import PersonalDetails from "../../components/registerComponents/PersonalDetails";
import BusinessDetails from "../../components/registerComponents/BusinessDetails";
import { useLocation } from "react-router-dom";
// import { createFreeTrialCheckoutSession } from "utils/stripe";
import { createImmediatePaymentCheckoutSession } from "utils/stripe";
import {
  defaultEstimateSettings,
  defaultInvoiceSettings,
} from "./utils/constantData";

// /sales-registration
// src/pages/SalesRegistrationLinks.jsx

export default function SalesRegistrationLinks() {
  const [step, setStep] = useState(1);
  const [plan, setPlan] = useState("");
  const [cycle, setCycle] = useState("");
  const [coupon, setCoupon] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [phoneBeta, setPhoneBeta] = useState("");
  const [country, setCountry] = useState("US");

  const [companyName, setCompanyName] = useState("");
  const [companyPhoneBeta, setCompanyPhoneBeta] = useState("");
  const [industry, setIndustry] = useState("Window Cleaning");
  const [website, setWebsite] = useState("");
  const [address, setAddress] = useState("");
  const [notFilledIn, setNotFilledIn] = useState(false); // address

  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  // Parse URL parameters
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const planParam = params.get("plan");
    const cycleParam = params.get("cycle");
    const couponParam = params.get("coupon");

    if (
      planParam &&
      ["starter", "growing", "enterprise"].includes(planParam.toLowerCase())
    ) {
      setPlan(planParam.toLowerCase());
      console.log("planParam", planParam);
    } else {
      setError("Invalid plan specified");
    }

    if (
      cycleParam &&
      ["monthly", "yearly"].includes(cycleParam.toLowerCase())
    ) {
      setCycle(cycleParam.toLowerCase());
      console.log("cycleParam", cycleParam);
    } else {
      setError("Invalid billing cycle specified");
    }

    if (couponParam) {
      setCoupon(couponParam.toUpperCase());
      console.log("couponParam", couponParam.toUpperCase());
    }
  }, [location]);

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const regex = /^\(\d{3}\) \d{3}-\d{4}$/;
    return regex.test(phoneNumber);
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    // Basic validation
    if (!password || !companyName || !companyPhoneBeta) {
      setError("Please fill out all required fields");
      return;
    }

    if (
      !plan ||
      !["starter", "growing", "enterprise"].includes(plan.toLowerCase())
    ) {
      setError("Invalid plan specified");
      return;
    }

    if (!cycle || !["monthly", "yearly"].includes(cycle.toLowerCase())) {
      setError("Invalid billing cycle specified");
      return;
    }
    if (!isValidEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }
    if (!address) {
      setError("Please enter a valid address");
      setNotFilledIn(true);
      return;
    }
    if (
      !isValidPhoneNumber(phoneBeta) ||
      !isValidPhoneNumber(companyPhoneBeta)
    ) {
      setError("Please enter a valid phone number");
      return;
    }

    setIsLoading(true);

    try {
      // Check if email already exists
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      if (signInMethods.length > 0) {
        setError("An account with this email already exists. Please sign in.");
        setIsLoading(false);
        return;
      }

      await createNewBusiness();
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  const createNewBusiness = async () => {
    // Format phone numbers ... could put this in a helper file
    const formatPhoneNumbers = (personalPhone, companyPhone) => {
      const prefix = "+1";
      const formatNumber = (phone) => prefix + phone.replace(/\D/g, "");

      return {
        final_phone: formatNumber(personalPhone),
        final_company_phone: formatNumber(companyPhone),
      };
    };
    const { final_phone, final_company_phone } = formatPhoneNumbers(
      phoneBeta,
      companyPhoneBeta
    );

    try {
      // Create user in Firebase Auth
      const userCred = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Prepare user data
      const userDataForDb = {
        id: userCred.user.uid,
        email: userCred.user.email,
        firstName: firstName,
        lastName: lastName,
        phone: final_phone,
        userType: "Admin",
        isAdmin: true,
        businessId: userCred.user.uid,
        createdAt: Timestamp.now(),
        color: {
          label: "Blue",
          value: "#3b82f6",
        },
      };

      // Prepare business data
      const businessData = {
        id: userCred.user.uid,
        email: userCred.user.email,
        firstName: firstName,
        lastName: lastName,
        phone: final_phone,
        companyName: companyName,
        companyPhone: final_company_phone,
        companyWebsite: website,
        address: address,
        industry: industry,
        createdAt: Timestamp.now(),
        timeZone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
        currency: country === "CA" ? "cad" : "usd",
        taxRates: [],
        yelpReviewLink: "",
        googleReviewLink: "",
        facebookReviewLink: "",
        estimateSettings: defaultEstimateSettings,
        invoiceSettings: defaultInvoiceSettings,
        fromWeb: true,
        fromSalesRegistration: true,
      };

      // Create Firestore documents
      await Promise.all([
        setDoc(doc(db, "businesses", userCred.user.uid), businessData),
        setDoc(doc(db, "users", userCred.user.uid), userDataForDb),
      ]);

      // Create Stripe checkout session
      const { url, error } = await createImmediatePaymentCheckoutSession({
        businessId: userCred.user.uid,
        plan,
        cycle,
        coupon,
      });

      if (url) {
        window.location.href = url;
      } else {
        // console.log("error --->>> should not be an object", error);
        setError(error || "Failed to create checkout session");
        setIsLoading(false);
      }
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  return (
    <div className="relative flex min-h-screen h-full bg-white justify-center pt-16 overflow-auto">
      {step === 1 ? (
        <PersonalDetails
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          phoneBeta={phoneBeta}
          setPhoneBeta={setPhoneBeta}
          email={email}
          setEmail={setEmail}
          setStep={setStep}
          error={error}
          setError={setError}
          country={country}
          setCountry={setCountry}
          salesRegistration={true}
        />
      ) : (
        <BusinessDetails
          companyPhoneBeta={companyPhoneBeta}
          setCompanyPhoneBeta={setCompanyPhoneBeta}
          companyName={companyName}
          setCompanyName={setCompanyName}
          address={address}
          setAddress={setAddress}
          website={website}
          setWebsite={setWebsite}
          industry={industry}
          setIndustry={setIndustry}
          handleSubmit={handleSubmit}
          error={error}
          setStep={setStep}
          isLoading={isLoading}
          notFilledIn={notFilledIn}
          password={password}
          setPassword={setPassword}
          passwordShown={passwordShown}
          togglePassword={togglePassword}
        />
      )}
    </div>
  );
}
