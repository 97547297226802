import PanelWrapper from '../lowerLevelComponents/PanelWrapper';
import { Link } from 'react-router-dom';

const DisplayItem = ({ title, body }) =>
  body ? (
    <div className='mb-2'>
      <p className='mr-2 font-bold'>{title} </p>
      <h4>{body}</h4>
    </div>
  ) : (
    <></>
  );

export default function CustomerPanel({ panelVisible, setPanelVisible }) {
  const customer = panelVisible?.data;
  return (
    <PanelWrapper
      visible={panelVisible?.type === 'customer'}
      closeFunction={() => setPanelVisible({ type: '', data: null })}
      title={'Customer Details'}
    >
      {panelVisible?.type === 'customer' && (
        <div className='w-full'>
          <DisplayItem title='Name: ' body={customer?.name} />
          <DisplayItem title='Address: ' body={customer?.address} />
          <DisplayItem title='Phone: ' body={customer?.phoneNumber} />
          <DisplayItem title='Email: ' body={customer?.name} />
          <DisplayItem title='Last Job: ' body={customer.lastJobDate?.toLocaleDateString()} />
          <DisplayItem title='Created at: ' body={customer.createdAt.toLocaleDateString()} />
          <Link to={`/customers/${customer.customerId}`} target='_blank' rel='noopener noreferrer'>
            <button className='mt-6 underline flex flex-row items-center'>
              {/* <FaExternalLinkAlt className='mr-2' size={14} /> */}
              Open customer
            </button>
          </Link>
        </div>
      )}
    </PanelWrapper>
  );
}
