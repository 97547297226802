import React, { useContext, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { BiSend, BiCheckCircle, BiCopyAlt, BiXCircle } from "react-icons/bi";
import { UserContext } from "../../../index";
import { approveEstimate, resetPublicStatus, revertEstimateApproval } from "../data/api";

export const EstimateButtons = ({ estimateId, estimateDetails, onClickSend, onClickCopy }) => {
  const { userData } = useContext(UserContext);

  const estimateSent = !!estimateDetails?.statusTimestamps?.sent;
  const estimateApproved = !!estimateDetails?.statusTimestamps?.approved;
  const estimateCopied = !!estimateDetails?.statusTimestamps?.copied;

  const estimateDeclinedByClient = !!(
    estimateDetails?.publicStatus && estimateDetails?.publicStatus.status === "declined"
  );
  const estimateApprovedByClient = !!(
    estimateDetails?.publicStatus && estimateDetails?.publicStatus.status === "approved"
  );

  return (
    <div className="relative flex flex-row items-center justify-start mx-8 mt-2 lg:mt-0 mb-12 z-0">
      <ActionButton
        label={estimateSent ? "Estimate Sent" : "Send Estimate"}
        completedTimestamp={estimateDetails?.statusTimestamps?.sent?.toDate()}
        Icon={BiSend}
        onClick={onClickSend}
        tooltipText={estimateSent ? "Resend Estimate" : null}
      />
      <div className="border-b-2 border-gray-900 flex w-10%" />

      {estimateApprovedByClient || estimateDeclinedByClient ? (
        <ActionButton
          label={estimateApprovedByClient ? "Client Approved" : "Declined"}
          completedTimestamp={new Date(estimateDetails?.publicStatus.timestamp)}
          Icon={estimateApprovedByClient ? BiCheckCircle : BiXCircle}
          onClick={() => {
            resetPublicStatus({ userData, estimateId });
          }}
          tooltipText={"Reset Status"}
          isDeclined={estimateDeclinedByClient}
        />
      ) : (
        <ActionButton
          label={estimateApproved ? "Approved" : "Approve"}
          completedTimestamp={estimateDetails?.statusTimestamps?.approved?.toDate()}
          Icon={BiCheckCircle}
          onClick={() => {
            if (!estimateApproved) approveEstimate({ userData, estimateId });
            else revertEstimateApproval({ userData, estimateId });
          }}
          tooltipText={estimateApproved ? "Revert Approval" : null}
        />
      )}
      <div className="border-b-2 border-gray-900 flex  w-10%" />
      <ActionButton
        label={estimateCopied ? "Copied to Job" : "Copy to Job"}
        completedTimestamp={estimateDetails?.statusTimestamps?.copied?.toDate()}
        Icon={BiCopyAlt}
        onClick={onClickCopy}
        tooltipText={estimateApproved ? "Copy to Job" : null}
      />
    </div>
  );
};

const getButtonClasses = (status) => {
  const baseClasses =
    "flex flex-col rounded-md shadow-bold lg:p-8 p-4 cursor-pointer flex-1 items-center truncate";
  let statusClasses = "";

  if (status === "completed") {
    statusClasses = "bg-gray-900 text-green-500";
  } else if (status === "declined") {
    statusClasses = "bg-gray-900 text-red-500";
  } else {
    statusClasses = "bg-gray-200 text-gray-800 hover:text-yellow-600/100";
  }

  return `${baseClasses} ${statusClasses}`;
};

const ActionButton = ({ completedTimestamp, label, onClick, Icon, tooltipText, isDeclined }) => {
  let status = !!completedTimestamp ? "completed" : "incompleted";
  if (isDeclined) status = "declined";

  const ButtonContent = (
    <div className="flex flex-col items-center ">
      <Icon className="text-5xl" />
      <h1 className="font-bold">{label}</h1>
    </div>
  );

  return (
    <div onClick={onClick} className={getButtonClasses(status)}>
      {tooltipText ? (
        <Tooltip title={tooltipText} arrow placement="top">
          {ButtonContent}
        </Tooltip>
      ) : (
        ButtonContent
      )}

      {completedTimestamp && (
        <div className="absolute cursor-default -bottom-10 pointer-events-none	">
          <p className="text-center text-xs text-gray-500 font-semibold">
            {completedTimestamp.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
          </p>
          <p className="text-center text-xs text-gray-500 font-semibold">
            {completedTimestamp.toLocaleDateString()}
          </p>
        </div>
      )}
    </div>
  );
};
