import React, { useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader, StreetViewPanorama, MarkerF } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const libraries = ['places'];

function GoogleMaps({ customer, isStreetViewOnly }) {
  const address = customer?.address?.[0];

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries,
  });

  const [location, setLocation] = useState(null);
  const [heading, setHeading] = useState(null);

  const [locationLoading, setLocationLoading] = useState(false);
  const [locationError, setLocationError] = useState(null);

  const [headingLoading, setHeadingLoading] = useState(false);
  const [headingError, setHeadingError] = useState(null);

  useEffect(() => {
    if (address) {
      setHeadingLoading(true);
      setLocationLoading(true);
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          const loc = results[0].geometry.location;
          setLocation({ lat: loc.lat(), lng: loc.lng() });
          setLocationLoading(false);

          const streetViewService = new window.google.maps.StreetViewService();
          streetViewService.getPanorama({ location: loc, radius: 50 }, (data, status) => {
            if (status === window.google.maps.StreetViewStatus.OK) {
              const computedHeading = window.google.maps.geometry.spherical.computeHeading(data.location.latLng, loc);
              setHeading(computedHeading);
              setHeadingLoading(false);
            } else {
              console.error('Street View data not found for this location.');
              setHeadingError(true);
              setHeadingLoading(false);
            }
          });
        } else {
          console.error('Geocode was not successful for the following reason: ' + status);
          setLocationError(status);
          setLocationLoading(false);
          setHeadingLoading(false);
        }
      });
    }
  }, [address]);

  // if (!isLoaded || locationLoading || headingLoading) {
  //   return <div className="w-full h-64 mt-3 bg-gray-50 animate-pulse"></div>;
  // }

  if (!isLoaded || !location || locationError) return null;

  return isStreetViewOnly ? (
    <div className='w-full h-64 mt-3'>
      {!headingLoading && (
        <GoogleMap mapContainerStyle={containerStyle} center={location} zoom={18}>
          {heading !== null && (
            <StreetViewPanorama
              position={location}
              visible={true}
              options={{ pov: { heading, pitch: 0 }, addressControl: false }}
            />
          )}
          {heading === null && <MarkerF position={location} />}
        </GoogleMap>
      )}
    </div>
  ) : (
    <>
      <div className='w-full h-80 mt-3'>
        <GoogleMap mapContainerStyle={containerStyle} center={location} zoom={18}>
          <MarkerF position={location} />
        </GoogleMap>
      </div>
      {heading !== null && !headingLoading && (
        <div className='w-full h-64 mt-3'>
          <GoogleMap mapContainerStyle={containerStyle} center={location} zoom={18}>
            <StreetViewPanorama
              position={location}
              visible={true}
              options={{ pov: { heading, pitch: 0 }, addressControl: false }}
            />

            {/* <MarkerF position={location} /> */}
          </GoogleMap>
        </div>
      )}
    </>
  );
}

export default React.memo(GoogleMaps);
