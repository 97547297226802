import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../index";
import { convertTerritoryFormat, isAbleToCreateMaps } from "../../helpers";
import { getAllTerritories, updateTerritoriesDB } from "utils/firestoreCalls";
import {
  deleteTestTerritories,
  generateRepetativeTestTerritories,
} from "utils/dataGenerators";

const useTerritories = () => {
  const { userData } = useContext(UserContext);
  const [territories, setTerritories] = useState([]);

  // useEffect(() => {
  //   deleteTestTerritories(userData);
  // }, [userData, territories]);

  const updateTerritories = ({ territoryToDelete, newTerritory }) => {
    // update local state
    setTerritories((state) => {
      let newState = [...state];
      if (territoryToDelete)
        newState = newState.filter(
          (territory) => territory.id !== territoryToDelete.id
        );
      if (newTerritory) {
        const [formattedNewTerritory] = convertTerritoryFormat(
          [newTerritory],
          "web"
        ); // we need to convert the obj to web format
        newState.push(formattedNewTerritory);
      }
      return newState;
    });
    // update DB
    updateTerritoriesDB({
      territoryToDelete,
      newTerritory,
      businessId: userData.userData.businessId,
    });
    updateTerritoriesDB({
      territoryToDelete,
      newTerritory,
      businessId: userData.userData.businessId,
    });
  };

  useEffect(() => {
    async function getTerritories() {
      try {
        const allTerritories = await getAllTerritories(
          userData.userData.businessId
        );
        const cleanedTerritories = convertTerritoryFormat(
          allTerritories.filter((polygon) => polygon?.coordinates?.length > 0),
          "web"
        ); // clean then format for react google maps api
        // if the person is not a manager or admin then we only want them to see the territories assigned to them
        if (isAbleToCreateMaps(userData))
          setTerritories(cleanedTerritories || []);
        else
          setTerritories(
            cleanedTerritories?.filter(
              (territory) => territory?.user?.id === userData?.userData?.id
            )
          );
      } catch (error) {
        console.error("Error getting territories:", error);
        console.error("Error getting territories:", error);
      }
    }
    getTerritories();
  }, [userData]);

  return { territories, updateTerritories };
};

export default useTerritories;
