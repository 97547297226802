import { db } from 'utils/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { useUserContext } from 'context/UserContext';

export default function useTags() {
  const [tags, setTags] = useState();
  const { userData } = useUserContext();

  useEffect(() => {
    const fetchTags = async () => {
      const tagRef = doc(db, 'businesses', userData?.bizData?.id, 'tags', 'tags');
      const snapshot = await getDoc(tagRef);
      if (!snapshot.exists()) {
        setTags([]);
        return;
      }
      const fetchedTags = snapshot.data().tags;
      setTags(fetchedTags);
    };

    fetchTags();
  }, [userData?.bizData?.id]);

  return tags;
}
