import { useContext, useEffect, useState } from 'react';
import { UserContext } from 'index';
import { db } from 'utils/firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { previousDay } from 'date-fns/esm';

export default function useLocation() {
  const { userData, setUserData } = useContext(UserContext);
  const [location, setLocation] = useState(
    userData?.userData?.location || {
      latitude: 37.78825,
      longitude: -96.4324,
      latitudeDelta: 57.8922,
      longitudeDelta: 57.8881,
    }
  );

  // useEffect(() => {
  //   return () => {
  //     console.log('running useLocation cleanup');
  //     const userRef = doc(db, 'users', userData.userData?.id);
  //     updateDoc(userRef, {
  //       location,
  //     }).catch((error) => {
  //       console.error('Failed to update user location:', error);
  //     });
  //     setUserData((prev) => ({
  //       ...prev,
  //       userData: {
  //         ...prev?.userData,
  //         location: location,
  //       },
  //     }));
  //   };
  // }, []);

  return { location, setLocation };
}
