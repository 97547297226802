const NoCustomersYet = () => {
  return (
    <>
      {[...Array(9)].map((_, index) => (
        <div
          key={index}
          className="flex flex-col shrink-0 justify-start  space-x-4 animate-pulse bg-gray-100 rounded h-auto"
        >
          <div className="flex flex-row shrink-0 items-start justify-start  space-x-4 animate-pulse mt-6 ">
            <div className="w-3/12 ml-3 h-6 bg-gray-200 rounded"></div>
            <div className="w-4/12 mr-3 h-6 bg-gray-200 rounded"></div>
            <div className="w-3/12 mr-3 h-6 bg-gray-200 rounded"></div>
            <div className="w-2/12 pr-3 h-6 bg-gray-200 rounded"></div>
          </div>
        </div>
      ))}
      {/* Overlay Message */}
      <div className="absolute top-1/4 right-1/4 left-1/4 flex items-center justify-center">
        <div className="bg-white p-4 rounded-md text-center shadow-lg">
          <h1 className="text-xl font-semibold text-gray-900 ">
            Start by creating a{" "}
            <span className="font-bold underline-effect ">customer</span>{" "}
          </h1>
          <p className="text-gray-700">
            Or click the three dots in the top right if you need customers{" "}
            <span className="font-bold underline-effect ">imported.</span> Once
            you build up your customers then you can take advantage of
            customized marketing blasts that will keep them coming back.
          </p>
        </div>
      </div>
    </>
  );
};

export default NoCustomersYet;
