import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { useState } from "react";
import { fields } from "./data/constants";
import { formatImportDataRowToCustomer } from "./data/helpers";
import { importCustomers } from "utils/firebaseApiCalls";
import { useUserContext } from "context/UserContext";
import { LoadingModal } from "../../../../components/LoadingModal/LoadingModal";
import { useSnackBar } from "context/SnackBarContext";

export default function CustomersImportModal({
  isOpen,
  setOpen,
  refetchCustomers,
}) {
  const { userData } = useUserContext();
  const { openSnackBar } = useSnackBar();

  const [isUploadingCustomers, setUploadingCustomers] = useState(false);

  const onSubmit = async ({ all, validData, invalidData }) => {
    setUploadingCustomers(true);
    try {
      const formattedCustomersData = validData.map(
        formatImportDataRowToCustomer
      );
      const { data } = await importCustomers({
        customersData: formattedCustomersData,
        businessId: userData.userData.businessId,
      });
      // console.log("data", data);
      // window.location.reload();
      // await getCount();
      // await getCustomers();
      await refetchCustomers();
      openSnackBar(
        `Successfully imported ${data.successfulImports} customers. ${data.failedImports} failed.`,
        true
      );

      setOpen("");
    } catch (err) {
      console.error("Error", err.message);
      openSnackBar("Failed to import customers", false, true);
    } finally {
      setUploadingCustomers(false);
    }
  };

  return (
    <>
      <ReactSpreadsheetImport
        isOpen={isOpen}
        onClose={() => setOpen("")}
        onSubmit={onSubmit}
        fields={fields}
        translations={{
          uploadStep: {
            title: "Upload Customers",
          },
        }}
        customTheme={{
          components: {
            UploadStep: {
              baseStyle: {
                tableWrapper: {
                  // mb: "20rem",
                  // h: "120px",
                  // borderRadius: 0,
                },
              },
            },
            // Modal: {
            //   baseStyle: {
            //     closeModalButton: {
            //       // padding: "120px",
            //       // color: "red",
            //       // padding: "2px",
            //       _hover: { backgroundColor: "rgba(100, 100, 100, 0.5)" },
            //     },
            //   },
            // },
          },

          fonts: {
            heading: `"Inter var", system-ui, sans-serif`,
            body: `"Inter var", system-ui, sans-serif`,
          },
          colors: {
            background: "white",
            secondaryBackground: "#e5e7eb",
            textColor: "#111827",
            rsi: {
              50: "#e5e7eb", // Tailwind gray-200
              100: "#d1d5db", // Tailwind gray-300
              200: "#9ca3af", // Tailwind gray-400
              300: "#6b7280", // Tailwind gray-500
              400: "#4b5563", // Tailwind gray-600
              500: "#1f2937", // Tailwind gray-800 - This will likely be the main button color
              600: "#111827", // Tailwind gray-900
              700: "#030712", // Tailwind gray-950
              800: "#000000", // Black
              900: "#000000", // Black
            },
            // rsi: {
            //   50: "#fefce8",
            //   100: "#fef9c3",
            //   200: "#fef08a",
            //   300: "#fde047",
            //   400: "#facc15",
            //   500: "#eab308",
            //   600: "#ca8a04",
            //   700: "#a16207",
            //   800: "#854d0e",
            //   900: "#713f12",
            // },
            green: {
              500: "#22c55e",
            },
            red: {
              50: "#fef2f2",
              100: "#fee2e2",
              200: "#fecaca",
              300: "#fca5a5",
              400: "#f87171",
              500: "#dc2626",
              600: "#b91c1c",
              700: "#b91c1c",
              800: "#991b1b",
              900: "#7f1d1d",
            },
          },
        }}
      />
      {/* <LoadingModal open={isUploadingCustomers} setOpen={setUploadingCustomers} /> */}
    </>
  );
}
