import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import {
  fetchPublicEstimateDetails,
  updatePublicEstimateStatus,
} from "../../utils/firebaseApiCalls";
import { useMemo, useEffect, useState } from "react";
import LoadingSpinner from "../../components/reusable/LoadingSpinner";
import { formatPhoneDisplay } from "../../utils/formatPhone";
import { formatCurrency, splitAddress } from "../../utils/helpers";
import { ErrorView } from "./components/ErrorView";

export const PublicEstimate = () => {
  const { businessId, estimateId } = useParams();

  const [publicStatus, setPublicStatus] = useState({ status: "pending" });

  const fetchArgs = useMemo(() => {
    return { businessId, estimateId };
  }, [businessId, estimateId]);

  const { data, isLoading, error } = useFetch(
    fetchPublicEstimateDetails,
    fetchArgs
  );

  useEffect(() => {
    if (
      !error &&
      data &&
      data.data &&
      data.data.estimateDoc &&
      data.data.estimateDoc.statusTimestamps
    ) {
      //  date formatting between Date(), firebase timestamp, and a datestring (what a nightmare)
      setPublicStatus(
        !!data.data.estimateDoc.statusTimestamps.approved
          ? {
              status: "approved",
              timestamp: new Date(
                data.data.estimateDoc.statusTimestamps.approved._seconds * 1000
              ),
            }
          : !!data.data.estimateDoc.publicStatus
          ? {
              ...data.data.estimateDoc.publicStatus,
              timestamp: new Date(data.data.estimateDoc.publicStatus.timestamp),
            }
          : { status: "pending", timestamp: null }
      );
    }
  }, [error, data]);

  if (isLoading) {
    return (
      <div className="flex-1 flex justify-center items-center h-screen">
        <LoadingSpinner size={96} color={"gray"} />
      </div>
    );
  }

  if (error || !data?.data.estimateDoc || !data?.data.businessDetails) {
    return <ErrorView errorMessage={"Estimate not found"} />;
  }

  const {
    businessName,
    businessAddress,
    businessPhone,
    businessWebsite,
    businessLogo,
    businessEmail,
  } = data?.data.businessDetails;

  const { estimateSettings, lineItems, estimateNumber, dateAdded, customer } =
    data?.data.estimateDoc;

  const businessAddressSplit = splitAddress(businessAddress);
  const customerAddressSplit =
    customer && customer.address
      ? splitAddress(customer?.address?.[0])
      : ["", ""];

  const subTotal = lineItems.reduce((acc, item) => {
    const quantity = parseFloat(item.quantity) || 0;
    const unitPrice = parseFloat(item.unitPrice) || 0;
    const total = quantity * unitPrice;
    return acc + total;
  }, 0);

  const taxTotal = lineItems.reduce((acc, item) => {
    const quantity = parseFloat(item.quantity) || 0;
    const unitPrice = parseFloat(item.unitPrice) || 0;
    const total = quantity * unitPrice;
    const taxRate = parseFloat(item?.taxRate?.rate) || 0;
    const tax = total * (taxRate / 100);
    return acc + tax;
  }, 0);

  const total = subTotal + taxTotal;

  return (
    <div className="sm:py-12 bg-gray-50">
      <div className="max-w-3xl mx-auto bg-white w-full rounded sm:shadow p-12 gap-6 flex flex-col">
        <div className="">
          <h1 className="text-2xl">
            Your estimate from{" "}
            <span className="font-semibold">{businessName}</span>
          </h1>
          <div className="mt-2">
            {publicStatus.status === "pending" && (
              <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-sm font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20 gap-1">
                <svg
                  viewBox="0 0 6 6"
                  aria-hidden="true"
                  className="h-2 w-2 fill-yellow-600"
                >
                  <circle r={3} cx={3} cy={3} />
                </svg>
                Pending Approval
              </span>
            )}
            {publicStatus.status === "declined" && (
              <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-sm font-medium text-red-700 ring-1 ring-inset ring-red-600/20 gap-1">
                <svg
                  viewBox="0 0 6 6"
                  aria-hidden="true"
                  className="h-2 w-2 fill-red-600"
                >
                  <circle r={3} cx={3} cy={3} />
                </svg>
                Declined on{" "}
                {new Date(publicStatus.timestamp).toLocaleDateString()}
              </span>
            )}
            {publicStatus.status === "approved" && (
              <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-sm font-medium text-green-700 ring-1 ring-inset ring-green-600/20 gap-1">
                <svg
                  viewBox="0 0 6 6"
                  aria-hidden="true"
                  className="h-2 w-2 fill-green-600"
                >
                  <circle r={3} cx={3} cy={3} />
                </svg>
                Approved on {publicStatus.timestamp.toLocaleDateString()}
              </span>
            )}
          </div>
        </div>
        <div className="border-b border-gray-500" />
        <div>
          <div className="flex justify-between ">
            <div className="flex flex-col">
              {/* Estimate Details */}
              <div className="mt-2 ">
                {estimateSettings?.estimateNumber && estimateNumber && (
                  <p className="font-bold text-lg">
                    Estimate #{estimateNumber}
                  </p>
                )}
                {(!estimateSettings ||
                  (estimateSettings?.estimateDate && dateAdded)) && (
                  <p className="">
                    {new Date(dateAdded._seconds * 1000).toLocaleDateString()}
                  </p>
                )}
              </div>
            </div>
            {(!estimateSettings ||
              (estimateSettings?.logo && businessLogo)) && (
              <img
                src={businessLogo}
                alt="Business Logo"
                className="w-24 h-24"
              />
            )}
          </div>
          <div className="flex justify-between  mt-4">
            {/* Customer Details */}
            <div>
              {(!estimateSettings || estimateSettings.customerDisplayName) && (
                <p className="font-medium">{customer?.displayName}</p>
              )}
              {(!estimateSettings || estimateSettings.customerAddress) && (
                <div>
                  <p className="">{customerAddressSplit[0]}</p>

                  {customerAddressSplit?.[1] ? (
                    <p className="text-wrap">{customerAddressSplit[1]}</p>
                  ) : null}
                </div>
              )}
            </div>
          </div>

          {/* Line Items */}
          <div className="mt-12  ">
            <table className="w-full">
              <thead className="border-b border-gray-300 ">
                <tr className="">
                  <th className="text-left  sm:px-2 w-1/2">Line Item</th>
                  {(!estimateSettings ||
                    estimateSettings.lineItemsQuantity) && (
                    <th className="text-center  px-2 hidden sm:table-cell">
                      Quantity
                    </th>
                  )}
                  {(!estimateSettings ||
                    estimateSettings.lineItemsUnitPrice) && (
                    <th className="text-right  sm:px-2 hidden sm:table-cell">
                      Unit Price
                    </th>
                  )}
                  {(!estimateSettings || estimateSettings?.lineItemsAmount) && (
                    <th className="text-right  sm:px-2">Total</th>
                  )}
                </tr>
              </thead>
              <tbody className="border-b border-gray-300 divide-y divide-gray-300">
                {lineItems.map((lineItem, index) => (
                  <LineItemRow
                    lineItem={lineItem}
                    estimateSettings={estimateSettings}
                    key={index}
                  />
                ))}
              </tbody>
            </table>
          </div>
          {/* Totals */}
          <div className="mt-4 flex justify-end text-right w-full">
            <div className="w-full sm:w-5/12">
              {(!estimateSettings || estimateSettings.subtotal) && (
                <p className="flex justify-between sm:px-2">
                  <span className="">Subtotal</span>{" "}
                  <span>${formatCurrency(subTotal)}</span>
                </p>
              )}
              {(!estimateSettings || estimateSettings.tax) && (
                <p className="flex justify-between sm:px-2 pt-2">
                  <span className="">Tax</span>
                  <span>${formatCurrency(taxTotal)}</span>
                </p>
              )}

              {(!estimateSettings ||
                estimateSettings?.tax ||
                estimateSettings?.subtotal) && (
                <div className="border-b border-gray-300 my-2"></div>
              )}

              <div className="flex justify-between sm:px-2">
                <p className="font-bold">Total</p>
                <p className="">${formatCurrency(total)}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b border-gray-500 mt-4" />
        {publicStatus.status === "pending" && (
          <>
            <div>
              <div className="flex flex-col sm:flex-row items-center gap-4">
                <button
                  type="button"
                  className={`min-w-44 rounded-md bg-white px-3 py-2 font-medium focus:outline-none text-red-600 ring-1 ring-inset ring-red-500 hover:bg-gray-50 flex flex-row items-center justify-center gap-2 text-sm flex-1 w-full`}
                  onClick={async () => {
                    const newStatus = {
                      status: "declined",
                      timestamp: new Date(),
                    };

                    try {
                      setPublicStatus(newStatus);
                      const res = await updatePublicEstimateStatus({
                        estimateId,
                        businessId,
                        publicStatus: newStatus,
                      });
                    } catch (err) {
                      console.log("error updating stauts", err);
                    }
                  }}
                >
                  <p>Decline Estimate</p>
                </button>
                <button
                  type="button"
                  className={`min-w-44 relative rounded-md px-3 py-2 font-medium focus:outline-none flex flex-row items-center justify-center  gap-2 bg-green-700  hover:bg-green-800  disabled:bg-gray-400 disabled:hover:bg-gray-400 disabled:hover:cursor-not-allowed text-white text-sm flex-1 w-full`}
                  onClick={async () => {
                    const newStatus = {
                      status: "approved",
                      timestamp: new Date(),
                    };
                    try {
                      setPublicStatus(newStatus);
                      const res = await updatePublicEstimateStatus({
                        estimateId,
                        businessId,
                        publicStatus: newStatus,
                      });
                    } catch (err) {
                      console.log("error updating stauts", err);
                    }
                  }}
                >
                  <p>Approve Estimate</p>
                </button>
              </div>
            </div>
            <div className="border-b border-gray-500" />
          </>
        )}
        <div className="gap-1 flex-col flex">
          <p className="font-bold">{businessName}</p>
          {(!estimateSettings ||
            (estimateSettings.footerBusinessWebsite && businessWebsite)) && (
            <a
              href={businessWebsite}
              // className=" text-blue-600 hover:text-blue-800 visited:text-purple-600"
              className=" "
            >
              <p>{businessWebsite}</p>
            </a>
          )}
          {(!estimateSettings ||
            (estimateSettings.businessPhone && businessPhone)) && (
            <a
              href={`tel:${businessPhone}`}
              // className="  visited:text-purple-600"
              className=" "
            >
              <p>{formatPhoneDisplay(businessPhone)}</p>
            </a>
          )}
          {(!estimateSettings ||
            (estimateSettings.businessEmail && businessEmail)) && (
            <a
              href={`mailto:${businessEmail}`}
              // className="  visited:text-purple-600"
              className=" "
            >
              <p>{businessEmail}</p>
            </a>
          )}
          {(!estimateSettings ||
            (estimateSettings.businessAddress && businessAddress)) && (
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${businessAddress}`}
              target="_blank"
              rel="noreferrer"
              // className="  visited:text-purple-600"
              className=" "
            >
              <div>
                <p className="">{businessAddressSplit[0]}</p>

                {businessAddressSplit?.[1] ? (
                  <p className="text-wrap">{businessAddressSplit[1]}</p>
                ) : null}
              </div>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

const LineItemRow = ({ estimateSettings, lineItem }) => {
  const amount =
    (parseFloat(lineItem.quantity) || 0) *
    (parseFloat(lineItem.unitPrice) || 0);

  return (
    <>
      <tr className="hidden sm:table-row">
        <td className="py-4 px-2 align-top">
          <p>{lineItem.name}</p>
          {(!estimateSettings ||
            (estimateSettings.lineItemsDescription &&
              lineItem.description)) && (
            <p className="mt-1 text-sm text-gray-500">{lineItem.description}</p>
          )}
        </td>
        {(!estimateSettings || estimateSettings?.lineItemsQuantity) && (
          <td className="text-center py-4 px-2 align-top">
            {lineItem.quantity}
          </td>
        )}
        {(!estimateSettings || estimateSettings?.lineItemsUnitPrice) && (
          <td className="text-right py-4 px-2 align-top">
            ${formatCurrency(lineItem.unitPrice)}
          </td>
        )}
        {(!estimateSettings || estimateSettings?.lineItemsAmount) && (
          <td className="text-right py-4 px-2 align-top">
            ${formatCurrency(amount)}
          </td>
        )}
      </tr>
      <tr className="sm:hidden w-full">
        <td className="py-4 flex gap-4 w-full">
          {(!estimateSettings || estimateSettings?.lineItemsQuantity) && (
            <div className="flex items-center">
              <p>{lineItem.quantity}x </p>
            </div>
          )}
          <div>
            <p className="">{lineItem.name}</p>
            <p>
              {(!estimateSettings || estimateSettings?.lineItemsUnitPrice) && (
                <span className="text-gray-500">
                  ${formatCurrency(lineItem.unitPrice)}
                </span>
              )}
            </p>
          </div>
        </td>
        <td className=" py-4 text-right">
          {(!estimateSettings || estimateSettings?.lineItemsAmount) && (
            <p className="">${formatCurrency(amount)}</p>
          )}
        </td>
      </tr>
    </>
  );
};
