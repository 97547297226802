import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../index";
import { BsCreditCard } from "react-icons/bs";
import { node } from "../../constants/constants";
import { db } from "../../utils/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { useSnackBar } from "../../context/SnackBarContext";
import BasicButton from "components/buttons/BasicButton";
import {
  createBillingPortalSession,
  createUpdatePlanCheckoutSession,
} from "utils/stripe";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "../../layouts/PageHeader";
import CenteredMaxWidthLayout from "../../layouts/CenteredMaxWidthLayout";

export default function Billing() {
  const { user, userData, setRerun, bannerVisible } = useContext(UserContext);
  const { openSnackBar } = useSnackBar();
  const [warningModal, setWarningModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cancelMessage, setCancelMessage] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [subData, setSubData] = useState(userData?.subData);
  const [loadingChangePlan, setLoadingChangePlan] = useState(false);
  const [loadingBillingPortal, setLoadingBillingPortal] = useState(false);

  useEffect(() => {
    setSubData(userData?.subData);
  }, [userData?.subData]);

  // for navigation
  const navigate = useNavigate();

  const notAbleToViewCompanyInfo =
    !userData?.userData?.isAdmin &&
    userData?.userData?.customPermissions?.view_company_info !== true;

  const notAbleToEditBillingInfo =
    !userData?.userData?.isAdmin &&
    userData?.userData?.customPermissions?.edit_billing !== true;

  // console.log("userData from billing", userData);

  async function getPaymentMethod() {
    try {
      const res = await fetch(`${node}/subscriptions/get-payment-methods`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerId: userData.subData?.customer,
        }),
      });
      const { paymentMethods, error } = await res.json();
      // console.log(paymentMethods);
      if (!error && paymentMethods?.length > 0) {
        setPaymentMethod(paymentMethods[0]);
      }
    } catch (err) {
      console.log("error getting payment method", err);
      setPaymentMethod(null);
    }
  }

  async function getSubscription() {
    try {
      const res = await fetch(`${node}/subscriptions/get-subscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subscriptionId: userData.subData?.id,
        }),
      });

      const { subscription } = await res.json();

      // console.log("new subscription object:", subscription);

      if (!subscription) {
        return;
      }
      setSubData(subscription);
    } catch (err) {
      console.log("error getting subscription", err);
    }
  }

  useEffect(() => {
    getPaymentMethod();
    getSubscription();
  }, []);

  async function handleCancel() {
    console.log("cancel");

    setLoading(true);
    try {
      const res = await fetch(`${node}/subscriptions/cancel-subscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subscriptionId: userData.subData.id,
        }),
      });
      const { subscription, subscriptionError } = await res.json();

      if (subscriptionError) {
        throw new Error(subscriptionError);
      }
      setSubData(subscription);
      setCancelMessage(
        `Your account has been cancelled, you have until ${new Date(
          subscription.cancel_at * 1000
        ).toLocaleDateString()} to use your account.`
      );

      setTimeout(() => {
        setRerun((prev) => !prev);
      }, 10000);
    } catch (err) {
      console.log(err);
      alert(
        "There was an error cancelling your account. Please contact support at support@homebase360.io"
      );
    }
    setLoading(false);
  }

  async function reactivateAccount() {
    console.log("reactivate account");
    try {
      const res = await fetch(`${node}/subscriptions/reactivate-subscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subscriptionId: userData.subData.id,
        }),
      });
      const { subscription, subscriptionError } = await res.json();

      if (subscriptionError) {
        throw new Error(subscriptionError);
      }

      setSubData(subscription);
    } catch (err) {
      console.log(err);
      alert(
        "There was an error reactivating your account. Please contact support at support@homebase360.io"
      );
    }
  }

  const handleUpdateSubscriptionPlan = async () => {
    setLoadingChangePlan(true);

    // navigate to ("/change-subscription")

    navigate("/change-subscription");

    setLoadingChangePlan(false);

    // const { url, error } = await createUpdatePlanCheckoutSession(
    //   userData?.subData?.customer,
    //   userData?.subData?.id
    // );
    // if (url) {
    //   console.log("session created:", url);
    //   window.location.href = url;
    // } else {
    //   console.error("Error creating update plan checkout session:", error);
    //   alert(
    //     "There was an error creating the update plan checkout session. Please contact support at support@homebase360.io"
    //   );
    //   setLoadingChangePlan(false);
    // }
  };

  const handleBillingPortal = async () => {
    setLoadingBillingPortal(true);
    const { url, error } = await createBillingPortalSession(
      userData?.subData?.customer
    );
    if (url) {
      console.log("Billing portal session created:", url);
      window.location.href = url;
    } else {
      console.error("Error creating billing portal session:", error);
      alert(
        "There was an error creating the billing portal session. Please contact support at support@homebase360.io"
      );
      setLoadingBillingPortal(false);
    }
  };

  if (notAbleToViewCompanyInfo) {
    return (
      <div
        className={`bg-gray-100 ${
          bannerVisible ? "h-full-minus-head-and-banner" : "h-full-minus-header"
        }  overflow-y-auto`}
      >
        <h1 className="text-3xl font-extrabold text-gray-900 ml-10 mt-5 mb-5 ">
          Billing (You do not have permission to view this page)
        </h1>
      </div>
    );
  }

  return (
    <>
      <div
        className={`bg-gray-100 ${
          bannerVisible ? "h-full-minus-head-and-banner" : "h-full-minus-header"
        }  overflow-y-auto`}
      >
        <CenteredMaxWidthLayout>
          <PageHeader title="Billing" />
          <div className="flex flex-col mx-5 bg-gray-50 shadow-md rounded-sm ">
            <h1 className="text-2xl font-bold text-gray-900 bg-gray-200 px-4 py-2 rounded-sm">
              Account Overview
            </h1>
            <div className="flex flex-row justify-between px-4 py-4 rounded-sm">
              <div className="flex flex-col gap-1">
                <h1 className="text-xl font-bold text-gray-900">Plan</h1>
                <h1 className="text-gray-900">{subData?.plan?.nickname}</h1>
                {(subData.status === "active" ||
                  subData.status === "trialing") &&
                  !subData?.cancel_at && (
                    <BasicButton
                      disabled={notAbleToEditBillingInfo || loadingChangePlan}
                      loading={loadingChangePlan}
                      onClick={handleUpdateSubscriptionPlan}
                      text="Change plan"
                      spinnerColor="#3730a3"
                      spinnerSize={18}
                      width="w-20"
                      height="h-8"
                    />
                  )}
              </div>
              <div className="flex flex-col gap-1">
                <h1 className="text-xl font-bold text-gray-900">
                  Account status
                </h1>
                <h1 className="text-gray-900">
                  {subData?.cancel_at ? (
                    `Subscription ending without renewal on ${new Date(
                      subData?.cancel_at * 1000
                    ).toLocaleDateString()}`
                  ) : (
                    <>
                      {subData?.status === "manual trial"
                        ? "Free trial"
                        : subData?.status.slice(0, 1).toUpperCase() +
                          subData?.status.slice(1)}{" "}
                      {subData?.status === "trialing" &&
                        `until ${new Date(
                          subData?.trial_end * 1000
                        ).toLocaleDateString()}`}
                    </>
                  )}
                </h1>

                {subData.status !== "manual trial" && (
                  <>
                    {subData?.cancel_at ? (
                      <button
                        disabled={notAbleToEditBillingInfo}
                        className="bg-green-200 hover:bg-green-300 text-gray-900 w-fit p-2 rounded-md text-xs font-medium shadow-md"
                        onClick={reactivateAccount}
                      >
                        Reactivate Account
                      </button>
                    ) : (
                      <div className="flex flex-row gap-2">
                        {/* <button
                        disabled={
                          notAbleToEditBillingInfo || loadingBillingPortal
                        }
                        className="bg-indigo-100 hover:bg-indigo-200 text-gray-900 w-fit p-2 rounded-md text-xs font-medium shadow-md"
                        onClick={handleBillingPortal}
                      >
                        Update account or Cancel
                      </button> */}
                        <BasicButton
                          disabled={
                            notAbleToEditBillingInfo || loadingBillingPortal
                          }
                          loading={loadingBillingPortal}
                          onClick={handleBillingPortal}
                          text="Update account or Cancel"
                          spinnerColor="#3730a3"
                          spinnerSize={18}
                          width="w-40"
                          height="h-8"
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="flex flex-col gap-1">
                <h1 className="text-xl font-bold text-gray-900">
                  Active since
                </h1>
                <h1 className="text-gray-900">
                  {subData?.start_date
                    ? new Date(subData?.start_date * 1000).toLocaleDateString()
                    : new Date(
                        subData?.manualTrialStart * 1000
                      ).toLocaleDateString()}
                </h1>
              </div>
            </div>
          </div>
          {subData.status !== "manual trial" && (
            <div className="flex flex-col mx-5 mt-10 bg-gray-50 shadow-md rounded-sm ">
              <h1 className="text-2xl font-bold text-gray-900 bg-gray-200 px-4 py-2 rounded-sm">
                Billing Details
              </h1>
              <div className="flex flex-row justify-start items-center px-4 py-4 rounded-sm">
                <BsCreditCard className="text-4xl text-gray-900 mr-2" />
                <h1 className="text-md font-semibold text-gray-900">
                  {paymentMethod?.card?.brand} ••••{" "}
                  {paymentMethod?.card?.last4 || "Card"}
                </h1>
              </div>
              {/* <button className="bg-gray-700 hover:bg-gray-600 text-xs w-fit text-green-200 hover:text-green-400 p-2 rounded-md mx-4 mb-4">
            Add Credit Card
          </button> */}
            </div>
          )}

          {/* <div className="flex flex-col mx-10 mt-10 mb-20 bg-gray-50 shadow-md rounded-sm ">
          <h1 className="text-2xl font-bold text-gray-900 bg-gray-200 px-4 py-2 rounded-sm">
            Payment History
          </h1>

          <div className="flex flex-row font-semibold items-center justify-start py-4 px-4 border-b-2 border-gray-600 ">
            <h1 className="w-4/12 ">Date</h1>
            <h1 className="w-4/12 ">Amount</h1>
            <h1 className="w-4/12 ">Payment Method</h1>
          </div>
          <div className="flex flex-col pb-4 px-2">
            {paymentHistory.map((payment, key) => (
              <div
                key={key}
                className="flex flex-row items-center justify-start py-2 first:pt-6"
              >
                <h1 className="w-4/12 ml-3 mr-3 ">{payment.date}</h1>
                <h1 className="w-4/12 mr-3 ">{payment.amount}</h1>
                <h1 className="w-4/12 mr-3 ">{payment.payment_method}</h1>

                <hr />
              </div>
            ))}
          </div>
        </div> */}
          {warningModal && (
            <div className="warning-modal">
              <div
                className="warning-overlay"
                onClick={() => console.log("clicked overlay")}
              ></div>
              <div className="warning-modal-content ">
                <div className="mt-5">
                  {cancelMessage ? (
                    <>
                      <h1>{cancelMessage}</h1>{" "}
                    </>
                  ) : (
                    <>
                      <h1 className="text-2xl font-semibold">
                        Are you sure you want to cancel?
                      </h1>
                      <h1>All your account data will be lost</h1>
                    </>
                  )}
                </div>
                {cancelMessage ? (
                  <button
                    className="border-2 border-gray-300 bg-gray-50 rounded-md p-2 mt-1 text-gray-500 text-md"
                    onClick={() => setWarningModal(false)}
                  >
                    Okay
                  </button>
                ) : (
                  <div className="flex flex-row justify-end items-center mb-4">
                    <button
                      className="border-2 border-gray-300 bg-gray-50 rounded-md p-2 mt-1 text-gray-500 text-md"
                      onClick={() => setWarningModal(false)}
                    >
                      Back
                    </button>
                    <button
                      className=" bg-red-700 font-semibold rounded-md p-2 mt-1 text-white text-md ml-2 hover:bg-red-800"
                      onClick={handleCancel}
                    >
                      Confirm
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </CenteredMaxWidthLayout>
      </div>
    </>
  );
}

const paymentHistory = [
  {
    date: "May 2021",
    amount: "$299.00",
    payment_method: "•••• 1234",
  },
  {
    date: "June 2021",
    amount: "$299.00",
    payment_method: "•••• 1234",
  },
  {
    date: "July 2021",
    amount: "$299.00",
    payment_method: "•••• 1234",
  },
];
