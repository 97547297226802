import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  AiOutlineSend,
  AiOutlineCloudUpload,
  AiFillCloseCircle,
} from "react-icons/ai";
import { ImSpinner } from "react-icons/im";
import { UserContext } from "../../index";
import { db } from "../../utils/firebase";
import {
  collection,
  query,
  orderBy,
  limit,
  addDoc,
  doc,
  setDoc,
  updateDoc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";

import { useCollectionData } from "react-firebase-hooks/firestore";
import { node } from "../../constants/constants";
import { sendMessage } from "../../utils/twilio";
import { uploadFile } from "../../utils/uploadFile";
import * as linkify from "linkifyjs";
import { useSnackBar } from "../../context/SnackBarContext";
import LoadingSpinner from "../../components/reusable/LoadingSpinner";
import { formatMessageDate } from "./utils";
import Tooltip from "@mui/material/Tooltip";
import { BiCheckDouble, BiCheck } from "react-icons/bi";
import { MdError } from "react-icons/md";

export default function Messages() {
  const location = useLocation();
  const navigate = useNavigate();
  const { conversationId } = useParams();
  const { phone, name } = location?.state || {};
  const { user, userData, bannerVisible } = useContext(UserContext);
  const { openSnackBar } = useSnackBar();
  const endOfMessagesRef = useRef();
  const [conversation, setConversation] = useState(null);
  const [loadingSend, setLoadingSend] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [enlargedImage, setEnlargedImage] = useState(null);

  // this should only be false if custom permissions are set to false for respond_messages
  const notAbleToSend =
    userData.userData?.customPermissions?.respond_messages === false;

  const notAbleToView =
    userData.userData?.customPermissions?.view_messages === false;

  const handleImageClick = (imageUrl) => {
    console.log("image clicked:", imageUrl);
    setEnlargedImage(imageUrl);
    setIsModalOpen(true);
  };

  //   console.log("location conversation:", conversation)
  //   console.log("user id:", userData?.id)
  //   console.log("converstaion phone", conversation.phone)
  // console.log("phone:", phone);
  // console.log("name:", name);

  const [messages, setMessages] = useState(null);
  const [input, setInput] = useState("");

  const conversationRef = doc(
    db,
    "businesses",
    userData.userData.businessId,
    "conversations",
    phone
  );

  const messagesRef = collection(
    db,
    "businesses",
    userData?.userData?.businessId,
    "conversations",
    phone,
    "messages"
  );

  const [messageSent, setMessageSent] = useState(false);

  const q = query(messagesRef, orderBy("createdAt", "desc"), limit(55));

  const [snapshot, loading, error] = useCollectionData(q, {
    idField: "_id",
  });

  useEffect(() => {
    if (snapshot) {
      setMessages(snapshot);
      // update conversation to read == true
      const updateConversationDoc = async () => {
        try {
          await updateDoc(conversationRef, {
            read: true,
          });
        } catch (error) {
          console.log("Error updating document: ", error);
        }
      };
      updateConversationDoc();

      // scroll to the bottom of the messages container whenever a new messages comes and snapshot changes
      // create event handler to do that

      endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [snapshot, conversationRef]);

  const handleSendMessageTwilio = async (e) => {
    e.preventDefault();
    if (!input && !selectedFile) {
      return;
    }
    if (notAbleToSend) {
      openSnackBar(
        "You do not have permission to send messages. Please contact your administrator."
      );
      return;
    }

    if (loadingSend) return;
    setLoadingSend(true);

    const to = phone;
    const from = userData?.bizData?.twilioNumber;
    const message = input;
    const bizId = userData?.userData?.businessId;
    const bizName = userData?.bizData?.companyName;
    const customerName = name ? name : "";
    let mediaUrl;

    if (selectedFile) {
      try {
        const fileUrl = await uploadFile(selectedFile, "message-images");
        mediaUrl = fileUrl;
      } catch (error) {
        console.error("Failed to upload file:", error);
        setLoadingSend(false);
        openSnackBar("Failed to upload file, please try again.", false, true);
      }
    }

    if (!message && !mediaUrl) {
      return;
    }
    try {
      const response = await fetch(`${node}/messages/twilioSend`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          to,
          from,
          message,
          bizId,
          bizName,
          customerName,
          mediaUrl,
        }),
      });

      const { message: responseMessage, error } = await response.json();

      if (responseMessage) {
        setMessageSent(true);
        console.log("responseMessage:", responseMessage);
      }
      if (error) {
        alert("There was an error sending your message, please try again.");
        throw new Error(error);
      }
    } catch (error) {
      console.log("error:", error);
    } finally {
      setInput("");
      setLoadingSend(false);
      setSelectedFile(null);
      setPreviewUrl("");
    }
  };

  const fileInputRef = useRef(null);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");

  async function handleFileChange(e) {
    setSelectedFile(e.target.files[0]);
    // Create a preview URL and update the state
    const previewUrl = URL.createObjectURL(e.target.files[0]);
    setPreviewUrl(previewUrl);
  }

  // console.log("bannervislbe from messages", bannerVisible);
  function LinkifyAndFormatText({ text, notAbleToView }) {
    // Convert newlines to <br /> tags
    let formattedText = text.replace(/\n/g, "<br />");

    // Convert URLs to clickable links
    formattedText = linkify.find(formattedText).reduce((current, next) => {
      return current
        .split(next.value)
        .join(
          `<a href="${next.href}" target="_blank" rel="noopener noreferrer">${next.value}</a>`
        );
    }, formattedText);

    return <div dangerouslySetInnerHTML={{ __html: formattedText }} />;
  }

  return (
    <>
      <div
        className={`bg-gray-50 relative ${
          bannerVisible ? "h-full-messages-minus-banner" : "h-full-messages"
        } overflow-y-auto text-gray-800`}
      >
        {/* <div className="p-2  text-gray-800  w-fit flex whitespace-nowrap ">
          Go back:
          <span
            onClick={() => navigate("/messages")}
            className="font-semibold text-yellow-500 hover:text-yellow-600 ml-1 hover:cursor-pointer"
          >
            Messages
          </span>
        </div> */}

        <div
          className="flex flex-col justify-start items-center w-full "
          style={{
            height: "calc(100% - 4rem)",
          }}
        >
          <div className="flex flex-row justify-between w-4/5 max-w-3xl mt-4">
            <h1 className="text-2xl font-extrabold text-gray-900   ">
              {name ? name : ""}
            </h1>
            <h1 className="text-2xl font-extrabold text-gray-900   ">
              {"(" +
                phone.substring(2, 5) +
                ") " +
                phone.substring(5, 8) +
                "-" +
                phone.substring(8, 12)}
            </h1>
          </div>
          <div className="flex flex-col-reverse pb-8 pt-10 bg-white rounded-t-md mt-2  h-full mb-10 w-4/5 max-w-3xl  overflow-y-auto overflow-x-hidden relative messages-container shadow-md">
            <div ref={endOfMessagesRef}></div>
            {loading ? (
              <>
                <div className="flex mx-5 flex-row mt-8 justify-start">
                  <div className="flex w-3/5 gap-4 items-start flex-row">
                    <div className="animate-pulse bg-gray-300 w-10 h-10 p-2 rounded-full shrink-0 items-center justify-center flex"></div>
                    <div className="flex flex-col w-full items-start justify-center rounded-b-lg p-2 px-4 bg-gray-200 rounded-r-lg">
                      <div className="animate-pulse bg-gray-300 w-full h-4 rounded mt-2"></div>

                      <div className="animate-pulse bg-gray-300 w-5/6 h-4 rounded mt-2 mb-2"></div>
                    </div>
                  </div>
                </div>
                <div className="flex mx-5 flex-row mt-8 justify-end">
                  <div className="flex w-3/5 gap-4 items-start  flex-row-reverse">
                    <div className="animate-pulse bg-gray-300 w-10 h-10 p-2 rounded-full shrink-0 items-center justify-center flex"></div>
                    <div className="flex flex-col w-full items-start justify-center rounded-b-lg p-2 px-4 bg-gray-200 rounded-l-lg">
                      <div className="animate-pulse bg-gray-300 w-full h-4 rounded mt-2"></div>

                      <div className="animate-pulse bg-gray-300 w-5/6 h-4 rounded mt-2 mb-2"></div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {messages && messages.length > 0 ? (
                  messages.map((message) => {
                    // if () console.log(message.deliveredAt, " ", message.createdAt);
                    return (
                      <div
                        className={`flex mx-5 flex-row mt-8 ${
                          message.user._id === userData.userData.businessId
                            ? "justify-end"
                            : "justify-start"
                        }`}
                        key={message._id}
                      >
                        {" "}
                        <div
                          className={`flex w-3/5 gap-4 items-start ${
                            message.user._id === userData.userData.businessId
                              ? "flex-row-reverse"
                              : "flex-row"
                          }`}
                        >
                          {/* <img
                      src={message.user.avatar}
                      className="w-10 h-10 rounded-full mx-2"
                    /> */}
                          <p className="text-xs text-yellow-400 w-10 bg-gray-800 h-10 p-2 rounded-full shrink-0 items-center justify-center flex">
                            {message.user.initials}
                          </p>
                          {/* <Tooltip
                            PopperProps={{
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -10],
                                  },
                                },
                              ],
                            }}
                            title={
                              message.deliveredAt
                                ? formatMessageDate(
                                    message.deliveredAt.toDate()
                                  )
                                : message.delivered === false
                                ? ""
                                : formatMessageDate(message.createdAt.toDate())
                            }
                          > */}
                          <div
                            className={`flex flex-col items-center justify-center rounded-b-lg p-2 ${
                              notAbleToView && "blur"
                            } ${
                              message.user._id === userData.userData.businessId
                                ? "bg-gray-700 text-gray-50  rounded-l-lg"
                                : "bg-gray-200 rounded-r-lg"
                            }`}
                          >
                            <LinkifyAndFormatText text={message.text} />
                            {/* {message.imageUrl && (
                            <img
                              src={message.imageUrl}
                              onClick={() => handleImageClick(message.imageUrl)}
                              className="w-1/2 cursor-pointer mt-2"
                            />
                          )} */}
                            {message.imageUrl &&
                              (message.imageUrl.endsWith(".pdf") ? (
                                <iframe
                                  src={message.imageUrl}
                                  width="100%"
                                  height="200px" // you can adjust the size as needed
                                  className="mt-2"
                                />
                              ) : (
                                <img
                                  src={message.imageUrl}
                                  onClick={() =>
                                    handleImageClick(message.imageUrl)
                                  }
                                  className="w-1/2 cursor-pointer mb-2"
                                />
                              ))}
                            {/* delivery info  */}
                            <div className="flex flex-row w-full justify-end gap-1">
                              {message.createdAt && (
                                <p className="text-xs text-gray-500 hover:cursor-default">
                                  {message.delivered
                                    ? formatMessageDate(
                                        message.deliveredAt.toDate()
                                      )
                                    : formatMessageDate(
                                        message.createdAt.toDate()
                                      )}
                                </p>
                              )}
                              {message.to === userData.bizData.telnyxNumber ? (
                                <></> // message is to user dont show status
                              ) : message.delivered ? (
                                <BiCheckDouble className="text-gray-500" />
                              ) : message.delivered === undefined ? (
                                <BiCheck className="text-gray-500" />
                              ) : (
                                <Tooltip
                                  title={
                                    message.error
                                      ? "Delivery of this message failed. " +
                                        message.error
                                      : "Delivery of this message failed. Please try again."
                                  }
                                >
                                  <div>
                                    <MdError className="text-red-500" />
                                  </div>
                                </Tooltip>
                              )}
                            </div>
                          </div>
                          {/* </Tooltip> */}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="flex flex-row justify-center items-center h-full">
                    <p className="text-gray-500">No messages</p>
                  </div>
                )}
              </>
            )}
            <div className=" flex flex-row flex-1 justify-end fixed bottom-10 w-4/5 max-w-3xl shadow-md">
              <input
                name="file"
                type="file"
                onChange={handleFileChange}
                ref={fileInputRef}
                className="hidden"
              />

              <AiOutlineCloudUpload
                className="absolute left-2 bottom-5 cursor-pointer text-2xl" // Add cursor-pointer to indicate a clickable icon
                onClick={handleIconClick}
              />
              {previewUrl && (
                <>
                  <AiFillCloseCircle
                    className={`absolute -left-16 bottom-8 text-2xl text-white bg-gray-900 rounded-full ${
                      !loadingSend && "hover:text-red-500 cursor-pointer"
                    }  z-10`}
                    onClick={() => {
                      if (loadingSend) return;
                      setPreviewUrl("");
                      setSelectedFile(null);
                    }}
                  />
                  <img
                    src={previewUrl}
                    className="h-12 w-auto absolute -left-16 bottom-2 rounded-md"
                    alt="No Preview"
                  />
                </>
              )}

              <input
                name="message"
                type="text"
                className="w-4/5 h-16 rounded-bl-md border pl-11 px-3 border-gray-300 focus:outline-none "
                onChange={(e) => setInput(e.target.value)}
                value={input}
                autoComplete="off"
              />
              <button
                type="submit"
                disabled={loadingSend || (!input && !selectedFile)}
                onClick={handleSendMessageTwilio}
                className={`w-1/5 h-16  text-white text-lg ${
                  !!input && "hover:text-yellow-100"
                } rounded-br-md ${
                  loadingSend ? "bg-gray-900 text-yellow-100" : "bg-gray-900"
                }  flex flex-row items-center justify-center`}
              >
                {loadingSend ? (
                  <div className="ml-2 flex flex-row items-center">
                    <LoadingSpinner />
                  </div>
                ) : (
                  <>
                    <span>Send</span>
                    <AiOutlineSend className="ml-2" />
                  </>
                )}
              </button>
            </div>{" "}
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div
          className="fixed inset-0 z-1 bg-black bg-opacity-75"
          onClick={() => setIsModalOpen(false)}
        >
          <div
            className="fixed z-10 inset-0 overflow-y-auto"
            onClick={() => setIsModalOpen(false)}
          >
            <div className="flex items-center justify-center min-h-screen">
              <div className="bg-white rounded-lg max-w-lg mx-auto relative overflow-hidden">
                <img
                  src={enlargedImage}
                  className="w-full"
                  alt="Enlarged Preview"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
