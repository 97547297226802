import { useToast } from '@chakra-ui/react';
import { useCallback, useEffect, useRef } from 'react';
import { FaXmark } from 'react-icons/fa6';

export default function useErrorToasts() {
  const toast = useToast();
  const retryFunctionsRef = useRef([]); // save a ref for functions to retry on error. This way, for example, if territories and markers fail to fetch we can trigger both to try again
  const errorToastIdCount = useRef(0);

  // toast that lets the user retry whatever failed, if this toast is called multiple times only one shows up and all retry functions are called when the user clicks retry
  const errorToastRetry = ({
    message = 'There was a problem retrieving data.',
    retryFunction = () => console.log('trying again'),
  }) => {
    retryFunctionsRef.current = [...retryFunctionsRef.current, retryFunction];
    if (!toast.isActive('error-toast-retry')) {
      toast({
        id: 'error-toast-retry',
        duration: 8000,
        isClosable: true,
        render: () => (
          <div
            className={
              'bg-white p-2 pl-4 pt-8 pb-4 relative flex justify-center items-center rounded-lg overflow-hidden'
            }
          >
            <button
              onClick={() => toast.close('error-toast-retry')}
              className='absolute p-1 top-1 rounded-md right-1 hover:bg-gray-100'
            >
              <FaXmark />
            </button>
            <div className='bg-red-200 h-full w-2 mr-1 absolute left-0 top-0'></div>
            {message}
            <button
              onClick={() => {
                toast.close('error-toast-retry');
                retryFunctionsRef.current.forEach((func) => {
                  func();
                });
                retryFunctionsRef.current = [];
              }} // call all functions that failed
              className='ml-3 px-2 py-1 rounded-md bg-gray-200 hover:bg-gray-100'
            >
              Retry
            </button>
          </div>
        ),
      });
    }
  };

  const errorToast = useCallback(
    (message) => {
      const toastNumber = errorToastIdCount.current;
      errorToastIdCount.current += 1;

      toast({
        id: 'error-toast-' + toastNumber,
        duration: 6000,
        isClosable: true,
        render: () => (
          <div
            className={
              'bg-white px-4 pb-4 pt-6 relative flex justify-center w-auto items-center rounded-lg overflow-hidden'
            }
          >
            <button
              onClick={() => toast.close('error-toast-' + toastNumber)}
              className='absolute p-1 top-1 rounded-md right-1 hover:bg-gray-100'
            >
              <FaXmark />
            </button>
            <div className='bg-red-200 h-full w-2 mr-1 absolute top-0 left-0'></div>
            {message}
          </div>
        ),
      });
    },
    [toast]
  );

  // close toasts on leaving screen
  useEffect(() => {
    return () => toast.closeAll();
  }, [toast]);

  useEffect(() => {}, [errorToast]);

  return { errorToastRetry, errorToast };
}
