import MenuItem from "@mui/material/MenuItem";
import { useMemo, useRef, useState } from "react";
import { PiColumnsFill } from "react-icons/pi";
import { FaCheck } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import { CiViewColumn } from "react-icons/ci";
import DropDownMenuTemplate from "./DropDownMenuTemplate";
import { HiMiniViewColumns } from "react-icons/hi2";
import { allColumnHeaders } from "../../helpers";

export default function ColumnsMenu({ columnHeaders, setColumnHeaders }) {
  // save visible cols into obj for quick lookup
  const columnHeadersObj = useMemo(
    () =>
      columnHeaders.reduce((accumObj, data) => {
        accumObj[data.headerName] = data;
        return accumObj;
      }, {}),
    [columnHeaders]
  );

  // add or remove columnHeader
  const handleMenuItemClick = (headerData) => {
    if (columnHeadersObj.hasOwnProperty(headerData.headerName)) {
      setColumnHeaders((p) => {
        const previous = [...p];
        previous.splice(previous.indexOf(headerData), 1);
        return previous;
      });
    } else setColumnHeaders((p) => [...p, headerData]);
  };

  return (
    <DropDownMenuTemplate
      Icon={<HiMiniViewColumns size={22} />}
      tooltip={"Edit Columns"}
      MenuItems={allColumnHeaders.map((headerData, i) => {
        return (
          <MenuItem
            key={"menu-column-header-option-" + i}
            onClick={() => handleMenuItemClick(headerData)}
          >
            <>
              {columnHeadersObj.hasOwnProperty(headerData.headerName) ? (
                <FaCheck size={14} className="mr-4" />
              ) : (
                <FaMinus size={14} className="mr-4" />
              )}
              {headerData.headerName}
            </>
          </MenuItem>
        );
      })}
    />
  );
}
