import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import { FaPlus } from "react-icons/fa";
import { PageHeader } from "../../../layouts/PageHeader";
import { SolidButton } from "../../../components/NewButtons/SolidButton";

export function CustomersPageHeader({
  toggleModal,
  userData,
  setImportModalOpen,
  customers,
}) {
  const [moreActionsAnchorEl, setMoreActionsAnchorEl] = useState(null);
  const isMoreActionsMenuOpen = Boolean(moreActionsAnchorEl);

  const handleMoreActionsClick = (event) => {
    setMoreActionsAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMoreActionsAnchorEl(null);
  };

  // This function demonstrates how you might trigger a download
  // You would replace this with your actual function logic
  // const handleExportToCsv = async () => {
  //   if (!userData?.userData?.isAdmin) {
  //     console.error("User is not an admin");
  //     alert("You do not have permission to export customers to CSV");
  //     return;
  //   }
  //   if (customers?.length === 0) {
  //     console.error("No customers to export");
  //     alert("No customers to export");
  //     return;
  //   }
  //   // // Adjust the Firestore collection path as necessary
  //   // const customerCollectionRef = collection(
  //   //   db,
  //   //   "businesses",
  //   //   userData?.bizData?.id,
  //   //   "customers"
  //   // );
  //   // let customerQuery = query(
  //   //   customerCollectionRef,
  //   //   orderBy("lastUpdated", "desc")
  //   // );

  //   try {
  //     // const customerSnapshot = await getDocs(customerQuery);
  //     // const customerList = customerSnapshot.docs.map((doc) => doc.data());

  //     // console.log("customers length", filteredCustomers?.length);

  //     // Organize customer data for the CSV
  //     const customersForCsv = customers.map((customer) => ({
  //       name: customer.displayName || "",
  //       address: customer.address?.[0] || "",
  //       emails: customer.email?.length > 0 ? customer.email.join(", ") : "",
  //       mobilePhone: customer.phone?.mobile?.slice(2) || "",
  //       homePhone: customer.phone?.home || "",
  //       workPhone: customer.phone?.work || "",
  //       additionalPhone: customer.phone?.additional || "",
  //       customerNotes: customer.notes || "",
  //     }));

  //     // Generate CSV
  //     const csv = Papa.unparse(customersForCsv);

  //     // trim company name and replace any spaces with dashes
  //     const companyName = userData?.bizData?.companyName
  //       .trim()
  //       .replace(/\s/g, "-");

  //     // Use file-saver to save the generated CSV
  //     const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
  //     saveAs(blob, `Customers-${companyName}-${new Date().toISOString()}.csv`);

  //     // console.log("should be done");

  //     // Close the menu
  //     handleMenuClose();
  //   } catch (error) {
  //     console.error("Failed to export customers to CSV", error);
  //     // Handle the error as appropriate in your application context
  //   }
  // };

  return (
    <PageHeader
      title="Customers"
      helperText="To perform bulk actions or send marketing messages: First select customer(s), then click the stacked squares icon in the toolbar to choose your action."
    >
      <div className="flex flex-row gap-2 items-center">
        <SolidButton
          icon={<FaPlus className="inline-block" />}
          text="New Customer"
          onClick={toggleModal}
        />
        <IconButton aria-label="more actions" onClick={handleMoreActionsClick}>
          <MoreVertIcon style={{ color: "#111827" }} />
        </IconButton>
        <Menu
          anchorEl={moreActionsAnchorEl}
          open={isMoreActionsMenuOpen}
          onClose={handleMenuClose}
          MenuListProps={{
            "aria-labelledby": "more-actions-button",
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            onClick={() => {
              // navigate("/customers/import");
              // toggleModal();
              handleMenuClose();
              setImportModalOpen(true);
            }}
          >
            Import
          </MenuItem>
          {/* <MenuItem onClick={handleExportToCsv}>Export to csv</MenuItem> */}
        </Menu>
      </div>
    </PageHeader>
  );
}
