// since territories use transparent rather than solid colors, we use this as a reference
export const territoryColorMap = {
  Blue: 'rgba(59,130,246,0.3)',
  Orange: 'rgba(249,115,22,0.3)',
  Purple: 'rgba(217,70,239,0.3)', // previously Fuchsia
  Yellow: 'rgba(250,204,21,0.3)',
  Teal: 'rgba(20,184,166,0.3)',
  Violet: 'rgba(139,92,246,0.3)',
  Pink: 'rgba(236,72,153,0.3)',
  Cyan: 'rgba(6,182,212,0.3)',
  Lime: 'rgba(132,204,22,0.3)',
  Red: 'rgba(239,68,68,0.3)',
  Green: 'rgba(34,197,94,0.3)',
  Sky: 'rgba(14,165,233,0.3)',
  Indigo: 'rgba(99,102,241,0.3)',
  Fuchsia: 'rgba(168,85,247,0.3)', // previously Purple
  Rose: 'rgba(253,164,175,0.3)',
  Emerald: 'rgba(16,185,129,0.3)',
  Amber: 'rgba(245,158,11,0.3)',
  Slate: 'rgba(100,116,139,0.3)',
  'Dark Blue': 'rgba(30,58,138,0.3)',
  'Dark Green': 'rgba(20,83,45,0.3)',
};

// storing tailwind colors here is redundant, but it makes it easier to access color values in a few places tailwind classes aren't available.
export const colors = {
  green100: '#dcfce7',
  green200: '#bbf7d0',
  green300: '#86efac',
  green400: '#4ade80',
  green500: '#22c55e',
  green600: '#16a34a',
  green700: '#15803d',
  green800: '#166534',
  green900: '#14532d',

  lime50: '#f7fee7',
  lime100: '#ecfccb',
  lime200: '#d9f99d',
  lime300: '#bef264',
  lime400: '#a3e635',
  lime500: '#84cc16',
  lime600: '#65a30d',
  lime700: '#4d7c0f',
  lime800: '#3f6212',
  lime900: '#365314',
  lime950: '#1a2e05',

  red100: '#fef2f2',
  red200: '#fecaca',
  red300: '#fca5a5',
  red400: '#f87171',
  red500: '#ef4444',
  red600: '#dc2626',
  red700: '#b91c1c',
  red800: '#991b1b',
  red900: '#7f1d1d',

  yellow700: '#a16207',
  yellow600: '#ca8a04',
  yellow500: '#eab308',
  yellow400: '#facc15',
  yellow300: '#fde047',
  yellow200: '#fef08a',
  yellow100: '#fef9c3',
  yellow50: '#fefce8',

  orange50: '#fff7ed',
  orange100: '#ffedd5',
  orange200: '#fed7aa',
  orange300: '#fdba74',
  orange400: '#fb923c',
  orange500: '#f97316',
  orange600: '#ea580c',
  orange700: '#c2410c',
  orange800: '#9a3412',
  orange900: '#7c2d12',
  orange950: '#431407',

  gray900: '#111827',
  gray800: '#1f2937',
  gray700: '#374151',
  gray600: '#4b5563',
  gray500: '#6b7280',
  gray400: '#9ca3af',
  gray300: '#d1d5db',
  gray200: '#e5e7eb',
  gray100: '#f3f4f6',
  gray50: '#f9fafb',

  indigo100: '#e0e7ff',
  indigo200: '#c7d2fe',
  indigo300: '#a5b4fc',
  indigo400: '#818cf8',
  indigo500: '#6366f1',
  indigo600: '#4f46e5',
  indigo700: '#4338ca',
  indigo800: '#3730a3',
  indigo900: '#312e81',

  blue100: '#dbeafe',
  blue200: '#bfdbfe',
  blue300: '#93c5fd',
  blue400: '#60a5fa',
  blue500: '#3b82f6',
  blue600: '#2563eb',
  blue700: '#1d4ed8',
  blue800: '#1e40af',
  blue900: '#1e3a8a',
};
