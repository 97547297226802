import React, { useState, useEffect, useContext, useRef } from "react";
import { UserContext } from "../../index";
import { useSnackBar } from "context/SnackBarContext";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import InputWithLabel from "../AddJob/components/InputWithLabel";
import { FaSave } from "react-icons/fa";
import { AiOutlineSend } from "react-icons/ai";
import { node } from "../../constants/constants";
import { updateDoc, doc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { db, functions } from "../../utils/firebase";
import ColorDropdown from "components/other/ColorDropdown";
import LoadingSpinner from "../../components/reusable/LoadingSpinner";
// import tooltip from MUI
import Tooltip from "@mui/material/Tooltip";
import { BackLink } from "../../components/NewButtons/BackLink";
import { PageHeader } from "../../layouts/PageHeader";
import { SolidButton } from "../../components/NewButtons/SolidButton";
import { DeleteButton } from "../../components/NewButtons/DeleteButton";
import { OutlineButton } from "../../components/NewButtons/OutlineButton";
import CenteredMaxWidthLayout from "../../layouts/CenteredMaxWidthLayout";

export default function EmployeeDetails() {
  const { user, userData, bannerVisible } = useContext(UserContext);
  const { openSnackBar } = useSnackBar();
  const location = useLocation();
  const navigate = useNavigate();

  const notAbleToEditEmployees =
    !userData?.userData?.isAdmin &&
    userData?.userData?.userType !== "Manger" &&
    userData?.userData?.customPermissions?.manage_employees !== true;

  // const { employeeId } = useParams()
  const { employee, employees } = location?.state || {};

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);

  const [firstName, setFirstName] = useState(employee?.firstName);
  const [lastName, setLastName] = useState(employee?.lastName);
  const [phone, setPhone] = useState(employee?.phone);
  const [email, setEmail] = useState(employee?.email); // email is not editable...
  const [userType, setUserType] = useState(employee?.userType);
  const [selectedColor, setSelectedColor] = useState(employee?.color);

  const [resendInvite, setResendInvite] = useState(false);

  const [warningModal, setWarningModal] = useState(false);
  const [assignedEmployees, setAssignedEmployees] = useState(
    employee?.assignedEmployees || []
  );
  // map the custom permissions object to an array of selected permissions (keys that are true in customPermissions)
  const [selectedPermissions, setSelectedPermissions] = useState(
    employee?.customPermissions
      ? Object.keys(employee?.customPermissions).filter(
          (key) => employee?.customPermissions[key]
        )
      : []
  );

  // console.log("userType", userType);
  // console.log("employee:", employee);

  async function handleResendInvite() {
    console.log("resend invite");
    if (!employee.temporaryPassword) {
      alert(
        "This employee has already changed their password. Please have them reset their password if they have forgotten it."
      );
      return;
    }
    if (loadingResend) {
      return;
    }

    setLoadingResend(true);
    try {
      const sendEmployeeInvite = httpsCallable(functions, "sendEmployeeInvite");
      const result = await sendEmployeeInvite({
        email: employee?.email,
        password: employee?.temporaryPassword,
        firstName: firstName,
        lastName: lastName,
        businessName: userData.bizData.companyName,
        phone: employee?.phone, // Assuming the employee object has a phone field
      });

      const { emailSent, textSent, error } = result.data;

      if (error) {
        throw new Error(error);
      }

      let message = "Invite ";
      if (emailSent && textSent) {
        message += "email and text sent successfully.";
      } else if (emailSent) {
        message += "email sent successfully, but text failed to send.";
      } else if (textSent) {
        message += "text sent successfully, but email failed to send.";
      } else {
        throw new Error("Failed to send both email and text invite.");
      }

      console.log(message);
      openSnackBar(message, true);
    } catch (error) {
      console.error("Error sending invite:", error);
      openSnackBar(`Error sending invite: ${error.message}`, false, true);
    } finally {
      setLoadingResend(false);
    }
    // try {
    //   const res = await fetch(`${node}/employees/send-email`, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       email: employee?.email,
    //       password: employee?.temporaryPassword,
    //       firstName: firstName,
    //       lastName: lastName,
    //       businessName: userData.bizData.companyName,
    //     }),
    //   });

    //   const { message, error: errorFromEmail } = await res.json();
    //   if (errorFromEmail) {
    //     throw new Error(errorFromEmail);
    //   }
    //   console.log(message);
    //   openSnackBar("Invite sent successfully");
    // } catch (error) {
    //   alert(`Error sending invite: ${error}`);
    //   console.log(error);
    // } finally {
    //   setLoadingResend(false);
    // }
  }

  async function handleUpdateEmployee() {
    if (notAbleToEditEmployees) {
      openSnackBar("You do not have permission to edit employees");
      return;
    }
    console.log("update employee");
    if (firstName === "" || lastName === "" || phone === "" || email === "") {
      alert("Please fill out all fields");
      return;
    }

    setLoadingUpdate(true);
    try {
      // create custom permission object and populate it with the selected permissions if userType === "Custom"
      let customPermissions = {};
      if (userType === "Custom") {
        permissionGroups.forEach((group) => {
          group.permissions.forEach((permission) => {
            // Set the permission value to true if it's included in selectedPermissions, false otherwise
            customPermissions[permission.id] = selectedPermissions.includes(
              permission.id
            );
          });
        });
      }

      const dataForDoc = {
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        email: email,
        userType: userType,
        color: selectedColor,
        isAdmin: userType === "Admin" ? true : false,
        assignedEmployees: assignedEmployees,
      };
      if (userType === "Custom") {
        dataForDoc.customPermissions = customPermissions;
      } else {
        dataForDoc.customPermissions = null;
      }
      await updateDoc(doc(db, "users", employee.id), dataForDoc);

      openSnackBar("Employee updated successfully", true);
    } catch (error) {
      setLoadingUpdate(false);
      alert(`Error updating employee: ${error}`);
      console.log(error);
    } finally {
      setLoadingUpdate(false);
    }
    // navigate("/settings/employees");
  }

  async function handleDeleteEmployee() {
    console.log("delete employee");
    if (notAbleToEditEmployees) {
      openSnackBar("You do not have permission to delete employees");
      return;
    }
    if (userData.userData.id == employee.id) {
      alert("You cannot delete yourself");
      return;
    }
    if (loadingDelete) {
      return;
    }

    setLoadingDelete(true);

    try {
      const deleteEmployeeFunction = httpsCallable(functions, "deleteEmployee");
      const result = await deleteEmployeeFunction({ uid: employee.id });

      setLoadingDelete(false);

      if (result.data.success === true) {
        openSnackBar("Employee deleted successfully", false);
        navigate("/settings/employees");
      } else {
        openSnackBar("Error deleting employee");
      }
    } catch (error) {
      setLoadingDelete(false);
      console.error("Error deleting employee:", error);
      // console.log(error.code, error.message)
      openSnackBar(error.message || "Error deleting employee", false, true);
    }

    // // TODO: switch this to a firebase function so that is secure
    // const response = await fetch(`${node}/employeedelete/delete`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({ uid: employee.id }),
    // });

    // const result = await response.json();
    // setLoadingDelete(false);
    // // console.log(result);
    // if (result.success === true) {
    //   openSnackBar("Employee deleted successfully", false);
    //   navigate("/settings/employees");
    // } else {
    //   alert("Error deleting employee");
    // }
  }

  const handleEmployeeSelection = (employeeId) => {
    setAssignedEmployees((prevAssignedEmployees) => {
      if (prevAssignedEmployees.includes(employeeId)) {
        return prevAssignedEmployees.filter((id) => id !== employeeId);
      } else {
        return [...prevAssignedEmployees, employeeId];
      }
    });
  };

  const handleCustomPermissionSelection = (permissionId) => {
    const exclusivePermissions = [
      "manage_all_jobs",
      "manage_assigned_jobs",
      "manage_team_jobs",
    ];

    setSelectedPermissions((prevSelectedPermissions) => {
      // Check if the selected permission is one of the exclusive permissions
      if (exclusivePermissions.includes(permissionId)) {
        // Filter out the other exclusive permissions
        const filteredPermissions = prevSelectedPermissions.filter(
          (id) => !exclusivePermissions.includes(id) || id === permissionId
        );

        // If the current permission is already selected, remove it, otherwise add it
        if (prevSelectedPermissions.includes(permissionId)) {
          return filteredPermissions.filter((id) => id !== permissionId);
        } else {
          return [...filteredPermissions, permissionId];
        }
      } else {
        // For non-exclusive permissions, use the existing toggle logic
        if (prevSelectedPermissions.includes(permissionId)) {
          return prevSelectedPermissions.filter((id) => id !== permissionId);
        } else {
          return [...prevSelectedPermissions, permissionId];
        }
      }
    });
  };

  // logic to determine if the user can see the assigned employees section
  let canSeeAssignedEmployeesSection = false;
  if (
    userType === "Custom" &&
    selectedPermissions.includes("manage_team_jobs")
  ) {
    canSeeAssignedEmployeesSection = true;
  }
  if (userType === "Team Lead") {
    canSeeAssignedEmployeesSection = true;
  }

  return (
    <>
      <div
        className={`bg-gray-100 pb-36 ${
          bannerVisible ? "h-full" : "h-full-minus-header"
        }  overflow-y-auto`}
      >
        <CenteredMaxWidthLayout>
          <PageHeader
            title={employee?.firstName}
            backLink={
              <BackLink toName="Employees" toLink="/settings/employees" />
            }
          >
            <div className="flex flex-row gap-2">
              <SolidButton
                text="Save"
                loading={loadingUpdate}
                onClick={handleUpdateEmployee}
              />
              <DeleteButton
                text="Delete"
                onClick={() => setWarningModal(true)}
              />
            </div>
          </PageHeader>

          <div className="flex flex-col mx-5 bg-gray-50 shadow-md rounded-sm ">
            <h1 className="text-2xl font-bold text-gray-900 bg-gray-200 px-4 py-2 rounded-sm">
              Personal Info
            </h1>

            <div className="flex flex-row justify-start px-4 py-4 rounded-sm gap-4 max-w-2xl">
              <div className="w-1/2">
                <InputWithLabel
                  label="Firstname"
                  style={{
                    maxWidth: "300px",
                  }}
                  value={firstName}
                  setValue={setFirstName}
                />
              </div>
              <div className="w-1/2">
                <InputWithLabel
                  label="Lastname"
                  style={{
                    maxWidth: "300px",
                  }}
                  value={lastName}
                  setValue={setLastName}
                />
              </div>
            </div>
            <div className="flex flex-row justify-start px-4 py-4 rounded-sm gap-4 max-w-2xl">
              <div className="w-1/2">
                <InputWithLabel
                  label="Phone"
                  style={{
                    maxWidth: "300px",
                  }}
                  value={phone}
                  setValue={setPhone}
                />
              </div>

              <Tooltip title="You cannot edit an employee's email address. To create an employee with a different email you will need to delete this employee and create another one.">
                <div className="w-1/2">
                  <InputWithLabel
                    label="Email"
                    disabled={true}
                    style={{
                      maxWidth: "300px",
                    }}
                    value={email}
                    setValue={setEmail}
                  />
                </div>
              </Tooltip>
            </div>
            <div className="flex flex-row justify-start px-4 py-4 rounded-sm gap-4 max-w-2xl">
              <ColorDropdown
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
              />
            </div>
            <div className="flex flex-row justify-start items-center px-4 py-4 rounded-sm gap-4 max-w-2xl">
              <button
                disabled={loadingResend}
                className="bg-gray-900 hover:text-yellow-100 hover:shadow-md text-white w-36 h-10 rounded-md text-md font-medium flex flex-row items-center justify-center"
                onClick={handleResendInvite}
              >
                {loadingResend ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <span>Resend Invite</span>
                    <AiOutlineSend className="text-lg text-yellow-400 ml-2" />
                  </>
                )}
              </button>
              {resendInvite && (
                <span className="text-green-500">Invite sent!</span>
              )}
            </div>
          </div>
          <div className="flex flex-col mx-5 mt-10 bg-gray-50 shadow-md rounded-sm ">
            <h1 className="text-2xl font-bold text-gray-900 bg-gray-200 px-4 py-2 rounded-sm">
              Permissions
            </h1>
            <div className="flex flex-col justify-start px-4 py-4 rounded-sm gap-4">
              <fieldset>
                <legend className="sr-only">Plan</legend>
                <div className="space-y-5">
                  {plans.map((plan) => (
                    <div key={plan.id} className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          id={plan.id}
                          aria-describedby={`${plan.id}-description`}
                          name="plan"
                          type="radio"
                          defaultChecked={plan.value === userType}
                          className="h-4 w-4 border-gray-300 text-red-600 focus:ring-red-600"
                          onChange={() => setUserType(plan.value)}
                        />
                      </div>
                      <div className="ml-3">
                        <label
                          htmlFor={plan.id}
                          className="text-sm font-medium leading-6 text-gray-900"
                        >
                          {plan.name}
                        </label>
                        <p
                          id={`${plan.id}-description`}
                          className="text-sm text-gray-500"
                        >
                          {plan.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div>
          </div>

          {userType === "Custom" && (
            <div className="flex flex-col mx-5 mt-10 bg-gray-50 shadow-md rounded-sm ">
              <h1 className="text-2xl font-bold text-gray-900 bg-gray-200 px-4 py-2 rounded-sm">
                Custom Permissions
              </h1>
              {permissionGroups.map((group) => (
                <div key={group.title} className="px-4 py-4">
                  <h2 className="text-lg font-bold text-gray-700">
                    {group.title}
                  </h2>
                  {group.subTitle && (
                    <p className="text-sm text-gray-500">{group.subTitle}</p>
                  )}
                  <div className="pt-2"></div>
                  <div className="flex flex-col justify-start rounded-sm gap-4">
                    {group.permissions.map((permission) => (
                      <div
                        key={permission.id}
                        className="relative flex items-start"
                      >
                        <div className="flex items-center h-6">
                          <input
                            id={permission.id}
                            name="customPermissions"
                            type="checkbox"
                            checked={selectedPermissions.includes(
                              permission.id
                            )}
                            className="h-4 w-4 border-gray-300 text-red-600 focus:ring-red-600"
                            onChange={() =>
                              handleCustomPermissionSelection(permission.id)
                            }
                          />
                        </div>
                        <div className="ml-3">
                          <label
                            htmlFor={permission.id}
                            className="text-sm font-medium leading-6 text-gray-900"
                          >
                            {permission.name}
                          </label>
                          <p className="text-sm text-gray-500">
                            {permission.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}

          {canSeeAssignedEmployeesSection && (
            <div className="flex flex-col mx-5 mt-10 bg-gray-50 shadow-md rounded-sm ">
              <h1 className="text-2xl font-bold text-gray-900 bg-gray-200 px-4 py-2 rounded-sm">
                Assigned Employees
              </h1>
              <div className="flex flex-col justify-start px-4 py-4 rounded-sm gap-4">
                {employees?.map((employee) => (
                  <div key={employee.id} className="relative flex items-start">
                    <div className="flex items-center h-6">
                      <input
                        id={employee.id}
                        name="assignedEmployees"
                        type="checkbox"
                        checked={assignedEmployees.includes(employee.id)}
                        className="h-4 w-4 border-gray-300 text-red-600 focus:ring-red-600"
                        onChange={() => handleEmployeeSelection(employee.id)}
                      />
                    </div>
                    <div className="ml-3">
                      <label
                        htmlFor={employee.id}
                        className="text-sm font-medium leading-6 text-gray-900"
                      >
                        {employee.firstName} {employee.lastName}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </CenteredMaxWidthLayout>
      </div>
      {warningModal && (
        <div className="warning-modal">
          <div
            className="warning-overlay"
            onClick={() => console.log("clicked overlay")}
          ></div>
          <div className="warning-modal-content ">
            <div className="mt-5">
              <h1 className="text-2xl font-semibold">
                Are you sure you want to delete?
              </h1>
              <h1>All data for this employee will be lost.</h1>
            </div>

            <div className="flex flex-row gap-3 items-center mt-8">
              <OutlineButton
                text="Back"
                disabled={loadingDelete}
                onClick={() => setWarningModal(false)}
                fullWidth
              />
              <DeleteButton
                text="Confirm"
                fullWidth
                loading={loadingDelete}
                onClick={handleDeleteEmployee}
              />
              {/* <button
                className="border-2 border-gray-300 bg-gray-50 rounded-md w-16 h-10 mt-1 text-gray-500 text-md"
                disabled={loadingDelete}
                onClick={() => setWarningModal(false)}
              >
                Back
              </button> */}
              {/* <button
                disabled={loadingDelete}
                className={`bg-red-700 font-semibold rounded-md w-20 h-10 mt-1 text-white text-md ml-2 ${
                  loadingDelete ? "" : "hover:bg-red-800"
                }  flex justify-center items-center`}
                onClick={handleDeleteEmployee}
              >
                {loadingDelete ? <LoadingSpinner color="#ffff" /> : "Confirm"}
              </button> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const permissionGroups = [
  {
    title: "Messages",
    subTitle: null,
    permissions: [
      {
        id: "view_messages",
        name: "View Messages",
        description: "Allows user to view all messages with customers.",
      },
      {
        id: "respond_messages",
        name: "Respond to Messages",
        description: "Grants permission to respond to messages.",
      },
      {
        id: "marketing_messages",
        name: "Send Mass Marketing Messages",
        description:
          "Grants permission to send mass marketing messages to customers.",
      },
    ],
  },
  {
    title: "Customers",
    subTitle: null,
    permissions: [
      {
        id: "view_all_customers",
        name: "View All Customers",
        description: "Allows viewing of all customer profiles in the database.",
      },
      {
        id: "manage_customers",
        name: "Manage Customers",
        description: "Enables user to create and edit customer profiles.",
      },
      {
        id: "delete_customers",
        name: "Delete Customers",
        description: "Grants permission to delete customers from the database.",
      },
    ],
  },
  {
    title: "Jobs",
    subTitle: null,
    permissions: [
      {
        id: "create_jobs",
        name: "Create Jobs",
        description: "Permission to create new job entries.",
      },

      {
        id: "manage_all_jobs",
        name: "View & Manage All Jobs",
        description:
          "Enables user to view/edit all job listings in the system.",
      },
      {
        id: "manage_assigned_jobs",
        name: "View & Manage Assigned Jobs",
        description: "User can view/edit jobs they created or are assigned to.",
      },
      {
        id: "manage_team_jobs",
        name: "View & Manage Team Jobs",
        description:
          "User can view/edit jobs that are created by or assigned to specific team members. (Specific team members can be selected below in the Assigned Employees section)",
      },
      {
        id: "create_estimates",
        name: "Create Estimates",
        description:
          "Allows the user to create and send estimates to customers.",
      },
    ],
  },
  {
    title: "Company Info & Stats",
    subTitle: null,
    permissions: [
      {
        id: "view_company_stats",
        name: "View Company Stats",
        description:
          "Permission to access and view company statistics such as average job size, top salespeople and daily/monthly revenue.",
      },
      {
        id: "view_company_info",
        name: "View Company Info",
        description:
          "Allows user to view general company information such as company phone number, tax rates, and review links.",
      },
      {
        id: "edit_company_info",
        name: "Edit Company Info",
        description:
          "Grants ability to edit company's basic information such as company phone number, tax rates, and review links.",
      },
      {
        id: "edit_billing",
        name: "Edit Billing Info",
        description:
          "Grants access to edit billing and subscription information.",
      },
    ],
  },
  {
    title: "Team Management",
    subTitle: null,
    permissions: [
      {
        id: "view_employees",
        name: "View Team Members",
        description: "Enables viewing of all team member profiles.",
      },
      {
        id: "manage_employees",
        name: "Manage Team Members",
        description: "Permission to edit or create new team member accounts.",
      },
    ],
  },
  {
    title: "Territory Maps",
    subTitle: "Users will always be able to create and view their own markers",
    permissions: [
      {
        id: "create_maps",
        name: "Create Territory Maps",
        description: "Allows the user to create and manage territory maps.",
      },
      {
        id: "view_all_markers",
        name: "View All Markers",
        description: "User can see all markers of everyone in the company.",
      },
    ],
  },
];

const plans = [
  {
    id: "1",
    name: "Admin",
    value: "Admin",
    description: "Can manage all areas.",
  },
  {
    id: "2",
    name: "Manager",
    value: "Manager",
    description:
      "Can see and edit all jobs, territories, and customer info. Recommended for team leads or office staff that need to view/edit all the jobs in the schedule, and all territories in the territory map.",
  },
  {
    id: "3",
    name: "Team Lead",
    value: "Team Lead",
    description:
      "Can manage their own jobs as well as the jobs of assigned salespeople. Recommend for team leads that do not need to see all jobs in the schedule, but need to manage the jobs of their assigned salespeople.",
  },
  {
    id: "4",
    name: "Salesperson (All Jobs)",
    value: "Salesperson",
    description:
      "Can see all jobs, can edit jobs and customer info, and can only see their territory but can see/edit all markers. Recommended for salespeople that need to know all of the jobs scheduled across the company.",
  },
  {
    id: "5",
    name: "Salesperson (Own Jobs Only)",
    value: "Salesperson2",
    description:
      "Can see only jobs they created or are assigned to them, can edit their own job and customer info, and can see only their territory but can see/edit all markers. Recommended for salespeople that only need to see their own jobs.",
  },
  {
    id: "6",
    name: "Field Tech",
    value: "Field Tech",
    description:
      "Can only view and edit jobs that are dispatched to them. Recommended for field techs who will be servicing jobs they are dispatched to.",
  },
  {
    id: "7",
    name: "Custom",
    value: "Custom",
    description:
      "Set custom permissions for this employee. Recommended for employees that need a custom set of permissions.",
  },
];
