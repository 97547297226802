import React, { useRef } from "react";
import InformationIconTooltip from "components/reusable/InformationIconTooltip";
import CustomFileInput from "components/reusable/CustomFileInput";
import { OutlineButton } from "components/NewButtons/OutlineButton";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import Tooltip from "@mui/material/Tooltip";

export default function AddAttachmentButton({ setAttachments }) {
  const handleFileChange = (e) => {
    console.log(e.target.files);
    const files = Array.from(e.target.files);
    const newAttachments = files.map((file, index) => {
      return {
        file: file,
        name: file.name,
      };
    });
    setAttachments((curAttachments) => [...curAttachments, ...newAttachments]);
  };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <Tooltip
        title={
          "Select any attachment that you would like to be included in the email that is sent to the customer"
        }
        arrow
        className="flex-1 flex"
      >
        <OutlineButton
          fullWidth
          text="Add Attachments"
          icon={<PaperClipIcon className="h-5 w-5 shrink-0" />}
          onClick={handleButtonClick}
        />
      </Tooltip>
      <input
        ref={fileInputRef}
        className="hidden"
        // accept="image/*"
        multiple
        type="file"
        onChange={handleFileChange}
      />
    </>
  );
}
