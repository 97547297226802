import React, { useState, useEffect, useRef } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { BackLink } from "../../../components/NewButtons/BackLink";
import { PageHeader } from "../../../layouts/PageHeader";
import { SolidButton } from "../../../components/NewButtons/SolidButton";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";

export default function CustomerDetailsHeader({
  customer,
  navigate,
  toggleModal,
  handleDeleteCustomer,
  notAbleToEditCustomers,
  notAbleToDeleteCustomers,
  openServicePlanModal,
  loading,
  jobId,
  subscriptionId,
}) {
  const backText = jobId
    ? "Job"
    : subscriptionId
    ? "Subscription"
    : "Customers";
  const backNavigatePath = jobId
    ? `/jobdetails/${jobId}`
    : subscriptionId
    ? `/servicesubscription/${subscriptionId}`
    : "/customers";

  const navigationState = subscriptionId
    ? { customerId: customer?.customerId }
    : null;
  const [moreActions, setMoreActions] = useState(false);
  const dropdownRef = useRef(null);
  const editButtonRef = useRef(null);
  function handleClick() {
    console.log("handle lcik ");
    navigate("/customers", { state: { customer: customer } });
  }

  useEffect(() => {
    //create event listener for click outside of dropdown to close it
    const handleClickOutside = (e) => {
      if (
        moreActions &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target) &&
        !editButtonRef.current.contains(e.target)
      ) {
        // console.log("clicked outside");
        setMoreActions(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [moreActions, dropdownRef]);

  return (
    <>
      <div className="absolute ml-0 top-2">
        <BackLink
          toLink={backNavigatePath}
          toName={backText}
          state={navigationState}
        />
      </div>

      <PageHeader
        title={customer?.displayName}
        loading={loading}
        // backLink={<BackLink toLink={backNavigatePath} toName={backText} state={navigationState} />}
      >
        <div className="flex flew-row text-gray-50 gap-3">
          <SolidButton
            onClick={toggleModal}
            text="Edit"
            icon={<AiOutlineEdit className="text-xl" />}
          />
          {/* <button
          onClick={toggleModal}
          className="bg-gray-900 p-2 rounded-md shadow-md flex flex-row items-center hover:text-yellow-100"
        >
          <AiOutlineEdit className="text-xl mr-1 mb-0.5" />
          Edit
        </button> */}
          <button
            // className="bg-gray-900 p-2 rounded-md shadow-md hover:text-yellow-100 ml-2 relative"
            className="rounded-md px-3.5 py-2.5 font-semibold text-white  flex flex-row items-center justify-center text-base bg-gray-900  hover:bg-gray-800 hover:text-yellow-50 disabled:bg-gray-500 disabled:hover:bg-gray-500 disabled:hover:cursor-not-allowed relative"
            onClick={() => setMoreActions(!moreActions)}
            ref={editButtonRef}
          >
            <EllipsisVerticalIcon className="h-5" />
            <p>Actions</p>
            {moreActions && (
              <ul
                className="absolute top-11 right-0 bg-gray-100 text-gray-600 rounded shadow-md"
                style={{
                  width: "130px",
                }}
                ref={dropdownRef}
              >
                <li
                  className="py-2 px-2 hover:bg-yellow-100 hover:rounded-t hover:text-gray-900 pt-2 "
                  onClick={() => navigate(`/addjob`, { state: { customer } })}
                >
                  New Job
                </li>
                <li
                  className="py-2 px-2 hover:bg-yellow-100 hover:text-gray-900 "
                  onClick={() =>
                    navigate(`/newestimate`, { state: { customer } })
                  }
                >
                  New Estimate
                </li>
                <li
                  className="py-2 px-2 hover:bg-yellow-100 hover:text-gray-900 "
                  onClick={openServicePlanModal}
                >
                  New Service Subscription
                </li>

                <li
                  className="py-2 px-2 hover:bg-red-500 hover:rounded-b hover:text-red-50 pb-2  rounded-b "
                  onClick={handleDeleteCustomer}
                >
                  Delete
                </li>
              </ul>
            )}
          </button>
        </div>
      </PageHeader>
    </>
  );
}
