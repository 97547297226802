import React, { useContext, useEffect, useMemo, useState } from "react";
import { useUserContext } from "context/UserContext";
import { BsCheck2Square } from "react-icons/bs";
import { node } from "../../constants/constants";
import { Link } from "react-router-dom";
import { BiMessageAltError } from "react-icons/bi";
import NormalButton from "components/buttons/NormalButton";
import { useSnackBar } from "context/SnackBarContext";
import { PageHeader } from "../../layouts/PageHeader";
import CenteredMaxWidthLayout from "../../layouts/CenteredMaxWidthLayout";
import LoadingSpinner from "../../components/reusable/LoadingSpinner";
import { SolidButton } from "../../components/NewButtons/SolidButton";
import useFetch from "../../hooks/useFetch";
import { AccountBalance } from "./components/AccountBalance";
import { getCustomerBalance, getStripeCustomer } from "./data/api";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

export default function Homebase360Payments() {
  const { user, userData, bannerVisible } = useUserContext();
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const { openSnackBar } = useSnackBar();
  // const [disabledReason, setDisabledReason] = useState(null);
  // const [verificationPending, setVerificationPending] = useState(false);
  const [stripeStatus, setStripeStatus] = useState(null);

  // console.log("userData.bizData", userData.bizData);

  useEffect(() => {
    if (
      true
      // !(
      //   userData.bizData?.stripeAccountId &&
      //   userData.bizData?.stripe_charges_enabled === true &&
      //   userData.bizData?.stripe_payouts_enabled === true &&
      //   userData.bizData?.stripe_details_submitted === true
      // )
    ) {
      getStripeCustomer(userData).then((stripeCustomer) => {
        if (stripeCustomer?.requirements.disabled_reason) {
          if (
            stripeCustomer?.requirements.disabled_reason ===
            "requirements.pending_verification"
          ) {
            setStripeStatus("pending");
          } else {
            setStripeStatus("blocked");
          }
        } else {
          setStripeStatus(null);
        }
      });
    }
  }, [userData]);

  const fetchData = async (url, data) => {
    try {
      setLoading(true);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      if (result.error) {
        throw new Error(result.error);
      }

      return result.url;
    } catch (error) {
      console.error("Error:", error.message);
      // Show error message to user here
      setLoading(false);
      openSnackBar("There was an error. Please try again later.");
      return null;
    }
  };

  const handleOnboard = async () => {
    const businessId = userData?.userData?.businessId;
    const url = await fetchData(`${node}/connect/onboard-user`, {
      business: businessId,
    });
    if (url) window.location.href = url;
  };

  const handleRefresh = async () => {
    const stripeAccountId = userData?.bizData?.stripeAccountId;
    const url = await fetchData(`${node}/connect/refresh`, { stripeAccountId });
    if (url) window.location.href = url;
    // // console.log("stripeAccountId", stripeAccountId);

    // // Submit the form with the stripeAccountId programatically
    // const form = document.createElement("form");
    // form.setAttribute("method", "post");
    // form.setAttribute("action", `${node}/connect/refresh`);

    // const stripeAccountIdInput = document.createElement("input");
    // stripeAccountIdInput.setAttribute("type", "hidden");
    // stripeAccountIdInput.setAttribute("name", "stripeAccountId");
    // stripeAccountIdInput.setAttribute("value", stripeAccountId);

    // form.appendChild(stripeAccountIdInput);
    // document.body.appendChild(form);
    // form.submit();
  };

  if (
    userData.bizData?.stripeAccountId &&
    userData.bizData?.stripe_charges_enabled === true &&
    userData.bizData?.stripe_payouts_enabled === true &&
    userData.bizData?.stripe_details_submitted === true
  ) {
    return (
      <>
        <div
          className={`bg-gray-100 ${
            bannerVisible
              ? "h-full-minus-head-and-banner"
              : "h-full-minus-header"
          } overflow-y-auto`}
        >
          <CenteredMaxWidthLayout>
            <PageHeader title={"Homebase 360 Payments"} />
            <div className="mx-5">
              <AccountBalance />
            </div>
          </CenteredMaxWidthLayout>
        </div>
      </>
    );
  }

  if (
    userData.bizData?.stripeAccountId &&
    (userData.bizData?.stripe_charges_enabled === false ||
      userData.bizData?.stripe_payouts_enabled === false ||
      userData.bizData?.stripe_details_submitted === false ||
      !userData.bizData?.stripe_details_submitted ||
      !userData.bizData?.stripe_payouts_enabled ||
      !userData.bizData?.stripe_charges_enabled)
  ) {
    return (
      <>
        <div
          className={`bg-gray-100 ${
            bannerVisible
              ? "h-full-minus-head-and-banner"
              : "h-full-minus-header"
          } overflow-y-auto`}
        >
          <h1 className="text-3xl font-extrabold text-gray-900 ml-10 mt-5 mb-5 ">
            Homebase 360 Payments
          </h1>
          <div className="flex flex-col mx-10 bg-gray-50 shadow-md rounded-sm pb-8 ">
            {/* <h1 className="text-xl font-bold text-gray-900 bg-gray-200 px-4 py-4 rounded-sm">
              Looks like you still have some pending actions
            </h1> */}
            <div className="h-16 flex flex-row gap-4 items-center p-4 border-b">
              <h3 className="text-lg font-semibold leading-6 text-gray-900">
                Stripe Account
              </h3>
              {stripeStatus && (
                <div className="">
                  {stripeStatus === "pending" ? (
                    <div className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20 gap-2">
                      <LoadingSpinner color="#ca8a04" />
                      Stripe verification pending. Please check in later.
                    </div>
                  ) : (
                    <div className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-600 ring-1 ring-inset ring-red-600/20">
                      Missing requirements
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* <p className="text-sm text-gray-900 px-4 pt-2 ">
              It may take a few days for your account and identity to be verified.
            </p> */}

            <h1 className="text-gray-500 p-4">
              {userData.bizData?.stripe_details_submitted === true ? (
                <span className="flex flex-row">
                  <BsCheck2Square className="text-xl text-green-500 mr-2 " />
                  Details submitted
                </span>
              ) : (
                <span className="font-medium text-red-500 flex flex-row items-center">
                  <BiMessageAltError className="text-xl text-red-500 mr-2 " />
                  Details not submitted
                </span>
              )}
            </h1>
            <h1 className="text-gray-500 px-4 pt-8 pb-4">
              {userData.bizData?.stripe_charges_enabled === true ? (
                <span className="flex flex-row">
                  <BsCheck2Square className="text-xl text-green-500 mr-2 " />
                  Charges enabled
                </span>
              ) : (
                <span className="font-medium text-red-500 flex flex-row items-center">
                  <BiMessageAltError className="text-xl text-red-500 mr-2 " />
                  Charges not enabled
                </span>
              )}
            </h1>
            <h1 className="text-gray-500 px-4 pt-8 pb-4">
              {userData.bizData?.stripe_payouts_enabled === true ? (
                <span className="flex flex-row">
                  <BsCheck2Square className="text-xl text-green-500 mr-2 " />
                  Payouts enabled
                </span>
              ) : (
                <span className="font-medium text-red-500 flex flex-row items-center">
                  <BiMessageAltError className="text-xl text-red-500 mr-2 " />
                  Payouts not enabled
                </span>
              )}
            </h1>

            <div className="p-4">
              <SolidButton
                text="Finish setting up"
                onClick={handleRefresh}
                loading={loading}
              />
            </div>

            <div className="flex flex-row gap-4 items-center p-4">
              <p className="text-gray-500">
                <span>
                  Still having trouble? Try logging in to your Stripe account
                  with your email
                  <span className="font-semibold">
                    {userData.bizData?.stripe_email
                      ? " " + userData.bizData?.stripe_email
                      : ""}
                  </span>
                  .
                </span>
              </p>
              <div>
                <button
                  className="relative inline-flex items-center rounded-md bg-indigo-600 px-3.5 py-2.5 font-medium  text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  gap-1 flex-row"
                  onClick={() =>
                    window.open("https://www.stripe.com/dashboard", "_blank")
                  }
                >
                  <ArrowTopRightOnSquareIcon height={18} strokeWidth={2} />
                  Open Stripe
                </button>
              </div>
            </div>
            <p className="text-gray-500 px-4 pt-2 pb-4">
              <span>
                Or email us at{" "}
                <a
                  href="mailto:support@homebase360.io"
                  className="font-bold text-indigo-600"
                >
                  support@homebase360.io
                </a>{" "}
              </span>
              <span></span>
            </p>
          </div>
        </div>
      </>
    );
  }

  if (!userData.bizData?.stripeAccountId) {
    return (
      <>
        <div
          className={`bg-gray-100 ${
            bannerVisible
              ? "h-full-minus-head-and-banner"
              : "h-full-minus-header"
          } overflow-y-auto`}
        >
          <h1 className="text-3xl font-extrabold text-gray-900 ml-10 mt-5 mb-5 ">
            Homebase 360 Payments
          </h1>
          <div className="flex flex-col mx-10 bg-gray-50 shadow-md rounded-sm pb-8 ">
            <h1 className="text-xl font-bold text-gray-900 bg-gray-200 px-4 py-4 rounded-sm">
              Collect payments from your clients
            </h1>

            <h1 className="text-gray-500 px-4 pt-8 pb-4">
              We've made getting paid simple. Turn on Homebase Payments to start
              accepting all major credit and debit cards on your jobs and
              invoices.
            </h1>

            {features.map((feature, index) => (
              <div
                key={index}
                className="flex flex-row justify-start items-center px-4 py-4 rounded-sm"
              >
                <div className="flex flex-col gap-1">
                  <h1 className="text-xl font-bold text-gray-900 flex flex-row items-center">
                    <BsCheck2Square className="text-xl text-green-500 mr-2 " />{" "}
                    {feature.title}
                  </h1>
                  <h1 className="text-gray-900">{feature.description}</h1>
                </div>
              </div>
            ))}
            {/* <form action={`${node}/connect/onboard-user`} method="post">
              <input
                type="hidden"
                name="business"
                value={userData.userData.businessId}
              />
              <button className="bg-gray-900 hover:text-yellow-100 text-yellow-50 font-medium w-fit py-3 px-3 rounded-md text-md ml-10 mt-5 mb-5">
                Turn on Homebase Payments
              </button>
            </form> */}
            <NormalButton
              text="Turn on Homebase Payments"
              width="w-64"
              onClick={handleOnboard}
              additionalClasses="mt-5 mb-5 ml-5"
              loading={loading}
              disabled={loading}
            />
          </div>
        </div>
      </>
    );
  }
}

const features = [
  {
    title: "Contactless Payments",
    description:
      "Send invoices by text or email and let your clients pay online with a credit or debit card.",
  },
  {
    title: "Collect tips",
    description: "Let your clients tip you directly from the invoice.",
  },
  {
    title: "Look professional",
    description:
      "Your clients will see your business name on their credit card statement.",
  },
  {
    title: "No monthly fees",
    description:
      "Homebase Payments is free to use. We only charge a 3.5% + $0.30 per transaction.",
  },
];
