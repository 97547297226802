export const findPriceId = (planName, period) => {
  if (period === "annual" && planName === "Starter") {
    return process.env.REACT_APP_STARTER_ANNUAL;
  }
  if (period === "annual" && planName === "Growing") {
    return process.env.REACT_APP_GROWING_ANNUAL;
  }
  if (period === "annual" && planName === "Enterprise") {
    return process.env.REACT_APP_ENTERPRISE_ANNUAL;
  }
  if (period === "month" && planName === "Starter") {
    return process.env.REACT_APP_STARTER_MONTH;
  }
  if (period === "month" && planName === "Growing") {
    return process.env.REACT_APP_GROWING_MONTH;
  }
  if (period === "month" && planName === "Enterprise") {
    return process.env.REACT_APP_ENTERPRISE_MONTH;
  }
};

export const findPriceIdNew = (planName, period) => {
  if (period === "yearly" && planName === "Starter") {
    return process.env.REACT_APP_STARTER_ANNUAL;
  }
  if (period === "yearly" && planName === "Growing") {
    return process.env.REACT_APP_GROWING_ANNUAL;
  }
  if (period === "yearly" && planName === "Enterprise") {
    return process.env.REACT_APP_ENTERPRISE_ANNUAL;
  }
  if (period === "monthly" && planName === "Starter") {
    return process.env.REACT_APP_STARTER_MONTH;
  }
  if (period === "monthly" && planName === "Growing") {
    return process.env.REACT_APP_GROWING_MONTH;
  }
  if (period === "monthly" && planName === "Enterprise") {
    return process.env.REACT_APP_ENTERPRISE_MONTH;
  }
};

export const findPriceIdSalesRegistration = (plan, cycle) => {
  if (!plan || !cycle) {
    return null;
  }
  // convert to lowercase
  const planName = plan.toLowerCase();
  const period = cycle.toLowerCase();

  if (period === "yearly" && planName === "starter") {
    return process.env.REACT_APP_STARTER_ANNUAL;
  }
  if (period === "yearly" && planName === "growing") {
    return process.env.REACT_APP_GROWING_ANNUAL;
  }
  if (period === "yearly" && planName === "enterprise") {
    return process.env.REACT_APP_ENTERPRISE_ANNUAL;
  }
  if (period === "monthly" && planName === "starter") {
    return process.env.REACT_APP_STARTER_MONTH;
  }
  if (period === "monthly" && planName === "growing") {
    return process.env.REACT_APP_GROWING_MONTH;
  }
  if (period === "monthly" && planName === "enterprise") {
    return process.env.REACT_APP_ENTERPRISE_MONTH;
  }

  return null;
};
