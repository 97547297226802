import React, { useState, useRef, useEffect, useContext } from "react";
import { BsQuestionCircleFill } from "react-icons/bs";
import { node } from "constants/constants";
import { useUserContext } from "context/UserContext";
import { useSnackBar } from "context/SnackBarContext";
import NormalButton from "components/buttons/NormalButton";

export default function QuestionMarkDropDown({ bannerVisible }) {
  const { userData } = useUserContext();
  const { openSnackBar } = useSnackBar();
  const [questionClicked, setQuestionClicked] = useState(false);
  const [message, setMessage] = useState("");
  const [feedbackSent, setFeedbackSent] = useState(false);
  const dropdownRef = useRef(null);
  const editButtonRef = useRef(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        questionClicked &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target) &&
        !editButtonRef.current.contains(e.target)
      ) {
        setQuestionClicked(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [questionClicked, dropdownRef]);

  const handleSendFeedback = async () => {
    console.log("Message: " + message);
    setLoading(true);

    try {
      const response = await fetch(`${node}/messages/help-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message: message,
          name:
            userData?.userData?.firstName + " " + userData?.userData?.lastName,
          email: userData?.userData?.email,
          businessId: userData?.userData?.businessId,
          businessName: userData?.bizData?.companyName,
          customerId: userData?.subData?.customer,
        }),
      });

      const { message: responseMessage, error } = await response.json();

      if (error) {
        console.log(error);
        alert(
          "Error sending message, please reach out to support@homebase360.io for help."
        );
        return;
      }

      // console.log(responseMessage);

      if (responseMessage) {
        openSnackBar(
          `Message sent. We'll respond to ${userData?.userData?.email} within 48 hours.`,
          true
        );
        setFeedbackSent(true);
        setTimeout(() => {
          setFeedbackSent(false);
          setMessage("");
          setQuestionClicked(false);
        }, 200);
      } else {
        throw new Error("Error");
      }
    } catch (error) {
      console.log(error);
      alert(
        "Error sending message, please reach out to support@homebase360.io for help."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <button
        className="shadow-md font-bold relative z-1000"
        onClick={() => setQuestionClicked(!questionClicked)}
        ref={editButtonRef}
      >
        <BsQuestionCircleFill className="text-gray-100 hover:text-yellow-50 text-xl" />
      </button>

      {questionClicked && (
        <div
          onClick={handleClickInside}
          className={`fixed ${
            bannerVisible ? "top-28" : "top-14"
          } right-0 p-5 rounded-md bg-gray-900 w-96 z-50`}
          ref={dropdownRef}
        >
          <div className="flex flex-col text-gray-50 font-medium gap-4 ">
            <>
              <h1 className="text-lg font-bold mt-6">Need some help?</h1>
              <p className="mt-6">
                Please let us know if you have any questions, comments, or
                concerns. Type your question here and we will get back to you
                within 48 hours!
              </p>

              <textarea
                className="p-2 rounded-md mt-10 text-gray-900 focus:outline-none focus:ring-2 focus:ring-yellow-500"
                placeholder="Type your message here..."
                rows={3}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <NormalButton
                disabled={message.length === 0 || loading}
                loading={loading}
                onClick={handleSendFeedback}
                text="Send"
                width="w-32"
                additionalClasses="mt-4 bg-indigo-600 text-white hover:text-white"
              />
            </>
          </div>
        </div>
      )}
    </>
  );
}
