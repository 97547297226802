import React from "react";
import Modal from "@mui/material/Modal";
import CancelButton from "components/buttons/CancelButton";
import NormalButton from "components/buttons/NormalButton";

export default function ConfirmUpdateModal({
  open,
  handleClose,
  handleConfirm,
  selectedPlan,
  loading,
  proratedInfo,
  currentPlan,
  currentPeriod,
  isTrial,
}) {
  console.log("proratedInfo", proratedInfo);
  console.log("isTrial", isTrial);

  const formattedProrationMessage = (prorationInfo) => {
    const { amountDue, totalProratedAmount } = prorationInfo;

    // Normalizing the plan names and periods to ensure case-insensitive comparison
    const currentPlanNameLower = currentPlan.toLowerCase();
    const selectedPlanNameLower = selectedPlan.planName.toLowerCase();
    const currentPeriodLower = currentPeriod.toLowerCase();
    const selectedPeriodLower = selectedPlan.period.toLowerCase();

    // console.log("currentPeriodLower", currentPeriodLower);
    // console.log("selectedPeriodLower", selectedPeriodLower);

    // if it's a trial, we let them know that the full amount will be charged at the end of the trial
    if (isTrial) {
      return (
        <span>
          When you activate your {selectedPlan.planName} {selectedPlan.period}{" "}
          plan, a payment of <strong>${amountDue.toFixed(2)}</strong> will be
          due at the end of your trial period.
        </span>
      );
    }

    // // This gave us the wrong amount when we were upgrading from monthly to yearly...
    // // Check for changing billing cycles, especially from a shorter to a longer period (e.g., monthly to yearly)
    // if (currentPeriodLower === "monthly" && selectedPeriodLower === "yearly") {
    //   return (
    //     <span>
    //       To activate your {selectedPlan.planName} {selectedPlan.period} plan, a
    //       payment of
    //       <strong> ${amountDue.toFixed(2)}</strong> is due today. This covers
    //       your subscription for the next year.
    //     </span>
    //   );
    // }

    // check for upgrading yearly plan to different yearly plan
    if (currentPeriodLower === "yearly" && selectedPeriodLower === "yearly") {
      return (
        <span>
          To activate your {selectedPlan.planName} {selectedPlan.period} plan, a
          payment of
          <strong> ${amountDue.toFixed(2)}</strong> is due today. This covers
          your subscription for the next year.
        </span>
      );
    }

    // Check if there's an immediate payment required for proration
    if (totalProratedAmount > 0) {
      return (
        <span>
          A prorated charge of{" "}
          <strong>${totalProratedAmount.toFixed(2)}</strong> will be applied to
          your account today to confirm the upgrade to the{" "}
          {selectedPlan.planName} {selectedPlan.period} plan.
        </span>
      );
    } else {
      return `No additional payment is required today to switch to the ${selectedPlan.planName} ${selectedPlan.period} plan.`;
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[550px] bg-white shadow-bold p-10 py-8 rounded-md">
        <h1 className="text-xl text-gray-900 font-medium">
          <span>Confirm change to</span>
          <span className="font-bold">
            {" "}
            {selectedPlan.planName} {selectedPlan.period}
          </span>{" "}
          plan
        </h1>
        <p className="mt-3 text-sm">
          {formattedProrationMessage(proratedInfo)} Your new plan will be
          effective immediately.
        </p>

        <div className="flex flex-row items-center justify-end mt-6">
          <CancelButton handleCancel={handleClose} disabled={loading} />
          <NormalButton
            text="Confirm"
            onClick={handleConfirm}
            loading={loading}
            additionalClasses="ml-4"
          />
        </div>
      </div>
    </Modal>
  );
}
