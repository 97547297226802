import React, { useState, useRef, useCallback, useEffect } from "react";
import ContentEditable from "react-contenteditable";

const RichTextInput = ({
  value,
  onChange,
  label,
  DYNAMIC_VARIABLES,
  LIMIT = 200,
  htmlToText,
  disabled = false,
  allowLineBreaks = true,
}) => {
  const [html, setHtml] = useState(value);
  const [isFocused, setIsFocused] = useState(false);
  const contentEditableRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    setHtml(value);
  }, [value]);

  const getTextLength = (htmlContent) => {
    const text = htmlToText(htmlContent);
    return text.length;
  };

  const handleChange = useCallback(
    (evt) => {
      const newHtml = evt.target.value;
      const textLength = getTextLength(newHtml);

      if (textLength <= LIMIT) {
        setHtml(newHtml);
        onChange(newHtml);
      } else {
        // If exceeding LIMIT characters, keep the previous state
        contentEditableRef.current.innerHTML = html;
        alert(`Message length cannot exceed ${LIMIT} characters.`);
      }
    },
    [onChange, html, LIMIT, htmlToText]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter" && !allowLineBreaks) {
        event.preventDefault();
        return;
      }

      if (event.key === "Enter" && !event.shiftKey && allowLineBreaks) {
        event.preventDefault();
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        const br = document.createElement("br");
        range.deleteContents();
        range.insertNode(br);
        range.setStartAfter(br);
        range.setEndAfter(br);
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);

        // Manually trigger the onChange event
        const newHtml = contentEditableRef.current.innerHTML;
        handleChange({ target: { value: newHtml } });
      }
    },
    [handleChange, allowLineBreaks]
  );

  const insertVariable = useCallback(
    (variable) => {
      if (!contentEditableRef.current) return;

      const selection = window.getSelection();
      let range;

      if (
        selection.rangeCount > 0 &&
        contentEditableRef.current.contains(selection.anchorNode)
      ) {
        range = selection.getRangeAt(0);

        let currentNode = range.startContainer;
        while (currentNode !== contentEditableRef.current) {
          if (
            currentNode.nodeType === Node.ELEMENT_NODE &&
            currentNode.hasAttribute("data-value")
          ) {
            range.setStartAfter(currentNode);
            range.collapse(true);
            break;
          }
          currentNode = currentNode.parentNode;
        }
      } else {
        range = document.createRange();
        range.selectNodeContents(contentEditableRef.current);
        range.collapse(false);
      }

      let spaceTextNode = null;
      // Add a space before the variable if it's not at the start
      if (range.startOffset > 0) {
        spaceTextNode = document.createTextNode(" ");
        range.insertNode(spaceTextNode);
        range.setStartAfter(spaceTextNode);
      }

      const span = document.createElement("span");
      span.contentEditable = false;
      span.className =
        "bg-yellow-50 border border-gray-400 text-gray-700 py-1 px-2 rounded-full cursor-default text-sm whitespace-nowrap";
      span.innerHTML = `${variable.label}<button class="ml-1 text-gray-800 hover:text-gray-400 font-bold text-lg transition-colors duration-300 ease-in-out" data-action="remove">×</button>`;
      span.setAttribute("data-value", variable.value);

      range.insertNode(span);

      // Move cursor after inserted variable
      const newRange = document.createRange();
      newRange.setStartAfter(span);
      newRange.collapse(true);
      selection.removeAllRanges();
      selection.addRange(newRange);

      // Update the html state
      const newHtml = contentEditableRef.current.innerHTML;
      if (getTextLength(newHtml) <= LIMIT) {
        setHtml(newHtml);
        onChange(newHtml);
      } else {
        // If exceeding LIMIT characters, don't insert the variable
        span.remove();
        if (spaceTextNode) spaceTextNode.remove();
        alert(`Message length cannot exceed ${LIMIT} characters.`);
      }

      contentEditableRef.current.focus();
    },
    [onChange]
  );

  const handleClick = useCallback(
    (e) => {
      if (e.target.dataset.action === "remove") {
        const variableElement = e.target.closest("[data-value]");
        if (variableElement) {
          // Remove the preceding space if it exists
          if (
            variableElement.previousSibling &&
            variableElement.previousSibling.nodeType === Node.TEXT_NODE
          ) {
            const prevText = variableElement.previousSibling.textContent;
            if (prevText.endsWith(" ")) {
              variableElement.previousSibling.textContent = prevText.slice(
                0,
                -1
              );
            }
          }
          variableElement.remove();
          // Update the html state
          const newHtml = contentEditableRef.current.innerHTML;
          setHtml(newHtml);
          onChange(newHtml);
        }
      }
    },
    [onChange]
  );

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div
      className={`relative ${
        disabled ? " pointer-events-none opacity-50" : ""
      }`}
      ref={containerRef}
    >
      <div className="relative">
        <div className="text-xs text-gray-500 absolute top-1 right-1">
          {getTextLength(html)}/{LIMIT}
        </div>
        <ContentEditable
          innerRef={contentEditableRef}
          html={html}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={`min-h-[56px] p-2 px-3 pt-4 bg-gray-50  rounded-t outline-none leading-loose border-b-custom-b border-gray-400`}
          onClick={handleClick}
        />
        <div
          className={`
          absolute bottom-0 left-0 right-0
          h-[2px] bg-yellow-200
          transform origin-center
          transition-transform duration-300 ease-in-out
          ${isFocused ? "scale-x-100" : "scale-x-0"}
        `}
        ></div>
      </div>
      <div className="flex flex-wrap gap-2 mt-4">
        {DYNAMIC_VARIABLES.map((variable) => (
          <button
            key={variable.value}
            className="px-2 py-1 bg-yellow-50 border border-gray-400 rounded-full shadow-sm hover:text-gray-900 hover:bg-yellow-100 text-sm transition-colors duration-300 ease-in-out"
            onClick={() => insertVariable(variable)}
          >
            {variable.label} +
          </button>
        ))}
      </div>
    </div>
  );
};

export default RichTextInput;
