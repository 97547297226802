import { db } from "./firebase";
import {
  collection,
  doc,
  setDoc,
  Timestamp,
  serverTimestamp,
} from "firebase/firestore";
import { node } from "../constants/constants";

export async function sendMessage(
  message,
  to,
  from = null,
  bizId,
  bizName,
  customerName
) {
  console.log("send message");
  let initials = "";

  const bizNameArray = bizName.split(" ");
  if (bizNameArray.length === 1) {
    initials = bizNameArray[0].slice(0, 2).toUpperCase();
  } else {
    // initials = bizNameArray
    //   .reduce((acc, cur) => acc + cur[0], "")
    //   .toUpperCase();
    initials = bizNameArray
      .slice(0, 2)
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  }

  try {
    const response = await fetch(`${node}/messages/twilioSend`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to: to,
        from: from,
        message: message,
        bizName: bizName,
        bizId: bizId,
        initials: initials,
        customerName: customerName,
      }),
    });

    // response status code is 200 then we continue with the rest of the code
    if (response.status === 200) {
      console.log("message sent");
      return true;
    } else {
      console.log("message not sent");
      return false;
    }
  } catch (error) {
    console.log("error from send message:", error);
    return false;
  }
}

export async function massText(bizId, message, customerList, bizName, from) {
  try {
    const response = await fetch(`${node}/messages/mass-text`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customerList,
        from: from,
        messageRaw: message,
        bizName: bizName,
        bizId: bizId,
      }),
    });

    const data = await response.json();

    if (!response.ok || !data.success) {
      throw new Error(
        data.error || "An error occurred while scheduling the messages."
      );
    }

    console.log("Response:", data.message);
    return { success: true, info: data.message };
  } catch (error) {
    console.error("Error:", error.message);
    return { success: false, error: error.message };
  }
}
