import { db } from "../../../utils/firebase";
import {
  collection,
  doc,
  updateDoc,
  getDoc,
  getCountFromServer,
  setDoc,
  arrayUnion,
  deleteDoc,
} from "firebase/firestore";
import { node } from "../../../constants/constants";
import { EstimateEventTypes } from "./constants";

export async function getEstimateDetails({ businessId, estimateId }) {
  if (!businessId || !estimateId) return;
  console.log("getting estimate details", businessId, estimateId);
  const estimateRef = doc(db, "businesses", businessId, "estimates", estimateId);
  const estimate = await getDoc(estimateRef);
  if (estimate.exists()) {
    return estimate.data();
    // return {
    //   ...estimate.data(),
    //   dateAdded: estimate.data()?.dateAdded?.toDate() ?? null,
    // };
  } else {
    throw new Error("No estimate found");
    // console.log("no estimate found");
    // return {};
  }
}

export const resendEstimate = async ({ estimateDetails, userData }) => {
  try {
    const response = await fetch(`${node}/estimate/estimate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        estimate: estimateDetails,
        businessName: userData.bizData.companyName,
        businessEmail: userData.bizData.email,
        businessPhone: userData.bizData.phone,
        businessAddress: userData.bizData.address,
      }),
    });

    const { message } = await response.json();
    return message;
  } catch (err) {
    console.log("Error", err);
    alert("There was an error sending the estimate, please try again");
  }
};

export const createNewEstimate = async ({ newEstimateData, userData }) => {
  const newEstimateRef = doc(
    collection(db, "businesses", userData.userData.businessId, "estimates")
  );

  newEstimateData.estimateId = newEstimateRef.id;
  newEstimateData.estimateSettings = {
    ...userData.bizData.estimateSettings,
  };

  const estimateCollectionRef = collection(
    db,
    "businesses",
    userData.userData.businessId,
    "estimates"
  );
  const estimateCountSnapshot = await getCountFromServer(estimateCollectionRef);
  const estimateCount = estimateCountSnapshot.data().count;
  newEstimateData.estimateNumber = estimateCount + 1;

  await setDoc(newEstimateRef, {
    ...newEstimateData,
    history: [
      {
        type: EstimateEventTypes.ESTIMATE_CREATED,
        timestamp: new Date(),
        initiatedBy: {
          userId: userData.userData.id,
          userName: `${userData.userData.firstName} ${userData.userData.lastName}`,
        },
        data: {},
      },
    ],
    statusTimestamps: {
      sent: null,
      approved: null,
      copied: null,
    },
    publicStatus: { status: "pending", timestamp: null },
  });
  return newEstimateRef.id;
};

export const approveEstimate = ({ userData, estimateId }) => {
  const estimateRef = doc(db, "businesses", userData.userData.businessId, "estimates", estimateId);
  return updateDoc(estimateRef, {
    "statusTimestamps.approved": new Date(),
    history: arrayUnion({
      type: EstimateEventTypes.ESTIMATE_APPROVED,
      timestamp: new Date(),
      initiatedBy: {
        userId: userData.userData.id,
        userName: `${userData.userData.firstName} ${userData.userData.lastName}`,
      },
      data: {},
    }),
  });
};

export const revertEstimateApproval = ({ userData, estimateId }) => {
  const estimateRef = doc(db, "businesses", userData.userData.businessId, "estimates", estimateId);
  return updateDoc(estimateRef, {
    "statusTimestamps.approved": null,
    history: arrayUnion({
      type: EstimateEventTypes.ESTIMATE_APPROVAL_REVERTED,
      timestamp: new Date(),
      initiatedBy: {
        userId: userData.userData.id,
        userName: `${userData.userData.firstName} ${userData.userData.lastName}`,
      },
      data: {},
    }),
  });
};

export const resetPublicStatus = ({ userData, estimateId }) => {
  const estimateRef = doc(db, "businesses", userData.userData.businessId, "estimates", estimateId);
  return updateDoc(estimateRef, {
    publicStatus: { status: "pending", timestamp: new Date() },
    "statusTimestamps.approved": null,
  });
};

export const estimateCopiedToJob = ({ userData, estimateId, jobId }) => {
  const estimateRef = doc(db, "businesses", userData.userData.businessId, "estimates", estimateId);
  return updateDoc(estimateRef, {
    "statusTimestamps.copied": new Date(),
    history: arrayUnion({
      type: EstimateEventTypes.ESTIMATE_COPIED,
      timestamp: new Date(),
      initiatedBy: {
        userId: userData.userData.id,
        userName: `${userData.userData.firstName} ${userData.userData.lastName}`,
      },
      data: { jobId },
    }),
  });
};

export const updateEstimateSettings = ({ estimateSettings, userData, estimateId }) => {
  const estimateRef = doc(db, "businesses", userData.userData.businessId, "estimates", estimateId);
  return updateDoc(estimateRef, {
    estimateSettings,
  });
};

export const deleteEstimate = ({ userData, estimateId }) => {
  const estimateRef = doc(db, "businesses", userData.userData.businessId, "estimates", estimateId);
  return deleteDoc(estimateRef);
};
