import { debounce } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { BiSearchAlt2 } from "react-icons/bi";

const submitChange = debounce((searchInput, setFilterOptions) => {
  setFilterOptions((p) => ({
    ...p,
    searchInput,
  }));
}, 400);

export default function CustomersSearch({ setFilterOptions, filterOptions }) {
  const [inputValue, setInputValue] = useState("");
  const inputValueRef = useRef();

  const handleChange = useCallback(
    (event) => {
      // set local state then debounced state change to fetch customers
      setInputValue(event.target.value || "");
      submitChange(event.target.value || "", setFilterOptions);
    },
    [setFilterOptions]
  );

  // reset search input if its deleted in filter option, this allows clearing of the input of changing filters for example
  useEffect(() => {
    if (inputValueRef.current && !filterOptions.searchInput) setInputValue("");
  }, [filterOptions, setInputValue]);
  useEffect(() => {
    inputValueRef.current = inputValue;
  }, [inputValue]);

  return (
    <div className="relative flex flex-col w-1/2 max-w-[36rem] justify-center">
      <input
        className="bg-white border border-gray-500 rounded-md text-md outline-none focus:border-yellow-300 py-1.5 pl-9"
        value={inputValue}
        placeholder={"Search all"}
        onChange={(event) => handleChange(event)}
      />
      <BiSearchAlt2
        className={`absolute top-2 left-2 text-2xl text-gray-400  ${
          !!inputValue && "text-gray-600"
        }`}
      />
    </div>
  );
}
