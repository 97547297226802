export const formatStripeNumber = (number) => {
  if (number) {
    let format = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return `${format.format(parseFloat(number) / 100)}`;
  } else {
    return "$0.00";
  }
};
