export const defaultEstimateSettings = {
  businessAddress: true,
  businessEmail: true,
  businessName: true,
  businessPhone: true,
  customerAddress: true,
  customerEmail: true,
  customerPhone: true,
  customerDisplayName: true,
  estimateDate: true,
  estimateNumber: true,
  footerBusinessName: true,
  footerBusinessWebsite: false,
  lineItemsAmount: true,
  lineItemsDescription: true,
  lineItemsQuantity: true,
  lineItemsUnitPrice: true,
  subtotal: true,
  tax: true,
  logo: false,
};

export const defaultInvoiceSettings = {
  businessEmail: true,
  businessName: true,
  businessPhone: true,
  businessAddress: true,
  customerAddress: true,
  customerDisplayName: true,
  customerEmail: true,
  customerPhone: true,
  footerBusinessName: true,
  footerBusinessWebsite: false,
  invoiceNumber: true,
  lineItemsAmount: true,
  lineItemsDescription: true,
  lineItemsQuantity: true,
  lineItemsUnitPrice: true,
  logo: false,
  subtotal: true,
  tax: true,
  savePaymentMethod: false,
};
