import Menu from "@mui/material/Menu";
import { Tooltip } from "@mui/material";
import { useState } from "react";
// settings: {closeOnClick: bool}
export default function DropDownMenuTemplate({
  MenuItems,
  Icon,
  tooltip,
  settings,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [tooltipOpen, setTooltipOpen] = useState(false);

  // open menu
  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
    setTooltipOpen(false);
  };

  return (
    <div
      onMouseEnter={() => setTooltipOpen(true)}
      onMouseLeave={() => setTooltipOpen(false)}
      className={"mx-2"}
    >
      <Tooltip open={tooltipOpen} title={open ? "" : tooltip} arrow>
        {/* col icon button */}
        <button
          onClick={handleButtonClick}
          className={
            "text-gray-50 font-bold px-1 shadow-md flex flex-row rounded-full h-10 w-10 items-center justify-center hover:bg-gray-700"
          }
        >
          {Icon}
        </button>
      </Tooltip>
      {/* menu items */}
      <Menu
        onClick={() => {
          if (settings?.closeOnClick) handleClose();
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {MenuItems}
      </Menu>
    </div>
  );
}
