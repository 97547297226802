import React, { useState, useEffect, useContext, useMemo } from "react";
import { FaSave } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../../index";
import { serverTimestamp } from "firebase/firestore";
import { utcToZonedTime } from "date-fns-tz";
import CircularProgress from "@mui/material/CircularProgress";
import { uploadFile } from "utils/uploadFile";
import CustomerModalCombined from "components/CustomerModalCombined/CustomerModalCombined";
import SubDetailsPopOverModal from "features/ServiceSubscription/components/SubDetails/SubDetailsPopOverModal";
import SearchCustomer from "./components/SearchCustomer";
import AddLineItem from "./components/AddLineItem";
import SelectDate from "./components/SelectDate";
import AddNote from "./components/AddNote";
import LeadSource from "./components/LeadSource";
import DispatchTo from "./components/DispatchTo";
import FutureVisits from "./components/FutureVisits";
import { generateCanViewIdsAndDispatchedToIds, uploadImages } from "./data/utils";
import { addSingleJob, addAllRecurringJobs, fetchSubscriptions } from "./data/api";
import useFetch from "../../hooks/useFetch";
import { useEmployeeContext } from "../../context/EmployeeContext";
import { PageHeader } from "../../layouts/PageHeader";
import { SolidButton } from "../../components/NewButtons/SolidButton";
import CenteredMaxWidthLayout from "../../layouts/CenteredMaxWidthLayout";
import { estimateCopiedToJob } from "../Estimate/data/api";

export default function AddJob() {
  const { userData, bannerVisible } = useContext(UserContext);

  const navigate = useNavigate();
  const location = useLocation();
  // get customer state from location if it exists
  const customerState = location.state?.customer;
  const subscriptionState = location?.state?.subscription;
  const jobDetails = location?.state?.jobDetails;

  useEffect(() => {
    if (subscriptionState) {
      pushVisitToLineItems(subscriptionState.selectedVisit);
    }
  }, [subscriptionState]);

  // this is the modal for adding a new customer
  const [modal, setModal] = useState(false);
  const timeZone = userData?.bizData?.timeZone;
  const nowInZone = utcToZonedTime(new Date(), timeZone);
  const today = new Date(
    nowInZone.getFullYear(),
    nowInZone.getMonth(),
    nowInZone.getDate(),
    9,
    0,
    0,
    0
  );
  const todayPlusTwoHours = new Date(today.getTime() + 2 * 60 * 60 * 1000); // adds 2 hours
  const [selectedCustomer, setSelectedCustomer] = useState(customerState || false);
  const defaultTaxRate = userData?.bizData?.defaultTaxRate || null;
  const [lineItems, setLineItems] = useState(
    jobDetails?.lineItems || [
      {
        name: "",
        description: "",
        quantity: 1,
        unitPrice: "0.00",
        taxRate: defaultTaxRate, // { name: "Tax Rate", rate: "5"}
      },
    ]
  );
  const [jobTotal, setJobTotal] = useState(0);
  const [start, setStart] = useState(today);
  const [end, setEnd] = useState(todayPlusTwoHours);
  const [dispatchedTo, setDispatchedTo] = useState([]);
  const [leadSource, setLeadSource] = useState(jobDetails?.leadSource || "");
  const [note, setNote] = useState(jobDetails?.note || "");
  const [loading, setLoading] = useState(false);
  const [recurrenceData, setRecurrenceData] = useState({
    label: "Does not repeat",
    type: null,
    rawDate: null,
    dayOfWeek: null,
    dayOfMonth: null,
    weekOfMonth: null,
    dayOfYear: null,
    endDate: null,
    occurrences: null,
    custom: false,
  });
  const [images, setImages] = useState([]);
  const [subscription, setSubscription] = useState(subscriptionState || null); // New state for subscription -- it's an object that contains selectedSubscription, subscriptions, and selectedVisit
  const [subDetailsModal, setSubDetailsModal] = useState(false);

  const { employees: employeeList } = useEmployeeContext();

  useEffect(() => {
    console.log("leadSource", leadSource);
  }, [leadSource]);

  // Preparing arguments for the fetch function
  const fetchSubscriptionsArgs = useMemo(
    () => ({
      businessId: userData.userData.businessId,
      customerId: selectedCustomer?.customerId,
      subscriptionState: subscriptionState,
    }),
    [selectedCustomer?.customerId, userData.userData.businessId, subscriptionState]
  );
  // Using useFetch to fetch subscription data
  const {
    data: subscriptionInfo,
    isLoading: subscriptionLoading,
    error: subscriptionError,
  } = useFetch(fetchSubscriptions, fetchSubscriptionsArgs);

  // Effect to update subscription state on fetched data change
  useEffect(() => {
    if (subscriptionInfo) {
      setSubscription(subscriptionInfo);
    } else {
      setSubscription(null); // Handle cases where no subscription data is returned
    }
  }, [subscriptionInfo]);

  async function handleAddJob() {
    if (loading) return;
    if (!selectedCustomer || lineItems.length === 0) {
      alert("Please select a customer and add at least one line item");
      return;
    }
    setLoading(true);

    const { dispatchedToIdsOnly, canViewIdsOnly } = generateCanViewIdsAndDispatchedToIds({
      dispatchedTo,
      leadSource,
      userData,
    });

    const isRecurring = recurrenceData.label === "Does not repeat" ? false : true;

    const newJobData = {
      dateAdded: serverTimestamp(),
      lastUpdated: serverTimestamp(),
      businessId: userData.userData.businessId,
      customer: selectedCustomer,
      lineItems: lineItems,
      jobTotal: jobTotal,
      start: start,
      end: end,
      dispatchedTo: dispatchedTo,
      dispatchedToIdsOnly: dispatchedToIdsOnly,
      canViewIdsOnly: canViewIdsOnly,
      createdById: userData.userData.id,
      leadSource: leadSource,
      note: note,
      timezone: userData.bizData.timeZone,
      isRecurring: isRecurring,
      estimateId: jobDetails && jobDetails.estimateId ? jobDetails.estimateId : null,
    };

    let uploadedImageUrls = [];
    if (images.length > 0) {
      uploadedImageUrls = await uploadImages({ images, uploadFile });
      newJobData.images = uploadedImageUrls;
    }

    try {
      const newJobId = isRecurring
        ? await addAllRecurringJobs({
            userData,
            newJobData,
            subscription,
            selectedCustomer,
            recurrenceData,
          })
        : await addSingleJob({
            userData,
            newJobData,
            subscription,
            selectedCustomer,
          });

      if (!!jobDetails) {
        estimateCopiedToJob({ userData, estimateId: jobDetails.estimateId, jobId: newJobId });
      }

      navigate(`/jobdetails/${newJobId}`, {
        state: { jobId: newJobId },
      });
    } catch (err) {
      console.error("Error", err);
      alert("Error adding job");
    } finally {
      setLoading(false);
    }
  }

  const toggleModal = () => {
    setModal(!modal);
  };

  const pushVisitToLineItems = (visit) => {
    if (!visit) return;
    setLineItems((prev) => {
      if (prev?.length > 1) {
        return [
          ...prev,
          {
            name: visit.name,
            quantity: 1,
            unitPrice: "0.00",
            taxRate: null,
            description: subscription?.selectedSubscription?.template?.title || "",
          },
        ];
      } else {
        return [
          {
            name: visit.name,
            quantity: 1,
            unitPrice: "0.00",
            taxRate: null,
            description: subscription?.selectedSubscription?.template?.title || "",
          },
        ];
      }
    });
  };

  return (
    <>
      <div
        className={`bg-gray-50 `}
        style={{
          height: bannerVisible ? "calc(100vh - 60px - 60px)" : "calc(100vh - 64px) ",
          overflowY: "auto",
        }}
      >
        <CenteredMaxWidthLayout>
          <PageHeader title="New Job">
            <SolidButton text="Save" loading={loading} onClick={handleAddJob} icon={<FaSave />} />
          </PageHeader>
          <div className="flex flex-col flex-1 px-4 pb-4 mx-10 lg:mx-1 lg:grid lg:grid-cols-5 lg:gap-8">
            <div className="lg:col-span-2">
              <SearchCustomer
                toggleModal={toggleModal}
                selected={selectedCustomer}
                setSelected={setSelectedCustomer}
              />
              {subscription && (
                <FutureVisits
                  subscription={subscription}
                  setSubscription={setSubscription}
                  openSubscriptionDetailsModal={() => setSubDetailsModal(true)}
                  pushVisitToLineItems={pushVisitToLineItems}
                />
              )}
              <SelectDate
                jobStart={start}
                setJobStart={setStart}
                jobEnd={end}
                setJobEnd={setEnd}
                recurrenceData={recurrenceData}
                setRecurrenceData={setRecurrenceData}
              />
              <DispatchTo employeeList={employeeList} setDispatchedTo={setDispatchedTo} />
              <LeadSource
                setLeadSource={setLeadSource}
                employeeList={employeeList}
                leadSource={leadSource}
                salesperson={
                  leadSource?.salesPerson
                    ? leadSource?.salesPerson
                    : userData.userData.userType === "Salesperson2" ||
                      userData.userData.userType === "Salesperson"
                    ? userData.userData
                    : null
                }
              />
              <div className="mt-8">
                <AddNote note={note} setNote={setNote} images={images} setImages={setImages} />
              </div>
            </div>

            <div className="lg:col-span-3 relative">
              <AddLineItem
                setLineItems={setLineItems}
                lineItems={lineItems}
                setJobTotal={setJobTotal}
                taxRates={userData?.bizData?.taxRates || [] || []}
                defaultTaxRate={defaultTaxRate}
                // lineItemsUnsavable={jobDetails?.lineItems?.length > 0}
              />
            </div>
          </div>
        </CenteredMaxWidthLayout>
      </div>

      {modal && (
        <CustomerModalCombined
          modal={modal}
          setModal={setModal}
          setSelectedCustomer={setSelectedCustomer}
        />
      )}

      {subscription?.selectedSubscription && (
        <SubDetailsPopOverModal
          open={subDetailsModal}
          onClose={() => setSubDetailsModal(false)}
          subscription={subscription?.selectedSubscription}
          setSubscription={(newSubscription) => {
            return setSubscription((prev) => ({
              ...prev,
              selectedSubscription: newSubscription,
            }));
          }}
        />
      )}
    </>
  );
}
